import { Grid, Stack, Typography, useTheme as useThemeMUI } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountingEmissionsBarsCell } from "./AccountingEmissionsBarsCell";

interface AccountingEmissionsBarsProps {
  emissionsDetails: {
    label: string;
    percentage: number;
    value: number;
  }[];
}

export const AccountingEmissionsBars: React.FC<AccountingEmissionsBarsProps> = (props) => {
  const { emissionsDetails } = props;
  const { t } = useTranslation();
  const [firstColumn, setFirstColumn] = useState([]);
  const [secondColumn, setSecondColumn] = useState([]);
  const theme = useThemeMUI();

  useEffect(() => {
    const middle = Math.round(emissionsDetails.length / 2);
    emissionsDetails.sort((first, second) =>
      first.label < second.label ? -1 : first.label > second.label ? 1 : 0
    );
    const firstGroupOfElements = [...emissionsDetails].splice(0, middle);
    setFirstColumn(firstGroupOfElements);
    const secondGroupOfElements = [...emissionsDetails].splice(middle);
    setSecondColumn(secondGroupOfElements);
  }, [emissionsDetails]);

  return (
    <Stack>
      <Typography fontSize={20} color={theme.palette.text.secondary} paddingBottom={2}>
        {t("accountingPage.emissions")}
      </Typography>

      <Grid container>
        <Grid item xs={6}>
          {firstColumn?.map((emissionDetail, index) => (
            <AccountingEmissionsBarsCell
              key={index}
              index={index}
              emissionDetail={emissionDetail}
            />
          ))}
        </Grid>
        <Grid item xs={6}>
          {secondColumn?.map((emissionDetail, index) => (
            <AccountingEmissionsBarsCell
              key={index}
              index={index}
              emissionDetail={emissionDetail}
            />
          ))}
        </Grid>
      </Grid>
    </Stack>
  );
};
