interface ScrollOverlayProps {
  children?: JSX.Element | JSX.Element[];
  isScrollDown?: boolean;
}

export const ScrollOverlay: React.FC<ScrollOverlayProps> = (props) => {
  const { children, isScrollDown } = props;
  return (
    <div
      style={{
        background: `transparent linear-gradient(0deg, #F3F7F8 0%, #F3F7F8A7 63%, #F3F7F800 100%) 0% 0% no-repeat padding-box`,
        position: "fixed",
        display: "flex",
        width: "40vw",
        justifyContent: "center",
        top: isScrollDown ? "unset" : "13vh",
        bottom: isScrollDown ? "14vh" : "unset",
        zIndex: 1000,
        height: "40px",
      }}
    >
      {children}
    </div>
  );
};
