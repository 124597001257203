import {
  Box,
  IconButton,
  Menu as MuiMenu,
  Stack,
  styled,
  TabProps,
  Typography,
  useTheme as useThemeMUI,
} from "@mui/material";
import { Menu, MenuItems } from "@smartb/g2-components";
import { useCallback, useState } from "react";

import { ArrowBottom, ArrowTop, AxessLogo, User, UserSelected } from "../icons";
import { BlackRiverLogo } from "../icons/BlackRiverLogo";
import { SlideMenu } from "../SlideMenu";
import { useAxessAuth } from "auth";

const StyledMuiMenu = styled(MuiMenu)(({ theme }) => ({
  marginTop: "10px",
  "& .MuiPaper-root": {
    background: theme.palette.primary.main,
    color: "white",
    borderRadius: "10px",
  },
  "& .MuiList-root": {
    padding: "0",
  },
  "& .MuiListItemIcon-root": {
    minWidth: "unset",
  },
}));

interface AppBarContentProps {
  tabs: TabProps[];
  currentTabIndex: number;
  onChangeTab: (tabIndex: number) => void;
  currentUser?: {
    firstName: string;
    lastName: string;
  };
  userMenu: MenuItems[];
  isAdminBar?: boolean;
}

export const AppBarContent = (props: AppBarContentProps) => {
  const { userMenu, currentUser, tabs, currentTabIndex, onChangeTab, isAdminBar } = props;

  const themeMUI = useThemeMUI();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { service } = useAxessAuth();

  const getLogo = useCallback(() => {
    let resultLogo = <></>;

    //@ts-ignore
    switch (themeMUI.themeName) {
      case "axess":
        resultLogo = <AxessLogo height={"60px"} style={{marginRight: isAdminBar ? "7vw" : "0vw"}} />;
        break;
      case "congoCarbon":
        resultLogo = <BlackRiverLogo height={"60px"} width={"200px"} style={{marginRight: isAdminBar ? "0vw" : "5vw"}} />;
        break;
      default:
        break;
    }

    return resultLogo;
  }, [isAdminBar]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);
  return (
    <>
      <div>{getLogo()}</div>
      <Box
        sx={{
          display: "flex",
          marginLeft: (service.is_polluter() || service.is_investor()) ? '0' : "5vw",
          justifyContent: "center",
          alignSelf: "flex-end",
          marginRight: isAdminBar ? "5vw" : "0vw"
        }}
      >
        <SlideMenu
          tabs={tabs}
          value={currentTabIndex}
          onChangeValue={onChangeTab}
          variant="title"
        />
      </Box>
      <Stack
        direction="row"
        sx={{
          "& .appBarIcon": {
            width: "25px",
            height: "25px",
          },
          "& .appBarArrow": {
            width: "12px",
            height: "12px",
            marginLeft: "10px",
          },
        }}
        spacing={1}
      >
        <IconButton
          sx={{ color: "white" }}
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
          disableRipple
        >
          {Boolean(anchorEl) ? (
            <>
              <UserSelected className="appBarIcon" />
              <ArrowTop className="appBarArrow" />
            </>
          ) : (
            <>
              <User className="appBarIcon" />
              <ArrowBottom className="appBarArrow" />
            </>
          )}
        </IconButton>
        <StyledMuiMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={close}>
          {currentUser && (
            <Typography
              sx={{ padding: "15px 30px 10px 20px" }}
              variant="body2"
            >{`${currentUser.firstName} ${currentUser.lastName}`}</Typography>
          )}
          <Menu
            sx={{ color: "white", "& .MuiListItemButton-root": { color: "white" } }}
            menu={userMenu}
          />
        </StyledMuiMenu>
      </Stack>
    </>
  );
};
