import { useAppRouter } from "App/routes";
import { useAdminAppRouter } from "AdminApp/routes";
import { Route } from "react-router";
import AdminApp from "../AdminApp";
import App from "../App";
import { Routes } from "react-router-dom";
import { useAxessAuth } from "../auth";
import { useEffect } from "react";
import withConnect from "./withConnect";

interface BaseRouterProps {
  initStore: (token?: string) => void;
}

const BaseRouter = (props: BaseRouterProps) => {
  const { initStore } = props;
  const appRouter = useAppRouter();
  const adminAppRouter = useAdminAppRouter();
  const { keycloak } = useAxessAuth();

  if (!keycloak.authenticated) {
    keycloak.authenticated = keycloak.idToken != null;
  }

  useEffect(() => {
    if (keycloak.authenticated) {
      initStore(keycloak.token);
    }
  }, []);

  return (
    <Routes>
      <Route key={"admin"} path="/admin" element={<AdminApp />}>
        {adminAppRouter}
      </Route>
      <Route key={"guest"} path="*" element={<App />}>
        {appRouter}
      </Route>
    </Routes>
  );
};

export default withConnect(BaseRouter);
