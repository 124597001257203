import { SdgDTO, ProjectDTO } from "datahub";

export const ProjectWithSDGHelper = (projectsArray: ProjectDTO[]) => {
  if (!projectsArray) return [];
  return projectsArray.map((project) => {
    const trace: number[] = [];
    const sdgList: SdgDTO[] = [];
    project.protocols.forEach((protocol) =>
      protocol.details.sdgList.forEach((sdg) => {
        if (!trace.includes(sdg.id)) {
          trace.push(sdg.id);
          sdgList.push(sdg);
        }
      })
    );
    return { ...project, sdgList: sdgList };
  });
};
