import { connect } from "react-redux";
import { refs } from "store/refs";
import { State } from "store";

const mapStateToProps = (state: State) => ({
  powerBiToken: refs.selectors.powerBiToken(state),
});

const mapDispatchToProps = {
  setPowerBiToken: refs.actions.setPowerBiToken,
};

export default connect(mapStateToProps, mapDispatchToProps);
