import Timeline from "@mui/lab/Timeline";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ProtocolStepsDTO, ProtocolTaskDTO } from "datahub";
import { useMemo, useState } from "react";
import { ConeOfUncertaintyTimelineItem } from "./ConeOfUncertaintyTimelineItem";

type ConeOfUncertaintyCellProps = {
  item: ProtocolStepsDTO;
};

const ConeOfUncertaintyCellBase = (props: ConeOfUncertaintyCellProps) => {
  const cell = props.item;
  const [showAllTasks, setShowAllTasks] = useState(false);

  const toggleTasksDisplay = () => {
    setShowAllTasks(!showAllTasks);
  };

  const showAllTasksTimelineCells = useMemo(
    () =>
      cell?.tasks?.map((task: ProtocolTaskDTO, index: number) => {
        if (index === cell?.tasks.length - 1 && index !== 0) {
          return (
            <ConeOfUncertaintyTimelineItem
              toggleTasksDisplay={toggleTasksDisplay}
              tasks={cell?.tasks}
              taskIndex={index}
              task={task}
              isLastItem
            />
          );
        }
        return (
          <ConeOfUncertaintyTimelineItem
            toggleTasksDisplay={toggleTasksDisplay}
            tasks={cell?.tasks}
            taskIndex={index}
            task={task}
          />
        );
      }),
    [cell.tasks, showAllTasks]
  );

  const buildTimeLineCells = useMemo(() => {
    if (showAllTasks) {
      return showAllTasksTimelineCells;
    } else {
      const firstTask = cell?.tasks[0];
      return (
        <ConeOfUncertaintyTimelineItem
          toggleTasksDisplay={toggleTasksDisplay}
          tasks={cell?.tasks}
          taskIndex={0}
          task={firstTask}
          hasTasksBelow={cell?.tasks?.length > 1}
          isFirstItem
        />
      );
    }
  }, [cell.tasks, showAllTasks]);

  return (
    <div>
      <Typography
        variant="h6"
        sx={{
          paddingLeft: "25px",
          fontSize: "19px",
          opacity: cell.tasks.some((task) => task.isValidated) ? "1" : "0.3",
        }}
      >
        {cell.name}
      </Typography>
      <div>
        <Timeline position="left"></Timeline>
        {buildTimeLineCells}
      </div>
    </div>
  );
};

export const ConeOfUncertaintyCell = styled(ConeOfUncertaintyCellBase)({
  border: "1px solid black",
});
