import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Tooltip } from "@smartb/g2";
import { OrganizationDTO } from "datahub";

import { Email, Location, Phone, Web } from "../icons";
import { AxessPaper, AxessPaperProps } from "../Paper";

export type StakeHolderSubProps = {
  stakeHolder: OrganizationDTO;
} & AxessPaperProps;

export const StakeHolderSub = (props: StakeHolderSubProps) => {
  const { stakeHolder, ...other } = props;

  const getStakeHolderTooltip = (name: string, title: string) => (
    <span>
      <strong>{name}</strong>
      <br />
      {title}
    </span>
  );

  return (
    <AxessPaper {...other}>
      <Stack
        direction="row"
        sx={{
          gap: "15px",
          alignItems: "strech",
        }}
      >
        <Box
          sx={{
            background: stakeHolder?.logo ? "white" : "#EDEDED",
            borderRadius: "12px",
            minWidth: "80px",
            maxWidth: "80px",
            minHeight: "80px",
            maxHeight: "80px",
            flex: 1,
          }}
        >
          {stakeHolder?.logo && (
            <img
              style={{ width: "100%", borderRadius: "8px", objectFit: "contain", height: "100%" }}
              src={stakeHolder?.logo}
            />
          )}
        </Box>
        <Stack sx={{ gap: "5px", overflow: "hidden" }}>
          <Tooltip
            //@ts-ignore
            helperText={
              // Cast to string is needed due to implementation glitch on @smartb/g2
              getStakeHolderTooltip(stakeHolder.name, stakeHolder.title) as unknown as string
            }
          >
            <Stack sx={{ gap: "5px" }}>
              <Typography
                sx={{
                  color: "primary.main",
                  fontWeight: 600,
                  whiteSpace: "noWrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                variant="body2"
              >
                {stakeHolder.name}
              </Typography>
              <Typography
                sx={{
                  color: "primary.main",
                  whiteSpace: "noWrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                variant="body2"
              >
                {stakeHolder.title}
              </Typography>
            </Stack>
          </Tooltip>
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              mt: "-4px",
              "& .contactIcon": {
                color: "text.secondary",
                width: "19px",
                height: "19px",
              },
              "& .MuiIconButton-root": {
                color: "primary.main",
              },
            }}
          >
            {stakeHolder.contact.address && (
              <Tooltip helperText={stakeHolder.contact.address}>
                <IconButton>
                  <Location className="contactIcon" />
                </IconButton>
              </Tooltip>
            )}
            {stakeHolder.contact.email && (
              <Tooltip helperText={stakeHolder.contact.email}>
                <IconButton href={`mailto:${stakeHolder.contact.email}`}>
                  <Email className="contactIcon" />
                </IconButton>
              </Tooltip>
            )}
            {stakeHolder.contact.phone && (
              <Tooltip helperText={stakeHolder.contact.phone}>
                <IconButton href={`tel:${stakeHolder.contact.phone}`}>
                  <Phone className="contactIcon" />
                </IconButton>
              </Tooltip>
            )}
            {stakeHolder.contact.website && (
              <Tooltip helperText={stakeHolder.contact.website}>
                <IconButton href={stakeHolder.contact.website} target="_blank">
                  <Web className="contactIcon" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Stack>
    </AxessPaper>
  );
};
