import { Stack } from "@mui/material";
import { useAxessAuth } from "auth";
import { OrderBooksTable, TransactionCreationValues } from "components";
import { OrderBookOverviewDTO, ProjectDTO, ProtocolDTO } from "datahub";
import { TransactionBuy } from "domain-components";
import { useCallback, useMemo, useState } from "react";

export interface CallsProps {
  orderBooks: OrderBookOverviewDTO[];
  project: ProjectDTO;
  onBuy: (values: TransactionCreationValues, orderBookId: string) => void;
  canBuy: boolean
}

export const Calls = (props: CallsProps) => {
  const { orderBooks, project, onBuy, canBuy } = props;
  const [buyingOrderbook, setbuyingOrderbook] =
    useState<OrderBookOverviewDTO | undefined>(undefined);

  const { keycloak } = useAxessAuth();

  const onOpenBuy = useCallback(
    (orderBook: OrderBookOverviewDTO) => {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        setbuyingOrderbook(orderBook);
      }
    },
    [keycloak.authenticated]
  );

  const onCloseBuy = useCallback(() => {
    setbuyingOrderbook(undefined);
  }, []);

  const callsDiplay = useMemo(() => {
    const oberbooksByProtocol: {
      [protocolId: string]: OrderBookOverviewDTO[];
    } = {};
    orderBooks.forEach((orderBook) => {
      const protocolId = orderBook.protocolId;
      if (!oberbooksByProtocol[protocolId]) {
        oberbooksByProtocol[protocolId] = [];
      }
      oberbooksByProtocol[protocolId].push(orderBook);
    });
    const calls: JSX.Element[] = [];
    for (const protocolId in oberbooksByProtocol) {
      const oberbooks = oberbooksByProtocol[protocolId];
      const protocol: ProtocolDTO = project.protocols.find(
        (protocol: ProtocolDTO) => protocol.id === protocolId
      );
      calls.push(
        <OrderBooksTable
          key={protocolId}
          orderBooks={oberbooks}
          protocolName={protocol.details.name}
          unit={protocol.type.unit}
          onBuy={onOpenBuy}
          showBuyButton={canBuy}
        />
      );
    }
    return calls;
  }, [orderBooks, project, onOpenBuy]);

  const onBuyMemoized = useCallback(
    async (values: TransactionCreationValues) => {
      onBuy(values, buyingOrderbook.id);
      onCloseBuy();
    },
    [onBuy, onCloseBuy, buyingOrderbook]
  );

  return (
    <>
      <Stack spacing={5}>{callsDiplay}</Stack>
      {buyingOrderbook && (
        <TransactionBuy
          project={project}
          onValidate={onBuyMemoized}
          open={!!buyingOrderbook}
          onClose={onCloseBuy}
          orderBook={buyingOrderbook}
        />
      )}
    </>
  );
};
