import { Box, Grid, Stack, Typography, useTheme as useThemeMUI } from "@mui/material";
import { useTheme } from "@smartb/g2-themes";
import { AxessPaper, PortfolioRegionsMap, PortfolioSectorsChart, SdgProgressBar } from "components";
import { PortfolioStatsDTO } from "datahub";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { groupBy } from 'utils'

function computeStats(stats: PortfolioStatsDTO[], getKeys: (stat: PortfolioStatsDTO) => string[]): {
  key: string,
  volume: number,
  percentage: number
}[] {
  let totalVolume = 0;
  const statPerKey = stats.flatMap(stat => {
    return getKeys(stat).map(key => [key, stat] as [string, PortfolioStatsDTO])
  })
  const groupedStats = groupBy(statPerKey, ([key]) => key, ([, stat]) => stat)

  return Array.from(groupedStats.entries())
    .map(([key, stats]) => {
      const volume = stats.reduce((sum, stat) => sum + stat.volume, 0)
      totalVolume += volume
      return { key, volume }
    }).map((stats) => ({
      ...stats,
      percentage: Math.round((stats.volume / totalVolume) * 100)
    }))
}

export interface DistributionProps {
  stats: PortfolioStatsDTO[];
}

export const Distribution = (props: DistributionProps) => {
  const { stats } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const themeMUI = useThemeMUI();

  const volumePercentBySdg = useMemo(() => {
    const sdgStats: number[] = []
    computeStats(stats, stat => stat.sdgs.map((sdg) => JSON.stringify(sdg.id)))
      .forEach(stat => sdgStats[parseInt(stat.key)] = stat.percentage)

    let displayLeft: JSX.Element[] = [];
    let displayRight: JSX.Element[] = [];

    for (let sdgId = 1; sdgId <= 17; sdgId++) {
      const percent = sdgStats[sdgId] ?? 0;
      if (sdgId <= 9) {
        displayLeft.push(
          <SdgProgressBar
            key={sdgId}
            percentProgress={percent}
            sdgId={sdgId}
            sx={{ width: "100%", minWidth: "200px" }}
          />
        );
      } else {
        displayRight.push(
          <SdgProgressBar
            key={sdgId}
            percentProgress={percent}
            sdgId={sdgId}
            sx={{ width: "100%", minWidth: "200px" }}
          />
        );
      }
    }

    return {
      displayLeft,
      displayRight,
    };
  }, [stats]);

  const volumePercentBySector = useMemo(() => {
    const sectorStats: number[] = []
    computeStats(stats, stat => [`${stat.sector?.id}`])
      .forEach(stat => sectorStats[parseInt(stat.key) - 1] = stat.percentage)
      
    return <PortfolioSectorsChart diameter={270} data={sectorStats} />;
  }, [stats]);

  const volumePercentByRegion = useMemo(() => {
    const regionsMapData = computeStats(stats, stat => [stat.region])
      .map(stat => ({ region: stat.key, percentage: stat.percentage }))
    return (
      <PortfolioRegionsMap
        data={regionsMapData}
        transparent
        style={{ height: "350px", width: "auto" }}
      />
    );
  }, [stats]);

  return (
    <Stack spacing={3}>
      <Typography variant="h5">{t("distribution")}</Typography>
      <Stack spacing={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} xl={5}>
            <AxessPaper sx={{ width: "100%", height: { sm: "auto", md: "400px" } }} variant="big">
              <Typography sx={{ color: theme.colors.tertiary, marginBottom: "12px" }} variant="h6">
                {t("portfolioPage.Sectors")}
              </Typography>
              <Stack marginTop="18px">{volumePercentBySector}</Stack>
            </AxessPaper>
          </Grid>
          <Grid item xs={12} lg={6} xl={7}>
            <AxessPaper
              sx={{ width: "100%", height: "400px", background: themeMUI.palette.mapBgColor.main }}
              variant="big"
            >
              <Typography sx={{ color: themeMUI.palette.text.mapFont, marginBottom: "12px" }} variant="h6">
                <Box
                  component="span"
                  sx={{
                    background: themeMUI.palette.mapBgColor.main,
                    padding: "0.5em",
                    borderRadius: "0.5em",
                    position: "relative",
                  }}
                >
                  {t("portfolioPage.Regions")}
                </Box>
              </Typography>
              <Box sx={{ marginTop: "-55px", overflow: "hidden" }}>{volumePercentByRegion}</Box>
            </AxessPaper>
          </Grid>
        </Grid>

        <AxessPaper sx={{ width: "100%", paddingRight: "15px !important" }} variant="big">
          <Typography sx={{ color: theme.colors.tertiary, marginBottom: "12px" }} variant="h6">
            SDGs
          </Typography>
          <Stack direction="row" flexWrap="wrap">
            <Stack spacing={1} flex={1} marginTop="8px" marginRight="20px">
              {volumePercentBySdg.displayLeft}
            </Stack>
            <Stack spacing={1} flex={1} marginTop="8px" marginRight="20px">
              {volumePercentBySdg.displayRight}
            </Stack>
          </Stack>
        </AxessPaper>
      </Stack>
    </Stack>
  );
};
