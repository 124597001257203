import { Stack, Typography } from "@mui/material";
import { AxessToggleButtons } from "components";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@smartb/g2-themes";

interface PositionsProps {
  groupedStats: {
    projectTablesDisplay: JSX.Element[];
    sectorTablesDisplay: JSX.Element[];
    protocolTablesDisplay: JSX.Element[];
  };
  isMarketplace?: boolean;
}

export const Positions = (props: PositionsProps) => {
  const { groupedStats, isMarketplace } = props;

  const [displayMode, setDisplayMode] = useState<string>("project");
  const { t } = useTranslation();
  const theme = useTheme();

  const options = useMemo(
    () => [
      {
        key: "project",
        content: "Project",
      },
      {
        key: "protocol",
        content: "Protocol",
      },
      {
        key: "sector",
        content: "Sector",
      },
    ],
    []
  );

  const onChangeDisplayMode = useCallback((_, key: string | number) => {
    setDisplayMode(key as string);
  }, []);

  return (
    <Stack spacing={3}>
      {!isMarketplace && <Typography variant="h5">{t("positions")}</Typography>}
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{t("portfolioPage.displayBy")}</Typography>
        <AxessToggleButtons
          sx={{
            "& .MuiToggleButtonGroup-grouped": {
              backgroundColor: "white",
            },
          }}
          value={displayMode}
          exclusive
          onChangeExclusive={onChangeDisplayMode}
          options={options}
        />
      </Stack>
      {displayMode === "sector" ? (
        <>
          <Typography sx={{ color: theme.colors.tertiary }}>
            {t("portfolioPage.SectorsNumber", {
              number: groupedStats.sectorTablesDisplay.length,
            })}
          </Typography>
          {groupedStats.sectorTablesDisplay}
        </>
      ) : displayMode === "protocol" ? (
        <>
          <Typography sx={{ color: theme.colors.tertiary }}>
            {t("portfolioPage.ProtocolsNumber", {
              number: groupedStats.protocolTablesDisplay.length,
            })}
          </Typography>
          {groupedStats.protocolTablesDisplay}
        </>
      ) : (
        <>
          <Typography sx={{ color: theme.colors.tertiary }}>
            {t("portfolioPage.ProjectsNumber", {
              number: groupedStats.projectTablesDisplay.length,
            })}
          </Typography>
          {groupedStats.projectTablesDisplay}
        </>
      )}
    </Stack>
  );
};
