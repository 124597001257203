import { Box, Stack } from "@mui/material";
import { useAxessAuth } from "auth";
import { Loading, TransactionCreationValues } from "components";
import { buy, getPublishedOrderBookOverviews, OrderBookOverviewDTO, ProjectRefDTO } from "datahub";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAsyncResponse } from "utils";
import { OrderBookWithActions } from "./components/OrderBookWithActions";
import { Button } from "@smartb/g2-components";
import { Link, LinkProps } from "react-router-dom";

interface ProjectMarketProps {
  setTitle: (title: string) => void;
  projectRefs: Map<string, ProjectRefDTO>
}

export const ProjectMarket = (props: ProjectMarketProps) => {
  const { setTitle, projectRefs } = props;
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();

  const { keycloak, service } = useAxessAuth();

  useEffect(() => {
    setTitle(t("projectMarket"));
  }, [setTitle, t]);

  const getOrderBooksMemoized = useCallback(
    () => getPublishedOrderBookOverviews(t, [projectId]),
    [projectId, t]
  );

  const orderBooksRequest = useAsyncResponse(getOrderBooksMemoized);

  const onBuy = useCallback(
    async (values: TransactionCreationValues, orderBook: OrderBookOverviewDTO) => {
      
      await buy(
        //@ts-ignore
        {
          emitter: service.getUser().id,
          id: orderBook.id,
          price: Number(values.price),
          volume: Number(values.volume),
        },
        t,
        keycloak.token
      );
      orderBooksRequest.execute();
    },
    [service.getUser, orderBooksRequest.execute, t, keycloak.token]
  );

  const projectRef = useMemo(() => projectRefs.get(projectId), [projectId, projectRefs]);

  const orderBooks = useMemo(
    () =>
      orderBooksRequest.result &&
      orderBooksRequest.result.map((orderBook) => {
        return (
          <OrderBookWithActions
            onBuy={(values) => onBuy(values, orderBook)}
            key={orderBook.id}
            orderBook={orderBook}
            project={projectRef}
          />
        );
      }),
    [orderBooksRequest.result, onBuy, projectRef]
  );

  return (
    <Box
      sx={{
        padding: "15px 10px",
      }}
    >
      <Button<LinkProps> component={Link} componentProps={{ to: `/project/${projectId}/details` }}>
        {t("projectDetails")}
      </Button>
      {orderBooksRequest.status === "SUCCESS" ? (
        <Stack direction="row" flexWrap="wrap">
          {orderBooks}
        </Stack>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
