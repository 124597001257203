import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  DataLabeled,
  OrderBookType,
  TransactionCreationPopUp,
  TransactionCreationValues,
} from "components";
import { OrderBookOverviewDTO, ProjectDTO, ProjectRefDTO } from "datahub";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface TransactionSellProps {
  orderBook: OrderBookOverviewDTO;
  ownedQuantity: number;
  project: ProjectDTO | ProjectRefDTO;
  open: boolean;
  onClose: () => void;
  onValidate: (values: TransactionCreationValues) => void;
}

export const TransactionSell = (props: TransactionSellProps) => {
  const { orderBook, onClose, onValidate, open, ownedQuantity, project } = props;
  const { t } = useTranslation();
  const date = useMemo(() => {
    const date = new Date(orderBook.startDate);
    return date.toLocaleDateString();
  }, [orderBook.startDate]);
  return (
    <TransactionCreationPopUp
      unit={orderBook.unit}
      price={orderBook.price}
      maxVolume={ownedQuantity}
      open={open}
      onClose={onClose}
      onValidate={onValidate}
      actionLabel={t("sell")}
    >
      <Stack
        sx={{
          gap: "15px",
        }}
        divider={<Divider sx={{ borderColor: "#E5EAEB" }} />}
      >
        <Typography variant="h5">{t("order")}</Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            gap: "15px",
          }}
        >
          <Stack
            sx={{
              gap: "10px",
            }}
          >
            <DataLabeled label={t("project") + " :"} data={project.details.name} />
            <DataLabeled
              label={t("status") + " :"}
              data={<OrderBookType type={orderBook.type.value} />}
            />
          </Stack>
          <Stack
            sx={{
              gap: "10px",
            }}
          >
            <DataLabeled label={t("protocol") + " :"} data={orderBook.unit.notation} />
            <DataLabeled label={t("date") + " :"} data={date} />
          </Stack>
        </Stack>
        <Box />
      </Stack>
    </TransactionCreationPopUp>
  );
};
