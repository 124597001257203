import {
  getMeasures,
  getProjectRefs,
  getProtocolTypes,
  getPublishedOrderBookOverviews,
  getUserProjectRights,
  getUserRefs,
} from "datahub";
import { Dispatch } from "redux";
import { State } from "store";
import { refs } from "../refs";

export const initStore = (token?: string) => {
  //@ts-ignore
  return async (dispatch: Dispatch, getState: () => State) => {
    if (token) {
      getUserProjectRights(token).then((userProjectRights) => {
        if (userProjectRights) {
          dispatch(refs.actions.setUserProjectRights(userProjectRights));
        }
      });
    }
    getMeasures().then((measures) => {
      if (measures) {
        dispatch(refs.actions.setMeasures(measures));
      }
    });
    getProtocolTypes().then((protocolTypes) => {
      if (protocolTypes) {
        dispatch(refs.actions.setProtocolTypes(protocolTypes));
      }
    });
    getProjectRefs().then((projectRefs) => {
      if (projectRefs) {
        dispatch(refs.actions.setProjectRefs(projectRefs));
      }
    });
    getUserRefs().then((userRefs) => {
      if (userRefs) {
        dispatch(refs.actions.setUserRefs(userRefs));
      }
    });
    getPublishedOrderBookOverviews().then((orderBookOverviews) => {
      if (orderBookOverviews) {
        dispatch(refs.actions.setOrderBookOverviews(orderBookOverviews));
      }
    });
  };
};
