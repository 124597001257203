import { TransactionCreationValues } from 'components';
import { burn, buy, OrderBookOverviewDTO, PortfolioGetStatsQueryResultDTO, sell } from 'datahub';
import { useCallback, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useAxessAuth } from 'web-app/src/auth';
import { parse } from "qs";
import { AsyncResponse, typeParam } from "utils";

export interface ActionsForOrderbookProps {
  orderBookOverviews: Map<string, OrderBookOverviewDTO>;
  portfolioStatsRequest: AsyncResponse<PortfolioGetStatsQueryResultDTO>;
}

const executeRequest = (execute: (params?: any) => Promise<void>) => {
  const params = parse(window.location.search, { ignoreQueryPrefix: true });
  execute({
    ...params,
    projects: typeParam(params.projects, "array"),
    units: typeParam(params.units, "array"),
    sectors: typeParam(params.sectors, "array"),
    protocols: typeParam(params.protocols, "array"),
  });
};

export default function useActionsForOrderbook(props: ActionsForOrderbookProps) {
  const { orderBookOverviews, portfolioStatsRequest } = props;
  const { service, keycloak } = useAxessAuth();
  const { t } = useTranslation();

  const [actionState, setActionState] = useState<{
    action?: "buy" | "sell" | "burn";
    orderBook?: OrderBookOverviewDTO;
    ownedQuantity?: number;
  }>({
    action: undefined,
    orderBook: undefined,
    ownedQuantity: undefined,
  });

  const onOpenAction = useCallback(
    (action: "sell" | "buy" | "burn", orderBookId: string, volume: number) => {
      setActionState({
        action,
        orderBook: orderBookOverviews.get(orderBookId),
        ownedQuantity: volume,
      });
    },
    [orderBookOverviews]
  );
  
  const onCloseAction = useCallback(() => {
    setActionState({
      action: undefined,
      orderBook: undefined,
      ownedQuantity: undefined,
    });
  }, []);

  const onBuy = useCallback(
    async (values: TransactionCreationValues) => {
      await buy(
        {
          emitter: service.getUser().id,
          id: actionState.orderBook.id,
          price: Number(values.price),
          volume: Number(values.volume),
          deadline: values.validity.getTime(),
        },
        t,
        keycloak.token
      );
      executeRequest(portfolioStatsRequest.execute);
      onCloseAction();
    },
    [
      service.getUser,
      portfolioStatsRequest.execute,
      t,
      keycloak.token,
      actionState.orderBook,
      onCloseAction,
    ]
  );

  const onSell = useCallback(
    async (values: TransactionCreationValues) => {
      await sell(
        {
          emitter: service.getUser().id,
          id: actionState.orderBook.id,
          price: Number(values.price),
          volume: Number(values.volume),
          deadline: values.validity.getTime(),
        },
        t,
        keycloak.token
      );
      executeRequest(portfolioStatsRequest.execute);
      onCloseAction();
    },
    [
      service.getUser,
      portfolioStatsRequest.execute,
      t,
      keycloak.token,
      actionState.orderBook,
      onCloseAction,
    ]
  );

  const onBurn = useCallback(
    async (values: TransactionCreationValues) => {
      await burn(
        //@ts-ignore
        {
          emitter: service.getUser().id,
          id: actionState.orderBook.id,
          volume: Number(values.volume),
        },
        t,
        keycloak.token
      );
      executeRequest(portfolioStatsRequest.execute);
      onCloseAction();
    },
    [
      service.getUser,
      portfolioStatsRequest.execute,
      t,
      keycloak.token,
      actionState.orderBook,
      onCloseAction,
    ]
  );

  return { actionState, onOpenAction, onCloseAction, onBuy, onSell, onBurn};
}