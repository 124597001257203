import { alpha } from '@mui/material';
import { ActiveElement, Chart } from "chart.js";
import { de, enGB, es, fr, pt } from "date-fns/locale";
import sub from "date-fns/sub";
import { TFunction } from "react-i18next";
import { muiTheme } from 'web-app/src/Themes';

interface TooltipItemContext {
  // The chart the tooltip is being shown on
  chart: Chart;
  // Label for the tooltip
  label: string;
  // Parsed data values for the given `dataIndex` and `datasetIndex`
  parsed: any;
  // Raw data values for the given `dataIndex` and `datasetIndex`
  raw: any;
  // Formatted value for the tooltip
  formattedValue: string;
  // The dataset the item comes from
  dataset: any;
  // Index of the dataset the item comes from
  datasetIndex: number;
  // Index of this data item in the dataset
  dataIndex: number;
  // The chart element (point, arc, bar, etc.) for this tooltip item
  element: Element;
}

const getDateLocale = (currentLanguage: string) =>
  ({
    en: enGB,
    de: de,
    fr: fr,
    es: es,
    pt: pt,
  }[currentLanguage] || enGB);

export const chartColors = {
  //@ts-ignore
  primary: muiTheme.palette.chartColorPrimary.main,
  //@ts-ignore
  secondary: muiTheme.palette.chartColorSecondary.main,
};

export const getChartActions = (t: TFunction<"translation", undefined>, lang: string) => [
  {
    key: 0,
    content: t("portfolioPage.evolutionButtons.day", { value: 1 }),
    scales: {
      x: {
        type: "time",
        min: sub(new Date(), { days: 1 }),
        time: {
          unit: "hour",
          displayFormats: {
            hour: "HH:MM",
          },
        },
        adapters: { date: { locale: getDateLocale(lang) } },
      },
    },
  },
  {
    key: 1,
    content: t("portfolioPage.evolutionButtons.week", { value: 1 }),
    scales: {
      x: {
        type: "time",
        min: sub(new Date(), { days: 7 }),
        time: { unit: "day" },
        adapters: { date: { locale: getDateLocale(lang) } },
      },
    },
  },
  {
    key: 2,
    content: t("portfolioPage.evolutionButtons.month", { value: 1 }),
    scales: {
      x: {
        type: "time",
        min: sub(new Date(), { months: 1 }),
        time: { unit: "day" },
        adapters: { date: { locale: getDateLocale(lang) } },
      },
    },
  },
  {
    key: 3,
    content: t("portfolioPage.evolutionButtons.month", { value: 6 }),
    scales: {
      x: {
        type: "time",
        min: sub(new Date(), { months: 6 }),
        time: { unit: "month" },
        adapters: { date: { locale: getDateLocale(lang) } },
      },
    },
  },
  {
    key: 4,
    content: t("portfolioPage.evolutionButtons.year", { value: 1 }),
    scales: {
      x: {
        type: "time",
        min: sub(new Date(), { years: 1 }),
        time: { unit: "month" },
        adapters: { date: { locale: getDateLocale(lang) } },
      },
    },
  },
  {
    key: 5,
    content: t("portfolioPage.evolutionButtons.all"),
    scales: {
      x: {
        type: "time",

        time: { unit: "year" },
        adapters: { date: { locale: getDateLocale(lang) } },
      },
    },
  },
];

export const getChartOptions = (
  t: TFunction<"translation", undefined>,
  lang: string,
  selectedScalesIndex: number
) => ({
  responsive: true,
  maintainAspectRatio: false,
  animation: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: getChartActions(t, lang)[selectedScalesIndex].scales,
  plugins: {
    legend: { display: false },
    tooltip: {
      displayColors: false,
      backgroundColor: "white",
      titleColor: chartColors.primary,
      bodyColor: chartColors.primary,
      footerColor: chartColors.primary,
      caretSize: 0,
      caretPadding: 10,
      borderColor: "rgba(0,0,0,0.2)",
      borderStyle: "solid",
      borderWidth: 1,
      callbacks: {
        title: () => "", // Do not display the date
        label: (context: TooltipItemContext) => {
          return context.parsed.y === null
            ? ""
            : new Intl.NumberFormat(lang, { style: "currency", currency: "USD" }).format(
                context.parsed.y
              );
        },
      },
    },
    tooltipVerticalLine: { display: true },
  },
});

export const createGradient = (ctx: CanvasRenderingContext2D) => {
  let gradient = ctx.createLinearGradient(0, 0, 0, 150);
  //@ts-ignore
  gradient.addColorStop(0, alpha(muiTheme.palette.chartColorPrimary.main, 0.7));
  //@ts-ignore
  gradient.addColorStop(0.4, alpha(muiTheme.palette.chartColorPrimary.main, 0.6));
  //@ts-ignore
  gradient.addColorStop(0.5, alpha(muiTheme.palette.chartColorPrimary.main, 0.5));
  //@ts-ignore
  gradient.addColorStop(0.6, alpha(muiTheme.palette.chartColorPrimary.main, 0.4));
  //@ts-ignore
  gradient.addColorStop(1, alpha(muiTheme.palette.chartColorPrimary.main, 0.1));
  return gradient;
};

export const tooltipVerticalLine = {
  id: "tooltipVerticalLine",
  beforeDraw: (chart: Chart) => {
    //@ts-ignore
    if (!chart.options.plugins.tooltipVerticalLine.display) {
      return;
    }
    const activeElements: ActiveElement[] = chart?.tooltip?.getActiveElements();
    if (activeElements && activeElements.length) {
      const { ctx } = chart;
      const [activePoint] = activeElements;
      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([6, 7]);
      ctx.moveTo(activePoint.element.x, chart.chartArea.top);
      ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = chartColors.primary;
      ctx.stroke();
      ctx.restore();
    }
  },
};
