import React, { useCallback, useEffect, useState } from "react";
import { IReportEmbedConfiguration, models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { getPowerBiToken } from "datahub/src/queries";
import { useDispatch } from "react-redux";
import { refs } from "../../../web-app/src/store/refs";
import "./PowerBiFrame.css";
import { Loading } from "../Loading";
import { isTokenExpired } from "utils/src/helpers/JWTHelper";

interface PowerBiIframeProps {
  link: string;
  powerBiToken: String;
  width?: number;
  height?: number;
  title?: string;
}

export interface PowerBiReport {
  id: string;
  embedUrl: string;
  name: string;
  webUrl: string;
  datasetId: string;
  reportType: string;
  isOwnedByMe: boolean;
}

export const PowerBiIframe: React.FC<PowerBiIframeProps> = (props) => {
  const { link, powerBiToken } = props;

  const [reportFetched, setReportFetched] = useState<PowerBiReport>();

  const [reportID] = useState(link.slice(link.indexOf("=") + 1, link.indexOf("&")));

  const dispatch = useDispatch();

  const reportClass = "report-container";

  const [sampleReportConfig, setReportConfig] = useState<IReportEmbedConfiguration>({
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Aad,
    accessToken: powerBiToken as string,
    settings: {
      panes: {
        filters: {
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  });

  const [eventHandlers] = useState(
    new Map([
      [
        "loaded",
        function () {
          console.log("Report loaded");
        },
      ],
      [
        "rendered",
        function () {
          console.log("Report rendered");
        },
      ],
      [
        "error",
        function (event) {
          console.log(event.detail);
        },
      ],
      ["visualClicked", () => console.log("visual clicked")],
      // Example custom event      ["pageChanged", (event) => console.log(event)],
    ])
  );

  useEffect(() => {
    if (!powerBiToken || isTokenExpired(powerBiToken as string)) {
      getPowerBiToken().then((powerBiToken) => {
        if (powerBiToken) {
          dispatch(refs.actions.setPowerBiToken(powerBiToken));
        }
      });
    }
  }, []);

  const handleErrors = (error: any) => {
    console.log(error);
    getPowerBiToken().then((powerBiToken) => {
      if (powerBiToken) {
        dispatch(refs.actions.setPowerBiToken(powerBiToken));
      }
    });
  };
  const PowerBIReportRequest = useCallback(() => {
    const powerBIAPIRoot = "https://api.powerbi.com/v1.0/myorg/";

    if (reportID.length < 1) {
      return;
    }

    return fetch(powerBIAPIRoot + "Reports/" + reportID, {
      headers: {
        Accept: "application/json;odata.metadata-minimal;",
        Authorization: "Bearer " + powerBiToken,
      },
    })
      .then((response) => response.json())
      .then((response) => setReportFetched(response as PowerBiReport))
      .catch((error) => handleErrors(error));
    // Need to fetch a new token and save on the store
  }, [powerBiToken, reportID]);

  useEffect(() => {
    PowerBIReportRequest();
  }, [link, powerBiToken]);

  useEffect(() => {
    if (reportFetched) {
      setReportConfig({
        ...sampleReportConfig,
        id: reportFetched.id,
        accessToken: powerBiToken,
        embedUrl: reportFetched.embedUrl,
      } as IReportEmbedConfiguration);
    }
  }, [reportFetched]);

  return link.length > 0 && reportFetched ? (
    <PowerBIEmbed
      embedConfig={sampleReportConfig}
      cssClassName={reportClass}
      eventHandlers={eventHandlers}
    />
  ) : (
    <Loading />
  );
};
