import { countryObject } from "components";
import { ProjectStatus } from "components/src/PlateformStatus";
import { Sdg } from "components/src/Sdg/Sdg";
import { ProjectDTO, ProjectRefDTO, ProjectStatusValues } from "datahub";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { distinct } from "../common";

export interface Opt {
  value: string | number;
  desc: string;
  imgComponent?: JSX.Element;
  chip?: JSX.Element;
}

export default function useFilterOptions(
  projectRefs?: ProjectRefDTO[],
  projectsDTO?: ProjectDTO[]
) {
  const { t } = useTranslation();

  const statusOptions = useMemo(() => {
    let statusOptions: Opt[] = [];

    statusOptions.push(
      {
        value: 0,
        desc: ProjectStatusValues.created(),
        chip: <ProjectStatus status={ProjectStatusValues.created()} />,
      },
      {
        value: 1,
        desc: ProjectStatusValues.published(),
        chip: <ProjectStatus status={ProjectStatusValues.published()} />,
      },
      {
        value: 2,
        desc: ProjectStatusValues.closed(),
        chip: <ProjectStatus status={ProjectStatusValues.closed()} />,
      }
    );

    return statusOptions;
  }, [projectRefs, projectsDTO]);

  const regionOptions = useMemo(() => {
    let regionsOptions: Opt[] = [];

    if (projectRefs) {
      projectRefs.forEach((projectRef) => {
        if (projectRef.country) {
          regionsOptions.push({
            value: projectRef.country,
            desc: countryObject[projectRef.country]?.name,
          });
        }
      });
    } else {
      projectsDTO?.forEach((project) => {
        if (project.land.country) {
          regionsOptions.push({
            value: project.land.country,
            desc: countryObject[project.land.country]?.name,
          });
        }
      });
    }

    return distinct(regionsOptions, (option) => option.value).sort((a, b) => {
      if (a.desc < b.desc) {
        return -1;
      }
      if (a.desc > b.desc) {
        return 1;
      }
      return 0;
    });
  }, [projectRefs, projectsDTO]);

  const sdgsOptions = useMemo(() => {
    let sdgsOptions: Opt[] = [];

    for (let i = 1; i < 18; i++) {
      sdgsOptions.push({
        value: i,
        desc: t(`sdg.${i}`) as string,
        imgComponent: <Sdg sdgId={i} noTooltip={true}></Sdg>,
      });
    }

    return sdgsOptions;
  }, [projectRefs, projectsDTO]);

  return {
    statusOptions: statusOptions,
    regionsOptions: regionOptions,
    sdgsOptions: sdgsOptions,
  };
}
