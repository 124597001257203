import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme as useThemeMUI,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useContext, useMemo, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { Opt } from "utils";
import { Close } from "../icons";
import { FilterContext } from "../FilterProjects";

export interface FilterInput {
  name: string;
  value: string | string[];
  placeholder?: string;
  label?: string;
  type?: "text" | "radio-group" | "email" | "password" | "select" | "checkbox";
  typeValue?: "string" | "boolean";
  selectProps?: { options?: Opt[]; multiple?: boolean };
}

export interface FiltersDrawerProps {
  open: boolean;
  resultCount?: string;
  drawerSide?: "right" | "left" | "top" | "bottom";
  onCloseDrawer: () => void;
  onSubmitFilters: () => void;
  onClearFilters: () => void;
}

export const FiltersDrawer = (props: FiltersDrawerProps) => {
  const { open, drawerSide, resultCount, onCloseDrawer, onSubmitFilters, onClearFilters } = props;
  const { filters, setFilters } = useContext(FilterContext);
  const themeMUI = useThemeMUI();
  const { t } = useTranslation();
  const [fieldsShowMoreActive, setFieldsShowMoreActive] = useState([]);

  const initialValues = useMemo(() => {
    let initialValues: { [key: string]: any } = {};

    for (const field of filters) {
      initialValues[field.name] = field.value;
    }

    return initialValues;
  }, [filters]);

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: () => {
      onSubmitFilters();
    },
    onReset: () => {
      onClearFilters();
      onSubmitFilters();
    },
  });

  const handleOnChange = (event, fieldName: string, fieldValue?: string) => {
    const filter = filters.find((elem) => elem.name == fieldName);

    if (fieldValue || Number(fieldValue) == 0) {
      const currentValues = filter?.value as string[];
      let newValues = [];

      if (currentValues?.includes(fieldValue)) {
        newValues = currentValues.filter((elem) => elem != fieldValue);
      } else {
        newValues = currentValues ? [...currentValues, fieldValue] : [fieldValue];
      }

      formik.setFieldValue(fieldName, newValues);

      filter.value = newValues;
    } else {
      formik.setFieldValue(fieldName, [...event.target.value]);
      filter.value = [...event.target.value];
    }

    setFilters(filters);
  };

  const toggleShowMore = (fieldName: string) => {
    const isActive = fieldsShowMoreActive.find((elem) => elem === fieldName);
    let result = [];
    if (isActive) {
      result = fieldsShowMoreActive.filter((elem) => elem !== fieldName);
    } else {
      result = [...fieldsShowMoreActive, fieldName];
    }

    setFieldsShowMoreActive(result);
  };

  const getFormField = useCallback(
    (field: FilterInput) => {
      let result = <></>;
      if (!field.label) {
        return;
      }
      const showMore = fieldsShowMoreActive.find((elem) => elem === field.name);
      const optionsList = showMore
        ? field?.selectProps.options
        : field.selectProps.options.slice(0, 5);

      switch (field.type) {
        case "select":
          result = (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={
                {
                  //color: theme.colors.tertiary,
                }
              }
              key={`field${field.label}`}
            >
              <FormControl
                style={{ width: "50%", marginBottom: "5%" }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      "& legend": {
                        width: (field.label.length * 8 + 10).toString() + "px",
                      },
                    },
                  },
                }}
              >
                <InputLabel>{field.label}</InputLabel>
                <Select
                  labelId={`"Selectlabel${field.name}`}
                  id={`"Selectlabel${field.name}`}
                  value={field.value}
                  onChange={(event) => {
                    handleOnChange(event, field.name);
                  }}
                  multiple={field.selectProps.multiple}
                  renderValue={(selected) =>
                    (selected as string[])
                      .map(
                        (elem) =>
                          field.selectProps.options.find((option) => option.value == elem).desc
                      )
                      .join(", ")
                  }
                >
                  {field.selectProps.options.map((option) => (
                    <MenuItem key={option.desc} value={option.value}>
                      <Checkbox checked={field?.value.includes(option.value as string)} />
                      {option.desc}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          );
          break;
        case "checkbox":
          result = (
            <Stack key={`field${field.label}`}>
              <FormControl style={{ width: "90%", marginBottom: "5%", display: "flex" }}>
                <Typography variant="h6">{field.label}</Typography>
                {optionsList?.map((option: Opt) => (
                  <FormControlLabel
                    key={option.desc}
                    control={
                      <>
                        <Checkbox
                          checked={field?.value?.includes(option.value as string)}
                          onChange={(event) => {
                            handleOnChange(event, field.name, option.value as string);
                          }}
                        />
                        {option.imgComponent}
                      </>
                    }
                    label={option.chip ? option.chip : option.desc}
                    sx={{
                      ".MuiTypography-body1": { marginLeft: option.imgComponent ? "3%" : "0px" },
                    }}
                  />
                ))}
                {field.selectProps.options.length > 5 && (
                  <Typography
                    onClick={() => {
                      toggleShowMore(field.name);
                    }}
                    sx={{ fontWeight: "bold", cursor: "pointer", display: "flex" }}
                  >
                    <span style={{ display: "flex" }}>
                      {showMore ? (
                        <KeyboardArrowUp style={{ display: "flex" }} />
                      ) : (
                        <KeyboardArrowDown style={{ display: "flex" }} />
                      )}
                      {showMore ? t("showLess") : t("showMore")}
                    </span>
                  </Typography>
                )}
              </FormControl>
            </Stack>
          );
          break;
      }
      return result;
    },
    [fieldsShowMoreActive, filters]
  );

  const actionBar = useMemo(() => {
    return (
      <Stack
        style={{
          position: "relative",
          width: "100%",
          marginTop: "5%",
          paddingBottom: "8%",
        }}
      >
        <Stack direction={"row"} justifyContent={"start"}>
          <Button
            variant="text"
            onClick={() => {
              formik.submitForm();
            }}
            sx={{
              marginRight: "20px",
              color: themeMUI.palette.text.filtersFont,
              backgroundColor: themeMUI.palette.filterButtonBgColor.main,
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: themeMUI.palette.filterButtonBgColor.secondary,
              },
            }}
          >
            {t("viewResults", { userCount: `${resultCount}` })}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              formik.resetForm();
            }}
            sx={{
              marginRight: "20px",
              color: themeMUI.palette.text.filtersFont,
              backgroundColor: themeMUI.palette.filterButtonBgColor.main,
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: themeMUI.palette.filterButtonBgColor.secondary,
              },
            }}
          >
            {t("clearAll")}
          </Button>
        </Stack>
      </Stack>
    );
  }, []);

  return (
    <Drawer
      anchor={drawerSide}
      open={open}
      onClose={onCloseDrawer}
      sx={{
        "& .MuiPaper-root": {
          padding: "20px 20px 0px 20px",
          width: "100%",
          maxWidth: "600px",
          background: "white",
          overflowY: "scroll",
        },
        "& .drawer-Form-form": {
          flexDirection: "column",
          alignItems: "flex-end",
        },
        "& .AruiForm-field": {
          width: "100%",
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        bgcolor={"white"}
      >
        <Typography variant="h6">{t("selectSearchCriteria")}</Typography>
        <IconButton onClick={onCloseDrawer}>
          <Close width={"15px"} color={"black"} />
        </IconButton>
      </Stack>
      <Divider light />
      <Box
        flexDirection={"column"}
        justifyContent={"start"}
        alignContent={"space-between "}
        mt={3}
        bgcolor={"white"}
      >
        <form onSubmit={formik.handleSubmit}>{filters.map((filter) => getFormField(filter))}</form>
      </Box>
      <Divider light />
      {actionBar}
    </Drawer>
  );
};

FiltersDrawer.defaultProps = {
  drawerSide: "right",
  resultCount: "",
};
