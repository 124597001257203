import { Box, CircularProgress, BoxProps } from "@mui/material";

export const Loading = (props: BoxProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        alignItems: "center",
        padding: "30px",
        boxSizing: "border-box",
      }}
      {...props}
    >
      <CircularProgress />
    </Box>
  );
};
