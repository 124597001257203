import { styled } from "@mui/material";
import {
  Agriculture,
  EnergyEfficiency,
  ForestryLandUse,
  HouseholdDevices,
  RenewableEnergy,
  WasteManagement,
  WaterManagement,
} from "../icons";
import { AxessChip, AxessChipProps } from "./AxessChip";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const sectorIcons = [
  <Agriculture />,
  <EnergyEfficiency />,
  <ForestryLandUse />,
  <HouseholdDevices />,
  <RenewableEnergy />,
  <WasteManagement />,
  <WaterManagement />,
];

export type SectorChipProps = AxessChipProps & {
  sector: number;
  noLabel?: boolean;
};

const SectorChipBase = (props: SectorChipProps) => {
  const { sector, noLabel, ...other } = props;
  const { t } = useTranslation();
  const Icon = useMemo(() => sectorIcons[sector - 1], [sector]);

  let chip = <></>;

  if(noLabel) {
    chip = <AxessChip icon={Icon} noLabel={noLabel} {...other} />
  }else {
    chip = <AxessChip label={t("sectors." + sector)} icon={Icon} {...other} />
  }

  return chip;
};

export const SectorChip = styled(SectorChipBase)(() => ({
  "& .MuiChip-icon": {
    width: "20px",
    height: "20px",
  },
}));
