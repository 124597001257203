import { Paper, Stack, styled } from "@mui/material";
import { OrderDTO } from "datahub";
import { DataTicket } from "../DataTicket";
import { CertificatIcon } from "../icons";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils";
import { useMemo } from "react";

const StyledPaper = styled(Paper)({
  "& .certificat-icon": {
    width: "40px",
    height: "40px",
  },
  padding: "5px 10px",
  maxWidth: "400px",
  background: "#FEFFC6",
  cursor: "pointer",
});

export interface CertificatProps {
  className?: string;
  order: OrderDTO;
  onClick?: () => void;
}

export const Certificat = (props: CertificatProps) => {
  const { className, order, onClick } = props;
  const { i18n, t } = useTranslation();

  const stringified = useMemo(() => {
    return {
      date: new Date(order.review.date).toLocaleString(i18n.language, {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      }),
      volume: formatNumber(order.volume, i18n.language),
    };
  }, [order, i18n.language]);

  return (
    <StyledPaper className={className} onClick={onClick}>
      <Stack direction="row" spacing={4} alignItems="center">
        <CertificatIcon className="certificat-icon" />
        <DataTicket data={stringified.volume} />
        <DataTicket label={t("burnedOn", { date: stringified.date })} />
      </Stack>
    </StyledPaper>
  );
};
