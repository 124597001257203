import { Stack, Typography, useTheme as useThemeMUI } from "@mui/material";
import React from "react";
import { ProgressBar } from "../ProgressBar";

interface AccountingEmissionsBarsCellProps {
  emissionDetail: {
    label: string;
    percentage: number;
    value: number;
  };
  index: number;
}

export const AccountingEmissionsBarsCell: React.FC<AccountingEmissionsBarsCellProps> = (props) => {
  const { emissionDetail, index } = props;
  const theme = useThemeMUI();
  return (
    <div>
      <Typography
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        color={theme.palette.text.secondary}
        fontSize={16}
      >
        {emissionDetail.label}
      </Typography>
      <Stack
        sx={{
          width: "95%",
        }}
      >
        <ProgressBar
          sx={{ width: "100%", minWidth: "200px" }}
          elementIndex={index}
          percentProgress={emissionDetail.percentage}
          value={emissionDetail.value}
          size="small"
        />
      </Stack>
      <p></p>
    </div>
  );
};
