import { org as orgAccounting } from "axess-accounting-api-domain";
import { org as orgMeasure } from "axess-measure-unit-api-domain";
import { org as orgorderbookApi } from "axess-orderbook-api-domain";
import { org as orgorderbookOver } from "axess-orderbook-overview-domain";
import { org as orgPorfolio } from "axess-portfolio-domain";
import { org } from "axess-project-api-domain";
import { org as orgProtocol } from "axess-protocol-api-domain";
import { org as orgProtocolType } from "axess-protocol-type-api-domain";
import { org as orgUserApi } from "axess-user-api-domain";
import { TFunction } from "react-i18next";
import { axessRequest } from "./axessRequest";
import { OrderBookOverviewDTO, ProjectDTO } from "./types";

const EMPTY = "{}";

export interface ProjectGetAllQueryDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetAllQueryDTO {}
export interface ProjectGetAllQueryResultDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetAllQueryResultDTO {}

export const getAllProject = async (
  command: ProjectGetAllQueryDTO,
  t: TFunction,
  token: string
) => {
  const response = await axessRequest<ProjectGetAllQueryResultDTO[]>(
    {
      url: "/getAllProjects",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.getAllProjects")
  );
  if (response) return response[0].projects;
};

export interface ProjectGetAllPublishedQueryDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetAllPublishedQueryDTO {}
export interface ProjectGetAllPublishedQueryResultDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetAllPublishedQueryResultDTO {}

export interface ProjectGetAllChildrenQueryDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetAllChildrenQueryDTO {}
export interface ProjectGetAllChildrenQueryResultDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetAllChildrenQueryResultDTO {}

export interface ProjectGetAllChildrenMappedQueryDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetAllChildrenMappedQueryDTO {}
export interface ProjectGetAllChildrenMappedQueryResultDTO
  extends org.axess.f2.project.api.domain.features.query
    .ProjectGetAllChildrenMappedQueryResultDTO {}

export const getAllPublishedProjects = async (
  t: TFunction,
  token: string,
  params?: ProjectGetAllPublishedQueryDTO
): Promise<ProjectDTO[] | undefined> => {
  const response = await axessRequest<ProjectGetAllPublishedQueryResultDTO[]>(
    {
      url: "/getAllPublishedProjects",
      body: JSON.stringify(params),
      jwt: token,
    },
    t,
    t("errorMessage.getAllPublishedProjects")
  );
  if (response) return response[0].projects;
};

export const getAllChildrenProjects = async (
  params: ProjectGetAllChildrenQueryDTO,
  t: TFunction,
  token: string
): Promise<ProjectDTO[] | undefined> => {
  const response = await axessRequest<ProjectGetAllChildrenQueryResultDTO[]>(
    {
      url: "/getAllChildrenProjects",
      body: JSON.stringify(params),
      jwt: token,
    },
    t,
    t("errorMessage.getAllChildrenProjects")
  );
  if (response) return response[0].projects;
};

export const getAllChildrenProjectsMapped = async (
  params: ProjectGetAllChildrenMappedQueryDTO,
  t: TFunction,
  token: string
): Promise<Map<string, ProjectDTO[]> | undefined> => {
  const response = await axessRequest<ProjectGetAllChildrenMappedQueryResultDTO[]>(
    {
      url: "/getAllChildrenProjectsMapped",
      body: JSON.stringify(params),
      jwt: token,
    },
    t,
    t("errorMessage.getAllChildrenProjects")
  );
  if (response) return response[0].projects;
};

export interface ProjectGetQueryDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetQueryDTO {}
export interface ProjectGetQueryResultDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetQueryResultDTO {}

export const getProject = async (projectId: string, t: TFunction, token?: string) => {
  const response = await axessRequest<ProjectGetQueryResultDTO[]>(
    {
      url: "/getProject",
      body: JSON.stringify({
        id: projectId,
      } as ProjectGetQueryDTO),
      jwt: token,
    },
    t,
    t("errorMessage.getProject")
  );
  if (response) return response[0].project;
};

export interface ProjectListSimilarQueryDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectListSimilarQueryDTO {}

export interface ProjectListSimilarResultDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectListSimilarResultDTO {}

export const listSimilarProjects = async (
  projectId: string,
  limit: number,
  projectIdsUserRights?: string[]
) => {
  const response = await axessRequest<ProjectListSimilarResultDTO[]>({
    url: "/projectListSimilar",
    body: JSON.stringify({ projectId, limit, projectIdsUserRights } as ProjectListSimilarQueryDTO),
  });
  if (response) return response[0].items;
};

export interface MeasureUnitGetAllQueryDTO
  extends orgMeasure.axess.f2.measure.unit.api.domain.features.query.MeasureUnitGetAllQueryDTO {}
export interface MeasureUnitGetAllQueryResultDTO
  extends orgMeasure.axess.f2.measure.unit.api.domain.features.query
    .MeasureUnitGetAllQueryResultDTO {}

export const getMeasures = async () => {
  const response = await axessRequest<MeasureUnitGetAllQueryResultDTO[]>({
    url: "/getAllMeasureUnits",
    body: EMPTY,
  });
  if (response) return response[0].units;
};

export interface OrderBookOverviewGetListQueryDTO
  extends orgorderbookOver.axess.f2.orderbook.overview.domain.features.query
    .OrderBookOverviewGetListQueryDTO {}
export interface OrderBookOverviewGetListQueryResultDTO
  extends orgorderbookOver.axess.f2.orderbook.overview.domain.features.query
    .OrderBookOverviewGetListQueryResultDTO {}

export const getOrderBookOverviews = async (t: TFunction, token: string, projectId?: string) => {
  const response = await axessRequest<OrderBookOverviewGetListQueryResultDTO[]>(
    {
      url: "/getOrderBookOverviews",
      body: JSON.stringify({
        projectId: projectId,
      } as OrderBookOverviewGetListQueryDTO),
      jwt: token,
    },
    t,
    t("errorMessage.getOrderBookOverviews")
  );
  if (response) return response[0].orderbooks;
};

export interface OrderBookOverviewGetPublishedListQueryDTO
  extends orgorderbookOver.axess.f2.orderbook.overview.domain.features.query
    .OrderBookOverviewGetPublishedListQueryDTO {}
export interface OrderBookOverviewGetPublishedListQueryResultDTO
  extends orgorderbookOver.axess.f2.orderbook.overview.domain.features.query
    .OrderBookOverviewGetPublishedListQueryResultDTO {}

export const getPublishedOrderBookOverviews = async (
  t?: TFunction,
  projectIds?: string[]
): Promise<OrderBookOverviewDTO[] | undefined> => {
  const response = await axessRequest<OrderBookOverviewGetPublishedListQueryResultDTO[]>(
    {
      url: "/getPublishedOrderBookOverviews",
      body: JSON.stringify({
        projectIds: projectIds,
      } as OrderBookOverviewGetPublishedListQueryDTO),
    },
    t,
    t ? t("errorMessage.getPublishedOrderBookOverviews") : undefined
  );
  if (response) return response[0].orderbooks;
};

export interface OrderGetAllQueryDTO
  extends orgorderbookApi.axess.f2.orderbook.api.domain.features.query.OrderGetAllQueryDTO {}
export interface OrderGetAllQueryResultDTO
  extends orgorderbookApi.axess.f2.orderbook.api.domain.features.query.OrderGetAllQueryResultDTO {}

export const getOrders = async (command: OrderGetAllQueryDTO, t: TFunction, token: string) => {
  const response = await axessRequest<OrderGetAllQueryResultDTO[]>(
    {
      url: "/getOrders",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.getOrders")
  );
  if (response) return response[0].orders;
};

export interface PortfolioGetStatsQueryDTO
  extends orgPorfolio.axess.f2.portfolio.domain.features.query.PortfolioGetStatsQueryDTO {}
export interface PortfolioGetStatsQueryResultDTO
  extends orgPorfolio.axess.f2.portfolio.domain.features.query.PortfolioGetStatsQueryResultDTO {}

export const getPortfolioStats = async (
  command: PortfolioGetStatsQueryDTO,
  t: TFunction,
  token: string
): Promise<PortfolioGetStatsQueryResultDTO> => {
  const response = await axessRequest<PortfolioGetStatsQueryResultDTO[]>(
    {
      url: "/getPortfolioStats",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.getPortfolioStats")
  );
  if (response) return response[0];
};

export interface ProjectGetRefsQueryDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetRefsQueryDTO {}
export interface ProjectGetRefsQueryResultDTO
  extends org.axess.f2.project.api.domain.features.query.ProjectGetRefsQueryResultDTO {}

export const getProjectRefs = async () => {
  const response = await axessRequest<ProjectGetRefsQueryResultDTO[]>({
    url: "/getProjectRefs",
    body: EMPTY,
  });
  if (response) return response[0].refs;
};

export interface UserGetRefsQueryDTO
  extends orgUserApi.axess.f2.user.api.domain.features.query.UserGetRefsQueryDTO {}
export interface UserGetRefsQueryResultDTO
  extends orgUserApi.axess.f2.user.api.domain.features.query.UserGetRefsQueryResultDTO {}

export const getUserRefs = async () => {
  const response = await axessRequest<UserGetRefsQueryResultDTO[]>({
    url: "/getUserRefs",
    body: EMPTY,
  });
  if (response) return response[0].users;
};

export interface UserProjectRightsGetAllQueryResultDTO
  extends orgUserApi.axess.f2.user.api.domain.features.query
    .UserProjectRightsGetAllQueryResultDTO {}
export const getUserProjectRights = async (token: string) => {
  const response = await axessRequest<UserProjectRightsGetAllQueryResultDTO[]>({
    body: EMPTY,
    url: "/getUserProjectRights",
    jwt: token,
  });
  if (response) return response[0].userRights;
};

export interface UserProfileGetQueryResultDTO
  extends orgUserApi.axess.f2.user.api.domain.features.query.UserProfileGetQueryResultDTO {}
export const getUserProfile = async (token: string) => {
  const response = await axessRequest<UserProfileGetQueryResultDTO>({
    body: EMPTY,
    url: "/getUserProfile",
    jwt: token,
  });
  if (response) return response[0].userProfile;
};

export interface UserPowerBIQueryResultDTO
  extends orgUserApi.axess.f2.user.api.domain.features.query.UserPowerBIQueryResultDTO {}
export const getPowerBiToken = async () => {
  const response = await axessRequest<UserPowerBIQueryResultDTO>({
    body: EMPTY,
    url: "/getPowerBiToken",
  });
  if (response) return response[0].token;
};

export interface ProtocolGetStepsQueryDTO
  extends orgProtocol.axess.f2.protocol.api.domain.features.query.ProtocolGetStepsQueryDTO {}
export interface ProtocolGetStepsQueryResultDTO
  extends orgProtocol.axess.f2.protocol.api.domain.features.query.ProtocolGetStepsQueryResultDTO {}

export interface ProtocolStepsDTO extends org.axess.f2.protocol.api.domain.model.ProtocolStepDTO {}
export interface ProtocolTaskDTO extends org.axess.f2.protocol.api.domain.model.ProtocolTaskDTO {}
export const getProtocolSteps = async (
  command: ProtocolGetStepsQueryDTO,
  t: TFunction,
  token: string
) => {
  const response = await axessRequest<ProtocolGetStepsQueryResultDTO[]>(
    {
      url: "/getProtocolSteps",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.getProtocolSteps")
  );
  if (response) return response[0].steps;
};

export interface ProtocolTypeGetQueryDTO
  extends orgProtocolType.axess.f2.protocol.type.api.domain.features.query
    .ProtocolTypeGetQueryDTO {}
export interface ProtocolTypeGetQueryResultDTO
  extends orgProtocolType.axess.f2.protocol.type.api.domain.features.query
    .ProtocolTypeGetQueryResultDTO {}

export interface ProtocolTypeGetAllQueryDTO
  extends orgProtocolType.axess.f2.protocol.type.api.domain.features.query
    .ProtocolTypeGetAllQueryDTO {}
export interface ProtocolTypeGetAllQueryResultDTO
  extends orgProtocolType.axess.f2.protocol.type.api.domain.features.query
    .ProtocolTypeGetAllQueryResultDTO {}

export const getProtocolTypes = async (t?: TFunction, token?: string) => {
  const response = await axessRequest<ProtocolTypeGetAllQueryResultDTO[]>(
    {
      url: "/getAllProtocolTypes",
      body: EMPTY,
      jwt: token,
    },
    t,
    t ? t("errorMessage.getProtocolTypes") : undefined
  );
  if (response) return response[0].types;
};

export const getProtocolType = async (
  command: ProtocolTypeGetQueryDTO,
  t: TFunction,
  token: string
) => {
  const response = await axessRequest<ProtocolTypeGetQueryResultDTO[]>(
    {
      url: "/getProtocolType",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.getProtocolType")
  );
  if (response) return response[0].type;
};

export interface AccountingGetQueryDTO
  extends orgAccounting.axess.f2.accounting.api.domain.features.query.AccountingGetQueryDTO {}
export interface AccountingGetQueryResultDTO
  extends orgAccounting.axess.f2.accounting.api.domain.features.query.AccountingGetQueryResultDTO {}
export interface AccountingDTO
  extends orgAccounting.axess.f2.accounting.api.domain.model.Accounting {}

export const getAccounting = async (
  command: AccountingGetQueryDTO,
  t: TFunction,
  token: string
) => {
  const response = await axessRequest<AccountingGetQueryResultDTO[]>(
    {
      url: "/getAccounting",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.getAccounting")
  );
  if (response) return response[0].accountings;
};
