import { Stack, Typography } from "@mui/material";
import { AxessPaper, PortfolioPositionsEvolutionChart } from "components";
import { TimeDataDTO } from "datahub";
import { useMemo } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface PerformanceProps {
  stats: TimeDataDTO[];
}

export const Performance: FC<PerformanceProps> = ({ stats }) => {
  const { t } = useTranslation();

  const positionsEvolutionsChart = useMemo(() => {
    const chartData = stats.map(({ time, value }) => ({ x: new Date(time), y: value }));
    return <PortfolioPositionsEvolutionChart data={chartData} />;
  }, [stats]);

  return (
    <Stack spacing={3}>
      <Typography variant="h5">{t("performance")}</Typography>
      <Stack direction="row" spacing={3} height="400px">
        <AxessPaper sx={{ flex: 1 }} variant="big">
          {positionsEvolutionsChart}
        </AxessPaper>
        {/* <AxessPaper sx={{ width: "400px" }} variant="big" /> */}
      </Stack>
    </Stack>
  );
};
