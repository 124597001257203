import { Box, Divider, Stack, styled, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { distinct } from "utils";
import { AxessPaper } from "../Paper";

export interface ProtocolsTicketProps {
  protocols: { name: string; image: string; typeName: string }[];
  className?: string;
}

const ProtocolsTicketBase = (props: ProtocolsTicketProps) => {
  const { protocols, className } = props;
  const { t } = useTranslation();
  const filteredProtocols = protocols.filter((protocol) => protocol.typeName !== "SDGs");
  const uniqueProtocols = distinct(filteredProtocols, (protocol) => protocol.name);
  const protocolCount = (protocolList: { name: string; image: string; typeName: string }[]) => {
    const protocolCounts = protocolList.reduce(
      (count, item) => ((count[item.name] = count[item.name] + 1 || 1), count),
      {}
    );
    return protocolCounts;
  };

  const protocolTypesDisplay = useMemo(
    () =>
      uniqueProtocols.map((protocol: { name: string; image: string }, index: number) => {
        return (
          <Stack
            key={index}
            direction="row"
            sx={{
              gap: "30px",
              alignItems: "center",
              margin: "15px 0",
            }}
          >
            <Box
              sx={{
                boxShadow: "0px 1px 6px #0000001A",
                borderRadius: "5px",
                padding: "5px",
                display: "flex",
                "& .unitImage": {
                  width: "auto",
                  height: "27px",
                },
              }}
            >
              {protocol.image && (
                <img
                  className="unitImage"
                  src={protocol.image}
                  alt={`image of the protocol: ${protocol.name}`}
                />
              )}
            </Box>
            <Typography variant="body2">
              {protocol.name}{" "}
              {protocolCount(filteredProtocols)[protocol.name] > 1
                ? `(${protocolCount(filteredProtocols)[protocol.name]})`
                : ""}
            </Typography>
          </Stack>
        );
      }),
    [protocols]
  );

  return (
    <AxessPaper variant="small" className={className}>
      <Typography sx={{ marginBottom: "5px" }} variant="h6">
        {t("protocols")}
      </Typography>
      <Stack alignItems="stretch" divider={<Divider />}>
        {protocolTypesDisplay}
      </Stack>
    </AxessPaper>
  );
};

export const ProtocolsTicket = styled(ProtocolsTicketBase)({
  padding: "25px 30px",
});
