import { Stack, Typography, TypographyProps, useTheme as useThemeMUI } from "@mui/material";
import { MergeMuiElementProps } from "@smartb/g2";
import { Option, Select, SelectProps } from "@smartb/g2-forms";
import React from "react";

export interface AxessLabeledSelectBasicProps {
  label?: string;
  typographyProps?: TypographyProps;
}

export type AxessOption = Option;

export type AxessLabeledSelectProps = MergeMuiElementProps<SelectProps, AxessLabeledSelectBasicProps>;

export const AxessLabeledSelect: React.FC<AxessLabeledSelectProps> = (
  props: AxessLabeledSelectProps
) => {
  const themeMUI = useThemeMUI();
  const { label, typographyProps, ...other } = props;
  return (
    <Stack direction="row" alignItems="center">
      {label && (
        <Typography
          {...typographyProps}
          sx={{
            marginRight: "1em",
            whiteSpace: "nowrap",
            ...typographyProps.sx,
          }}
        >
          {label}
        </Typography>
      )}
      {/* @ts-ignore */}
      <Select
        {...other}
        sx={{
          backgroundColor: themeMUI.palette.selectBackground.main + ' !important',
          color: themeMUI.palette.text.selectFont,
          "& .MuiSelect-icon": {
            fill: themeMUI.palette.selectIcon.main
          }
        }}
      />
    </Stack>
  );
};
