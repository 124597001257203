import { Stack, styled, Typography } from "@mui/material";
import { SectorDTO } from "datahub";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SectorChip } from "../Chip";
import { AxessPaper } from "../Paper";

export interface SectorTicketProps {
  sectors: SectorDTO[];
  className?: string;
}

const SectorTicketBase = (props: SectorTicketProps) => {
  const { sectors, className } = props;
  const { t } = useTranslation();

  const sectorsDisplay = useMemo(
    () =>
      sectors?.map((sector) => {
        return (
          <SectorChip
            sector={sector.id}
            key={sector.id}
            sx={{
              margin: "10px 0",
            }}
          />
        );
      }),
    [sectors]
  );

  return (
    <AxessPaper variant="small" className={className}>
      <Typography sx={{ marginBottom: "15px" }} variant="h6">
        {t("Sectors")}
      </Typography>
      <Stack direction="row" justifyContent="center">
        <Stack width="fit-content" alignItems="stretch">
          {sectorsDisplay}
        </Stack>
      </Stack>
    </AxessPaper>
  );
};

export const SectorTicket = styled(SectorTicketBase)({
  padding: "25px 30px",
});
