import { Box, Divider, Stack, Typography } from "@mui/material";
import { CellProps, Column, Row, Table } from "@smartb/g2-layout";
import { useTheme } from "@smartb/g2-themes";
import { OrderDTO, ProjectRefDTO } from "datahub";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils";
import { SectorChip } from "../Chip";
import { CopyToClipboardElement } from "../CopyToClipboardElement";
import { countryObject } from "../countryObject";
import { DataTicket } from "../DataTicket";
import { ArrowTop, Location } from "../icons";
import { OrderBookType } from "../OderBookType";
import { OrderStatus } from "../PlateformStatus/OrderStatus";
import { SdgCard } from "../SdgCard";

export interface OrdersTableProps {
  protocolName?: string;
  orders: OrderDTO[];
  projectRefs: Map<string, ProjectRefDTO>;
  isMarketplace?: boolean;
}

export const OrdersTable = (props: OrdersTableProps) => {
  const { protocolName, orders, projectRefs, isMarketplace } = props;
  const [paginatedOrders, setPaginatedOrders] = useState(orders);
  const [pageNumber, setPageNumber] = useState(1);
  const theme = useTheme();
  
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const newOrders = [...orders].splice(0, 5);
    setPaginatedOrders(newOrders);
  }, [orders]);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
    const newOrders = [...orders].splice((page - 1) * 5, 5);
    setPaginatedOrders(newOrders);
  };

  const columns = useMemo((): Column<OrderDTO>[] => {
    const lng = i18n.language;
    return [
      {
        Header: t("project"),
        id: "project",
        Cell: ({ row }: CellProps<OrderDTO>) => {
          const project = projectRefs.get(row.original.projectId);
          return (
            <Stack spacing={0}>
              <Typography variant="body2">{project?.details.name}</Typography>

              <CopyToClipboardElement stringToCopy={project?.id}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.tableSecondary",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  ID {project?.id}
                </Typography>
              </CopyToClipboardElement>
            </Stack>
          );
        },
      },
      {
        Header: t("date"),
        id: "date",
        Cell: ({ row }: CellProps<OrderDTO>) => (
          <Typography sx={{ color: "text.secondary" }}>
            {new Date(row.original.creationDate).toLocaleDateString()}
          </Typography>
        ),
      },
      {
        Header: t("status"),
        id: "status",
        Cell: ({ row }: CellProps<OrderDTO>) => (
          <OrderBookType type={row.original.orderbookType.value} />
        ),
        className: "dataColumn",
      },
      ...(isMarketplace
        ? []
        : [
            {
              Header: t("purchaseprice"),
              id: "purchaseprice",
              Cell: ({ row }: CellProps<OrderDTO>) => (
                <Typography>{formatNumber(row.original.price, lng, 2, true)}</Typography>
              ),
              className: "dataColumn",
            },
          ]),

      {
        Header: t("quantity"),
        id: "quantity",
        Cell: ({ row }: CellProps<OrderDTO>) => (
          <Typography>
            {formatNumber(row.original.volume, lng) + ` ${row.original.unit.notation}`}
          </Typography>
        ),
        className: "dataColumn",
      },
      {
        Header: t("price"),
        id: "price",
        Cell: ({ row }: CellProps<OrderDTO>) => (
          <Typography>
            {formatNumber(row.original.price, lng, 2, true) + ` / ${row.original.unit.notation}`}
          </Typography>
        ),
        className: "dataColumn",
      },
      {
        Header: t("total"),
        id: "total",
        Cell: ({ row }: CellProps<OrderDTO>) => (
          <Typography>
            {formatNumber(row.original.volume * row.original.price, lng, 2, true)}
          </Typography>
        ),
        className: "dataColumn",
      },
      {
        Header: t("paymentStatus"),
        id: "paymentStatus",
        Cell: ({ row }: CellProps<OrderDTO>) => <OrderStatus status={row.original.status.value} />,
        className: "dataColumn",
      },
    ];
  }, [t, i18n.language, projectRefs, isMarketplace]);

  const renderSubRows = useCallback(
    (row: Row<OrderDTO>) => {
      const project = projectRefs.get(row.original.projectId);
      if (!project) return <></>;
      return (
        <Box padding="0px 12px" paddingRight="37px">
          <Divider sx={{ borderColor: "#E5EAEB" }} />
          <Stack
            direction="row"
            sx={{
              gap: "50px",
              paddingTop: "15px",
              paddingBottom: "25px",
            }}
            flexWrap="wrap"
          >
            <DataTicket
              minified
              label={t("location")}
              data={
                <Stack
                  direction="row"
                  sx={{
                    "& .locationIcon": {
                      width: "17px",
                      height: "17px",
                    },
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Location className="locationIcon" />
                  <Typography variant="body2">{countryObject[project.country]?.name}</Typography>
                </Stack>
              }
            />
            <DataTicket minified label={t("protocol")} data={row.original.unit.name} />
            <DataTicket
              minified
              label={t("Sectors")}
              data={
                <Stack spacing={1} alignItems="flex-start">
                  {project?.sectors.map((sector) => (
                    <SectorChip key={sector?.id} sector={sector?.id} />
                  ))}
                </Stack>
              }
            />
            <DataTicket
              minified
              label={t("sdgs")}
              data={<SdgCard aligned defaultSdgs={project.sdgList} size="small" />}
            />
            <DataTicket minified label={t("vintage")} data={"No"} />
            <DataTicket minified label={t("transactionId")} data={row.original.id} />
          </Stack>
        </Box>
      );
    },
    [projectRefs, t]
  );

  return (
    <Box
      sx={{
        "& .dataColumn": {
          textAlign: "center",
        },
        "& .AruiTable-tableRow": {
          padding: "0 20px",
          paddingRight: "5px",
          borderRadius: "12px !important",
          boxShadow: "none !important",
          cursor: "pointer",
          marginBottom: "15px !important",
        },
        "& .AruiTable-tableHead": {
          padding: "0 20px",
          paddingRight: "5px",
        },
        "& .AruiTable-tableHeaderCell": {
          color: theme.colors.tertiary,
        },
        "& .expandIcon": {
          color: theme.colors.primary,
          width: "12px",
          height: "12px",
        },
        "& .AruiTable-tableCell": {
          padding: "20px 12px !important",
        },
      }}
    >
      {protocolName && (
        <Typography sx={{ marginBottom: "40px" }} variant="h5">
          {protocolName}
        </Typography>
      )}
      <Table<OrderDTO>
        data={paginatedOrders}
        columns={columns}
        expandIconPosition="end"
        expandInElevatedRow
        renderSubComponent={renderSubRows}
        expandIcon={<ArrowTop className="expandIcon" />}
        toggleExpandOnRowClicked
        page={orders.length / 5 > 1 ? pageNumber : undefined}
        totalPages={orders.length / 5 > 1 ? Math.ceil(orders.length / 5) : 1}
        onPageChange={handlePageChange}
      />
    </Box>
  );
};
