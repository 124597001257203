import { Paper, PaperProps, styled } from "@mui/material";

export interface BluredPaperBaseProps extends PaperProps {
  className?: string;
  isNotBlured?: boolean;
  children?: React.ReactNode;
}

export const BluredPaperBase = (props: BluredPaperBaseProps) => {
  const { className, children, ...other } = props;
  return (
    <Paper elevation={!props.isNotBlured ? 0 : 1} className={className} {...other}>
      {children}
    </Paper>
  );
};

export const BluredPaper = styled(BluredPaperBase)((props) => ({
  borderRadius: "7px",
  backdropFilter: !props.isNotBlured ? "blur(5px)" : "",
  width: "fit-content",
  padding: "8px",
  background: !props.isNotBlured ? "rgba(0, 0, 0, 0.7)" : "",
  color: !props.isNotBlured ? "white" : "",
  cursor: !props.onClick ? "" : "pointer",
  "&:hover": {
    background: !props.isNotBlured && props.onClick ? "rgba(0, 0, 0, 0.8)" : "",
  },
  "& .annotationText": {
    color: !props.isNotBlured ? "#CBD9F4" : "",
  },
  "& .MuiSlider-root.Mui-disabled": {
    color: !props.isNotBlured ? "#e3e3e3" : "",
  },
}));
