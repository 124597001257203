import { Close } from "@mui/icons-material";
import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import { TimeRange } from "components";
import { AxessButton } from "components/src/Button/AxessButton";
import React from "react";
import { useTranslation } from "react-i18next";

interface FilterProps {
  closeFilters: Function;
  showFilters: boolean;
  submitFilters: Function;
  clearFilters: Function;
  selectedYears: number[];
  onSelectedYearsChange: Function;
}

export const Filters: React.FC<FilterProps> = (props) => {
  const {
    closeFilters,
    showFilters,
    submitFilters,
    clearFilters,
    selectedYears,
    onSelectedYearsChange,
  } = props;
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      open={showFilters}
      onClose={() => closeFilters()}
      sx={{
        "& .MuiPaper-root": {
          padding: "20px",
          width: "100%",
          maxWidth: "350px",
          background: "#F3F7F8",
        },
        "& .AruiForm-field": {
          width: "100%",
        },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{t("filters")}</Typography>
        <IconButton onClick={() => closeFilters()}>
          <Close />
        </IconButton>
      </Stack>
      <Stack
        sx={{
          height: "70vh",
        }}
      >
        <TimeRange selectedYearsRange={selectedYears} updateYearsRange={onSelectedYearsChange} />
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-end">
        <AxessButton className="mobileForm-validate" onClick={() => submitFilters()}>
          {t("apply")}
        </AxessButton>
        <AxessButton variant="text" onClick={() => clearFilters()}>
          {t("clear")}
        </AxessButton>
      </Stack>
    </Drawer>
  );
};
