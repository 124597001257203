import styled from "@emotion/styled";
import { Box, Divider, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { Loading, StakeHolderSub } from "components";
import { useTranslation } from "react-i18next";
import { Close, Waves } from "@mui/icons-material";
import { OrganizationDTO, UserProfileDTO } from "datahub";
import { useDispatch } from "react-redux";
import { getUserProfile } from "datahub/src/queries";
import { useEffect } from "react";
import { useAxessAuth } from "web-app/src/auth";

interface ProfileProps {
  open: boolean;
  onCloseDrawer: () => void;
  userProfile: UserProfileDTO;
  setUserProfile: (userProfile: UserProfileDTO) => void;
}

export const Profile = (props: ProfileProps) => {
  const { onCloseDrawer, open, userProfile, setUserProfile } = props;
  const { t } = useTranslation();
  const { keycloak } = useAxessAuth();
  const dispatch = useDispatch();

  const StyledClientCard = styled(StakeHolderSub)({
    width: "50% !important",
  });

  useEffect(() => {
    if (!userProfile) {
      getUserProfile(keycloak?.token).then((userProfile) => {
        dispatch(setUserProfile(userProfile));
      });
    }
  }, [keycloak?.token]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onCloseDrawer}
      sx={{
        "& .MuiPaper-root": {
          padding: "20px",
          width: "100%",
          maxWidth: "600px",
          background: "#F3F7F8",
        },
        "& .drawer-Form-form": {
          flexDirection: "column",
          alignItems: "flex-end",
        },
        "& .AruiForm-field": {
          width: "100%",
        },
      }}
    >
      {userProfile === undefined && <Loading />}
      {userProfile === null && (
        <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
          <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Typography variant="body2">{t("profileNotFound")}</Typography>
          </Stack>
        </Box>
      )}
      {userProfile && (
        <Box sx={{ display: "grid", gap: 6, ml: 3 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              <Waves />
              <Typography variant="h5">{userProfile?.title}</Typography>
            </Stack>
            <IconButton onClick={onCloseDrawer}>
              <Close />
            </IconButton>
          </Stack>
          <StyledClientCard
            key={userProfile?.name + userProfile?.title}
            stakeHolder={userProfile as OrganizationDTO}
          />
          <Box>
            <Typography sx={{ marginBottom: "20px" }} variant="h6">
              {t("description")}
            </Typography>
            <Typography sx={{ marginBottom: "80px" }} variant="body2">
              {userProfile?.userDescription}
            </Typography>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};
