import { Box, Button, Stack, Typography } from "@mui/material";
import { CellProps, Column, Table } from "@smartb/g2-layout";
import { MeasureUnitDTO, PortfolioStatsDTO } from "datahub";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils";

import { OrderBookType } from "..";
import { Burn, Buy, Close, Sell } from "../icons";
import { LowLevelMenu } from "../LowLevelMenu/LowLevelMenu";

export interface PortfolioStatsTableProps {
  stats: PortfolioStatsDTO[];
  situationnalColumns: Column<PortfolioStatsDTO>[];
  onBuy?: (stat: PortfolioStatsDTO) => void;
  onSell?: (stat: PortfolioStatsDTO) => void;
  onBurn?: (stat: PortfolioStatsDTO) => void;
  measures: Map<string, MeasureUnitDTO>;
  isMarketplace?: boolean;
  userCanChange: boolean
}

export const PortfolioStatsTable = (props: PortfolioStatsTableProps) => {
  const { situationnalColumns, stats, onBuy, onSell, onBurn, measures, userCanChange, isMarketplace } = props;
  const { t, i18n } = useTranslation();

  const totals = useMemo((): Partial<PortfolioStatsDTO> => {
    return stats.reduce(
      (acc, curr) => {
        return {
          currentValue: acc.currentValue + curr.currentValue,
          orderbookId: "",
          projectId: "",
          purchaseValue: acc.purchaseValue + curr.purchaseValue,
          sectors: [],
          type: {
            value: "",
          },
          unitId: curr.unitId,
          volume: acc.volume + curr.volume,
        };
      },
      { currentValue: 0, purchaseValue: 0, volume: 0 }
    );
  }, [stats]);

  const moreMenuOptions = useMemo(
    () => (stat: PortfolioStatsDTO) => {
      if(!userCanChange) return (<></>);
      const options = [
        {
          key: "buy",
          icon: <Buy color="#6bba28" />,
          label: t("buy"),
          goto: () => onBuy && onBuy(stat),
        },
        {
          key: "sell",
          icon: <Sell color="red" />,
          label: t("sell"),
          goto: () => onSell && onSell(stat),
        },

        ...(!isMarketplace
          ? [
              {
                key: "burn",
                icon: <Burn color="#ffba00" />,
                label: t("burn"),
                goto: () => onBurn && onBurn(stat),
              },
            ]
          : []),
      ];
      const buttonSize = 50;
      const fontSize = 15;
      const closeIconSize = 20;
      return (
        <>
          {options.map(({ key, icon, label, goto }) => (
            <Button key={key} onClick={goto} style={{ textTransform: "none" }}>
              <Stack spacing={0.6} width={buttonSize} height={buttonSize}>
                {icon}
                <Typography sx={{ color: "primary.main", fontSize }}>{label}</Typography>
              </Stack>
            </Button>
          ))}
          <Button>
            <Stack
              width={buttonSize}
              height={buttonSize}
              alignItems="center"
              justifyContent="center"
            >
              <Close color="#5d5d5d" width={closeIconSize} height={closeIconSize} />
            </Stack>
          </Button>
        </>
      );
    },
    [onBuy, onSell, onBurn, t, userCanChange]
  );

  const columns = useMemo((): Column<PortfolioStatsDTO>[] => {
    const lng = i18n.language;
    return [
      ...situationnalColumns,
      {
        Header: t("status"),
        id: "type",
        Cell: ({ row }) => <OrderBookType type={row.original.type.value} />,
        className: "dataColumn",
      },
      {
        Header: t("vintage"),
        id: "vintage",
        Cell: () => <Typography variant="body2">-</Typography>,
        className: "dataColumn",
      },
      ...(isMarketplace
        ? [
            {
              Header: t("price"),
              id: "price",
              Cell: ({ row }) => (
                <Typography variant="body2">
                  {row.original.purchaseValue !== 0
                    ? formatNumber(
                        Math.abs(row.original.purchaseValue / row.original.volume),
                        lng,
                        2,
                        true
                      )
                    : "-"}{" "}
                  / {measures.get(row.original.unitId)?.notation}
                </Typography>
              ),
              className: "dataColumn",
            },
          ]
        : [
            {
              Header: t("marketprice"),
              id: "marketprice",
              Cell: ({ row }) => (
                <Typography variant="body2">
                  {row.original.volume !== 0
                    ? formatNumber(
                        Math.abs(row.original.currentValue / row.original.volume),
                        lng,
                        2,
                        true
                      )
                    : "-"}
                </Typography>
              ),
              className: "dataColumn",
            },
            {
              Header: t("purchaseprice"),
              id: "purchaseprice",
              Cell: ({ row }: CellProps<PortfolioStatsDTO>) => (
                <Typography variant="body2">
                  {row.original.purchaseValue !== 0
                    ? formatNumber(
                        Math.abs(row.original.purchaseValue / row.original.volume),
                        lng,
                        2,
                        true
                      )
                    : "-"}
                </Typography>
              ),
              Footer: () => (
                <Typography variant="subtitle2">
                  {formatNumber(totals.currentValue / totals.volume, lng, 2, true)}
                </Typography>
              ),
              className: "dataColumn",
            },
          ]),
      {
        Header: t("gain"),
        id: "gain",
        Cell: ({ row }: CellProps<PortfolioStatsDTO>) => {
          const gain = getGain(row.original.purchaseValue, row.original.currentValue, lng);
          return row.original.currentValue > 0 ? (
            <Typography
              sx={{ color: gain.gain >= 0 ? "success.main" : "error.main" }}
              variant="body2"
            >{`${gain.gain >= 0 ? "+" : "-"} ${gain.formatted}%`}</Typography>
          ) : (
            "-"
          );
        },
        Footer: () => {
          const gain = getGain(totals.purchaseValue, totals.currentValue, lng);
          return (
            <Typography
              sx={{ color: gain.gain >= 0 ? "success.main" : "error.main" }}
              variant="subtitle2"
            >{`${gain.gain >= 0 ? "+" : "-"} ${gain.formatted}%`}</Typography>
          );
        },
        className: "dataColumn",
      },
      {
        Header: t("quantity"),
        id: "volume",
        Cell: ({ row }: CellProps<PortfolioStatsDTO>) => (
          <Typography variant="body2">{`${formatNumber(row.original.volume, lng)} ${
            measures.get(row.original.unitId)?.notation
          }`}</Typography>
        ),
        Footer: () => (
          <Typography variant="subtitle2">{`${formatNumber(totals.volume, lng)} ${
            measures.get(totals.unitId)?.notation
          }`}</Typography>
        ),
        className: "dataColumn",
      },
      {
        Header: t("total"),
        id: "currentValue",
        Cell: ({ row }: CellProps<PortfolioStatsDTO>) => (
          <Typography variant="body2">
            {row.original.currentValue !== 0
              ? formatNumber(row.original.currentValue, lng, 2, true)
              : "-"}
          </Typography>
        ),
        Footer: () => (
          <Typography variant="subtitle2">
            {formatNumber(totals.currentValue, lng, 2, true)}
          </Typography>
        ),
        className: "dataColumn",
      },
      ...(userCanChange ?
      [{
        id: "moreoptions",
        Cell: ({ row }: CellProps<PortfolioStatsDTO>) => (
          <LowLevelMenu menuStyle={{ padding: "0 1em", marginRight: "15px" }}>
            {moreMenuOptions(row.original)}
          </LowLevelMenu>
        ),
        className: "moreOptionsColumn",
      }]
      :[]),
    ];
  }, [situationnalColumns, t, totals, i18n.language, moreMenuOptions, measures, isMarketplace, userCanChange]);

  return (
    <Box
      sx={{
        "& .dataColumn": {
          textAlign: "center",
        },
        "& .moreOptionsColumn": {
          maxWidth: "auto",
        },
      }}
    >
      <Table<PortfolioStatsDTO> data={stats} columns={columns} variant="grounded" withFooter />
    </Box>
  );
};

//a function that take a purchase price and a total price and return the percent gain of the purchase price
export const getGain = (
  purchasePrice: number,
  totalPrice: number,
  lng: string
): { gain: number; formatted: string } => {
  const gain = ((totalPrice - purchasePrice) / purchasePrice) * 100;
  return {
    formatted: formatNumber(gain, lng),
    gain: gain,
  };
};
