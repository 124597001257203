import { Typography } from "@mui/material";
import { Transaction } from "./Transaction";
import { OrderDTO } from "datahub";

export interface CanceledTransactionProps {
  order: OrderDTO;
}

export const CanceledTransaction = (props: CanceledTransactionProps) => {
  const { order } = props;
  return (
    <Transaction order={order}>
      <Typography variant="body2">{order?.review?.comment}</Typography>
    </Transaction>
  );
};
