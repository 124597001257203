import { FileWithPath } from "@mantine/dropzone";
import { Box, Stack } from "@mui/material";
import { FsFile } from "datahub";
import { ImageDrop } from "../ImageDrop";
import { GalleryImage } from "./GalleryImage";

interface ImageAutomatedGalleriesProps {
  galleryImageFiles: FsFile[];
  imagesToSave: FileWithPath[];
  onImageDropUpdate: (imageFiles: FileWithPath[]) => void;
  onDeleteImage: (image: FsFile) => void;
}

export const ImageAutomatedGalleries: React.FC<ImageAutomatedGalleriesProps> = (props) => {
  const { galleryImageFiles, onImageDropUpdate, onDeleteImage, imagesToSave } = props;

  const handleImageFileDrop = (files: FileWithPath[]) => {
    onImageDropUpdate([...imagesToSave, ...files]);
  };

  const handleDeleteImage = (file: FileWithPath | FsFile, isAlreadySaved?: boolean) => {
    if (isAlreadySaved) {
      const iFile = file as FsFile;
      onDeleteImage(iFile);
    } else {
      const imgFile = file as FileWithPath;
      const updatedImagesAdded = [...imagesToSave].filter((img) => img.name !== imgFile.name);
      onImageDropUpdate(updatedImagesAdded);
    }
  };

  return (
    <Stack direction="row">
      {galleryImageFiles?.map((image, index) => (
        <Box key={index} sx={{ paddingRight: 2.5 }}>
          <GalleryImage
            imageUrl={image.url}
            onDeleteImage={() => handleDeleteImage(image, true)}
            fileIndex={index}
          />
        </Box>
      ))}
      {imagesToSave?.map((img, index) => (
        <Box key={index} sx={{ paddingRight: 2.5 }}>
          <GalleryImage
            imageUrl={URL.createObjectURL(img)}
            onDeleteImage={() => handleDeleteImage(img)}
            fileIndex={index}
          />
        </Box>
      ))}
      <ImageDrop onDrop={handleImageFileDrop} />
    </Stack>
  );
};
