import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { ArrowBottom } from "../icons";

export type Section = {
  key: string;
  title: string;
  typeName: string;
  SDG?: React.ReactNode;
  content: React.ReactNode;
};

export interface AxessAcordionProps {
  sections: Section[];
  className?: string;
  disabled?: boolean;
}

const AxessAcordionBase = (props: AxessAcordionProps) => {
  const { sections, className, disabled } = props;
  const [expanded, setExpanded] = React.useState<string | undefined>(undefined);
  const filteredSections = sections.filter((section) => section.typeName !== "SDGs");

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, newExpanded: boolean, sectionId: string) => {
      setExpanded(newExpanded ? sectionId : undefined);
    },
    []
  );

  const accordions = useMemo(() => {
    return filteredSections.map((section) => (
      <Accordion
        key={section.key}
        className={className}
        expanded={expanded === section.key}
        onChange={(event, newExpanded) => handleChange(event, newExpanded, section.key)}
        disabled={disabled}
      >
        <AccordionSummary
          sx={{
            "& .expanderIcon": {
              color: "#03323D80",
              width: "17px",
              height: "17px",
            },
            "& .MuiAccordionSummary-content": {
              margin: "25px 0",
            }
          }}
          expandIcon={!disabled && <ArrowBottom className="expanderIcon" />}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              gap: "25px",
            }}
          >
            {section.SDG && <>{section.SDG}</>}
            <Typography variant="h6">{section.title}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{section.content}</AccordionDetails>
      </Accordion>
    ));
  }, [handleChange, sections, className, expanded]);

  return <div>{accordions}</div>;
};

export const AxessAcordion = styled(AxessAcordionBase)({
  borderBottom: `1px solid #03323D1A`,
  boxShadow: "none",
  background: "transparent",
  borderRadius: "0 !important",
  "&:before": {
    display: "none",
  },
  "&:first-of-type": {
    borderTop: `1px solid #03323D1A`,
  }
});
