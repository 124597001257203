import {
  Form,
  FormAction,
  FormField,
  FormPartialField,
  Option,
  useFormWithPartialFields,
} from "@smartb/g2-forms";
import { MeasureUnitDTO, ProtocolDTO, ProtocolTypeDTO, SdgDTO } from "datahub";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProtocolValues } from "./ProtocolInfoCard";

export interface ProtocolEditInfoCardProps {
  protocol: ProtocolDTO;
  selectedSdgs?: SdgDTO[];
  onSubmit?: (values: ProtocolValues) => void;
  measures?: Map<string, MeasureUnitDTO>;
  protocolTypes?: Map<string, ProtocolTypeDTO>;
  onDelete?: (protocolDTO: Partial<ProtocolDTO>) => void;
  evaluate?: boolean;
  validate?: boolean;
  onCancel?: () => void;
  isEditing?: boolean;
}

export const ProtocolEditInfoCard = (props: ProtocolEditInfoCardProps) => {
  const {
    protocol,
    onSubmit,
    measures = new Map<string, MeasureUnitDTO>(),
    evaluate = false,
    validate = false,
    onCancel,
    protocolTypes = new Map<string, ProtocolTypeDTO>(),
    selectedSdgs = [],
    isEditing
  } = props;
  const { t } = useTranslation();
  const [sdgsSelected, setSdgsSelected] = useState(false);

  useEffect(() => {
    if(protocol?.type?.name === t("chooseTheSdgs")) {
      setSdgsSelected(true);
    }
  },[protocol?.type?.name])

  const sdgsOptions = useMemo(() => {
    const table: Option[] = [];
    //@ts-ignore
    const selectedIds = selectedSdgs.map((sdg) => sdg.id);
    for (let i = 1; i <= 17; i++) {
      if (!isEditing && selectedIds.includes(i)) continue;
      table.push({
        key: i,
        label: t(`sdg.${i}`) as string,
      });
    }
    return table;
  }, [t]);

  const protocolTypeOptions = useMemo(() => {
    const table: Option[] = [];
    const keyList = Array.from(protocolTypes.keys());
    keyList.forEach((key) => {
      table.push({
        key: protocolTypes.get(key).id,
        label: protocolTypes.get(key).name,
      });
    });
    return table;
  }, [t]);

  const sectorOptions = useMemo(() => {
    const table: Option[] = [];
    for (let i = 1; i <= 7; i++) {
      table.push({
        key: i,
        label: t(`sectors.${i}`) as string,
      });
    }
    return table;
  }, [t]);

  const partialFields = useMemo(
    (): FormPartialField[] => [
      {
        name: "name",
        defaultValue: protocol.details.name,
        validator: (value?: string) =>
          !value || value.trim() === ""
            ? (t("formError.protocolNameRequired") as string)
            : undefined,
      },
      {
        name: "type",
        defaultValue: protocol?.type?.id ?? "",
      },
      {
        name: "sdgList",
        defaultValue: protocol?.details?.sdgList?.map((elem) => elem.id) ?? [],
      },
      {
        name: "sector",
        defaultValue: protocol?.details?.sector?.id ?? undefined,
      },
      {
        name: "description",
        defaultValue: protocol.details.description,
        validator: (value?: string) =>
          !value || value.trim() === ""
            ? (t("formError.protocolDescriptionRequired") as string)
            : undefined,
      },
      {
        name: "unit",
        defaultValue:
          protocol?.type?.unit?.id ?? Array.from(measures.keys() as IterableIterator<string>)[0] ?? "",
      },
      {
        name: "availableQuantity",
        defaultValue: protocol.availableQuantity,
        ...(sdgsSelected
          ? {}
          : {
              validator: (value?: number | string) => {
                if (!value) return t("formError.theQuantityIsRequired") as string;
                const number = Number(value);
                if (number < 0) return t("formError.theQuantityHasToBePositive") as string;
                return undefined;
              },
            }),
      },
      ...(validate
        ? [
            {
              name: "price",
              validator: (value?: number | string) => {
                if (!value) return t("formError.thePriceIsRequired") as string;
                const number = Number(value);
                if (number < 0) return t("formError.thePriceHasToBePositive") as string;
                return undefined;
              },
            },
          ]
        : []),
    ],
    [t, validate, sdgsSelected]
  );

  const protocolFormState = useFormWithPartialFields({
    fields: partialFields,
    //@ts-ignore
    onSubmit: onSubmit,
  });

  const nameField = useMemo(
    (): FormField[] => [
      {
        key: "protocol-form-name",
        name: "name",
        type: "textfield",
        textFieldProps: {
          className: "protocol-form-name-field",
          placeholder: t("projectPage.enterTheProtocolName"),
        },
      },
    ],
    [t]
  );

  const protocolTypeField = useMemo(
    (): FormField[] => [
      {
        key: "protocol-form-type",
        name: "type",
        type: "select",
        selectProps: {
          size: "small",
          options: protocolTypeOptions,
          className: "protocol-form-type-unit",
          placeholder: "Protocol type"
        },
        defaultValue: protocolTypeOptions.filter((type: Option) => type.label == protocol.details.name)[0] ?? undefined,
        onChange: (event) => {
          let protocolSelected = protocolTypeOptions.filter((p: Option) => p.key == event)[0];
          if (protocolSelected.label === t("chooseTheSdgs")) {
            setSdgsSelected(true);
          } else {
            setSdgsSelected(false);
          }
        },
      },
    ],
    [t, protocolTypeOptions]
  );

  const headerFields = useMemo(
    (): FormField[] => [
      {
        key: "protocol-form-sector",
        name: "sector",
        type: "select",
        selectProps: {
          size: "small",
          options: sectorOptions,
          placeholder: "Sector",
          className: "protocol-form-sector",
        },
      },
      ...(sdgsSelected
        ? [
            {
              key: "protocol-form-sdgList",
              name: "sdgList",
              type: "select",
              selectProps: {
                size: "small",
                options: sdgsOptions,
                className: "protocol-form-sdgList",
                multiple: true,
                placeholder: t("chooseTheSdgs"),
              },
            } as FormField,
          ]
        : []),
    ],
    [t, sdgsOptions, sectorOptions, protocolFormState.values.type]
  );

  const unitField = useMemo((): FormField[] => {
    return [
      {
        key: "protocol-form-description",
        name: "description",
        type: "textfield",
        textFieldProps: {
          placeholder: t("projectPage.enterTheProtocolDescription"),
          multiline: true,
          rows: 4,
        },
      },
      ...(sdgsSelected
        ? []
        : [
            {
              key: "protocol-form-unit",
              name: "unit",
              label: t("theProtocolUnit") + ":",
              type: "select",
              selectProps: {
                options: Array.from(measures.values()).map((measure): Option => {
                  return {
                    key: measure.id,
                    label: measure.notation,
                  };
                }),
                className: "protocol-form-name-unit",
              },
            } as FormField,
          ]),
    ];
  }, [t, measures, protocolFormState.values.type]);

  const dataFields = useMemo((): FormField[] => {
    const currentUnit = protocolFormState.values.unit;
    return [
      ...(!sdgsSelected
        ? [
            {
              key: "protocol-form-availableQuantity",
              name: "availableQuantity",
              label: t("availableQuantity"),
              type: "textfield",
              textFieldProps: {
                textFieldType: "number",
                inputProps: {
                  min: 0,
                },
                iconPosition: "end",
                inputIcon: measures.get(currentUnit)?.notation ?? protocol?.type?.unit?.notation,
                noCheckOrClearIcon: true,
                className: "protocol-form-name-availableQuantity",
              },
            } as FormField,
          ]
        : []),
      ...(validate && !sdgsSelected
        ? [
            {
              key: "protocol-form-price",
              name: "price",
              label: t("price"),
              type: "textfield",
              textFieldProps: {
                textFieldType: "number",
                inputProps: {
                  min: 0,
                  step: "0.01",
                },
                iconPosition: "end",
                inputIcon:
                  "$ / " + (measures.get(currentUnit)?.notation ?? protocol?.type?.unit?.notation),
                noCheckOrClearIcon: true,
                className: "protocol-form-name-price",
              },
            } as FormField,
          ]
        : []),
    ];
  }, [
    t,
    protocolFormState.values.unit,
    protocol?.type?.unit?.notation,
    measures,
    validate,
    protocolFormState.values.type,
  ]);

  const actions = useMemo(
    (): FormAction[] => [
      ...(evaluate || validate || isEditing
        ? [
            {
              label: t("cancel"),
              key: "cancelFormButton",
              onClick: onCancel,
              className: "protocol-form-cancel",
              variant: "text",
            } as FormAction,
          ]
        : []),
      {
        label: isEditing ? t("saveChanges") : !evaluate && !validate ? t("create") : t("validate"),
        key: "validateFormButton",
        type: "submit",
        className: "protocol-form-validate",
      },
    ],
    [t, evaluate, validate, onCancel]
  );

  return (
    <>
      {!evaluate && !validate && (
        <>
          <Form fields={nameField} formState={protocolFormState} />
          <Form fields={protocolTypeField} formState={protocolFormState} />
          <Form
            fieldsStackProps={{ direction: "row", spacing: 3 }}
            fields={headerFields}
            formState={protocolFormState}
          />
          <Form className="unitFieldForm" fields={unitField} formState={protocolFormState} />
        </>
      )}
      <Form
        className="dataFieldsForm"
        fieldsStackProps={{ direction: "row", justifyContent: "space-between" }}
        actionsStackProps={{
          direction: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          spacing: 1,
        }}
        fields={dataFields}
        actions={actions}
        formState={protocolFormState}
      />
    </>
  );
};
