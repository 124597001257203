import { initRedux } from "@smartb/g2-providers";
import { titleReducer } from "./title/title.reducer";
import thunk from "redux-thunk";
import { alertReducer } from "./alert/alert.reducer";
import { refsReducer } from "./refs/refs.reducer";

const reducers = {
  title: titleReducer,
  refs: refsReducer,
  alert: alertReducer,
};

export const { store, reducer } = initRedux<typeof reducers>(reducers, [thunk]);

export type State = ReturnType<typeof reducer>;
