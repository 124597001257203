import { basicSetup } from "@codemirror/basic-setup";
import { indentWithTab } from "@codemirror/commands";
import { EditorState, Extension } from "@codemirror/state";
import { EditorView, keymap, ViewUpdate } from "@codemirror/view";
import { useEffect, useRef } from "react";
import { json as jsonLanguage } from "@codemirror/lang-json";

interface JsonEditorProps {
  json?: string;
  onUpdate?: (update: ViewUpdate) => void;
  className?: string;
}

export const JsonEditor = (props: JsonEditorProps) => {
  const { json, onUpdate, className } = props;

  const editor = useRef(null);

  useEffect(() => {
    const currentEditor = editor.current as Exclude<typeof editor["current"], null>;
    if (currentEditor) {
      const extensions: Extension[] = [basicSetup, keymap.of([indentWithTab]), jsonLanguage()];

      if (onUpdate) extensions.push(EditorView.updateListener.of(onUpdate));

      const state = EditorState.create({
        doc: json,
        extensions,
      });

      const view = new EditorView({ state, parent: currentEditor });
      return () => view.destroy();
    }
  }, [editor.current, onUpdate]);

  return <div className={className} ref={editor} />;
};
