import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Box, Stack, Typography } from "@mui/material";
import { useRef } from "react";

interface ImageDropProps {
  onDrop: (files: FileWithPath[]) => void;
  onReject?: (files: any[]) => void;
}

export const ImageDrop: React.FC<ImageDropProps> = (props) => {
  const { onDrop, onReject } = props;
  const dropzoneBoxRef = useRef(null);
  return (
    <Box ref={dropzoneBoxRef} minWidth={300}>
      <Dropzone
        useFsAccessApi={false}
        onDrop={onDrop}
        onReject={onReject}
        accept={IMAGE_MIME_TYPE}
        sx={{ height: 200, borderRadius: 20 }}
      >
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 160,
          }}
        >
          <AddPhotoAlternateIcon
            sx={{
              fontSize: "3rem",
            }}
          />
          <Typography>Add one or more images</Typography>
        </Stack>
      </Dropzone>
    </Box>
  );
};
