import { Box, Stack, Typography, useTheme as useThemeMUI } from "@mui/material";
import { FormPartialField, FormState, Option, useFormWithPartialFields } from "@smartb/g2-forms";
import { useTheme } from "@smartb/g2-themes";
import { User } from "auth";
import {
  AxessChip,
  AxessPaper,
  countryObject,
  DataTicket,
  ExtendedField,
  Hello,
  ResponsiveFilters,
  SectorChip,
} from "components";
import {
  allSectors,
  MeasureUnitDTO,
  PortfolioStatsDTO,
  ProjectRefDTO,
  ProtocolTypeDTO,
} from "datahub";
import { parse } from "qs";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { distinct, formatNumber, typeParam } from "utils";

export interface HeaderProps {
  stats?: PortfolioStatsDTO[];
  measures: Map<string, MeasureUnitDTO>;
  projectTypes: Map<string, ProtocolTypeDTO>;
  projectRefs: Map<string, ProjectRefDTO>;
  user: User;
  onSubmitFilters?: (values: any) => void;
  onClear: (formState: FormState) => void;
}

export const Header = (props: HeaderProps) => {
  const { stats, user, onSubmitFilters, measures, projectRefs, onClear, projectTypes } = props;

  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const themeMUI = useThemeMUI();

  const summedStats = useMemo(() => {
    if (!stats || stats.length === 0)
      return {
        total: undefined,
        groupedByUnit: undefined,
      };
    const groupedByUnit: { [key: string]: number } = {};
    const summed = stats.reduce(
      (acc, curr) => {
        const { currentValue, volume, unitId } = curr;
        const grouped = groupedByUnit[unitId] || 0;
        groupedByUnit[unitId] = grouped + volume;
        return {
          total: acc.total + currentValue,
        };
      },
      { total: 0 }
    );
    return {
      total: formatNumber(summed.total, i18n.language, 2, true),
      groupedByUnit: groupedByUnit,
    };
  }, [stats, i18n.language]);

  const partFields = useMemo((): FormPartialField[] => {
    const params = parse(window.location.search, { ignoreQueryPrefix: true });
    return [
      {
        name: "search",
        defaultValue: typeParam(params.search, "string"),
      },
      {
        name: "regions",
        defaultValue: typeParam(params.regions, "array"),
      },
      {
        name: "projects",
        defaultValue: typeParam(params.projects, "array"),
      },
      {
        name: "protocols",
        defaultValue: typeParam(params.protocols, "array"),
      },
      {
        name: "sectors",
        defaultValue: typeParam(params.sectors, "array"),
      },
    ];
  }, []);

  const onSubmit = useCallback(
    (values: {
      search?: string;
      projects?: string[];
      protocols?: string[];
      sectors?: string[];
    }) => {
      onSubmitFilters &&
        onSubmitFilters({
          ...values,
          search: values.search ? values.search : undefined,
        });
    },
    [onSubmitFilters]
  );

  const formState = useFormWithPartialFields({
    fields: partFields,
    onSubmit: onSubmit,
  });

  const options = useMemo(() => {
    let projectsOptions: Option[] = [];
    let regionsOptions: Option[] = [];
    Array.from(projectRefs.values()).forEach((projectRef) => {
      projectsOptions.push({
        key: projectRef.id,
        label: projectRef.details.name,
      });
      if (projectRef.country) {
        regionsOptions.push({
          key: projectRef.country,
          label: countryObject[projectRef.country]?.name,
        });
      }
    });
    regionsOptions = distinct(regionsOptions, (option) => option.key);
    const typeOptions = projectTypes
      ? Array.from(projectTypes.values()).map((type) => {
          return {
            key: type.id,
            label: type.name,
          };
        })
      : [];
    const sectorOptions = allSectors.map(
      (sectorId): Option => ({
        key: sectorId.toString(),
        label: t("sectors." + sectorId) as string,
      })
    );
    return {
      projectsOptions: projectsOptions,
      regionsOptions: regionsOptions,
      typeOptions: typeOptions,
      sectorOptions: sectorOptions,
    };
  }, [projectRefs, t, projectTypes]);

  const fields = useMemo((): ExtendedField[] => {
    return [
      {
        key: "search-field",
        name: "search",
        type: "textfield",
        textFieldProps: {
          textFieldType: "search",
          onSearch: formState.submitForm,
          placeholder: t("search"),
          iconPosition: "end",
        },
        isImportant: true,
      },
      {
        key: "regions-field",
        name: "regions",
        label: t("regions"),
        type: "select",
        selectProps: {
          options: options.regionsOptions,
          multiple: true,
        },
      },
      {
        key: "projects-field",
        name: "projects",
        label: t("projects"),
        type: "select",
        selectProps: {
          options: options.projectsOptions,
          multiple: true,
        },
      },
      {
        key: "protocols-field",
        name: "protocols",
        label: t("protocols"),
        type: "select",
        selectProps: {
          options: options.typeOptions,
          multiple: true,
        },
      },
      {
        key: "sectors-field",
        name: "sectors",
        label: t("Sectors"),
        type: "select",
        selectProps: {
          options: options.sectorOptions,
          multiple: true,
        },
      },
    ];
  }, [formState.submitForm, t, options]);

  const volumeByUnit = useMemo(() => {
    if (!summedStats.groupedByUnit) return [];
    const display = [];
    for (var unitId in summedStats.groupedByUnit) {
      const volume = summedStats.groupedByUnit[unitId];
      const unit = measures.get(unitId);
      display.push(
        <AxessPaper className="dataTicketContainer" key={unitId}>
          <DataTicket
            label={"• " + unit?.name}
            data={`${formatNumber(volume, i18n.language)} ${unit?.notation}`}
            decreaseLabel="12%"
          />
        </AxessPaper>
      );
    }
    return display;
  }, [summedStats.groupedByUnit, measures, i18n.language]);

  const filtersReminder = useMemo(() => {
    const diplay: JSX.Element[] = [];
    formState.values?.regions &&
      formState.values.regions.forEach((region, index) => {
        diplay.push(
          <AxessChip
            sx={{
              background: themeMUI.palette.chipColor.filterChip,
              margin: "5px",
            }}
            key={region}
            label={countryObject[region]?.name}
            onDelete={() => {
              const regions = [...formState.values.regions];
              regions.splice(index, 1);
              formState.setFieldValue("regions", regions);
              formState.submitForm();
            }}
          />
        );
      });
    formState.values?.projects &&
      formState.values.projects.forEach((projectId, index) => {
        const project = projectRefs.get(projectId);
        if (project) {
          diplay.push(
            <AxessChip
              sx={{
                background: themeMUI.palette.chipColor.filterChip,
                margin: "5px",
              }}
              key={projectId}
              label={project.details.name}
              onDelete={() => {
                const projects = [...formState.values.projects];
                projects.splice(index, 1);
                formState.setFieldValue("projects", projects);
                formState.submitForm();
              }}
            />
          );
        }
      });
    formState.values?.protocols &&
      formState.values.protocols.forEach((typeId, index) => {
        const type = projectTypes.get(typeId);
        if (type) {
          diplay.push(
            <AxessChip
              sx={{
                background: themeMUI.palette.chipColor.filterChip,
                margin: "5px",
              }}
              icon={<img src={type.image} alt={type.name} width={20} height={15} />}
              key={typeId}
              label={type.name}
              onDelete={() => {
                const protocols: any[] = [...formState.values.protocols];
                protocols.splice(index, 1);
                formState.setFieldValue("protocols", protocols);
                formState.submitForm();
              }}
            />
          );
        }
      });
    formState.values?.sectors &&
      formState.values.sectors.forEach((sector, index) => {
        diplay.push(
          <SectorChip
            sx={{
              background: themeMUI.palette.chipColor.filterChip,
              margin: "5px",
            }}
            key={sector}
            sector={sector}
            onDelete={() => {
              const sectors = [...formState.values.sectors];
              sectors.splice(index, 1);
              formState.setFieldValue("sectors", sectors);
              formState.submitForm();
            }}
          />
        );
      });
    return diplay;
  }, [formState.values, projectRefs, measures, formState.setFieldValue, t, projectTypes]);

  const onClearMemoized = useCallback(() => {
    onClear(formState);
    formState.submitForm();
  }, [onClear, formState]);

  return (
    <Box>
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        sx={{
          gap: "25px",
          "& .dataTicketContainer": {
            minWidth: "115px",
          },
          "& .dataTicketContainer > div > p": {
            color: themeMUI.palette.text.portfolioHeaderCardsTitle,
          },
        }}
      >
        <Stack
          sx={{
            gap: "16px"
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{
              "& .helloIcon": {
                width: "30px",
                height: "30px",
              },
            }}
          >
            <Hello className="helloIcon" />
            <Typography variant="h4">
              {t("helloUser", { user: `${user.firstName} ${user.lastName}` })}
            </Typography>
          </Stack>
          <ResponsiveFilters
            onClear={onClear}
            fields={fields}
            formState={formState}
            alwaysResponsive
          />
        </Stack>
        {!(!stats || stats.length === 0) && (
          <Stack direction="row" flexWrap="wrap">
            <Stack spacing={3} direction="row">
              <AxessPaper className="dataTicketContainer">
                <DataTicket
                  label={"• " + t("portfolioPage.totalPositions")}
                  data={summedStats.total}
                  increaseLabel="4%"
                />
              </AxessPaper>
              {volumeByUnit}
            </Stack>
          </Stack>
        )}
      </Stack>
      {filtersReminder.length > 0 && (
        <Stack direction="row" alignItems="center" flexWrap="wrap" marginTop="10px">
          {filtersReminder}
          <Typography
            sx={{
              color: theme.colors.tertiary,
              textDecoration: "underline",
              cursor: "pointer",
              margin: "5px",
            }}
            onClick={onClearMemoized}
            variant="body2"
          >
            {t("clearAll")}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};
