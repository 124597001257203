import { InfoOutlined } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "@smartb/g2-components";
import { FormState } from "@smartb/g2-forms";
import { useAxessAuth } from "auth";
import { Loading, Profile } from "components";
import {
  getPortfolioStats,
  MeasureUnitDTO,
  OrderBookOverviewDTO,
  PortfolioStatsDTO,
  ProjectRefDTO,
  ProtocolTypeDTO,
  UserProjectRightDTO,
} from "datahub";
import { parse } from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import { useNavigatePortfolio } from "store/router";
import { ActionsForOrderbookProps, typeParam, useAsyncResponse } from "utils";
import useActionsForOrderbook from "utils/src/hooks/useActionsForOrderbook";
import { Distribution } from "./components/Distribution";
import { Header } from "./components/Header";
import { OrderbookActions } from "./components/OrderbookActions";
import { Performance } from "./components/Performance";
import { Positions } from "./components/Positions";
import { ProjectTable } from "./components/ProjectTable";
import { ProtocolTable } from "./components/ProtocolTable";
import { SectorTable } from "./components/SectorTable";
import useUserRights from "utils/src/hooks/useUserAccess";

interface PortfolioProps {
  measures: Map<string, MeasureUnitDTO>;
  projectTypes: Map<string, ProtocolTypeDTO>;
  userRights: Map<string, UserProjectRightDTO>;
  setTitle: (title: string) => void;
  projectRefs: Map<string, ProjectRefDTO>;
  orderBookOverviews: Map<string, OrderBookOverviewDTO>;
}

export const Portfolio = (props: PortfolioProps) => {
  const { setTitle, measures, projectRefs, orderBookOverviews, projectTypes, userRights } = props;
  const { t } = useTranslation();
  const { keycloak, service } = useAxessAuth();
  const [shouldOpen, setShouldOpen] = useState<boolean>(false);

  useEffect(() => {
    setTitle(t("portfolio"));
  }, [setTitle, t]);

  const accessAndProjects = useUserRights(
    userRights,
    Array.from(projectRefs.values()).map((project) => project.id)
  );

  const getPortfolioStatsMemoized = useCallback(
    (params) => {
      const sectors = params.sectors
        ? params.sectors.map((value: any) => ({
            id: Number(value),
          }))
        : undefined;
      return getPortfolioStats(
        //@ts-ignore
        {
          userId: service.is_admin() ? service.getUser().id : undefined,
          projectIds: !params.projects
            ? service.is_admin()
              ? []
              : Array.from(accessAndProjects?.userAccessMap.keys())
            : params.projects,
          sectors: sectors,
          protocolTypeIds: params.protocols,
        },
        t,
        keycloak.token
      );
    },
    [t, service.getUser, keycloak.token, accessAndProjects?.userAccessMap.size]
  );

  const portfolioStatsRequest = useAsyncResponse(getPortfolioStatsMemoized, false);

  const user = useMemo(() => service.getUser(), [service.getUser]);

  const onCloseDrawer = useCallback(() => setShouldOpen(false), []);

  const onOpenDrawer = useCallback(() => setShouldOpen(true), []);

  useEffect(() => {
    if (accessAndProjects?.userAccessMap.size > 0 || service.is_admin()) {
      executeRequest(portfolioStatsRequest.execute);
    }
  }, [portfolioStatsRequest.execute, accessAndProjects?.userAccessMap.size]);

  const gotoPortfolio = useNavigatePortfolio();

  const onSubmitFilters = useCallback(
    (values: any) => {
      gotoPortfolio(values);
      portfolioStatsRequest.execute(values);
    },
    [gotoPortfolio, portfolioStatsRequest.execute]
  );

  const onClearFilters = useCallback((formState: FormState) => {
    formState.resetForm({
      values: {
        search: formState.values.search,
      },
    });
  }, []);

  const { actionState, onOpenAction, onCloseAction, onBurn, onBuy, onSell } =
    useActionsForOrderbook({
      orderBookOverviews,
      portfolioStatsRequest,
    } as ActionsForOrderbookProps);

  const groupedStats = useMemo(() => {
    const projectsGrouped: { [key: string]: PortfolioStatsDTO[] } = {};
    const sectorsGrouped: { [key: string]: PortfolioStatsDTO[] } = {};
    const unitsGrouped: { [key: string]: PortfolioStatsDTO[] } = {};

    portfolioStatsRequest.result?.portfolioStatsList.forEach((stat: PortfolioStatsDTO) => {
      const projectId = stat.projectId;

      if (!service.is_admin() && !accessAndProjects?.userAccessMap.get(projectId)) {
        return;
      }

      if (!projectsGrouped[projectId]) {
        projectsGrouped[projectId] = [];
      }
      projectsGrouped[projectId].push(stat);

      if (!sectorsGrouped[stat.sector?.id]) {
        sectorsGrouped[stat.sector?.id] = [];
      }
      sectorsGrouped[stat.sector?.id].push(stat);

      const unitId = stat.unitId;
      if (!unitsGrouped[unitId]) {
        unitsGrouped[unitId] = [];
      }
      unitsGrouped[unitId].push(stat);
    });

    const projectTablesDisplay = [];
    for (var projectId in projectsGrouped) {
      projectTablesDisplay.push(
        <ProjectTable
          onOpenAction={onOpenAction}
          key={projectId}
          measures={measures}
          userRights={userRights}
          projectId={projectId}
          projectRefs={projectRefs}
          stats={projectsGrouped[projectId]}
        />
      );
    }

    const protocolTablesDisplay = [];
    for (var unitId in unitsGrouped) {
      protocolTablesDisplay.push(
        <ProtocolTable
          onOpenAction={onOpenAction}
          key={unitId}
          measures={measures}
          userRights={userRights}
          unitId={unitId}
          projectRefs={projectRefs}
          stats={unitsGrouped[unitId]}
        />
      );
    }

    const sectorTablesDisplay = [];
    for (var sectorId in sectorsGrouped) {
      sectorTablesDisplay.push(
        <SectorTable
          onOpenAction={onOpenAction}
          key={sectorId}
          measures={measures}
          userRights={userRights}
          sectorId={sectorId}
          projectRefs={projectRefs}
          stats={sectorsGrouped[sectorId]}
        />
      );
    }
    return {
      projectTablesDisplay: projectTablesDisplay,
      sectorTablesDisplay: sectorTablesDisplay,
      protocolTablesDisplay: protocolTablesDisplay,
    };
  }, [portfolioStatsRequest.result, measures, projectRefs, onOpenAction]);

  const main =
    portfolioStatsRequest.status !== "SUCCESS" ? (
      <Loading />
    ) : !portfolioStatsRequest?.result?.portfolioStatsList ||
      portfolioStatsRequest?.result?.portfolioStatsList.length <= 0 ? (
      <Stack alignItems="center">
        <Typography>{t("portfolioPage.noStats")}</Typography>
        <Link<LinkProps> component={RouterLink} componentProps={{ to: "/projects" }}>
          {t("checktTheProjects")}
        </Link>
      </Stack>
    ) : (
      <>
        <Button
          startIcon={<InfoOutlined />}
          onClick={onOpenDrawer}
          sx={{
            width: "100px",
            backgroundColor: "white",
            color: "black",
          }}
        >
          {t("portfolioPage.profile")}
        </Button>
        <Performance stats={portfolioStatsRequest.result.positionsEvolution ?? []} />
        <Distribution stats={portfolioStatsRequest.result.portfolioStatsList ?? []} />
        <Positions groupedStats={groupedStats} />
        <OrderbookActions
          projectRefs={projectRefs}
          onClose={onCloseAction}
          ownedQuantity={actionState.ownedQuantity}
          currentOrderBook={actionState.orderBook}
          action={actionState.action}
          onBuy={onBuy}
          onSell={onSell}
          onBurn={onBurn}
        />
        <Profile open={shouldOpen} onCloseDrawer={onCloseDrawer} />
      </>
    );

  return (
    <Stack spacing={6}>
      <Header
        onClear={onClearFilters}
        onSubmitFilters={onSubmitFilters}
        projectRefs={projectRefs}
        measures={measures}
        projectTypes={projectTypes}
        user={user}
        stats={
          portfolioStatsRequest.status === "SUCCESS" &&
          portfolioStatsRequest.result.portfolioStatsList
        }
      />
      {main}
    </Stack>
  );
};

const executeRequest = (execute: (params?: any) => Promise<void>) => {
  const params = parse(window.location.search, { ignoreQueryPrefix: true });
  execute({
    ...params,
    projects: typeParam(params.projects, "array"),
    units: typeParam(params.units, "array"),
    sectors: typeParam(params.sectors, "array"),
    protocols: typeParam(params.protocols, "array"),
  });
};
