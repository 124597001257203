export const parseJwt = (token: string) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const isTokenExpired = (token: string) => {
  if (!token) {
    return true;
  }
  const parsedToken = parseJwt(token);
  const expDate = new Date(parsedToken.exp * 1000); // Multiply by 1000 to convert from unix time to epochTime
  const now = new Date();
  //const expTime = 1000 * 60 * 3; // 3 minutes
  const expTime = 3600000; // 1 hour

  return expDate.getTime() - now.getTime() < expTime;
};
