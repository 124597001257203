import { TFunction } from "react-i18next";
import { HttpOptions, request } from "utils";
import { alert, store } from "web-app";

//@ts-ignore
const apiUrl = window._env_.API_URL;

const sendAlert = (message: string, severity: "error" | "success") => {
  store.dispatch(alert.actions.setAlert({ message: message, severity: severity }));
};

export const axessRequest = async <T>(
  options: Partial<HttpOptions>,
  t?: TFunction,
  errorMessage?: string,
  successMessage?: string,
  isExternalUrl?: boolean
): Promise<Nullable<T>> => {
  const url = `${isExternalUrl ? "" : apiUrl}${options.url}`;
  const errorHandler = (error: Error, responseCode?: number) => {
    const c = responseCode;
    if (t && errorMessage) {
      if (c === 401 || c === 403) {
        sendAlert(t("http.401"), "error");
      } else if (c === 500 || c === 503 || c === 504) {
        sendAlert(t("http.500", { errorMessage: errorMessage }), "error");
      } else if (c === 600) {
        sendAlert(t("http.600", { errorMessage: errorMessage }), "error");
      }
    }
    console.error(error);
  };
  const response = await request<T>({
    method: "POST",
    ...options,
    url: url,
    errorHandler: errorHandler,
  });
  if (response && successMessage) {
    sendAlert(successMessage, "success");
  }
  return response;
};
