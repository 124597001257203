import { FileWithPath } from "@mantine/dropzone";
import { org as orgFundcall } from "axess-fundcall-domain";
import { org as orgOrderbookApi } from "axess-orderbook-api-domain";
import { org as orgOrderbookOver } from "axess-orderbook-overview-domain";
import { org as orgProjectApi } from "axess-project-api-domain";
import { org as orgProject } from "axess-project-domain";
import { org as ordProtocolApi } from "axess-protocol-api-domain";
import { org as ordProtocol } from "axess-protocol-domain";
import { TFunction } from "react-i18next";
import { axessRequest } from "./axessRequest";

export interface ProjectCreateCommandDTO
  extends orgProject.axess.s2.project.domain.features.command.ProjectCreateCommandDTO {}
export interface ProjectCreateResultDTO
  extends orgProjectApi.axess.f2.project.api.domain.features.command.ProjectCreateResultDTO {}

export const createProject = async (
  command: ProjectCreateCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<ProjectCreateResultDTO[]>(
    {
      url: "/createProject",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.createProject"),
    t("successMessage.createProject")
  );
  if (response) return response[0];
};

export interface ImageFileDTO {
  directory: string;
  objectId: string;
  objectType: string;
  metadata?: any;
  name?: string;
}

export interface FsFile {
  id: string;
  metadata: { value: string; items: any[]; key: string };
  path: { directory: string; name: string; objectId: string; objectType: string };
  url: string;
}

export const fetchGalleryImages = async (command: ImageFileDTO, token: string) => {
  const response = await axessRequest<any[]>(
    {
      //@ts-ignore
      url: `${window._env_.fs.url}/fileList`,
      body: JSON.stringify(command),
      jwt: token,
      contentType: "application/json",
    },
    undefined,
    undefined,
    undefined,
    true
  );
  if (response) return response[0];
};

export const deleteGalleryImages = async (files: ImageFileDTO[], token: string, t: TFunction) => {
  const response = await axessRequest<any[]>(
    {
      //@ts-ignore
      url: `${window._env_.fs.url}/fileDelete`,
      body: JSON.stringify(files),
      jwt: token,
      contentType: "application/json",
    },
    t,
    t("errorMessage.saveGalleryImage"),
    t("successMessage.saveGalleryImage"),
    true
  );
  if (response) return response[0];
};

export const saveGalleryImages = async (
  files: FileWithPath[],
  command: ImageFileDTO,
  token: string,
  t: TFunction
) => {
  let fileFormData = new FormData();
  let commandObject;
  files.forEach((file) => {
    fileFormData.append("file", file, file.name);
    commandObject = {
      ...commandObject,
      [file.name]: {
        path: {
          directory: command.directory,
          objectId: command.objectId,
          objectType: command.objectType,
          name: file.name,
        },
        metadata: command.metadata || {},
      },
    };
  });
  const data = new Blob([JSON.stringify(commandObject)], {
    type: "application/json",
  });
  fileFormData.append("command", data);

  const response = await axessRequest<any[]>(
    {
      //@ts-ignore
      url: `${window._env_.fs.url}/fileUploads`,
      body: fileFormData,
      jwt: token,
      contentType: "none",
    },
    t,
    t("errorMessage.saveGalleryImage"),
    t("successMessage.saveGalleryImage"),
    true
  );
  if (response) return response[0];
};

export interface ProjectUpdateCommandDTO
  extends orgProject.axess.s2.project.domain.features.command.ProjectUpdateCommandDTO {}
export interface ProjectUpdateResultDTO
  extends orgProjectApi.axess.f2.project.api.domain.features.command.ProjectUpdateResultDTO {}

export const updateProject = async (
  command: ProjectUpdateCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<ProjectUpdateResultDTO[]>(
    {
      url: "/updateProject",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.updateProject"),
    t("successMessage.updateProject")
  );
  if (response) return response[0];
};

export interface ProjectAddChildCommandDTO
  extends orgProject.axess.s2.project.domain.features.command.ProjectAddChildCommandDTO {}

export interface ProjectAddedChildEventDTO
  extends orgProject.axess.s2.project.domain.features.command.ProjectAddedChildEventDTO {}

export const addChildProjects = async (
  command: ProjectAddChildCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<ProjectAddedChildEventDTO[]>(
    {
      url: "/addChildProjects",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.updateProject"),
    t("successMessage.updateProject")
  );
  if (response) return response[0];
};

export interface ProjectPublishCommandDTO
  extends orgProject.axess.s2.project.domain.features.command.ProjectPublishCommandDTO {}
export interface ProjectPublishedEventDTO
  extends orgProject.axess.s2.project.domain.features.command.ProjectPublishedEventDTO {}

export const publishProject = async (projectId: string, token: string, t: TFunction) => {
  const response = await axessRequest<ProjectPublishedEventDTO[]>(
    {
      url: "/publishProject",
      body: JSON.stringify({ id: projectId } as ProjectPublishCommandDTO),
      jwt: token,
    },
    t,
    t("errorMessage.publishProject"),
    t("successMessage.publishProject")
  );
  if (response) return response[0];
};

export interface ProtocolCreateCommandDTO
  extends ordProtocol.axess.s2.protocol.domain.features.command.ProtocolCreateCommandDTO {}
export interface ProtocolCreateResultDTO
  extends ordProtocolApi.axess.f2.protocol.api.domain.features.command.ProtocolCreateResultDTO {}

export const createProtocol = async (
  command: ProtocolCreateCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<ProtocolCreateResultDTO[]>(
    {
      url: "/createProtocol",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.createProtocol"),
    t("successMessage.createProtocol")
  );
  if (response) return response[0];
};

export interface ProtocolDeleteCommandDTO
  extends ordProtocol.axess.s2.protocol.domain.features.command.ProtocolDeleteCommandDTO {}
export interface ProtocolDeleteResultDTO
  extends ordProtocolApi.axess.f2.protocol.api.domain.features.command.ProtocolDeleteResultDTO {}

export const deleteProtocol = async (
  command: ProtocolDeleteCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<ProtocolDeleteResultDTO[]>(
    {
      url: "/deleteProtocol",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.deleteProtocol"),
    t("successMessage.deleteProtocol")
  );
  if (response) return response[0];
};

export interface ProtocolUpdateCommandDTO
  extends ordProtocol.axess.s2.protocol.domain.features.command.ProtocolUpdateCommandDTO {}
export interface ProtocolUpdateResultDTO
  extends ordProtocolApi.axess.f2.protocol.api.domain.features.command.ProtocolUpdateResultDTO {}

export const updateProtocol = async (
  command: ProtocolUpdateCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<ProtocolUpdateResultDTO[]>(
    {
      url: "/updateProtocol",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.updateProtocol"),
    t("successMessage.updateProtocol")
  );
  if (response) return response[0];
};

export interface ProtocolEvaluateCommandDTO
  extends ordProtocol.axess.s2.protocol.domain.features.command.ProtocolEvaluateCommandDTO {}
export interface ProtocolEvaluatedEventDTO
  extends ordProtocol.axess.s2.protocol.domain.features.command.ProtocolEvaluatedEventDTO {}

export const evaluateProtocol = async (
  command: ProtocolEvaluateCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<ProtocolEvaluatedEventDTO[]>(
    {
      url: "/evaluateProtocol",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.evaluateProtocol"),
    t("successMessage.evaluateProtocol")
  );
  if (response) return response[0];
};

export interface ProtocolValidateCommandDTO
  extends ordProtocol.axess.s2.protocol.domain.features.command.ProtocolValidateCommandDTO {}
export interface ProtocolValidatedEventDTO
  extends ordProtocol.axess.s2.protocol.domain.features.command.ProtocolValidatedEventDTO {}

export const validateProtocol = async (
  command: ProtocolValidateCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<ProtocolValidatedEventDTO[]>(
    {
      url: "/validateProtocol",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.validateProtocol"),
    t("successMessage.validateProtocol")
  );
  if (response) return response[0];
};

export interface FundCallCreateCommandDTO
  extends orgFundcall.axess.f2.fundcall.domain.features.command.FundCallCreateCommandDTO {}
export interface FundCallCreateResultDTO
  extends orgFundcall.axess.f2.fundcall.domain.features.command.FundCallCreateResultDTO {}

export const createFundCall = async (
  command: FundCallCreateCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<FundCallCreateResultDTO[]>(
    {
      url: "/createFundCall ",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.createFundCall"),
    t("successMessage.createFundCall")
  );
  if (response) return response[0];
};

export interface FundCallUpdateCommandDTO
  extends orgFundcall.axess.f2.fundcall.domain.features.command.FundCallUpdateCommandDTO {}
export interface FundCallUpdateResultDTO
  extends orgFundcall.axess.f2.fundcall.domain.features.command.FundCallUpdateResultDTO {}

export const updateFundCall = async (
  command: FundCallUpdateCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<FundCallUpdateResultDTO[]>(
    {
      url: "/updateFundCall",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.updateFundCall"),
    t("successMessage.updateFundCall")
  );
  if (response) return response[0];
};

export interface FundCallPublishCommandDTO
  extends orgFundcall.axess.f2.fundcall.domain.features.command.FundCallPublishCommandDTO {}
export interface OrderBookPublishedEventDTO
  extends orgOrderbookOver.axess.s2.orderbook.domain.features.command.OrderBookPublishedEventDTO {}

export const publishFundCall = async (
  command: FundCallPublishCommandDTO,
  token: string,
  t: TFunction
) => {
  const response = await axessRequest<OrderBookPublishedEventDTO[]>(
    {
      url: "/publishFundCall",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.publishFundCall"),
    t("successMessage.publishFundCall")
  );
  if (response) return response[0];
};

export interface OrderBookBuyCommandDTO
  extends orgOrderbookApi.axess.s2.orderbook.domain.features.command.OrderBookBuyCommandDTO {}
export interface OrderBookBuyResultDTO
  extends orgOrderbookApi.axess.f2.orderbook.api.domain.features.command.OrderBookBuyResultDTO {}

export const buy = async (command: OrderBookBuyCommandDTO, t: TFunction, token: string) => {
  const response = await axessRequest<OrderBookBuyResultDTO[]>(
    {
      url: "/buy",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.buy"),
    t("successMessage.buy")
  );
  if (response) return response[0];
};

export interface OrderBookSellCommandDTO
  extends orgOrderbookApi.axess.s2.orderbook.domain.features.command.OrderBookSellCommandDTO {}
export interface OrderBookSellResultDTO
  extends orgOrderbookApi.axess.f2.orderbook.api.domain.features.command.OrderBookSellResultDTO {}

export const sell = async (command: OrderBookSellCommandDTO, t: TFunction, token: string) => {
  const response = await axessRequest<OrderBookSellResultDTO[]>(
    {
      url: "/sell",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.sell"),
    t("successMessage.sell")
  );
  if (response) return response[0];
};

export interface OrderBookBurnCommandDTO
  extends orgOrderbookApi.axess.s2.orderbook.domain.features.command.OrderBookBurnCommandDTO {}
export interface OrderBookBurnResultDTO
  extends orgOrderbookApi.axess.f2.orderbook.api.domain.features.command.OrderBookBurnResultDTO {}

export const burn = async (command: OrderBookBurnCommandDTO, t: TFunction, token: string) => {
  const response = await axessRequest<OrderBookBurnResultDTO[]>(
    {
      url: "/burn",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.burn"),
    t("successMessage.burn")
  );
  if (response) return response[0];
};

export interface OrderCancelCommandDTO
  extends orgOrderbookApi.axess.s2.orderbook.domain.features.command.OrderCancelCommandDTO {}
export interface OrderCanceledEventDTO
  extends orgOrderbookApi.axess.s2.orderbook.domain.features.command.OrderCanceledEventDTO {}

export const cancelOrder = async (command: OrderCancelCommandDTO, t: TFunction, token: string) => {
  const response = await axessRequest<OrderCanceledEventDTO[]>(
    {
      url: "/cancelOrder",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.cancelOrder"),
    t("successMessage.cancelOrder")
  );
  if (response) return response[0];
};

export interface OrderValidateCommandDTO
  extends orgOrderbookApi.axess.s2.orderbook.domain.features.command.OrderValidateCommandDTO {}
export interface OrderValidatedEventDTO
  extends orgOrderbookApi.axess.s2.orderbook.domain.features.command.OrderValidatedEventDTO {}

export const validateOrder = async (
  command: OrderValidateCommandDTO,
  t: TFunction,
  token: string
) => {
  const response = await axessRequest<OrderValidatedEventDTO[]>(
    {
      url: "/validateOrder",
      body: JSON.stringify(command),
      jwt: token,
    },
    t,
    t("errorMessage.validateOrder"),
    t("successMessage.validateOrder")
  );
  if (response) return response[0];
};
