import { Button } from "@smartb/g2-components";
import { OrderDTO, UserRefDTO } from "datahub";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { PopUp, PopUpAction } from "@smartb/g2-layout";
import { TextField } from "@smartb/g2-forms";
import { AdminTransaction } from "./AdminTransaction";

export interface PendingAdminTransactionProps {
  order: OrderDTO;
  onValidate?: () => void;
  onRefuse?: (comment: string) => void;
  userRef: UserRefDTO;
}

export const PendingAdminTransaction = (props: PendingAdminTransactionProps) => {
  const { order, onValidate, onRefuse, userRef } = props;
  const [refusing, setrefusing] = useState(false);
  const [comment, setcomment] = useState("");
  const { t } = useTranslation();

  const onClosePopUp = useCallback(() => setrefusing(false), []);

  const onOpenPopUp = useCallback(() => {
    setrefusing(true);
    setcomment("");
  }, []);

  const onRefuseMemoized = useCallback(() => {
    setrefusing(false);
    onRefuse(comment);
  }, [onRefuse, comment]);

  const actions = useMemo(
    (): PopUpAction[] => [
      {
        key: "adminTransaction-cancelAction",
        label: t("cancel"),
        onClick: onClosePopUp,
        variant: "text",
      },
      {
        key: "adminTransaction-refuseAction",
        label: t("refuse"),
        onClick: onRefuseMemoized,
      },
    ],
    [t, onClosePopUp, onRefuseMemoized]
  );

  return (
    <>
      <AdminTransaction userRef={userRef} withPrice order={order}>
        <Stack
          direction="row"
          sx={{
            flexGrow: 1,
            justifyContent: "flex-end",
          }}
          spacing={1}
        >
          <Button onClick={onOpenPopUp}>{t("refuse")}</Button>
          <Button onClick={onValidate}>{t("validate")}</Button>
        </Stack>
      </AdminTransaction>
      <PopUp open={refusing} onClose={onClosePopUp} actions={actions}>
        <Typography>{t("TransactionWallet.EnterReason") + ":"}</Typography>
        <TextField value={comment} onChange={setcomment} />
      </PopUp>
    </>
  );
};
