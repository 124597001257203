import {
  MeasureUnitDTO,
  OrderBookOverviewDTO,
  ProjectRefDTO,
  ProtocolTypeDTO,
  UserRefDTO,
  UserProjectRightDTO,
  UserProfileDTO,
} from "datahub";
import { createActionCreator } from "deox";

const setMeasures = createActionCreator(
  "SET_MEASURES",
  (resolve) => (measures: MeasureUnitDTO[]) => resolve({ measures: measures })
);

const setProtocolTypes = createActionCreator(
  "SET_PROTOCOLTYPES",
  (resolve) => (protocolTypes: ProtocolTypeDTO[]) => resolve({ protocolTypes: protocolTypes })
);

const setProjectRefs = createActionCreator(
  "SET_PROJECTREFS",
  (resolve) => (projectRefs: ProjectRefDTO[]) => resolve({ projectRefs: projectRefs })
);

const setUserRefs = createActionCreator(
  "SET_USERREFS",
  (resolve) => (userRefs: UserRefDTO[]) => resolve({ userRefs: userRefs })
);

const setUserProjectRights = createActionCreator(
  "SET_USER_PROJECT_RIGHTS",
  (resolve) => (userProjectRight: UserProjectRightDTO[]) =>
    resolve({ userProjectRights: userProjectRight })
);

const setUserProfile = createActionCreator(
  "SET_USER_PROFILE",
  (resolve) => (userProfile: UserProfileDTO) => resolve({ userProfile: userProfile })
);

const setOrderBookOverviews = createActionCreator(
  "SET_ORDERBOOK_OVERVIEWS",
  (resolve) => (orderBookOverviews: OrderBookOverviewDTO[]) =>
    resolve({ orderBookOverviews: orderBookOverviews })
);

const setPowerBiToken = createActionCreator(
  "SET_POWER_BI_TOKEN",
  (resolve) => (powerBiToken: String) => resolve({ powerBiToken: powerBiToken })
);

export const actions = {
  setMeasures: setMeasures,
  setProjectRefs: setProjectRefs,
  setUserRefs: setUserRefs,
  setUserProjectRights: setUserProjectRights,
  setUserProfile: setUserProfile,
  setOrderBookOverviews: setOrderBookOverviews,
  setPowerBiToken: setPowerBiToken,
  setProtocolTypes: setProtocolTypes,
};
