import { MarketplaceConnected } from "App/Pages/Marketplace";
import { PortfolioConnected } from "App/Pages/Portfolio";
import ViewProject from "App/Pages/Project/details";
import ProjectMarketConnected from "App/Pages/Project/martket";
import TransactionsConnected from "App/Pages/Transactions";
import { useAxessAuth } from "auth";
import { Route } from "react-router-dom";
import { useMemo } from "react";
import Accounting from "App/Pages/Accounting";

export const useAppRouter = () => {
  const { keycloak, service } = useAxessAuth();

  const sharedRoutes = [
      <Route key={"marketplace"} path="" element={<MarketplaceConnected />} />,
      <Route key={"projects"} path="projects" element={<MarketplaceConnected />} />,
      <Route key={"projectDetail"} path="project/:projectId/details" element={<ViewProject />} />,
      <Route key={"projectMarket"} path="project/:projectId/market" element={<ProjectMarketConnected />} />,
  ]

  const adminRoutes = [
      <Route key={"transactions"} path="transactions" element={<TransactionsConnected />} />,
      <Route key={"portfolio"} path="portfolio" element={<PortfolioConnected />} />,
      <Route key={"accounting"} path="accounting" element={<Accounting />} />
  ]

  const polluterRoutes = [
      <Route key={"accounting"} path="accounting" element={<Accounting />} />
  ]

  const investorRoutes = [
      <Route key={"portfolio"} path="portfolio" element={<PortfolioConnected />} />
  ]

  return useMemo(() => {
    let resultRoutes = [];

    if (keycloak.authenticated){
      if(service.is_admin() || service.is_project_developer()){
        resultRoutes.push(...adminRoutes)
      }

      if(service.is_polluter()) {
        resultRoutes.push(...polluterRoutes)
      } 

      if(service.is_investor()) {
        resultRoutes.push(...investorRoutes)
      }
    }

    resultRoutes.push(...sharedRoutes)

    return <>{[resultRoutes]}</>;

  }, [keycloak.authenticated, service])
};
