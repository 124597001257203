import React from "react";

import svgPath, { ReactComponent } from "./water-management.svg";

export const WaterManagement = React.forwardRef(
  (props: React.ComponentPropsWithRef<"svg">, ref: React.Ref<SVGSVGElement>) => {
    return (
      <ReactComponent ref={ref} {...props} style={{ ...props.style, color: "currentColor" }} />
    );
  }
);
//@ts-ignore
WaterManagement.path = svgPath;
