import "chartjs-adapter-date-fns";

import { Box, Stack } from "@mui/material";
import { Chart, ChartData, registerables } from "chart.js";
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { AxessToggleButtons } from "../ToggleButtons";
import {
  chartColors,
  createGradient,
  getChartActions,
  getChartOptions,
  tooltipVerticalLine,
} from "./chart-config";

Chart.register(...registerables, tooltipVerticalLine);

export interface PortfolioPositionsEvolutionChartProps {
  data: { x: Date; y: number }[];
}

export const PortfolioPositionsEvolutionChart: FC<PortfolioPositionsEvolutionChartProps> = ({
  data,
}) => {
  const chartRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [selectedButton, setSelectedButton] = useState(4);
  const [chartData, setChartData] = useState<ChartData<"line">>({ datasets: [] });

  useEffect(() => {
    if (chartRef.current) {
      setChartData({
        datasets: [
          {
            //@ts-ignore
            data: data.slice().sort((a: any, b: any) => a.x - b.x),
            backgroundColor: createGradient(chartRef.current.ctx),
            borderColor: chartColors.primary,
            borderWidth: 2,
            fill: true,
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 3,
            pointHoverBorderColor: chartColors.primary,
            pointHoverBackgroundColor: chartColors.secondary,
          },
        ],
      });
    }
  }, [chartRef.current, data]);

  const handleChartActionButtonClicked = useCallback(
    (_: MouseEvent<HTMLElement, MouseEvent>, key: string | number) => {
      if (Number.isInteger(key)) {
        setSelectedButton(key as number);
      }
    },
    []
  );

  const chartActions = useMemo(
    () => (
      <AxessToggleButtons
        fullWidth
        value={selectedButton}
        exclusive
        options={getChartActions(t, i18n.language)}
        //@ts-ignore
        onChangeExclusive={handleChartActionButtonClicked}
        sx={{ gap: "7%", height: 50 }}
      />
    ),
    [selectedButton, handleChartActionButtonClicked, i18n.language]
  );

  return (
    <Stack spacing={3} height={"100%"}>
      <Box position={"relative"} height={"100%"}>
        <Line
          ref={chartRef}
          data={chartData}
          //@ts-ignore
          options={getChartOptions(t, i18n.language, selectedButton)}
        />
      </Box>
      {chartActions}
    </Stack>
  );
};
