import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Paper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Children, CSSProperties, FC, useCallback, useEffect, useRef, useState } from "react";

export interface LowLevelMenuProps {
  children?: JSX.Element | JSX.Element[];
  buttonStyle?: CSSProperties;
  menuStyle?: CSSProperties;
}

export const LowLevelMenu: FC<LowLevelMenuProps> = ({ children, buttonStyle, menuStyle }) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const wrapperRef = useRef<HTMLElement>(null);

  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    const { parentElement } = wrapperRef.current;
    const { height } = parentElement.getBoundingClientRect();
    parentElement.style.position = "relative";
    setHeight(height);
  }, [wrapperRef.current]);

  return (
    <Box ref={wrapperRef} sx={{ height: "100%", display: "flex", alignItems: "center" }}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <IconButton onClick={handleClick} style={buttonStyle}>
          <MoreVertIcon fontSize="inherit" />
        </IconButton>
      </ClickAwayListener>
      {open && (
        <Paper
          elevation={5}
          sx={{
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            position: "absolute",
            top: 0,
            right: 0,
            height: height,
            width: "max-content",
            zIndex: 1,
            borderRadius: 4,
          }}
          style={menuStyle}
        >
          {Children.toArray(children)}
        </Paper>
      )}
    </Box>
  );
};
