import { Box, Stack, styled, Typography } from "@mui/material";
import { SdgDTO } from "datahub";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AxessPaper } from "../Paper";
import { Sdg } from "../Sdg/Sdg";

export interface SdgTicketProps {
  sdgs: SdgDTO[];
  className?: string;
}

const SdgTicketBase = (props: SdgTicketProps) => {
  const { sdgs, className } = props;
  const { t } = useTranslation();

  const sdgsDisplay = useMemo(
    () =>
      sdgs?.map((sdg) => {
        return (
          <Box
            key={sdg.id}
            sx={{
              margin: "10px 0",
              marginRight: "15px",
            }}
          >
            <Sdg sdgId={sdg.id} size="extraLarge" />
          </Box>
        );
      }),
    [sdgs]
  );

  return (
    <AxessPaper variant="small" className={className}>
      <Typography sx={{ marginBottom: "15px" }} variant="h6">
        {t("sdgs")}
      </Typography>
      <Stack direction="row" flexWrap="wrap">
        {sdgsDisplay}
      </Stack>
    </AxessPaper>
  );
};

export const SdgTicket = styled(SdgTicketBase)({
  paddingRight: "15px",
  padding: "25px 30px",
});
