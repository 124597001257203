import {
  Box,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme as useThemeMUI,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils";
import { ProgressBarElement } from "./ProgressBarElement";

export type Size = "small" | "medium" | "large" | "extraLarge";
export interface ProgressBarProps {
  elementIndex: number;
  percentProgress: number;
  value: number;
  className?: string;
  size?: Size;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.tabsFontColor,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "7px 7px 7px 0px",
    fontSize: 13,
    margin: "-20px !important",
  },
}));
export const ProgressBarBase = (props: ProgressBarProps) => {
  const { percentProgress, className, elementIndex, size, value } = props;
  const theme = useThemeMUI();
  const barColors: string[] = [
    theme.palette.scopesColors.scope4,
    theme.palette.scopesColors.scope1,
  ];

  const { i18n } = useTranslation();

  const [parentRef, setparentRef] = useState<HTMLDivElement | undefined>(undefined);

  const percentDownGraded = useMemo(() => {
    const base = parentRef ? 90 / parentRef.clientWidth : 0.2;
    return percentProgress * (1 - base);
  }, [percentProgress, parentRef]);

  const formattedPercentage = useMemo(() => {
    return formatNumber(percentProgress, i18n.language, 0);
  }, [percentProgress, i18n.language]);

  return (
    <Box
      className={className}
      sx={
        size === "small"
          ? { width: "25px", height: "25px" }
          : size === "medium"
          ? { width: "30px", height: "30px" }
          : size === "large"
          ? { width: "40px", height: "40px" }
          : { width: "55px", height: "55px" }
      }
    >
      <LightTooltip placement="top" title={`${value.toLocaleString(i18n.language)} tCO2`}>
        <Stack
          ref={setparentRef}
          direction="row"
          display="inline-flex"
          spacing={1}
          sx={{
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            display="inline-flex"
            sx={{
              background: barColors[elementIndex % 2 === 0 ? 0 : 1],
              borderRadius: "15px",
              width: `calc(40px + ${percentDownGraded}%)`,
              minWidth: "40px",
              maxWidth: "calc(100% - 50px)",
              paddingRight: "8px",
              height: "100%",
              alignItems: "center",
              boxSizing: "border-box",
            }}
          >
            <ProgressBarElement noTooltip size={size} />
          </Stack>
          <Typography fontSize="12px" variant="body2">
            {formattedPercentage}%
          </Typography>
        </Stack>
      </LightTooltip>
    </Box>
  );
};

export const ProgressBar = styled(ProgressBarBase)({
  overflow: "hidden",
  background: "#F1F6F7",
  borderRadius: "15px",
});
