import { Button, Stack, useTheme as useThemeMUI } from "@mui/material";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import { Duplicate } from "components";
import { useTranslation } from "react-i18next";

export interface ActionBarProps {
  onClickOpenDialog?: (object: any) => void;
  onClickDuplicate?: () => void;
}

export const ActionBar = (props: ActionBarProps) => {
  const { onClickOpenDialog, onClickDuplicate } = props;
  const { t } = useTranslation();
  const theme = useThemeMUI();

  return (
    <Stack
      position={"fixed"}
      direction={"column"}
      alignItems={"center"}
      justifyItems={"center"}
      width="100%"
      bgcolor={theme.palette.actionBarBgColor.main}
      left={0}
      bottom={0}
      paddingTop={"1%"}
    >
      <Stack direction={"row"}>
        <Button
          variant="text"
          startIcon={<CreateNewFolderOutlinedIcon color="primary" />}
          onClick={() => {
            onClickOpenDialog({ isOpen: true });
          }}
          sx={{ marginBottom: "1%", color: theme.palette.text.actionBarFont }}
        >
          {t("addToGroup")}
        </Button>
        <Button
          variant="text"
          startIcon={<Duplicate color="primary" />}
          onClick={() => {
            onClickDuplicate();
          }}
          sx={{ marginBottom: "1%", color: theme.palette.text.actionBarFont }}
        >
          {t("duplicate")}
        </Button>
      </Stack>
    </Stack>
  );
};
