import "mapbox-gl/dist/mapbox-gl.css";

import { Box, useTheme as useThemeMUI } from "@mui/material";
import { ProjectDTO, ProjectType } from "datahub";
import validator from "geojson-validation";
import { useCallback, useMemo, useRef, useState } from "react";
import Map, { Layer, MapRef, Marker, Source } from "react-map-gl";

import { countryArray } from "../countryArray";
import { Country } from "../countryObject";
import { LocationDark } from "../icons";

//@ts-ignore
const MAPBOX_TOKEN = window._env_.API_MAPBOX_ACCESS_TOKEN;

export interface ProjectsMapProps {
  projects: ProjectDTO[];
  onMouseEnterMarker?: (project: ProjectDTO) => void;
  onMouseLeaveMarker?: (project: ProjectDTO) => void;
  onClickMarker?: (country: Country) => void;
  onClickArea?: (projectIds: string[]) => void;
}

export const ProjectsMap = (props: ProjectsMapProps) => {
  const { projects, onMouseEnterMarker, onMouseLeaveMarker, onClickMarker, onClickArea } = props;
  const themeMUI = useThemeMUI();

  const mapRef = useRef<MapRef | undefined>();
  const [interactiveLayerIds, setInteractiveLayerIds] = useState([]);

  const projectsNumberByCountry = useCallback(
    (countryCode: string) => {
      return projects.reduce(
        (acc, curr) =>
          curr.projectType !== ProjectType.PARENT && curr.land.country === countryCode
            ? acc + 1
            : acc,
        0
      );
    },
    [projects]
  );

  const handleMapClick = (e: mapboxgl.MapLayerMouseEvent) => {
    const ids = e.features?.map((f) => f?.layer?.id?.split("highlightedAreaFill-")?.[1]);
    onClickArea(ids);
  };

  const markers = useMemo(() => {
    return projects.map((project) => {
      if (!project.land?.country) return undefined;
      const countryCoordinates: Country = countryArray.find(
        (country) => country.code === project.land.country
      );
      const id = `markerOf-${project.id}`;
      const markerCount = projectsNumberByCountry(project.land.country);
      return (
        markerCount > 0 && (
          <Marker
            key={`${id}-marker`}
            onClick={() => onClickMarker && onClickMarker(countryCoordinates)}
            latitude={countryCoordinates.lat}
            longitude={countryCoordinates.long}
            anchor="bottom"
          >
            <Box
              onMouseEnter={() => onMouseEnterMarker && onMouseEnterMarker(project)}
              onMouseLeave={() => onMouseLeaveMarker && onMouseLeaveMarker(project)}
              key={`${id}-location-icon`}
              sx={{
                "& .locationIcon": {
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                  transition: "0.2s",
                  color: "primary.main",
                },
                "&:hover .locationIcon": {
                  transform: "scale(1.2)",
                  color: themeMUI.palette.secondary.main,
                },
                "&:hover .locationProjectsNumber": {
                  color: "black",
                  zIndex: 10,
                },

                textAlign: "center",
              }}
            >
              <Box
                className="locationProjectsNumber"
                key={`${id}-location-projects-numbers`}
                sx={{
                  color: "white",
                  position: "absolute",
                  width: "2.5em",
                  fontWeight: "bold",
                }}
              >
                {markerCount}
              </Box>
              <LocationDark id={id} key={`${id}-dark`} className={"locationIcon"} />
            </Box>
          </Marker>
        )
      );
    });
  }, [projects, onMouseEnterMarker, onMouseLeaveMarker, onClickMarker]);

  const layers = useMemo(() => {
    return projects.map((project) => {
      if (!project.land?.geoData) return undefined;
      const geoData = JSON.parse(project.land.geoData);
      const isPoint = validator.isPoint(geoData.geometry, false);
      if (!isPoint) {
        setInteractiveLayerIds((prev) => [...prev, `highlightedAreaFill-${project.id}`]);
      }
      return (
        <Box key={`container-${project.id}`} component="div">
          {!isPoint && (
            <>
              <Source
                id={`highlightedArea-${project.id}`}
                key={`highlightedArea-${project.id}`}
                type="geojson"
                data={geoData}
              />
              <Layer
                id={`highlightedAreaFill-${project.id}`}
                key={`highlightedAreaFill-${project.id}`}
                type="fill"
                source={`highlightedArea-${project.id}`}
                paint={{
                  "fill-color": themeMUI.palette.mapAreaFillColor.main,
                  "fill-opacity": 0.3,
                }}
              />
              <Layer
                id={`highlightedAreaLine-${project.id}`}
                key={`highlightedAreaLine-${project.id}`}
                type="line"
                source={`highlightedArea-${project.id}`}
                paint={{ "line-color": themeMUI.palette.mapAreaFillColor.main, "line-width": 1 }}
              />
            </>
          )}
        </Box>
      );
    });
  }, [projects, onMouseEnterMarker, onMouseLeaveMarker, onClickMarker]);

  return (
    <Map
      ref={mapRef}
      key="map-ref"
      mapStyle="mapbox://styles/mapbox/outdoors-v11"
      style={{ width: "100%", minHeight: 300 }}
      initialViewState={{
        latitude: 18,
        longitude: 6,
        zoom: 1,
      }}
      mapboxAccessToken={MAPBOX_TOKEN}
      interactiveLayerIds={interactiveLayerIds}
      onClick={handleMapClick}
    >
      {markers}
      {layers}
    </Map>
  );
};
