import { ProjectDTO } from "datahub";
import { useCallback } from "react";

export const useProjectsMarkersInteractions = () => {
  const removePersistentHighlight = useCallback((project: ProjectDTO) => {
    const projectCards = document.querySelectorAll(".highlightClickProject");
    projectCards.forEach((projectCard) => {
      if (projectCard.id !== `projectCard-${project.id}`) {
        projectCard.classList.remove("highlightClickProject");
      }
    });
    const markers = document.querySelectorAll(".highlightClickMarker");
    markers.forEach((marker) => {
      if (marker.id !== `markerOf-${project.id}`) {
        marker.classList.remove("highlightClickMarker");
      }
    });
  }, []);

  const onMouseEnterMarker = useCallback(
    (project: ProjectDTO) => {
      const projectCard = document.querySelector(`#projectCard-${project.id}`);
      if (projectCard) {
        projectCard.scrollIntoView({ behavior: "smooth", block: "center" });
        projectCard.classList.add("highlightProject");
      }

      removePersistentHighlight(project);
    },
    [removePersistentHighlight]
  );

  const onMouseLeaveMarker = useCallback((project: ProjectDTO) => {
    const projectCard = document.querySelector(`#projectCard-${project.id}`);
    if (projectCard) {
      projectCard.classList.remove("highlightProject");
    }
  }, []);

  const onClickMarker = useCallback((project: ProjectDTO) => {
    const projectCard = document.querySelector(`#projectCard-${project.id}`);
    const marker = document.querySelector(`#markerOf-${project.id}`);
    if (projectCard && marker) {
      projectCard.classList.toggle("highlightClickProject");
      marker.classList.toggle("highlightClickMarker");
    }
  }, []);

  const onMouseEnterProjectCard = useCallback(
    (project: ProjectDTO) => {
      const marker = document.querySelector(`#markerOf-${project.id}`);
      if (marker) {
        marker.classList.add("highlightMarker");
      }
      removePersistentHighlight(project);
    },
    [removePersistentHighlight]
  );

  const onMouseLeaveProjectCard = useCallback((project: ProjectDTO) => {
    const marker = document.querySelector(`#markerOf-${project.id}`);
    if (marker) {
      marker.classList.remove("highlightMarker");
    }
  }, []);

  return {
    onMouseEnterMarker,
    onMouseLeaveMarker,
    onClickMarker,
    onMouseEnterProjectCard,
    onMouseLeaveProjectCard,
    removePersistentHighlight,
  };
};
