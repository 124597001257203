import { TransactionCreationValues } from "components";
import { OrderBookOverviewDTO, ProjectRefDTO } from "datahub";
import { TransactionBurn, TransactionBuy, TransactionSell } from "domain-components";
import { useMemo } from "react";

export interface OrderbookActionsProps {
  action?: "sell" | "buy" | "burn";
  onClose: () => void;
  onBuy: (values: TransactionCreationValues) => void;
  onSell: (values: TransactionCreationValues) => void;
  onBurn: (values: TransactionCreationValues) => void;
  projectRefs: Map<string, ProjectRefDTO>;
  ownedQuantity?: number;
  currentOrderBook?: OrderBookOverviewDTO;
}

export const OrderbookActions = (props: OrderbookActionsProps) => {
  const { action, onClose, onBuy, onSell, onBurn, ownedQuantity, currentOrderBook, projectRefs } =
    props;

  const project = useMemo(
    () =>
      projectRefs && currentOrderBook ? projectRefs.get(currentOrderBook.projectId) : undefined,
    [projectRefs, currentOrderBook]
  );

  if (!currentOrderBook) {
    return <></>;
  }
  return (
    <>
      <TransactionBuy
        project={project}
        onValidate={onBuy}
        open={action === "buy"}
        onClose={onClose}
        orderBook={currentOrderBook}
      />
      <TransactionSell
        project={project}
        ownedQuantity={ownedQuantity}
        onValidate={onSell}
        open={action === "sell"}
        onClose={onClose}
        orderBook={currentOrderBook}
      />
      <TransactionBurn
        project={project}
        ownedQuantity={ownedQuantity}
        onValidate={onBurn}
        open={action === "burn"}
        onClose={onClose}
        orderBook={currentOrderBook}
      />
    </>
  );
};
