import { Box, Stack } from "@mui/material";
import { Button } from "@smartb/g2-components";
import { Filters, FiltersField, Option, useFilters } from "@smartb/g2-forms";
import { useAxessAuth } from "auth";
import { ProjectTable, Loading } from "components";
import {
  addChildProjects,
  createProject,
  getAllProject,
  LinkedProjectDTO,
  ProjectDTO,
  ProjectStatusValues,
} from "datahub";
import { parse } from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, LinkProps } from "react-router-dom";
import {
  useNavigateAdminEditProject,
  useNavigateAdminProjects,
  useNavigateAdminViewProject,
} from "store/router";
import { useAsyncResponse, ProjectWithSDGHelper } from "utils";

interface AdminProjectsProps {
  setTitle: (title: string) => void;
}

export type DuplicateProjectDTO = Omit<
  ProjectDTO,
  "childProjectsRefs" | "creationDate" | "parentRef" | "reportUrl" | "details"
>;

export const AdminProjects = (props: AdminProjectsProps) => {
  const { setTitle } = props;
  const { t } = useTranslation();
  const { keycloak } = useAxessAuth();
  const [projectsWithSdg, setProjectsWithSdg] = useState([]);

  const getAllProjectAuth = useCallback(() => {
    return getAllProject(
      {
        status: undefined,
        search: undefined,
        sdgs: undefined,
        regions: undefined,
        isANDCriteria: false,
      },
      t,
      keycloak.token
    );
  }, [keycloak.token]);

  const projects = useAsyncResponse(getAllProjectAuth, false);

  useEffect(() => {
    const params = parse(window.location.search, { ignoreQueryPrefix: true });
    projects.execute(params);
  }, []);

  useEffect(() => {
    setTitle(t("Projects"));
  }, [setTitle, t]);

  useEffect(() => {
    if (projects.result) {
      setProjectsWithSdg(createProjectsWithSdg);
    }
  }, [projects]);

  const createProjectsWithSdg: ProjectDTO[] = useMemo(() => {
    return ProjectWithSDGHelper(projects.result);
  }, [projects.result]);

  const fields = useMemo((): FiltersField[] => {
    const params = parse(window.location.search, { ignoreQueryPrefix: true });
    const options = Object.values(ProjectStatusValues).map((value): Option => {
      return {
        key: value(),
        label: t("plateformStatus." + value) as string,
      };
    });
    return [
      {
        key: "AdminProjects-filters-status",
        name: "status",
        label: t("status"),
        type: "select",
        defaultValue: typeof params.status === "string" ? params.status : undefined,
        selectProps: {
          options: options,
          color: "default",
          variant: "outlined",
        },
      },
    ];
  }, [t]);

  const onClickDuplicate = async (selectedProjects: LinkedProjectDTO[]) => {
    let duplicateProjects = [];

    selectedProjects.forEach((selectedProject) => {
      duplicateProjects = projects?.result?.filter((project: ProjectDTO) => {
        return project?.id === selectedProject?.projectId;
      });
    });

    duplicateProjects.forEach(async (project: ProjectDTO) => {
      let auxProject: DuplicateProjectDTO = project as DuplicateProjectDTO;
      await createProject(
        {
          ...auxProject,
          ...project.details,
          ...{ id: "New project", image: undefined, details: { gallery: undefined } },
        },
        keycloak.token ?? "",
        t
      );
    });

    projects.execute();
  };

  const gotoEditProject = useNavigateAdminEditProject();
  const gotoViewProject = useNavigateAdminViewProject();
  const gotoProjects = useNavigateAdminProjects();

  const onSubmitFilters = useCallback(
    (params?: object) => {
      gotoProjects(params);
      projects.execute(params);
    },
    [projects.execute, gotoProjects]
  );

  const filtersFormState = useFilters({
    fields: fields,
    onSubmit: onSubmitFilters,
  });

  const deleteProject = (projectId: string) => {
    alert(`TODO: To be implemented\nDelete project ${projectId}`);
  };

  const handleAddSubProjectsToGroup = useCallback(
    async (subProjectIdsToAdd: LinkedProjectDTO[], parentProjectId: string) => {
      const childrenIds = subProjectIdsToAdd.map((project) => project.projectId);

      const childrenAdded = await addChildProjects(
        {
          id: parentProjectId,
          childProjectIds: childrenIds,
        },
        keycloak.token ?? "",
        t
      );

      if (childrenAdded) projects.execute();
    },
    []
  );

  if (projects.status !== "SUCCESS" || !projects.result) return <Loading />;
  return (
    <Box sx={{ paddingBottom: "20px", paddingTop: "20px" }}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={3}
        sx={{ marginBottom: "20px" }}
      >
        <Button<LinkProps> component={Link} componentProps={{ to: "/admin/project/create" }}>
          {t("projectPage.createAProject")}
        </Button>
        <Stack display="none">
          <Filters formState={filtersFormState} fields={fields} />
        </Stack>
      </Stack>
      <ProjectTable
        onClickEdit={gotoEditProject}
        onClickView={gotoViewProject}
        onClickDelete={deleteProject}
        onClickAddToGroup={handleAddSubProjectsToGroup}
        onClickDuplicate={onClickDuplicate}
        projects={projectsWithSdg}
      />
    </Box>
  );
};
