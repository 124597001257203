import { useAxessAuth } from "auth";
import { OrderBookValues, ProtocolValues } from "components";
import {
  createFundCall,
  createProtocol,
  deleteProtocol,
  updateProtocol,
  evaluateProtocol,
  getOrderBookOverviews,
  MeasureUnitDTO,
  OrderBookOverviewDTO,
  OrderBookStatusValues,
  ProjectDTO,
  ProtocolDTO,
  ProtocolEvaluateCommandDTO,
  ProtocolTypeDTO,
  ProtocolValidateCommandDTO,
  publishFundCall,
  updateFundCall,
  validateProtocol,
} from "datahub";
import { ProjectAdditionnals } from "domain-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAsyncResponse } from "utils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { AxessButton } from 'components/src/Button/AxessButton';

interface ProjectProtocolsProps {
  measures: Map<string, MeasureUnitDTO>;
  protocolTypes: Map<string, ProtocolTypeDTO>;
  updateProject?: (concatKeysTab: string[], newValues: any[]) => void;
  setProtocols: React.Dispatch<React.SetStateAction<ProtocolDTO[]>>;
  protocols: ProtocolDTO[];
  isNew: boolean;
  project: ProjectDTO;
  edit?: boolean;
}

type DeleteConfirmDialogState = { isOpen: boolean; protocol?: Partial<ProtocolDTO> };

export const ProjectProtocols = (props: ProjectProtocolsProps) => {
  const {
    measures,
    isNew,
    updateProject,
    project,
    setProtocols,
    protocols,
    edit = true,
    protocolTypes,
  } = props;
  const { t } = useTranslation();
  const { keycloak } = useAxessAuth();
  const { projectId } = useParams<{ projectId: string }>();
  const [deleteDialogState, setDeleteDialogState] = useState<DeleteConfirmDialogState>({
    isOpen: false,
  });

  const openDeleteDialog = (protocolDTO: Partial<ProtocolDTO>) => setDeleteDialogState({ 
    isOpen: true, protocol: { id: protocolDTO.id, details: protocolDTO.details }
  });

  const handleDeleteProtocol = (protocolID: string) => {
    setDeleteDialogState((prev) => ({ ...prev, isOpen: false }));
    onDeleteProtocol(protocolID);
  };

  const handleDeleteProtocolCancel = () => {
    setDeleteDialogState({ isOpen: false });
  };

  const onAddProtocol = useCallback(
    async (values: ProtocolValues) => {
      //@ts-ignore
      const sdgs = values.sdgList.map((sdg) => ({ id: Number(sdg) }));
      const sector = values.sector ? { id: Number(values.sector) } : undefined;
      const event = await createProtocol(
        {
          name: values.name,
          //@ts-ignore
          typeId: values.type,
          projectId: projectId,
          quantity: values.availableQuantity,
          sdgList: sdgs,
          sector: sector,
          description: values.description,
        },
        keycloak.token ?? "",
        t
      );
      if (event) {
        setProtocols((oldProtocols) => [...oldProtocols, event.protocol]);
      }
    },
    [projectId, keycloak.token]
  );

  const onDeleteProtocol = useCallback(
    async (protocolId: string) => {
      //@ts-ignore
      const event = await deleteProtocol(
        {
          id: protocolId,
        },
        keycloak.token ?? "",
        t
      );
      
      if (event) {
        setProtocols((oldProtocols) => oldProtocols.filter((protocol) => protocol.id !== protocolId));
      }
    },
    [projectId, keycloak.token]
  );

  const onUpdateProtocol = useCallback(
    async (values: ProtocolValues) => {
      //@ts-ignore
      const sdgs = values.sdgList.map((sdg) => ({ id: Number(sdg) }));
      const sector = { id: Number(values.sector) };
      const event = await updateProtocol(
        {
          id: values.id,
          name: values.name,
          //@ts-ignore
          typeId: values.type,
          projectId: projectId,
          quantity: values.availableQuantity,
          sdgList: sdgs,
          sector: sector,
          description: values.description,
        },
        keycloak.token ?? "",
        t
      );
      if (event) {
        const indexFound = protocols.findIndex((protocol) => protocol.id !== event.protocol.id);
        protocols[indexFound] = event.protocol;
        setProtocols((oldProtocols) => [...oldProtocols.map((protocol) =>
          protocol.id === event.protocol.id ? event.protocol : protocol
        )]);

      }
    },
    [projectId, keycloak.token]
  );

  const [orderBooks, setOrderBooks] = useState<OrderBookOverviewDTO[]>([]);

  const getOrderBooksMemoized = useCallback(
    () => getOrderBookOverviews(t, keycloak.token, projectId),
    [projectId, t, keycloak.token]
  );

  const orderBooksRequest = useAsyncResponse(getOrderBooksMemoized, false);

  useEffect(() => {
    if (!isNew) {
      orderBooksRequest.execute();
    }
  }, [isNew, orderBooksRequest.execute]);

  useEffect(() => {
    const result = orderBooksRequest.result;
    if (orderBooksRequest.status === "SUCCESS" && result) {
      setOrderBooks(result);
    }
  }, [orderBooksRequest.status, orderBooksRequest.result]);

  const onCreateOrderBook = useCallback(
    async (values: OrderBookValues, protocol: ProtocolDTO) => {
      const command = {
        period: {
          start: values.start.getTime(),
          end: values.end.getTime(),
        },
        price: values.price,
        protocolId: protocol.id,
        volume: values.volume,
      };
      const event = await createFundCall(command, keycloak.token, t);
      if (event) {
        setOrderBooks((oldOrderBooks) => [...oldOrderBooks, event.fundcall]);
      }
    },
    [t, projectId, keycloak.token]
  );

  const onUpdateOrderBook = useCallback(
    async (values: OrderBookValues, orderBook: OrderBookOverviewDTO) => {
      const command = {
        period: {
          start: values.start.getTime(),
          end: values.end.getTime(),
        },
        price: values.price,
        volume: values.volume,
        id: orderBook.id,
      };
      const event = await updateFundCall(command, keycloak.token, t);
      if (event) {
        setOrderBooks((oldOrderBooks) =>
          oldOrderBooks.map((ob) => (orderBook.id === ob.id ? event.fundcall : ob))
        );
      }
    },
    [t, keycloak.token]
  );

  const onPublishOrderBook = useCallback(
    async (orderBook: OrderBookOverviewDTO) => {
      const event = await publishFundCall({ id: orderBook.id }, keycloak.token, t);
      if (event) {
        setOrderBooks((oldOrderBooks) => {
          const copy = oldOrderBooks;
          const index = copy.findIndex((it) => orderBook.id === it.id);
          copy[index] = {
            ...orderBook,
            status: {
              value: OrderBookStatusValues.published(),
            },
          };
          return [...copy];
        });
      }
    },
    [t, keycloak.token]
  );

  const onEvaluate = useCallback(
    async (quantity: number, protocol: ProtocolDTO) => {
      const event = await evaluateProtocol(
        { id: protocol.id, quantity: quantity } as ProtocolEvaluateCommandDTO,
        keycloak.token,
        t
      );
      if (event) {
        setProtocols((oldProtocols) =>
          oldProtocols.map((protocol) =>
            protocol.id === event.id ? { ...protocol, availableQuantity: quantity } : protocol
          )
        );
      }
    },
    [keycloak.token, t]
  );

  const onValidate = useCallback(
    async (quantity: number, price: number, protocol: ProtocolDTO) => {
      const event = await validateProtocol(
        {
          id: protocol.id,
          quantity: quantity,
          price: price,
        } as ProtocolValidateCommandDTO,
        keycloak.token,
        t
      );
      if (event) {
        orderBooksRequest.execute();
      }
    },
    [keycloak.token, t, orderBooksRequest.execute]
  );

  return (<>
    <ProjectAdditionnals
      isNew={isNew}
      isAdmin
      onEvaluate={onEvaluate}
      onValidate={onValidate}
      onPublishOrderBook={onPublishOrderBook}
      onCreateOrderBook={onCreateOrderBook}
      onEditOrderBook={onUpdateOrderBook}
      orderBooks={orderBooks}
      measures={measures}
      onAddProtocol={onAddProtocol}
      onDeleteProtocol={openDeleteDialog}
      onUpdateProtocol={onUpdateProtocol}
      updateProject={updateProject}
      protocolTypes={protocolTypes}
      protocols={protocols}
      project={project}
      edit={edit}
    />
    <Dialog open={deleteDialogState.isOpen} onClose={handleDeleteProtocolCancel}>
        <DialogTitle>{t("deleteProtocol")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("deleteProtocolConfirm") + " "}
            <strong>{deleteDialogState.protocol?.details?.name}</strong>
            {"?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AxessButton onClick={handleDeleteProtocolCancel} autoFocus>
            {t("cancel")}
          </AxessButton>
          <AxessButton onClick={() => handleDeleteProtocol(deleteDialogState.protocol?.id)}>
            {t("delete")}
          </AxessButton>
        </DialogActions>
      </Dialog>
      </>
  );
};
