import { useState, useMemo, useCallback } from "react";
import { BaseEditor, createEditor, Descendant, Node } from "slate";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { HistoryEditor, withHistory } from "slate-history";
import { styled } from "@mui/material";

const StyledEditable = styled(Editable)({
  fontFamily: "'Montserrat',roboto",
  fontWeight: 500,
  fontSize: "1rem",
  textAlign: "justify",
  lineHeight: 1.5,
});

type CustomElement = { type: "paragraph"; children: CustomText[] };
type CustomText = { text: string };

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export interface BasicTextEditorProps {
  placeholder?: string;
  onChange?: (value: string) => void;
  text?: string;
  className?: string;
}

const serialize = (nodes: Descendant[]) => {
  return nodes.map((n) => Node.string(n)).join("\n");
};

export const BasicTextEditor = (props: BasicTextEditorProps) => {
  const { onChange, placeholder, text, className } = props;
  const initialValue = useMemo(
    (): Descendant[] => [
      {
        type: "paragraph",
        children: [
          {
            text: text ?? "",
          },
        ],
      },
    ],
    [text]
  );

  const [value, setValue] = useState<Descendant[]>(initialValue);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const onChangeMemoiszed = useCallback((value: Descendant[]) => {
    setValue(value);
  }, []);

  const onBlur = useCallback(() => {
    const serialized = serialize(value);
    onChange && onChange(serialized);
  }, [value, onChange]);

  return (
    <Slate editor={editor} value={value} onChange={onChangeMemoiszed}>
      <StyledEditable className={className} placeholder={placeholder} onBlur={onBlur} />
    </Slate>
  );
};
