import { Stack } from "@mui/material";
import { OrganizationDTO } from "datahub";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxessButton } from "../Button/AxessButton";
import { OrganizationPaper } from "./OrganizationPaper";

export interface OrganizationListProps {
  stakeHolders: OrganizationDTO[];
  onUpdate: (stakeHolders: OrganizationDTO[]) => void;
  edit?: boolean;
}

export const OrganizationList = (props: OrganizationListProps) => {
  const { stakeHolders, onUpdate, edit = false } = props;
  const [add, setadd] = useState(false);
  const { t } = useTranslation();

  const display = useMemo(() => {
    return stakeHolders.map((stakeHolder, index) => {
      return (
        <OrganizationPaper
          key={stakeHolder.name ?? index}
          defaultStakeHolder={stakeHolder}
          onValidate={(stakeHolder) => {
            const stakeHoldersCopy = [...stakeHolders];
            stakeHoldersCopy[index] = stakeHolder;
            onUpdate(stakeHoldersCopy);
          }}
          onDelete={() => {
            const stakeHoldersCopy = [...stakeHolders];
            stakeHoldersCopy.splice(index, 1);
            onUpdate(stakeHoldersCopy);
          }}
          edit={edit}
        />
      );
    });
  }, [stakeHolders, onUpdate, edit]);

  const onAddStakeHolder = useCallback(() => {
    setadd(true);
  }, []);

  const onDeleteAddedStakeHolder = useCallback(() => {
    setadd(false);
  }, []);

  const onAddDefinitiveStakeHolder = useCallback(
    (stakeHolder: OrganizationDTO) => {
      const stakeHoldersCopy = [...stakeHolders];
      stakeHoldersCopy.push(stakeHolder);
      onUpdate(stakeHoldersCopy);
      setadd(false);
    },
    [stakeHolders]
  );

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      sx={{
        gap: "15px",
        alignItems: "center",
      }}
    >
      {display}
      {add && (
        <OrganizationPaper
          onValidate={onAddDefinitiveStakeHolder}
          onDelete={onDeleteAddedStakeHolder}
          edit={edit}
        />
      )}
      {!add && edit && (
        <AxessButton onClick={onAddStakeHolder}>{t("projectPage.addStakeHolder")}</AxessButton>
      )}
    </Stack>
  );
};
