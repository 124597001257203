import { ViewUpdate } from "@codemirror/view";
import { Alert, Box, Stack, useTheme as useThemeMUI } from "@mui/material";
import { center, points } from "@turf/turf";
import { ProjectDTO } from 'datahub';
import validator from "geojson-validation";
import "mapbox-gl/dist/mapbox-gl.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Map, { Layer, MapRef, Marker, Source } from "react-map-gl";
import { AxessButton } from "../Button/AxessButton";
import { LocationDark } from "../icons";
import { JsonEditor } from "../JsonEditor/JsonEditor";

//@ts-ignore
const MAPBOX_TOKEN = window._env_.API_MAPBOX_ACCESS_TOKEN;

const emptyGeoJson = `{
    "type": "Feature",
    "geometry": {
        "type": "Polygon",
        "coordinates": [
            [
            ]
        ]
    },
    "properties": {}
}
`;

export interface TerritoryMapProps {
  geojson?: string;
  /**
   * @default false
   */
  editable?: boolean;
  jsonEditorClassName?: string;
  onGeoJsonUpdate?: (geoJson: string) => void;
  projects?: ProjectDTO[];
}

export const TerritoryMap = (props: TerritoryMapProps) => {
  const { geojson, editable = false, jsonEditorClassName, onGeoJsonUpdate, projects } = props;
  const { t } = useTranslation();
  const inputFileRef = useRef(null);

  const themeMUI = useThemeMUI();
  const [geoJsonHasError, setGeoJsonHasError] = useState<boolean>(false);
  const [currentGeoJson, setCurrentGeoJson] = useState<{
    string: string;
    obj: any;
    type: "polygon" | "point";
    featureType: "Feature" | "FeatureCollection";
  }>(
    geojson
      ? {
          string: geojson,
          obj: JSON.parse(geojson),
          type: validator.isPoint(JSON.parse(geojson).geometry, false) ? "point" : "polygon",
          featureType: validator.isFeature(JSON.parse(geojson), false)
            ? "Feature"
            : "FeatureCollection",
        }
      : {
          string: emptyGeoJson,
          obj: JSON.parse(emptyGeoJson),
          type: "polygon",
          featureType: "Feature",
        }
  );
  const [innerMapRef, setInnerMapRef] = useState(null);
  const mapRef = useRef<MapRef>(null);

  // This detection is needed, in case the map is out of viewin the beginning.
  // This callback will force a re-render when the children of the Map are available
  const onRefChange = useCallback((node) => {
    setInnerMapRef(node);
  }, []);

  useEffect(() => {
    try {
      if (
        mapRef.current &&
        (validator.isFeature(currentGeoJson.obj, false) ||
          validator.isFeatureCollection(currentGeoJson.obj, false))
      ) {
        let coordinates: number[][];
        if (currentGeoJson.obj.type === "FeatureCollection") {
          const geom = currentGeoJson.obj?.features[0].geometry; 
          coordinates = geom?.type === "Polygon" ? geom.coordinates[0] : geom.coordinates[0][0];
        } else {
          coordinates = currentGeoJson.obj.geometry?.coordinates[0];
        }

        const centerpoints =
          currentGeoJson.type === "polygon" && currentGeoJson.obj.geometry?.type !== "Multipolygon"
            ? center(points(coordinates))
            : currentGeoJson.obj;

        mapRef.current.flyTo({
          center: centerpoints.geometry.coordinates,
          zoom: projects ? 4 : 7,
        });

      }
    } catch (error) {

      return;
    }
  }, [currentGeoJson, mapRef.current, innerMapRef]);

  const onUpdate = useCallback(
    (update: ViewUpdate) => {
      const json = update.state.doc.toString();
      if (currentGeoJson.string === json) return;

      validateAndSetGeoJson(json);
    },
    [currentGeoJson.string, onGeoJsonUpdate]
  );

  const validateAndSetGeoJson = (json: string) => {
    try {
      const parsed = JSON.parse(json);
      if (validator.isFeature(parsed, false) || validator.isFeatureCollection(parsed, false)) {
        setCurrentGeoJson({
          string: json,
          obj: parsed,
          type: validator.isPoint(parsed.geometry, false) ? "point" : "polygon",
          featureType: validator.isFeature(parsed, false) ? "Feature" : "FeatureCollection",
        });
        onGeoJsonUpdate && onGeoJsonUpdate(json);
      } else {
        setGeoJsonHasError(true);
      }
    } catch {
      setGeoJsonHasError(true);
    }
  };

  const handleUpload = (e: any) => {
    setGeoJsonHasError(false);
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      validateAndSetGeoJson(e.target.result as string);
    };
  };

  const childrenLayer = useMemo(() => {
     return projects?.map((project) => {
      if (!project.land?.geoData) return undefined;
      const geoData = JSON.parse(project.land.geoData);
      const isPoint = validator.isPoint(geoData.geometry, false);

      return (
        <Box key={project.id} component="div">
          {!isPoint && (
            <>
              <Source id={`highlightedArea-${project.id}`} type="geojson" data={geoData} />
              <Layer
                id={`highlightedAreaFill-${project.id}`}
                type="fill"
                source={`highlightedArea-${project.id}`}
                paint={{
                  "fill-color": themeMUI.palette.mapAreaFillColor.main,
                  "fill-opacity": 0.3,
                }}
              />
              <Layer
                id={`highlightedAreaLine-${project.id}`}
                type="line"
                source={`highlightedArea-${project.id}`}
                paint={{ "line-color": themeMUI.palette.mapAreaFillColor.main, "line-width": 1 }}
              />
            </>
          )}
        </Box>
      );
    });
  }, [projects])

  const layers = useMemo(() => {
    if((currentGeoJson.featureType === "Feature" || currentGeoJson.featureType === "FeatureCollection")
    && currentGeoJson.type !== "point")
    {
     return (<>
        <Source id="highlightedArea" type="geojson" data={currentGeoJson.obj} />
        <Layer
          id="highlightedAreaFill"
          type="fill"
          source="highlightedArea"
          paint={{
            "fill-color": themeMUI.palette.mapAreaFillColor.main,
            "fill-opacity": 0.3,
          }}
        />
        <Layer
          id="highlightedAreaLine"
          type="line"
          source="highlightedArea"
          paint={{ "line-color": themeMUI.palette.mapAreaFillColor.main, "line-width": 1 }}
        />
      </>)
    }
  }, [currentGeoJson.obj])

  return (
    <>
      <Map
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/outdoors-v11"
        style={{ width: "100%", minHeight: 300, height: "auto" }}
        initialViewState={{
          latitude: 18,
          longitude: 6,
          zoom: 1,
        }}
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        <div ref={onRefChange}>
          <>
          {projects ? childrenLayer : layers}
          </>
          {currentGeoJson.type === "point" && (
            <Marker
              latitude={currentGeoJson.obj.geometry?.coordinates[1]}
              longitude={currentGeoJson.obj.geometry?.coordinates[0]}
              anchor="bottom"
            >
              <Box
                sx={{
                  "& .locationIcon": {
                    width: "30px",
                    height: "30px",
                    color: "primary.main",
                  },
                }}
              >
                <LocationDark className={"locationIcon"} />
              </Box>
            </Marker>
          )}
        </div>
      </Map>
      {editable && (
        <>
          <JsonEditor
            className={jsonEditorClassName}
            json={currentGeoJson.string}
            onUpdate={onUpdate}
          />
          <Stack justifyContent="end">
            <AxessButton onClick={() => inputFileRef.current.click()}>
              {t("editProjectPage.importGeoJson")}
              <input
                type="file"
                ref={inputFileRef}
                hidden
                onChange={(e) => {
                  handleUpload(e);
                }}
              />
            </AxessButton>
            {geoJsonHasError && (
              <Box>
                <Alert
                  severity="error"
                  onClick={() => setGeoJsonHasError(false)}
                  onClose={() => setGeoJsonHasError(false)}
                >
                  {t("errorMessage.invalidGeoJson")}!
                </Alert>
              </Box>
            )}
          </Stack>
        </>
      )}
    </>
  );
};
