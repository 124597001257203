import reportWebVitals from "reportWebVitals";
import {
  AppProvider,
  ReduxProvider,
  G2ConfigBuilder,
  KeycloakProvider,
} from "@smartb/g2-providers";
import { languages } from "i18n";
import { store } from "store";
import { ThemeContextProvider } from "@smartb/g2-themes";
import { theme, muiTheme } from "Themes";
import { AlertHub } from "@smartb/g2-notifications";
import { alert } from "store/alert";
import { Loading } from "components";
import BaseRouter from "BaseRouter/BaseRouter";
import { QueryClient } from "react-query";
import { createRoot } from "react-dom/client";
import { keycloakConfigUrl } from "auth";
import React from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 86400000, //stale time set to one day
    },
  },
});

//@ts-ignore
G2ConfigBuilder(window._env_);

//@ts-ignore
const container: HTMLElement = document.getElementById("root");

const root = createRoot(container);

root.render(
  <ThemeContextProvider theme={theme} customMuiTheme={muiTheme}>
    <AppProvider languages={languages} queryClient={queryClient} loadingComponent={<Loading />}>
      <ReduxProvider reduxStore={store}>
        <KeycloakProvider
          config={keycloakConfigUrl}
          initOptions={{ onLoad: "login-required" }}
          loadingComponent={<Loading />}
        >
          <React.StrictMode>
            <AlertHub>
              <BaseRouter />
            </AlertHub>
          </React.StrictMode>
        </KeycloakProvider>
      </ReduxProvider>
    </AppProvider>
  </ThemeContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { store, alert };
