import { Box, Stack, styled, Typography, useTheme as useThemeMUI} from "@mui/material";
import { ProjectDTO, ProtocolDTO, SdgDTO } from "datahub";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { distinct } from "utils";
import { SectorChip } from "../Chip";
import { AxessPaper, AxessPaperProps } from "../Paper";
import { ProjectTitle } from "../ProjectTitle";
import { SdgCard } from '../SdgCard';
import defaultImage from "./forest.png";

export type ProjectCardProps = {
  project: ProjectDTO;
  childProjects?: ProjectDTO[];
  isParent: boolean
  className?: string;
  onClick?: (project: ProjectDTO) => void;
} & AxessPaperProps;

const ProjectCardBase = (props: ProjectCardProps) => {
  const { project, isParent, childProjects, className, onClick, ...other } = props;
  const themeMUI = useThemeMUI();

  const { t } = useTranslation();

  const image = useMemo(() => {
    if (project.details.image) return project.details.image;
    return defaultImage;
  }, [project.details.image]);

  const sdgList = useMemo(() => {
    const auxProtocols = isParent ? childProjects?.flatMap((project) => project.protocols) : project.protocols;

    const sdgList =  auxProtocols?.flatMap((protocol: ProtocolDTO) => {
      return protocol.details.sdgList;
    }) || [];

    const distinctSDG = distinct(sdgList, (sdg) => sdg?.id) as SdgDTO[];

    return (<>
      <>
          <Typography
            sx={{ color: themeMUI.palette.text.primary, margin: "10px 0px" }}
            component="p"
            variant="caption"
          >
            {t("sdgs")}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              overflow: "auto",
              maxWidth: "100%",
            }}
          >
            <SdgCard defaultSdgs={distinctSDG} />
          </Stack>
        </>
    </>)
  }, [project, childProjects]);

  /*const units = useMemo(() => {
    const units = distinct(
      project.protocols.map((protocol: ProtocolDTO) => {
        return protocol.type.unit;
      }),
      (unit) => unit.id
    );
    return units.map((unit) => {
      return <AxessChip key={"unit" + unit.id} label={unit.notation} />;
    });
  }, [project]);*/

  const sectors = useMemo(() => {

    const auxProtocols = isParent ? childProjects?.flatMap((project) => project.protocols) || [] : project.protocols;

    const filteredSectors = auxProtocols.map((protocol: ProtocolDTO) => protocol.details.sector);

    const distinctSectors = distinct(filteredSectors, (sector) => sector?.id)?.filter((sector) => sector);
    return distinctSectors.map((sector: any) => {
      return <SectorChip key={"sector" + sector?.id} sector={sector?.id} noLabel={isParent}/>;
    });
  }, [project, childProjects]);

  const handleClick = useCallback(() => {
    onClick && onClick(project);
  }, [onClick, project]);

  return (
    <Box
      className={"container-" + className}
      sx={{
        position: "relative",
      }}
    >
      <AxessPaper
        variant="small"
        className={className}
        sx={{
          position: "relative",
          cursor: "pointer",
          transition: "0.3s",
          "&:hover": {
            boxShadow: "0px 6px 26px #0241524D",
          },
          width: "100%",
          overflow: "hidden",
          "& .projectImage": {
            position: "absolute",
            width: "35%",
            height: "100%",
            objectFit: "cover",
            top: "0px",
            left: "0px",
          },
          paddingLeft: "calc(35% + 20px) !important",
          boxSizing: "border-box",
        }}
        onClick={handleClick}
        {...other}
      >
        <img
          className="projectImage"
          src={image}
          alt={"image of the project: " + project.details.name}
        />
        <ProjectTitle
          defaultLocation={project.land.country}
          defaultProjectName={project.details.name}
        />
        {sdgList}
        <Typography
          sx={{ color: themeMUI.palette.text.primary, margin: "10px 0px" }}
          component="p"
          variant="caption"
        >
          {t("Sectors")}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            overflow: "auto",
            maxWidth: "100%",
          }}
        >
          {sectors}
        </Stack>
      </AxessPaper>
    </Box>
  );
};

export const ProjectCard = styled(ProjectCardBase)({
  "& *::-webkit-scrollbar": {
    background: "transparent",
  },
  "& *::-webkit-scrollbar-thumb": {
    background: "transparent",
  },
  "&:hover *::-webkit-scrollbar": {
    background: "#03323D33",
  },
  "&:hover *::-webkit-scrollbar-thumb": {
    background: "#03323D",
  },
});
