import { useTranslation } from "react-i18next";
import { ProjectStatusValues } from "../types";
import { PlateformStatus } from "./PlateformStatus";

const colors: { [status: string]: string } = {
  [ProjectStatusValues.created()]: "#7343d9",
  [ProjectStatusValues.published()]: "#59de81",
  [ProjectStatusValues.closed()]: "#b0b1bf",
};

export interface ProjectStatusProps {
  status: string;
}

export const ProjectStatus = (props: ProjectStatusProps) => {
  const { status } = props;
  const { t } = useTranslation();
  return <PlateformStatus label={t("plateformStatus." + status)} color={colors[status]} />;
};
