import { Button, ButtonProps } from "@smartb/g2-components";
import { makeG2STyles } from "@smartb/g2-themes";

export const useStyles = makeG2STyles()((theme) => ({
  base: {
    minWidth: "unset",
    padding: "10px 20px",
    fontSize: "1rem",
  },
  textVariant: {
    color: theme.colors.primary,
    background: "#E5EAEB",
  },
}));

export const AxessButton = (props: ButtonProps) => {
  const { variant, className, ...rest } = props;
  const { cx, classes } = useStyles();
  return (
    <Button
      className={cx(classes.base, variant === "text" && classes.textVariant, className)}
      variant={variant}
      {...rest}
    />
  );
};
