import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import { Box, Paper, Stack, styled, Typography } from "@mui/material";
import { AutomatedGallery, Link } from "@smartb/g2";
import {
  AxessAcordion,
  AxessPaper,
  Loading,
  Sdg,
  Section,
  StakeHolderSub,
  TerritoryMap,
} from "components";
import { AxessButton } from "components/src/Button/AxessButton";
import { OrganizationDTO, ProjectDTO, ProtocolDTO, SdgDTO, ProjectType } from "datahub";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { constants, distinct, generateSpacedText } from "utils";

export interface AboutProps {
  project: ProjectDTO;
  childrenProjects?: ProjectDTO[];
  sdgs: SdgDTO[];
  openConeOfUncertainty: Function;
  isLoading: boolean;
  isGuestUser: boolean;
}

export const About = (props: AboutProps) => {
  const { project, childrenProjects, sdgs, openConeOfUncertainty, isLoading, isGuestUser } = props;

  const { t } = useTranslation();

  const presentation = useMemo(() => {
    return generateSpacedText(project.details.description);
  }, [project.details.description]);

  const sections = useMemo((): Section[] => {
    let providedProtocols = project.protocols;
    providedProtocols = distinct(
      providedProtocols,
      (protocols) => protocols.details.name && protocols.details.description
    );
    return providedProtocols.map((protocol: ProtocolDTO): Section => {
      return {
        key: protocol.id,
        title: protocol.details.name,
        typeName: protocol.type.name,
        content: (
          <Stack key={"content-" + protocol.id} alignItems="center" padding="0 25px">
            <Typography sx={{ marginBottom: "1rem" }}>{protocol.details.description}</Typography>
            {isLoading ? (
              <Loading sx={{ padding: "0px" }} />
            ) : (
              <AxessButton
                icon={<TimelineOutlinedIcon />}
                sx={{
                  paddingBottom: "10px",
                  "& .MuiSvgIcon-root": {
                    marginRight: "10px",
                  },
                }}
                onClick={() => openConeOfUncertainty(protocol.details.name, protocol.id)}
              >
                {t("viewTimeline")}
              </AxessButton>
            )}
          </Stack>
        ),
      };
    });
  }, [project.protocols, isLoading]);

  const sdgWithProtocolDescription = useCallback(
    (sdgId: number) => {
      const providedProtocols = project.protocols;
      const protocol = providedProtocols.filter((protocol) => {
        return protocol.details.sdgList.some((sdg) => sdg.id === sdgId);
      });
      return protocol?.[0]?.details;
    },
    [project.protocols]
  );

  const sectionsSDGs = useMemo((): Section[] => {
    return sdgs.map((sdg) => {
      const sdgDetails = sdgWithProtocolDescription(sdg.id);
      return {
        key: `${sdg?.id}`,
        title: `${sdgDetails?.name}`,
        typeName: `${sdgDetails?.name}`,
        SDG: (
          <>
            <Link
              target="_blank"
              rel="noreferrer"
              href={`${constants.SDGS_UN_URL}${sdg.id}`}
              variant="body2"
            >
              <Box>
                <Sdg sdgId={sdg.id} size="extraLarge" sx={{ width: "55px", height: "55px" }} />
              </Box>
            </Link>
          </>
        ),
        content: (
          <Stack key={"sdg-content-" + `${sdg?.id}`} alignItems="center" padding="0 25px">
            <Typography sx={{ marginBottom: "1rem" }}>{sdgDetails?.description}</Typography>
            {isLoading && <Loading sx={{ padding: "0px" }} />}
          </Stack>
        ),
      };
    });
  }, [project.protocols, isLoading, sdgs]);

  const stakeHoldersDisplay = useMemo(() => {
    return project.stakeholders.map((stakeholder: OrganizationDTO) => {
      return (
        <StakeHolderSub key={stakeholder.name + stakeholder.title} stakeHolder={stakeholder} />
      );
    });
  }, [project]);

  const StyledMapContainer = styled(Paper)({
    maxHeight: "600px",
    overflow: "hidden",
    "& .project-jsonEditor": {
      maxHeight: "300px",
      overflowY: "auto",
    },
    projectMap: {
      width: "100%",
    },
  });

  return (
    <Stack>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        sx={{
          gap: "25px",
          alignItems: "flex-start",
        }}
      >
        <AxessPaper
          variant="small"
          sx={{
            padding: "50px !important",
            minWidth: "430px",
            flex: 1,
            flexGrow: 2.2,
            gap: 3,
            "& .Mui-disabled": {
              backgroundColor: "transparent !important",
            },
            "& div": {
              opacity: "1 !important",
            },
          }}
        >
          <Typography sx={{ marginBottom: "20px" }} variant="h5">
            {t("presentation")}
          </Typography>
          <Typography sx={{ marginBottom: "80px" }} variant="body2">
            {presentation}
          </Typography>
          {project?.projectType !== ProjectType.PARENT && (
            <>
              <Typography sx={{ marginBottom: "40px" }} variant="h5">
                {t("protocols")}
              </Typography>
              <AxessAcordion sections={sections} disabled={isGuestUser} />
              <Typography sx={{ marginBottom: "40px", marginTop: "60px" }} variant="h5">
                {t("sdgs")}
              </Typography>
              <Stack
                sx={{
                  gap: "30px",
                }}
              >
                <AxessAcordion sections={sectionsSDGs} disabled={isGuestUser} />
              </Stack>
            </>
          )}
        </AxessPaper>

        <Stack
          sx={{
            gap: 3,
            alignSelf: "flex-start",
            width: "400px",
          }}
        >
          {stakeHoldersDisplay.length > 0 && (
            <AxessPaper
              variant="small"
              sx={{
                padding: "50px !important",
                maxWidth: "500px",
                minWidth: "400px",
              }}
            >
              <Typography sx={{ marginBottom: "50px" }} align="center" variant="h5">
                {t("keyStakeholders")}
              </Typography>
              <Stack
                sx={{
                  gap: "25px",
                }}
              >
                {stakeHoldersDisplay}
              </Stack>
            </AxessPaper>
          )}
          <StyledMapContainer elevation={3}>
            <TerritoryMap jsonEditorClassName="project-jsonEditor" geojson={project.land.geoData} projects={project.projectType === ProjectType.PARENT ? childrenProjects : undefined} />
          </StyledMapContainer>
        </Stack>
      </Stack>
      <AutomatedGallery
        galleryName="projectBottomGallery"
        directoryPath={{
          directory: "projectBottomGallery",
          objectId: project.id,
          objectType: "project",
        }}
        variant="grid"
        gridProps={{
          sx: {
            marginTop: "45px",
          },
          cols: 2,
        }}
      />
    </Stack>
  );
};
