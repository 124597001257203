import { Button, InputAdornment, Stack, styled, TextField } from "@mui/material";
import { ProjectDTO, ProjectRefDTO } from "datahub";
import { createContext, useCallback, useEffect, useState } from "react";
import { FilterInput, FiltersDrawer } from "../FiltersDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import useFilterOptions from "utils/src/hooks/useFilterOptions";
import { ProjectDTOWithSdg } from "../MarketplaceTable";

export interface FilterProjectsProps {
  projects: ProjectDTOWithSdg[] | ProjectDTO[] | ProjectRefDTO[];
  areProjectRefs?: boolean;
  filterInputs?: FilterInput[];
  onSubmit(values?: any): void;
}

export const FilterContext = createContext(null);

const StyledFilterButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.chipColor.increaseOrDecrease,
  color: theme.palette.primary.main,
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "30px",
  alignSelf: "center",
  borderRadius: "45px",
  //border: '1.5px solid #b7ff08 !important',
  marginLeft: "5%",
  "&:hover": {
    backgroundColor: theme.palette.chipColor.main,
    border: "3px solid",
  },
}));

export const FilterProjects = (props: FilterProjectsProps) => {
  const { projects, filterInputs, areProjectRefs, onSubmit } = props;
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const { t } = useTranslation();

  const filterOptions = useFilterOptions(
    areProjectRefs ? (projects as ProjectRefDTO[]) : undefined,
    areProjectRefs ? undefined : (projects as ProjectDTO[])
  );

  useEffect(() => {
    let inputs: FilterInput[] = filterInputs
      ? [...filterInputs, ...FilterProjects.default.filterInputs]
      : FilterProjects.default.filterInputs;

    inputs.forEach((filter) => {
      switch (filter.label) {
        case "Location":
          filter.selectProps.options = filterOptions.regionsOptions;
          break;
        case "SDGs":
          filter.selectProps.options = filterOptions.sdgsOptions;
          break;
        case "Status":
          filter.selectProps.options = filterOptions.statusOptions;
          break;
        default:
          break;
      }
    });
    setFilters(inputs);
  }, [projects]);

  const onSubmitFilters = (filtersSent?: any[]) => {
    let submitValues: { [key: string]: any } = {};

    const fieldsToSubmit = [...(filtersSent || filters), { name: "search", value: searchInput }];

    for (const field of fieldsToSubmit) {
      submitValues[field.name] = field.value;
    }

    onSubmit(submitValues);
    onCloseDrawer();
  };

  const onClearFilters = () => {
    let filterClean = filterInputs || FilterProjects.default.filterInputs;
    filterClean.map((filter) => {
      if (filter.name !== "search") {
        filter.value = undefined;
      } else {
        filter.value = "";
        setSearchInput("");
      }
    });

    setFilters(filterClean);
  };

  const onCloseDrawer = useCallback(() => setOpen(false), []);

  const onOpenDrawer = useCallback(() => setOpen(true), []);

  const submitSearchFilter = (e: any) => {
    if (e.keyCode == 13) {
      const filterWithSearch = [...filters, { name: "search", value: e.target.value }];
      setFilters(filterWithSearch);
      onSubmitFilters(filterWithSearch);
    }
  };

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      <Stack flexDirection={"row"} marginLeft={0}>
        <TextField
          id="search"
          sx={{ mt: 1, mb: 1, mr: 1, width: "50ch" }}
          placeholder="Search by keywords"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onKeyDown={submitSearchFilter}
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
        <StyledFilterButton onClick={onOpenDrawer} variant="contained">
          <span>{t("filters")}</span>
        </StyledFilterButton>
        <FiltersDrawer
          open={open}
          onSubmitFilters={onSubmitFilters}
          onCloseDrawer={onCloseDrawer}
          onClearFilters={onClearFilters}
        ></FiltersDrawer>
      </Stack>
    </FilterContext.Provider>
  );
};

FilterProjects.default = {
  filterInputs: [
    // {
    //   name: "status",
    //   value: [],
    //   placeholder: "Status",
    //   label: "Status",
    //   type: "checkbox",
    //   typeValue: "string",
    //   selectProps: { options: [], multiple: true },
    // } as FilterInput,
    {
      name: "regions",
      value: [],
      placeholder: "Location",
      label: "Location",
      type: "checkbox",
      typeValue: "string",
      selectProps: { options: [], multiple: true },
    } as FilterInput,
    {
      name: "sdgs",
      value: [],
      placeholder: "SDGs",
      label: "SDGs",
      type: "checkbox",
      typeValue: "string",
      selectProps: { options: [], multiple: true },
    } as FilterInput,
    {
      name: "search",
      value: "",
    } as FilterInput,
  ],
};
