import { styled } from "@mui/material";
import { StatusTag } from "@smartb/g2-notifications";

//@ts-ignore
const StyledStatusTag = styled(StatusTag)({
  margin: "0",
  height: "27px",
  borderRadius: "15px",
  borderWidth: "2px !important",
});

export interface PlateformStatusProps {
  label: string;
  color: string;
}

export const PlateformStatus = (props: PlateformStatusProps) => {
  const { label, color } = props;
  return (
    //@ts-ignore
    <StyledStatusTag label={label} customColor={color} />
  );
};
