import { Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FormField,
  useFormWithPartialFields,
  FormPartialField,
  Form,
  FormAction,
} from "@smartb/g2-forms";
import { useMemo } from "react";
import { OrderBookOverviewDTO } from "datahub";
import { OrderBookValues } from "./OrderBookOverview";

export interface OrderBookEditOverviewProps {
  orderBook: OrderBookOverviewDTO;
  onChangeOrderBook?: (values: OrderBookValues) => void;
  className?: string;
  style?: React.CSSProperties;
  availableQuantity?: number;
}

export const OrderBookEditOverview = (props: OrderBookEditOverviewProps) => {
  const { orderBook, onChangeOrderBook, availableQuantity, ...other } = props;
  const { t } = useTranslation();

  const partialFields = useMemo(
    (): FormPartialField[] => [
      {
        name: "start",
        defaultValue: orderBook.startDate ? new Date(orderBook.startDate) : new Date(),
        validator: (value?: Date) =>
          !value ? (t("formError.fundCallStartRequired") as string) : undefined,
      },
      {
        name: "end",
        defaultValue: orderBook.endDate ? new Date(orderBook.endDate) : undefined,
        validator: (value?: Date) =>
          !value ? (t("formError.fundCallEndRequired") as string) : undefined,
      },
      {
        name: "volume",
        defaultValue: orderBook.volume,
        validator: (value?: number | string) => {
          if (!value || value === "") return t("formError.theVolumeIsRequired") as string;
          const number = Number(value);
          if (number < 0) return t("formError.theVolumeHasToBePositive") as string;
          if (availableQuantity !== undefined && number > availableQuantity)
            return t("formError.volumeAboveMax") as string;
          return undefined;
        },
      },
      {
        name: "price",
        defaultValue: orderBook.price,
        validator: (value?: number | string) => {
          if (!value || value === "") return t("formError.thePriceIsRequired") as string;
          const number = Number(value);
          if (number < 0) return t("formError.thePriceHasToBePositive") as string;
          return undefined;
        },
      },
    ],
    [orderBook, t, availableQuantity]
  );

  const orderBookFormState = useFormWithPartialFields({
    fields: partialFields,
    //@ts-ignore
    onSubmit: onChangeOrderBook,
  });

  const periodFields = useMemo((): FormField[] => {
    return [
      {
        key: "orderBook-form-start",
        name: "start",
        label: t("from"),
        type: "datepicker",
      },
      {
        key: "orderBook-form-end",
        name: "end",
        label: t("to"),
        type: "datepicker",
        datePickerProps: {
          style: {
            marginLeft: "10px",
          },
        },
      },
    ];
  }, [t]);

  const dataFields = useMemo((): FormField[] => {
    const unit = orderBook.unit.notation;
    return [
      {
        key: "orderBook-form-volume",
        name: "volume",
        label: t("volume"),
        type: "textfield",
        textFieldProps: {
          textFieldType: "number",
          inputProps: {
            min: 0,
          },
          iconPosition: "end",
          inputIcon: unit,
          noCheckOrClearIcon: true,
        },
      },
      {
        key: "orderBook-form-price",
        name: "price",
        label: t("currentPrice"),
        type: "textfield",
        textFieldProps: {
          textFieldType: "number",
          style: {
            marginLeft: "10px",
          },
          inputProps: {
            min: 0,
            step: "0.01",
          },
          iconPosition: "end",
          inputIcon: "$ / " + unit,
          noCheckOrClearIcon: true,
        },
      },
    ];
  }, [t, orderBook.unit]);

  const actions = useMemo(
    (): FormAction[] => [
      {
        label: t("validate"),
        key: "validateFormButton",
        type: "submit",
        className: "orderBook-form-validate",
      },
    ],
    [t]
  );

  return (
    <Box
      sx={{
        padding: "10px 0px",
        position: "relative",
        "& .MuiFormHelperText-root": {
          whiteSpace: "normal",
        },
      }}
      {...other}
    >
      <Divider
        sx={{
          marginBottom: "15px",
          borderBottomWidth: "2px",
          borderBottomColor: "#C4C4C4",
        }}
      />

      <Form
        fieldsStackProps={{ direction: "row", justifyContent: "space-between" }}
        fields={periodFields}
        formState={orderBookFormState}
      />

      <Form
        fieldsStackProps={{ direction: "row", justifyContent: "space-between" }}
        actionsStackProps={{ direction: "row", justifyContent: "flex-end" }}
        fields={dataFields}
        actions={actions}
        formState={orderBookFormState}
      />
    </Box>
  );
};
