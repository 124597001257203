import AdminProjects from "AdminApp/Pages/AdminProjects";
import EditProject from "AdminApp/Pages/Project/details";
import ProjectMarketConnected from "AdminApp/Pages/Project/market";
import { useAxessAuth } from "auth";
import { useMemo } from "react";
import { Route } from "react-router-dom";

export const useAdminAppRouter = () => {
  const { service } = useAxessAuth();
  return useMemo(() => !service.is_admin() ? <> </> : (
    <>
      <Route path="" element={<AdminProjects />}/>
      <Route path="project/:projectId/details" element={<EditProject isNew={false} />}/>
      <Route path="project/create" element={<EditProject isNew={true} />}/>
      <Route path="project/:projectId/market" element={<ProjectMarketConnected />}/>
    </>
  ), [service.is_admin]);
};
