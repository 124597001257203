import { Divider, Stack, Typography, Box } from "@mui/material";
import { EditButton, Button } from "@smartb/g2-components";
import { OrderBookOverviewDTO } from "datahub";
import { useCallback, useMemo, useState } from "react";
import { DataTicket } from "../DataTicket";
import { OrderBookType } from "../OderBookType";
import { OrderBookStatus } from "../PlateformStatus";
import { useTranslation } from "react-i18next";
import { OrderBookEditOverview } from "./OrderBookEditOverview";
import { OrderBookStatusValues, OrderBookTypeValues } from "../types";
import { formatNumber } from "utils";

export type OrderBookValues = {
  start: Date;
  end: Date;
  price: number;
  volume: number;
};

export interface OrderBookOverviewProps {
  orderBook: OrderBookOverviewDTO;
  availableQuantity?: number;
  canEdit?: boolean;
  edit?: boolean;
  onChangeOrderBook?: (values: OrderBookValues) => void;
  onPublish?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export const OrderBookOverview = (props: OrderBookOverviewProps) => {
  const {
    orderBook,
    canEdit = false,
    edit = false,
    onChangeOrderBook,
    onPublish,
    ...other
  } = props;
  const [editState, setEditState] = useState(edit);
  const { t, i18n } = useTranslation();

  const period = useMemo(() => {
    const startDate = new Date(orderBook.startDate);
    const endDate = orderBook.endDate ? new Date(orderBook.endDate) : undefined;
    return {
      start: startDate.toLocaleDateString(i18n.language),
      end: endDate ? endDate.toLocaleDateString(i18n.language) : undefined,
    };
  }, [orderBook.startDate, orderBook.endDate, i18n.language]);

  const onEdit = useCallback(() => setEditState(true), []);

  const onChangeOrderBookMemoized = useCallback(
    (values: OrderBookValues) => {
      onChangeOrderBook && onChangeOrderBook(values);
      setEditState(false);
    },
    [onChangeOrderBook]
  );

  if (editState)
    return (
      <OrderBookEditOverview
        {...other}
        orderBook={orderBook}
        onChangeOrderBook={onChangeOrderBookMemoized}
      />
    );
  return (
    <Box
      sx={{
        paddingTop: "10px",
        position: "relative",
        minWidth: "300px",
        "@media (max-width: 600px)": {
          minWidth: "280px",
        },
      }}
      {...other}
    >
      <Divider
        sx={{
          marginBottom: "15px",
          borderBottomWidth: "2px",
          borderBottomColor: "#C4C4C4",
        }}
      />
      <Stack
        direction="row"
        justifyContent="center"
        position="absolute"
        width="100%"
        top="0px"
        left="0px"
      >
        {orderBook.type.value === OrderBookTypeValues.exante() ? (
          <>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#C4C4C4",
                marginRight: "20px",
                background: "white",
                padding: "0 5px",
              }}
            >
              {t("fromDate", { date: period.start })}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#C4C4C4",
                marginLeft: "20px",
                background: "white",
                padding: "0 5px",
              }}
            >
              {t("toDate", { date: period.end })}
            </Typography>
          </>
        ) : (
          <Typography
            variant="subtitle2"
            sx={{ color: "#C4C4C4", background: "white", padding: "0 5px" }}
          >
            {t("startedOn", { date: period.start })}
          </Typography>
        )}
      </Stack>
      <Stack spacing={2} padding="10px" paddingTop="5px">
        <Stack direction="row" justifyContent="space-between">
          {canEdit && <OrderBookStatus status={orderBook.status.value} />}
          <OrderBookType type={orderBook.type.value} />
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <DataTicket
            label={t("volume")}
            data={formatNumber(orderBook.volume, i18n.language) + " " + orderBook.unit.notation}
          />
          <DataTicket
            label={t("currentPrice")}
            data={
              formatNumber(orderBook.price, i18n.language, 2, true) +
              " / " +
              orderBook.unit.notation
            }
          />
        </Stack>
        {canEdit && OrderBookStatusValues.created() === orderBook.status.value && (
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <EditButton onClick={onEdit}>{t("edit")}</EditButton>
            <Button onClick={onPublish}>{t("publish")}</Button>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
