import { ArrowForwardIosRounded } from "@mui/icons-material";
import { Paper, Stack, styled, Typography } from "@mui/material";
import { OrderBookOverviewDTO, ProjectRefDTO } from "datahub";
import { useMemo } from "react";
import { BluredPaper } from "../BluredPaper";
import { OrderBookTicket } from "../OrderBookTicket";
import { ProjectTitle } from "../ProjectTitle";
import { SdgCard } from "../SdgCard";
import { useTranslation } from "react-i18next";
import defaultImage from "./forest.png";

const StyledPaper = styled(Paper)({
  padding: "5px",
  maxWidth: "400px",
  position: "relative",
  borderRadius: "5px",
  background: "#c0daed",
  overflow: "hidden",
  "& .projectImage": {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    top: "0px",
    left: "0px",
  },
});

export interface ProjectSubProps {
  projectRef: ProjectRefDTO;
  orderbooks: OrderBookOverviewDTO[];
  onClickDiscoverMore?: () => void;
  onClickOrderBook?: (orderBook: OrderBookOverviewDTO) => void;
  className?: string;
}

export const ProjectSub = (props: ProjectSubProps) => {
  const { orderbooks, onClickDiscoverMore, className, onClickOrderBook, projectRef } = props;
  const { t } = useTranslation();

  const orderbooksDisplay = useMemo(
    () =>
      orderbooks.map((orderBook, index) => (
        <OrderBookTicket
          key={index}
          orderBook={orderBook}
          onClick={() => onClickOrderBook && onClickOrderBook(orderBook)}
        />
      )),
    [orderbooks, onClickOrderBook]
  );

  const image = useMemo(() => {
    if (projectRef.details.image) return projectRef.details.image;
    return defaultImage;
  }, [projectRef.details.image]);

  return (
    <StyledPaper className={`${className ?? ""} projectSubmary-paper`}>
      <img
        className="projectImage"
        src={image}
        alt={"image of the project: " + projectRef.details.name}
      />
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="flex-start"
        marginBottom="15px"
      >
        <BluredPaper>
          <ProjectTitle
            defaultProjectName={projectRef.details.name}
            defaultLocation={projectRef.country}
          />
        </BluredPaper>
        <BluredPaper
          sx={{
            padding: "2px",
          }}
        >
          <SdgCard defaultSdgs={projectRef.sdgList} maxLength={3} size="small" />
        </BluredPaper>
      </Stack>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        marginBottom="15px"
      >
        <BluredPaper sx={{ padding: "3px 10px" }}>
          <Typography variant="body2">{t("orderbooks") + ":"}</Typography>
        </BluredPaper>
        <BluredPaper
          sx={{
            padding: "3px 10px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={onClickDiscoverMore}
        >
          <Typography variant="body2">{t("marketplacePage.discoverTheProject")}</Typography>
          <ArrowForwardIosRounded sx={{ marginRight: "-5px" }} />
        </BluredPaper>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          width: "100%",
          position: "relative",
          overflowX: "auto",
          marginTop: "-50px",
          paddingTop: "50px",
          paddingBottom: "5px",
          overflowY: "visible",
        }}
      >
        {orderbooksDisplay}
      </Stack>
    </StyledPaper>
  );
};
