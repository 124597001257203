import { Typography, Divider } from "@mui/material";
import { BasicTextEditor } from "components";
import { ProjectDTO } from "datahub";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generateSpacedText } from "utils";

interface ProjectDetailsProps {
  edit?: boolean;
  project: ProjectDTO;
  updateProject?: (concatKeysTab: string[], newValues: any[]) => void;
}

export const ProjectDetails = (props: ProjectDetailsProps) => {
  const { edit = false, project, updateProject } = props;
  const { t } = useTranslation();

  const onChangeDescription = useCallback(
    (value: string) => {
      updateProject && updateProject(["details.description"], [value]);
    },
    [updateProject]
  );

  const onChangeSummary = useCallback(
    (value: string) => {
      updateProject && updateProject(["details.summary"], [value]);
    },
    [updateProject]
  );

  const summary = useMemo(() => {
    return generateSpacedText(project.details.summary);
  }, [edit, project.details.description]);

  const presentation = useMemo(() => {
    return generateSpacedText(project.details.description);
  }, [edit, project.details.description]);

  return (
    <>
      {edit ? (
        <>
          <Typography marginTop="60px" variant="h5">{t("editProjectPage.theSummary")}</Typography>
          <Divider
            sx={{
              marginTop: "10px",
              marginBottom: "15px",
              borderBottomWidth: "2px",
              borderBottomColor: "#C4C4C4",
            }}
          />
          <BasicTextEditor
            text={project.details.summary}
            onChange={onChangeSummary}
            placeholder={t("editProjectPage.typeProjectSummary")}
          />
          <Typography marginTop="60px" variant="h5">{t("editProjectPage.thePresentation")}</Typography>
          <Divider
            sx={{
              marginTop: "10px",
              marginBottom: "15px",
              borderBottomWidth: "2px",
              borderBottomColor: "#C4C4C4",
            }}
          />
          <BasicTextEditor
            text={project.details.description}
            onChange={onChangeDescription}
            placeholder={t("editProjectPage.typeProjectPresentation")}
          />
        </>

      ) : (
        <>
        <Typography marginTop="60px" variant="h5">{t("editProjectPage.theSummary")}</Typography>
          <Typography align="justify"  >
            {summary}
          </Typography>
          <Typography marginTop="60px" variant="h5">{t("editProjectPage.thePresentation")}</Typography>
          <Typography align="justify"  >
            {presentation}
          </Typography>
        </>
      )}
    </>
  );
};
