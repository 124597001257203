import { alpha } from "@mui/material";
import { CustomThemeOptions } from "@mui/material/styles";
import { createBreakpoints } from "@mui/system";
import { Theme as AruiTheme } from "@smartb/g2-themes";
import { DeepPartial } from "utils";

const axessTheme: DeepPartial<AruiTheme> = {
  name: "axess",
  colors: {
    primary: "#03323D",
    secondary: "#046079",
    tertiary: "#7F979C",
  },
  shadows: [
    "none",
    "0px 3px 12px rgba(0, 0, 0, 0.06)",
    "0px 3.125px 14px rgba(0, 0, 0, 0.08)",
    "0px 3.25px 16px rgba(0, 0, 0, 0.1)",
    "0px 3.375px 18px rgba(0, 0, 0, 0.12)",
    "0px 3.5px 20px rgba(0, 0, 0, 0.14)",
    "0px 3.625px 22px rgba(0, 0, 0, 0.16)",
    "0px 3.75px 24px rgba(0, 0, 0, 0.18)",
    "0px 3.875px 26px rgba(0, 0, 0, 0.2)",
    "0px 4px 28px rgba(0, 0, 0, 0.22)",
    "0px 4.125px 30px rgba(0, 0, 0, 0.24)",
    "0px 4.25px 32px rgba(0, 0, 0, 0.26)",
    "0px 4.375px 34px rgba(0, 0, 0, 0.28)",
    "0px 4.5px 36px rgba(0, 0, 0, 0.3)",
  ],
};

const congoCarbonTheme: DeepPartial<AruiTheme> = {
  name: "congoCarbon",
  colors: {
    primary: "#000000",
    secondary: "#b7ff08",
    tertiary: "#000000",
  },
  shadows: [
    "none",
    "0px 3px 12px rgba(0, 0, 0, 0.06)",
    "0px 3.125px 14px rgba(0, 0, 0, 0.08)",
    "0px 3.25px 16px rgba(0, 0, 0, 0.1)",
    "0px 3.375px 18px rgba(0, 0, 0, 0.12)",
    "0px 3.5px 20px rgba(0, 0, 0, 0.14)",
    "0px 3.625px 22px rgba(0, 0, 0, 0.16)",
    "0px 3.75px 24px rgba(0, 0, 0, 0.18)",
    "0px 3.875px 26px rgba(0, 0, 0, 0.2)",
    "0px 4px 28px rgba(0, 0, 0, 0.22)",
    "0px 4.125px 30px rgba(0, 0, 0, 0.24)",
    "0px 4.25px 32px rgba(0, 0, 0, 0.26)",
    "0px 4.375px 34px rgba(0, 0, 0, 0.28)",
    "0px 4.5px 36px rgba(0, 0, 0, 0.3)",
  ],
};

const breakpoints = createBreakpoints({});

const muiThemeAxess: CustomThemeOptions = {
  themeName: "axess",
  palette: {
    text: {
      primary: "#000000",
      secondary: "#7F979C",
      marketFilterHeader: "#8C999D",
      portfolioHeaderCardsTitle: "#7F979C",
      selectFont: "#000000",
      tableSecondary: "#7F979C",
      modalSecondary: "#7F979C",
      projectSecondary: "#7F979C",
      mapFont: "#ffffff",
      tabsFontColor: "#046079",
      moreProjectsFont: "#046079",
      actionBarFont: "#046079",
      filtersFont: "#ffffff",
    },
    primary: {
      main: "#03323F",
    },
    secondary: {
      main: "#046079",
    },
    chipColor: {
      increaseOrDecrease: "#E5EAEB",
      main: "#F3F7F8",
      filterChip: "#ffffff",
    },
    selectBackground: {
      main: "#ffffff",
    },
    selectIcon: {
      main: "#98A5AE",
    },
    dividerColor: {
      main: "#E5EAEB",
    },
    chartColorPrimary: {
      main: "#006077",
    },
    chartColorSecondary: {
      main: "#D6FE6E",
    },
    scopesColors: {
      main: "",
      scope1: "#006077",
      scope2: "#6EA4B1",
      scope3: "#A6C7CF",
      scope4: "#4C8F9F",
      scope5: "#CCDFE4",
      scope6: "#196F84",
      scope7: "#337F92",
    },
    sectorsColors: {
      main: "",
      agriculture: "#006077",
      energyEfficiency: "#196F84",
      forestryLandUse: "#337F92",
      householdDevices: "#4C8F9F",
      renewableEnergy: "#6EA4B1",
      wasteManagement: "#A6C7CF",
      waterManagement: "#CCDFE4",
    },
    mapBgColor: {
      main: "#A2BCC3",
    },
    mapColor: {
      main: "#FFF",
    },
    mapMarkerBgColor: {
      main: "#B7FF08",
    },
    mapMarkerColor: {
      main: "#03323D",
    },
    mapAreaFillColor: {
      main: "#72bf67",
    },
    actionBarBgColor: {
      main: "#FFFFFF"  
    },
    filterButtonBgColor: {
      main:"#046079",
      secondary: "#6EA4B1",
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          [breakpoints.down("sm")]: {
            fontSize: "0.7rem",
          },
        },
      },
    },
  },
  typography: {
    allVariants: {
      fontWeight: 600,
    },
    h1: {
      [breakpoints.down("sm")]: {
        fontSize: "5rem",
      },
    },
    h2: {
      [breakpoints.down("sm")]: {
        fontSize: "3rem",
      },
    },
    h3: {
      [breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
    },
    h4: {
      [breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
    },
    h5: {
      [breakpoints.down("sm")]: {
        fontSize: "1.3rem",
      },
    },
    h6: {
      [breakpoints.down("sm")]: {
        fontSize: "1.1rem",
      },
    },
    subtitle1: {
      [breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    subtitle2: {
      [breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
    },
    body1: {
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    body2: {
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
    },
    button: {
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
    },
    caption: {
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.7rem",
      },
    },
    overline: {
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.7rem",
      },
    },
  },
};

const muiThemeCongoCarbon: CustomThemeOptions = {
  themeName: "congoCarbon",
  palette: {
    text: {
      primary: "#000000",
      secondary: "#7F979C",
      marketFilterHeader: "#000000",
      portfolioHeaderCardsTitle: "#000000",
      selectFont: "#ffffff",
      tableSecondary: "#9b9b9b",
      modalSecondary: "#9b9b9b",
      projectSecondary: "#7F979C",
      mapFont: "#000000",
      tabsFontColor: "#000000",
      moreProjectsFont: "#000000",
      actionBarFont: "#000000",
      filtersFont: "#ffffff",
    },
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#b7ff08",
    },
    chipColor: {
      increaseOrDecrease: "#E5EAEB",
      main: "#F3F7F8",
      filterChip: "#ecfebb",
    },
    selectBackground: {
      main: "#000000",
    },
    selectIcon: {
      main: "#ffffff",
    },
    dividerColor: {
      main: "#000000",
    },
    chartColorPrimary: {
      main: "#000000",
    },
    chartColorSecondary: {
      main: "#D6FE6E",
    },
    scopesColors: {
      main: "",
      scope1: alpha("#000000", 1.0),
      scope2: "#B7FF08",
      scope3: alpha("#000000", 0.3),
      scope4: alpha("#000000", 0.5),
      scope5: alpha("#000000", 0.1),
      scope6: alpha("#000000", 0.7),
      scope7: alpha("#000000", 0.6),
    },
    sectorsColors: {
      main: "",
      agriculture: alpha("#000000", 1.0),
      energyEfficiency: alpha("#000000", 0.7),
      forestryLandUse: alpha("#000000", 0.6),
      householdDevices: alpha("#000000", 0.5),
      renewableEnergy: alpha("#000000", 0.4),
      wasteManagement: alpha("#000000", 0.3),
      waterManagement: alpha("#000000", 0.1),
    },
    mapBgColor: {
      main: "#ffffff",
    },
    mapColor: {
      main: "#e4e4e4",
    },
    mapMarkerBgColor: {
      main: "#000000",
    },
    mapMarkerColor: {
      main: "#B7FF08",
    },
    mapAreaFillColor: {
      main: "#72bf67",
    },
    actionBarBgColor: {
      main: "#FFFFFF"
    },
    filterButtonBgColor: {
      main:"#000000",
      secondary: alpha("#000000", 0.6),
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          [breakpoints.down("sm")]: {
            fontSize: "0.7rem",
          },
        },
      },
    },
  },
  typography: {
    allVariants: {
      fontWeight: 600,
    },
    h1: {
      [breakpoints.down("sm")]: {
        fontSize: "5rem",
      },
    },
    h2: {
      [breakpoints.down("sm")]: {
        fontSize: "3rem",
      },
    },
    h3: {
      [breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
    },
    h4: {
      [breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
    },
    h5: {
      [breakpoints.down("sm")]: {
        fontSize: "1.3rem",
      },
    },
    h6: {
      [breakpoints.down("sm")]: {
        fontSize: "1.1rem",
      },
    },
    subtitle1: {
      [breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    subtitle2: {
      [breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
    },
    body1: {
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    body2: {
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
    },
    button: {
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
    },
    caption: {
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.7rem",
      },
    },
    overline: {
      fontWeight: 500,
      [breakpoints.down("sm")]: {
        fontSize: "0.7rem",
      },
    },
  },
};

//@ts-ignore
const pickTheme = (themeName: string) => {
  let theme: DeepPartial<AruiTheme> = axessTheme;
  let muiTheme: CustomThemeOptions = muiThemeAxess;

  switch (themeName) {
    case "carbonCongoTheme": {
      theme = congoCarbonTheme;
      muiTheme = muiThemeCongoCarbon;
      break;
    }
    default:
      break;
  }

  return { theme, muiTheme };
};

//@ts-ignore
const selectedTheme = pickTheme(window?._env_?.APP_THEME);

export const theme: DeepPartial<AruiTheme> = selectedTheme.theme;
export const muiTheme: CustomThemeOptions = selectedTheme.muiTheme;
