import { AdminTransaction } from "./AdminTransaction";
import { OrderDTO, UserRefDTO } from "datahub";

export interface CanceledAdminTransactionProps {
  order: OrderDTO;
  userRef: UserRefDTO;
}

export const CanceledAdminTransaction = (props: CanceledAdminTransactionProps) => {
  const { order, userRef } = props;
  return <AdminTransaction userRef={userRef} withPrice order={order} />;
};
