import { Box, Paper, Stack, Typography } from "@mui/material";
import { useAxessAuth } from "auth";
import {
  Loading,
  OrderBookOverview,
  TransactionsWallet,
  TransactionsWalletFiltersValues,
} from "components";
import {
  cancelOrder,
  getOrders,
  getPublishedOrderBookOverviews,
  OrderDTO,
  OrderGetAllQueryDTO,
  UserRefDTO,
  validateOrder,
} from "datahub";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAsyncResponse } from "utils";
import { Button } from "@smartb/g2-components";
import { Link, LinkProps } from "react-router-dom";

interface ProjectMarketProps {
  setTitle: (title: string) => void;
  userRefs: Map<string, UserRefDTO>;
}

export const ProjectMarket = (props: ProjectMarketProps) => {
  const { setTitle, userRefs } = props;
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();

  const { keycloak, service } = useAxessAuth();

  useEffect(() => {
    setTitle(t("projectMarket"));
  }, [setTitle, t]);

  const getOrderBooksMemoized = useCallback(
    () => getPublishedOrderBookOverviews(t, [projectId]),
    [projectId, t]
  );

  const orderBooksRequest = useAsyncResponse(getOrderBooksMemoized);

  const getOrdersMemoized = useCallback(
    (params?: TransactionsWalletFiltersValues) => {
      const period =
        params?.from && params?.to ? { start: params.from, end: params.to } : undefined;
      return getOrders(
        {
          projectId: projectId,
          period: period,
          unitId: params?.unit,
        } as OrderGetAllQueryDTO,
        t,
        keycloak.token
      );
    },
    [projectId, t, keycloak.token]
  );

  const ordersRequest = useAsyncResponse(getOrdersMemoized);

  const onRefuseOrder = useCallback(
    async (comment: string, order: OrderDTO) => {
      await cancelOrder(
        {
          canceller: service.getUser().id,
          id: order.id,
          comment: comment,
        },
        t,
        keycloak.token
      );
      ordersRequest.execute();
    },
    [service.getUser, ordersRequest.execute, t, keycloak.token]
  );

  const onValidateOrder = useCallback(
    async (order: OrderDTO) => {
      await validateOrder(
        {
          id: order.id,
          validator: service.getUser().id,
        },
        t,
        keycloak.token
      );
      ordersRequest.execute();
    },
    [service.getUser, ordersRequest.execute, t, keycloak.token]
  );

  const orderBooks = useMemo(
    () =>
      orderBooksRequest.result &&
      orderBooksRequest.result.map((orderBook) => (
        <Paper
          key={orderBook.id}
          sx={{
            paddingTop: "10px",
          }}
        >
          <OrderBookOverview orderBook={orderBook} />
        </Paper>
      )),
    [orderBooksRequest]
  );

  return (
    <Box
      sx={{
        padding: "15px 10px",
      }}
    >
      {orderBooksRequest.status === "SUCCESS" ? (
        <Stack direction="row" flexWrap="wrap" spacing={3}>
          {orderBooks}
        </Stack>
      ) : (
        <Loading />
      )}

      <Typography variant="h5">{t("projectMarketPage.allThePositions")}</Typography>
      <Paper
        sx={{
          margin: "10px",
        }}
      >
        <Box
          sx={{
            margin: "10px",
            marginTop: "0px",
          }}
        >
          <TransactionsWallet
            userRefs={userRefs}
            isAdmin
            onRefuseOrder={onRefuseOrder}
            onValidateOrder={onValidateOrder}
            orders={ordersRequest.result ?? []}
            isLoading={ordersRequest.status !== "SUCCESS" && userRefs.size === 0}
          />
        </Box>
      </Paper>
      <Button<LinkProps>
        component={Link}
        componentProps={{ to: `/admin/project/${projectId}/details` }}
      >
        {t("projectDetails")}
      </Button>
    </Box>
  );
};
