import { connect } from "react-redux";
import { State } from "store";
import { refs } from "store/refs";
import { title } from "store/title";

const mapStateToProps = (state: State) => ({
  measures: refs.selectors.mesures(state),
  protocolTypes: refs.selectors.protocolTypes(state),
  projectRefs: refs.selectors.projectRefs(state),
  orderBookOverviews: refs.selectors.orderBookOverviews(state),
  userRights: refs.selectors.userProjectRights(state),
  powerBiToken: refs.selectors.powerBiToken(state),
});

const mapDispatchToProps = {
  setTitle: title.actions.setTitle,
};

export default connect(mapStateToProps, mapDispatchToProps);
