import React from "react";

interface RiskTriangleHalfProps {
  isLeft?: boolean;
}

export const RiskTriangleHalf: React.FC<RiskTriangleHalfProps> = (props) => {
  const { isLeft } = props;
  return (
    <div
      style={{
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: isLeft ? "70vh 0px 0px 20px" : "70vh 20px 0px 0",
        borderColor: "#04607924 transparent transparent transparent",
        opacity: isLeft ? 0.76 : 1,
      }}
    ></div>
  );
};
