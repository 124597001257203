import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Stack,
  Tooltip,
  TabProps,
  Typography,
  useTheme as useThemeMUI,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "@smartb/g2";
import { useAxessAuth } from "auth";
import {
  ProjectTable,
  ArrowRight,
  ArrowLeft,
  AxessPaper,
  ConeOfUncertainty,
  Loading,
  PowerBiIframe,
  ProjectCard,
  ProjectPresentation,
  SdgTicket,
  SectorTicket,
  SlideMenu,
  TransactionCreationValues,
} from "components";
import { AxessButton } from "components/src/Button/AxessButton";
import { ProtocolsTicket } from "components/src/ProtocolsTicket";
import {
  buy,
  getOrders,
  getProject,
  getAllChildrenProjects,
  getProtocolSteps,
  getPublishedOrderBookOverviews,
  listSimilarProjects,
  OrderGetAllQueryDTO,
  ProjectRefDTO,
  ProjectType,
  ProtocolDTO,
  SdgDTO,
  SectorDTO,
  ProjectDTO,
  UserProjectRightDTO,
  UserAccessEnum,
  ProjectStatusValues,
} from "datahub";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigateProjectDetails, useNavigateProjects } from "store/router";
import { useAsyncResponse, ProjectWithSDGHelper, distinct } from "utils";
import useUserAccess from "utils/src/hooks/useUserAccess";
import { About } from "./components/About";
import { Calls } from "./components/Calls";
import { Transactions } from "./components/Transactions";

interface EditProjectProps {
  setTitle: (title: string) => void;
  projectRefs: Map<string, ProjectRefDTO>;
  userRights: Map<string, UserProjectRightDTO>;
}

export const EditProject = (props: EditProjectProps) => {
  const { setTitle, projectRefs, userRights } = props;
  const themeMUI = useThemeMUI();
  const { service, keycloak } = useAxessAuth();
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const [currentTab, setCurrentTab] = useState(0);
  const [showConeOfUncertainty, setShowConeOfUncertainty] = useState(false);
  const [coneOfUncertaintyProtocolName, setConeOfUncertaintyProtocolName] = useState("");
  const [loadingTimeLine, setLoadingTimeLine] = useState(false);
  const [showProjectReport, setShowProjectReport] = useState(false);
  const [projectsWithSdg, setProjectsWithSdg] = useState([]);
  const [shouldShowBITooltip, setShouldShowBITooltip] = useState<boolean>(true);

  const accessAndProjects = useUserAccess(
    userRights,
    Array.from(projectRefs.values()).map((project) => project.id)
  );

  const getProjectMemoized = useCallback(
    () => getProject(projectId, t),
    [projectId, keycloak.token]
  );

  const projectRequest = useAsyncResponse(getProjectMemoized);

  useEffect(() => {
    setTitle(t("viewProject"));
  }, [setTitle, t]);

  const projectProtocolData = useMemo(() => {
    const trace: string[] = [];
    const sdgList: SdgDTO[] = [];
    const sectors: SectorDTO[] = [];
    const protocols: { name: string; image: string; typeName: string }[] = [];
    projectRequest.result?.protocols.forEach((protocol: ProtocolDTO) => {
      protocol.details.sdgList.forEach((sdg: SdgDTO) => {
        if (!trace.includes("sdg" + sdg.id)) {
          trace.push("sdg" + sdg.id);
          sdgList.push(sdg);
        }
      });
      if (!trace.includes("name" + protocol.details.name)) {
        trace.push("name" + protocol.details.name);
        protocols.push({
          name: protocol.details.name,
          image: protocol.type.image,
          typeName: protocol.type.name,
        });
      }
      if (protocol.details.sector && !trace.includes(`trace${protocol.details.sector?.id}`)) {
        trace.push(`trace${protocol.details.sector?.id}`);
        sectors.push(protocol.details.sector);
      }
    });
    return {
      sdgList,
      sectors,
      protocols,
    };
  }, [projectRequest.result]);

  const getOrderBooksMemoized = useCallback(
    () => getPublishedOrderBookOverviews(t, [projectId]),
    [projectId, t]
  );

  const orderBooksRequest = useAsyncResponse(getOrderBooksMemoized);

  const getOrdersMemoized = useCallback(() => {
    return getOrders(
      {
        projectId: projectId,
        emitterId: service.getUser().id,
      } as OrderGetAllQueryDTO,
      t,
      keycloak.token
    );
  }, [projectId, t, keycloak.token, service.getUser]);

  const ordersRequest = useAsyncResponse(getOrdersMemoized, false);

  useEffect(() => {
    if (keycloak.authenticated) {
      ordersRequest.execute();
    }
  }, [keycloak.authenticated]);

  const listSimilarProjectsMemoized = useCallback(() => {
    return listSimilarProjects(
      projectId,
      4,
      !service.is_admin() ? Array.from(accessAndProjects.userAccessMap.keys()) : undefined
    );
  }, [projectId, accessAndProjects]);

  const similarProjectsRequest = useAsyncResponse(listSimilarProjectsMemoized, false);

  const gotoProjects = useNavigateProjects();

  const gotoProjectsMemoized = useCallback(() => {
    gotoProjects();
  }, [gotoProjects]);

  useEffect(() => {
    similarProjectsRequest.execute();
  }, [projectId, accessAndProjects.userAccessMap.size]);

  const resetTabHandler = () => {
    setCurrentTab(0);
  };

  const tabs = useMemo(
    (): TabProps[] => [
      {
        label: t("about"),
      },
      ...(projectRequest?.result?.projectType === ProjectType.PARENT
        ? [{ label: t("subProject") } as TabProps]
        : []),
      ...(keycloak.authenticated
        ? projectRequest?.result?.projectType === ProjectType.PARENT
          ? [
              {
                label: t("transactions"),
              } as TabProps,
            ]
          : [
              {
                label: t("calls"),
              } as TabProps,
              {
                label: t("transactions"),
              } as TabProps,
            ]
        : []),
    ],
    [keycloak.authenticated, t, projectRequest]
  );

  const onBuy = useCallback(
    async (values: TransactionCreationValues, orderBookId: string) => {
      await buy(
        {
          emitter: service.getUser().id,
          id: orderBookId,
          price: Number(values.price),
          volume: Number(values.volume),
          deadline: values.validity.getTime(),
        },
        t,
        keycloak.token
      );
      ordersRequest.execute();
    },
    [service.getUser, t, keycloak.token, ordersRequest.execute]
  );

  const openConeOfUncertainty = (protocolName: string, protocolId: string) => {
    setLoadingTimeLine(true);
    setConeOfUncertaintyProtocolName(protocolName);
    onTimelineOpen.execute(protocolId);
  };
  const gotoProjectDetails = useNavigateProjectDetails();

  const gotoParentProjectDetails = useCallback(() => {
    gotoProjectDetails(projectRequest.result?.parentRef.projectId);
    resetTabHandler();
  }, [gotoProjectDetails, projectRequest.result]);

  const projectSuggestions = useMemo(() => {
    const projects = similarProjectsRequest.result ?? [];
    return projects.map((project: ProjectDTO) => (
      <ProjectCard
        key={project.id}
        id={`projectCard-${project.id}`}
        project={project}
        isParent={project.projectType === ProjectType.PARENT}
        className="projectCard"
        onClick={() => {
          resetTabHandler();
          gotoProjectDetails(project.id);
        }}
      />
    ));
  }, [similarProjectsRequest.result, gotoProjectDetails]);

  const getAllChildrenProjectAuth = useCallback(() => {
    return getAllChildrenProjects(
      {
        parentRefId: projectId,
        projectIds: Array.from(accessAndProjects.userAccessMap.keys()).filter(
          (elem) => projectId !== elem
        ),
      },
      t,
      keycloak.token
    );
  }, [keycloak.token, accessAndProjects.userAccessMap.size, projectId, gotoProjectDetails]);

  const childProjects = useAsyncResponse(getAllChildrenProjectAuth, false);

  useEffect(() => {
    childProjects.execute();
  }, [projectId, accessAndProjects.userAccessMap.size]);

  const createProjectsWithSdg: ProjectDTO[] = useMemo(() => {
    return ProjectWithSDGHelper(childProjects?.result);
  }, [childProjects?.result]);

  useEffect(() => {
    if (childProjects?.result) {
      setProjectsWithSdg(
        createProjectsWithSdg.filter(
          (project) => project.status.value === ProjectStatusValues.published()
        )
      );
    }
  }, [childProjects?.result]);

  const parentProjectSDGs = useMemo(() => {
    const childSDGList = [];
    const childSectors = [];
    let childProtocols = [];
    let summarizedProtocols = [];
    let uniqueChildSDGList = [];
    let uniqueChildSectors = [];

    if (!projectsWithSdg || projectRequest?.result?.projectType !== ProjectType.PARENT) {
      childProtocols = undefined;
      return {
        uniqueChildSDGList,
        uniqueChildSectors,
        summarizedProtocols,
      };
    }

    childProjects?.result?.forEach((child) => {
      if (child?.protocols?.length > 0) {
        child?.protocols?.forEach((childProtocol) => {
          childProtocols?.push(childProtocol);
          if (childProtocol?.type?.name === "SDGs") {
            childSDGList?.push(...childProtocol?.details?.sdgList);
          }
          if (childProtocol?.details?.sector) {
            childSectors?.push(childProtocol?.details?.sector);
          }
        });
      }
    });

    uniqueChildSDGList = distinct(childSDGList, (sdg) => sdg.id);
    uniqueChildSectors = distinct(childSectors, (sector) => sector.id);
    summarizedProtocols = childProtocols.map((protocol: ProtocolDTO) => {
      return {
        name: protocol.details.name,
        image: protocol.type.image,
        typeName: protocol.type.name,
      };
    });

    return {
      uniqueChildSDGList,
      uniqueChildSectors,
      summarizedProtocols,
    };
  }, [projectRequest?.result, projectsWithSdg, childProjects?.result]);

  const firstTab = useMemo(
    () => (
      <>
        {projectRequest?.result?.projectType === ProjectType.PARENT ? (
          <ProjectTable
            projects={projectsWithSdg}
            onClickView={gotoProjectDetails}
            isReadOnly={true}
            resetTabHandler={resetTabHandler}
            isProjectDetails
          />
        ) : (
          <Calls
            onBuy={onBuy}
            orderBooks={orderBooksRequest?.result}
            project={projectRequest?.result}
            canBuy={
              projectRequest?.result &&
              (accessAndProjects?.userAccessMap.get(projectRequest.result.id) ===
                UserAccessEnum.WRITE_AND_READ ||
                service.is_admin())
            }
          />
        )}
      </>
    ),
    [
      orderBooksRequest.result,
      ordersRequest.result,
      projectRequest,
      projectsWithSdg,
      childProjects,
      accessAndProjects,
    ]
  );

  const secondTab = useMemo(
    () => (
      <Transactions
        orders={ordersRequest?.result}
        project={projectRequest?.result}
        projectRefs={projectRefs}
      />
    ),
    [orderBooksRequest.result, ordersRequest.result, projectRequest]
  );

  const tabContent = useMemo(
    () => (
      <>
        {currentTab === 2 ? (
          <>{secondTab}</>
        ) : currentTab === 1 ? (
          <>{firstTab}</>
        ) : (
          <About
            sdgs={
              projectRequest?.result?.projectType === ProjectType.PARENT
                ? parentProjectSDGs.uniqueChildSDGList
                : projectProtocolData.sdgList
            }
            project={projectRequest?.result}
            childrenProjects={childProjects?.result}
            openConeOfUncertainty={openConeOfUncertainty}
            isLoading={loadingTimeLine}
            isGuestUser={!keycloak.authenticated}
          />
        )}
      </>
    ),
    [
      orderBooksRequest.result,
      ordersRequest.result,
      projectRequest,
      firstTab,
      secondTab,
      currentTab,
      accessAndProjects,
      keycloak.token,
      childProjects,
    ]
  );

  const fetchProtocolSteps = useCallback(
    (id: string) => {
      return getProtocolSteps({ id }, t, keycloak.token).then((response) => {
        setLoadingTimeLine(false);
        return response;
      });
    },
    [keycloak.token]
  );

  const onTimelineOpen = useAsyncResponse(fetchProtocolSteps, false);

  const toggleProjectReport = () => {
    setShowProjectReport(!showProjectReport);
  };

  useEffect(() => {
    if (onTimelineOpen.result) {
      setShowConeOfUncertainty(true);
    }
  }, [onTimelineOpen.execute, onTimelineOpen.result]);

  const projectReportMemoized = useMemo(() => {
    return <PowerBiIframe height={550} link={projectRequest.result?.reportUrl} />;
  }, [projectRequest.result]);

  const handleOpenBITooltip = () => {
    setShouldShowBITooltip(true);
  };

  const handleCloseBITooltip = () => {
    setShouldShowBITooltip(false);
  };

  const tooltipHeader = useMemo(
    () => (
      <>
        {navigator.userAgent.match(/iPad/i) ? (
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">
              {t("projectPage.projectReport")}
              <Tooltip
                open={shouldShowBITooltip}
                onClose={handleCloseBITooltip}
                onOpen={handleOpenBITooltip}
                onClick={() => {
                  setShouldShowBITooltip(!shouldShowBITooltip);
                }}
                title={
                  <span style={{ whiteSpace: "pre-line" }}>{t("projectPage.powerBIToolTip")}</span>
                }
                placement="right"
                componentsProps={{
                  tooltip: {
                    sx: {
                      marginTop: "20px !important",
                      marginLeft: "20px !important",
                    },
                  },
                }}
              >
                <IconButton
                  onClick={() => {
                    setShouldShowBITooltip(!shouldShowBITooltip);
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <Link
              target="_blank"
              rel="noreferrer"
              href={projectRequest.result?.reportUrl}
              variant="body2"
            >
              <OpenInNewIcon />
            </Link>
          </Stack>
        ) : (
          <Typography variant="h5">
            {t("projectPage.projectReport")}
            <Link
              target="_blank"
              rel="noreferrer"
              href={projectRequest.result?.reportUrl}
              variant="body2"
            >
              <OpenInNewIcon />
            </Link>
          </Typography>
        )}
      </>
    ),
    [shouldShowBITooltip]
  );

  useEffect(() => {
    if (navigator.userAgent.match(/iPad/i)) {
      handleOpenBITooltip();
    }
  }, []);

  if (!projectRequest.result || projectRequest.status !== "SUCCESS") return <Loading />;
  return (
    <Stack sx={{ gap: "30px" }}>
      {showConeOfUncertainty && (
        <ConeOfUncertainty
          items={onTimelineOpen.result}
          protocolName={coneOfUncertaintyProtocolName}
          onClose={() => setShowConeOfUncertainty(false)}
        />
      )}
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <Stack
          onClick={gotoProjectsMemoized}
          alignItems="center"
          direction="row"
          sx={{
            cursor: "pointer",
            "& .backIcon": {
              width: "13px",
              height: "13px",
              marginRight: "5px",
              marginBottom: "2px",
            },
          }}
        >
          <ArrowLeft className="backIcon" />
          <Typography variant="subtitle2">{t("projectPage.backToProjects")}</Typography>
        </Stack>
        {projectRequest.result.projectType === ProjectType.CHILD && (
          <Stack
            onClick={gotoParentProjectDetails}
            alignItems="center"
            direction="row"
            sx={{
              cursor: "pointer",
              "& .backIcon": {
                width: "13px",
                height: "13px",
                marginLeft: "5px",
                marginBottom: "2px",
              },
            }}
          >
            <Typography onClick={() => resetTabHandler()} variant="subtitle2">
              {t("projectPage.backToParentProject")}
            </Typography>
            <ArrowRight className="backIcon" />
          </Stack>
        )}
      </Stack>

      <Stack direction="row" flexWrap="wrap" alignSelf="center" sx={{ gap: "25px", width: "100%" }}>
        <ProjectPresentation
          sx={{ flex: 1, minWidth: "350px", marginBottom: "20px" }}
          project={projectRequest.result}
        />

        <Stack
          alignItems="strech"
          sx={{
            flex: 1,
            flexGrow: 1.4,
            minWidth: "430px",
            marginBottom: "20px",
          }}
        >
          <Stack direction="row" alignItems="stretch">
            <SdgTicket
              sx={{ marginRight: "12px", flex: 0.5 }}
              sdgs={
                projectRequest?.result?.projectType === ProjectType.PARENT
                  ? parentProjectSDGs.uniqueChildSDGList
                  : projectProtocolData.sdgList
              }
            />
            <SectorTicket
              sx={{ marginLeft: "12px", flex: 0.5 }}
              sectors={
                projectRequest?.result?.projectType === ProjectType.PARENT
                  ? parentProjectSDGs.uniqueChildSectors
                  : projectProtocolData.sectors
              }
            />
          </Stack>
          <ProtocolsTicket
            sx={{ marginTop: "25px", flex: 1 }}
            protocols={
              projectRequest?.result?.projectType === ProjectType.PARENT
                ? parentProjectSDGs.summarizedProtocols
                : projectProtocolData.protocols
            }
          />
        </Stack>
      </Stack>
      <SlideMenu
        sx={{
          marginBottom: "20px",
          "& .axessTab": {
            color: themeMUI.palette.text.tabsFontColor,
            "&:hover": { color: themeMUI.palette.text.tabsFontColor },
            "&.Mui-selected": { color: themeMUI.palette.text.tabsFontColor },
          },

          "& .MuiTabs-indicator": { backgroundColor: themeMUI.palette.text.tabsFontColor },
        }}
        tabs={tabs}
        value={currentTab}
        onChangeValue={setCurrentTab}
      />
      <Box sx={{ marginBottom: "20px" }}>{tabContent}</Box>

      {keycloak.authenticated && projectRequest.result?.reportUrl && (
        <Stack>
          <AxessPaper>
            {tooltipHeader}

            <Stack
              direction="row"
              flexWrap="wrap"
              sx={{
                gap: "15px",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <AxessButton onClick={toggleProjectReport}>
                {showProjectReport
                  ? t("projectPage.hideProjectReport")
                  : t("projectPage.showProjectReport")}
              </AxessButton>
            </Stack>

            {showProjectReport && projectReportMemoized}
          </AxessPaper>
        </Stack>
      )}
      <Stack>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ color: themeMUI.palette.text.moreProjectsFont, marginBottom: "20px" }}
        >
          {t("projectPage.projectSuggestions")}
        </Typography>
        {similarProjectsRequest.status === "SUCCESS" ? (
          <Stack
            flex={1}
            flexWrap={{ sm: "nowrap", md: "wrap" }}
            flexDirection={{ sm: "column", md: "row" }}
            alignItems="stretch"
            justifyContent="center"
            sx={{
              gap: "30px",
              marginTop: "20px",
              "& .projectCard": {
                height: "100%",
                flex: 1,
              },
              "& .container-projectCard": {
                flex: 1,
                minWidth: { sm: "", md: "550px" },
                maxWidth: "800px",
              },
            }}
          >
            {projectSuggestions}
          </Stack>
        ) : (
          <Loading />
        )}
      </Stack>
    </Stack>
  );
};
