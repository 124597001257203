import { Box } from "@mui/material";
import { Tooltip } from "@smartb/g2-notifications";
import { useTranslation } from "react-i18next";
import { Size } from "./ProgressBar";

export interface ProgressBarElementProps {
  className?: string;
  size?: Size;
  noTooltip?: boolean;
  labelString?: string;
}

export const ProgressBarElement = (props: ProgressBarElementProps) => {
  const { className, size = "medium", noTooltip = false, labelString } = props;
  const { t } = useTranslation();
  return (
    <Tooltip open={noTooltip ? false : undefined} helperText={t(`${labelString}`)}>
      <Box
        sx={
          size === "small"
            ? { width: "25px", height: "25px" }
            : size === "medium"
            ? { width: "30px", height: "30px" }
            : size === "large"
            ? { width: "40px", height: "40px" }
            : { width: "55px", height: "55px" }
        }
        className={className}
      />
    </Tooltip>
  );
};
