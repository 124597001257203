import { Stack, Typography } from "@mui/material";
import { AxessPaper, countryObject, Loading, PortfolioStatsTable, SdgCard } from "components";
import {
  MeasureUnitDTO,
  PortfolioStatsDTO,
  ProjectRefDTO,
  ProjectType,
  UserProjectRightDTO,
  UserAccessEnum,
} from "datahub";
import { useCallback, useMemo } from "react";
import { CellProps } from "@smartb/g2-layout";
import { useTranslation } from "react-i18next";
import useUserAccess from "utils/src/hooks/useUserAccess";

export interface ProjectTableProps {
  projectRefs: Map<string, ProjectRefDTO>;
  measures: Map<string, MeasureUnitDTO>;
  userRights: Map<string, UserProjectRightDTO>;
  stats: PortfolioStatsDTO[];
  projectId: string;
  onOpenAction: (action: "sell" | "buy" | "burn", orderBookId: string, volume: number) => void;
  isMarketplace?: boolean;
}

export const ProjectTable = (props: ProjectTableProps) => {
  const { projectRefs, measures, stats, projectId, onOpenAction, userRights, isMarketplace } =
    props;

  const accessAndProjects = useUserAccess(userRights, [projectId]);
  const { t } = useTranslation();

  const situationnalColumns = useMemo(
    () => [
      {
        Header: t("protocol"),
        id: "protocol",
        Cell: ({ row }: CellProps<PortfolioStatsDTO>) => (
          <Typography variant="body2">{measures.get(row.original.unitId)?.notation}</Typography>
        ),
      },
      {
        Header: t("Sectors"),
        id: "sectors",
        Cell: ({ row }: CellProps<PortfolioStatsDTO>) => {
          return (
            <Typography variant="body2" sx={{ color: "text.tableSecondary" }}>
              {t(`sectors.${row.original.sector?.id}`)}
            </Typography>
          );
        },
        className: "sectorRow",
      },
    ],
    [t, measures]
  );

  const project = useMemo(() => projectRefs.get(projectId), [projectId, projectRefs]);

  const onBurn = useCallback(
    (stat: PortfolioStatsDTO) => {
      onOpenAction("burn", stat.orderbookId, stat.volume);
    },
    [onOpenAction]
  );

  const onBuy = useCallback(
    (stat: PortfolioStatsDTO) => {
      onOpenAction("buy", stat.orderbookId, stat.volume);
    },
    [onOpenAction]
  );

  const onSell = useCallback(
    (stat: PortfolioStatsDTO) => {
      onOpenAction("sell", stat.orderbookId, stat.volume);
    },
    [onOpenAction]
  );

  return project ? (
    <AxessPaper variant="big">
      <Stack
        spacing={3}
        sx={{
          "& .sectorRow": {
            maxWidth: "150px",
          },
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h5">{project.details.name}</Typography>
          {isMarketplace && project?.projectType === ProjectType.CHILD ? (
            <Typography variant="h6" sx={{ color: "text.tableSecondary" }}>
              {countryObject[project.country]?.name}
            </Typography>
          ) : (
            <Typography variant="h6" sx={{ color: "text.tableSecondary" }}>
              ID {props.projectId}
            </Typography>
          )}
        </Stack>
        <SdgCard aligned defaultSdgs={project.sdgList} />
        <PortfolioStatsTable
          onBurn={onBurn}
          onBuy={onBuy}
          onSell={onSell}
          stats={stats}
          //@ts-ignore
          situationnalColumns={situationnalColumns}
          measures={measures}
          isMarketplace
          userCanChange={
            accessAndProjects.userAccessMap.get(projectId) === UserAccessEnum.WRITE_AND_READ
          }
        />
      </Stack>
    </AxessPaper>
  ) : (
    <Loading />
  );
};
