import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TablePagination,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Tooltip,
  useTheme as useThemeMUI,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { CellProps, Column } from "@smartb/g2-layout";
import { countryObject, Loading } from "components";
import {
  LinkedProjectDTO,
  ProjectDTO,
  ProjectGetAllPublishedQueryDTO,
  ProjectStatusValues,
  ProjectType,
  ProtocolDTO,
  SdgDTO,
  getAllPublishedProjects,
} from "datahub";
import { Pencil } from "components";
import { LowLevelMenu } from "../LowLevelMenu/LowLevelMenu";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigateProjectDetails } from "web-app/src/store/router";
import { ActionBar } from "../ActionBar/ActionBar";
import { AxessDialog } from "../AxessDialog";
import { AxessButton } from "../Button/AxessButton";
import { SdgCard } from "../SdgCard/SdgCard";
import { Close } from "@mui/icons-material";
import { FilterProjects } from "../FilterProjects";
import { distinct, ProjectWithSDGHelper, useAsyncResponse } from "utils";
import { FilterInput } from "../FiltersDrawer";
import { useAxessAuth } from "web-app/src/auth";

const StyledSdgCard = styled(SdgCard)({
  boxShadow: "none",
});

const StyledTooltip = (props) => (
  <Tooltip
    {...props}
    componentsProps={{
      tooltip: {
        sx: {
          marginTop: "-20px !important",
          marginLeft: "-20px !important",
        },
      },
    }}
    arrow
  />
);

export type ProjectDTOWithSdg = ProjectDTO & { sdgList: SdgDTO[] };

export interface MarketProjectTableProps {
  projects?: ProjectDTOWithSdg[];
  onClickEdit?: (projectId: string) => void;
  onClickView?: (projectId: string) => void;
  onClickDelete?: (projectId: string) => void;
  onClickAddToGroup?: (subProjectIdsToAdd: LinkedProjectDTO[], parentProjectId: string) => void;
  onClickDuplicate?: (selectedProjects: LinkedProjectDTO[]) => void;
  resetTabHandler?: () => void;
  setTabValue?: (event: React.MouseEvent<HTMLElement>, value: number) => void;
  currentTab?: number;
  isReadOnly?: boolean;
  isProjectDetails?: boolean;
  isMarketplace?: boolean;
  accessAndProjects?: any;
}

type DeleteConfirmDialogState = { isOpen: boolean; project?: Partial<ProjectDTOWithSdg> };

export const MarketProjectTable = (props: MarketProjectTableProps) => {
  const {
    onClickEdit,
    onClickView,
    onClickDelete,
    projects,
    onClickAddToGroup,
    onClickDuplicate,
    isProjectDetails,
    isMarketplace,
    currentTab,
    setTabValue,
    resetTabHandler,
    isReadOnly,
    accessAndProjects,
  } = props;
  const { t } = useTranslation();
  const { keycloak, service } = useAxessAuth();
  const theme = useThemeMUI();
  const [deleteDialogState, setDeleteDialogState] = useState<DeleteConfirmDialogState>({
    isOpen: false,
  });
  const [addToParentDialogState, setAddToParentDialogState] = useState<DeleteConfirmDialogState>({
    isOpen: false,
  });
  const [selectedProjectsRef, setSelectedProjectsRef] = useState<LinkedProjectDTO[]>([]);
  const [dialogSelectedParent, setDialogSelectedParent] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [projectsDisplay, setProjectsDisplay] = useState(projects);
  const [openCollapse, setOpenCollapse] = useState<string>("");

  const gotoProjectDetails = useNavigateProjectDetails();
  const handleDeleteProject = (projectId: string) => {
    setDeleteDialogState((prev) => ({ ...prev, isOpen: false }));
    onClickDelete && onClickDelete(projectId);
  };

  const handleDeleteProjectCancel = () => {
    setDeleteDialogState({ isOpen: false });
  };

  const handleAddToParentCancel = () => {
    setAddToParentDialogState({ isOpen: false });
  };

  const handleProjectDuplicate = () => {
    onClickDuplicate(selectedProjectsRef);
  };

  const columns = useMemo(
    (): Column<ProjectDTOWithSdg>[] => [
      {
        Header: t("name"),
        id: "name",
        Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
          <Typography className="projectTitle">{row.original.details.name}</Typography>
        ),
      },
      ...(!isProjectDetails
        ? [
            {
              Header: t("groups"),
              id: "groups",
              Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
                <div>
                  {row.original.projectType === ProjectType.PARENT ? (
                    <Typography className="projectTitle">({t("parentProject")})</Typography>
                  ) : row.original.projectType === ProjectType.CHILD ? (
                    <Typography
                      className="projectTitle"
                      onClick={() => gotoProjectDetails(row.original.parentRef.projectId)}
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      {row.original.parentRef.name}
                    </Typography>
                  ) : undefined}
                </div>
              ),
            },
          ]
        : []),
      {
        Header: t("start"),
        id: "start",
        Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
          <div>
            <Typography className="projectTitle">
              {new Date(row.original.creationDate).toLocaleDateString()}
            </Typography>
          </div>
        ),
      },

      {
        Header: t("location"),
        id: "status4",
        Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
          <Typography className="projectTitle">
            {countryObject[row.original.land.country]?.name}
          </Typography>
        ),
      },
      {
        Header: t("sdgs"),
        id: "sdgs",
        Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
          <Stack sx={{ width: "100px" }}>
            <StyledSdgCard size="medium" aligned defaultSdgs={row.original.sdgList} maxLength={3} />
          </Stack>
        ),
      },
      {
        Header: t("protocols"),
        id: "protocols",
        Cell: ({
          row: {
            original: { protocols },
          },
        }: CellProps<ProjectDTOWithSdg>) => {
          const filteredProtocols = protocols.filter(
            (protocol: ProtocolDTO) => protocol.type?.name !== "SDGs"
          );
          return (
            <Typography className="projectTitle">
              {filteredProtocols.length > 0 ? filteredProtocols.length : ""}
            </Typography>
          );
        },
      },
      {
        Header: t("Sectors"),
        id: "sectors",
        Cell: ({
          row: {
            original: { protocols },
          },
        }: CellProps<ProjectDTOWithSdg>) => {
          const filteredSectors = protocols.filter(
            (protocol: ProtocolDTO) => protocol.details.sector
          );
          return (
            <Typography className="projectTitle">
              {filteredSectors.length > 0 ? filteredSectors.length : ""}
            </Typography>
          );
        },
      },
      {
        Header: "",
        id: "action",
        Cell: ({
          row: {
            original: { details, status, id },
          },
        }: CellProps<ProjectDTOWithSdg>) => (
          <Stack direction="row" spacing={2}>
            {status.value === ProjectStatusValues.published() && onClickView && (
              <AxessButton
                onClick={() => {
                  if (isProjectDetails) {
                    resetTabHandler();
                  }
                  onClickView && onClickView(id);
                }}
              >
                {t("view")}
              </AxessButton>
            )}
            {!isReadOnly && (
              <AxessButton onClick={() => onClickEdit && !isReadOnly && onClickEdit(id)}>
                {t("edit")}
              </AxessButton>
            )}
            {status.value === ProjectStatusValues.created() && onClickAddToGroup && (
              <AxessButton
                onClick={() => setDeleteDialogState({ isOpen: true, project: { id, details } })}
              >
                {t("delete")}
              </AxessButton>
            )}
          </Stack>
        ),
      },
    ],
    [onClickView, onClickEdit, t, selectedProjectsRef]
  );

  const handleAddSelectedProjectsToParent = () => {
    setAddToParentDialogState({ isOpen: false });
    onClickAddToGroup(selectedProjectsRef, dialogSelectedParent);
    setDialogSelectedParent("");
    setSelectedProjectsRef([]);
  };

  const addToParentDialogBody = useMemo(
    () => (
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="parent-project-label">{t("parentProject")}</InputLabel>
        {projects && (
          <Select
            fullWidth
            label={t("parentProject")}
            labelId="parent-project-label"
            onChange={(e) => setDialogSelectedParent(e.target.value)}
            value={dialogSelectedParent}
          >
            {projects
              .filter((project) => project.projectType === ProjectType.PARENT)
              .map((project) => (
                <MenuItem key={project.id} id={project.id} value={project.id}>
                  {project.details.name}
                </MenuItem>
              ))}
          </Select>
        )}
      </FormControl>
    ),
    [projects, dialogSelectedParent]
  );

  const moreMenuOptions = useMemo(
    () => (stat: string) => {
      const options = [
        {
          key: "view",
          label: t("view"),
          goto: () => onClickView(stat),
        },
      ];
      const buttonSize = 50;
      const fontSize = 15;
      const closeIconSize = 20;
      return (
        <>
          {options.map(({ key, label, goto }) => (
            <Button key={key} onClick={goto} style={{ textTransform: "none" }}>
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={0.6}
                width={buttonSize}
                height={buttonSize}
              >
                <Typography sx={{ color: "primary.main", fontSize }}>{label}</Typography>
              </Stack>
            </Button>
          ))}
          <Button>
            <Stack
              width={buttonSize}
              height={buttonSize}
              alignItems="center"
              justifyContent="center"
            >
              <Close width={closeIconSize} height={closeIconSize} />
            </Stack>
          </Button>
        </>
      );
    },
    [onClickView]
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDialogSelectedParent(event.target.value);
    if (event.target.checked) {
      const allProjectsRefs = projects.map(
        (selectedProject) =>
          ({
            projectId: selectedProject.id,
            name: selectedProject.details.name,
          } as LinkedProjectDTO)
      );

      setSelectedProjectsRef(allProjectsRefs);
      return;
    }
    setSelectedProjectsRef([]);
  };
  const handleClick = (row) => {
    const id = row.id;
    const projectRef = [row].map(
      (selectedProject) =>
        ({
          projectId: selectedProject.id,
          name: selectedProject.details.name,
        } as LinkedProjectDTO)
    );
    const check = selectedProjectsRef.filter((project) => project.projectId === id);
    if (check.length > 0) {
      setSelectedProjectsRef(selectedProjectsRef.filter((project) => project.projectId !== id));
      setDialogSelectedParent("");
    } else {
      setDialogSelectedParent("on");
      setSelectedProjectsRef([...selectedProjectsRef, ...projectRef]);
    }
  };

  const isSelected = useCallback(
    (projectId: string) => {
      let isChecked = false;

      const check = selectedProjectsRef.filter((project) => project.projectId === projectId);
      if (check.length > 0) {
        isChecked = true;
      }
      return isChecked;
    },
    [selectedProjectsRef, handleClick]
  );

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentPageProjects = useMemo(() => {
    return rowsPerPage > 0
      ? projectsDisplay?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : projectsDisplay;
  }, [projectsDisplay, page, rowsPerPage]);

  const getPublishedProjectMemoized = useCallback(
    (params: { search?: any; status?: any; sdgs?: any; regions?: any }) => {
      //const sectorsTosend = params?.sectors?.map((s) => Number(s));
      return getAllPublishedProjects(t, keycloak.token, {
        ...params,
        projectIds: !service.is_admin()
          ? Array.from(accessAndProjects?.userAccessMap.keys())
          : undefined,
        isANDCriteria: true,
        parentRefsIds: Array.from(accessAndProjects?.userAccessMap.keys()),
        unitIds: [],
        withParentProjects:
          (params?.search && params?.search.length > 0) ||
          (params?.sdgs && params?.sdgs.length > 0) ||
          (params?.regions && params?.regions.length > 0)
            ? true
            : false,
      } as unknown as ProjectGetAllPublishedQueryDTO);
    },
    [t, accessAndProjects, keycloak]
  );

  const projectsRequest = useAsyncResponse(getPublishedProjectMemoized, false);

  useEffect(() => {
    if (
      (accessAndProjects?.userAccessMap.size > 0 || service.is_admin()) &&
      projectsRequest?.status === "IDLE"
    ) {
      projectsRequest.execute();
    }
  }, [projectsRequest.status, accessAndProjects]);

  const onSubmitFilters = useCallback(
    (values: { search?: any; status?: any; sdgs?: any; regions?: any }) => {
      projectsRequest.execute({
        ...values,
        isANDCriteria: true,
        projectIds: Array.from(accessAndProjects?.userAccessMap.keys()) || null,
      });
      setPage(0);
    },

    [projectsRequest.execute]
  );

  useEffect(() => {
    setProjectsDisplay(projects);
  }, [projects]);

  useEffect(() => {
    if (projectsRequest?.result) {
      setProjectsDisplay(ProjectWithSDGHelper(projectsRequest.result));
    }
  }, [projectsRequest.result]);

  const parentProjectSDGs = useMemo(
    () => (parentProject: ProjectDTOWithSdg) => {
      const childSDGList = [];
      const childSectors = [];
      let childProjectArray = [];
      let childProtocols = [];
      let summarizedProtocols = [];
      let uniqueChildSDGList = [];
      let uniqueChildSectors = [];

      if (parentProject?.projectType !== ProjectType.PARENT) {
        childProtocols = undefined;
        return {
          uniqueChildSDGList,
          uniqueChildSectors,
          summarizedProtocols,
        };
      }

      childProjectArray = Array.from(Object.values(parentProject.childProjectsRefs)).map(
        (project: any) => project?.projectId as string
      );

      childProjectArray = projectsDisplay.filter((project) =>
        childProjectArray.includes(project.id)
      );

      childProjectArray?.forEach((child) => {
        if (child?.protocols?.length > 0) {
          child?.protocols?.forEach((childProtocol) => {
            childProtocols?.push(childProtocol);
            if (childProtocol?.type?.name === "SDGs") {
              childSDGList?.push(...childProtocol?.details?.sdgList);
            }
            if (childProtocol?.details?.sector) {
              childSectors?.push(childProtocol?.details?.sector);
            }
          });
        }
      });

      uniqueChildSDGList = distinct(childSDGList, (sdg) => sdg.id);
      uniqueChildSectors = distinct(childSectors, (sector) => sector.id);
      summarizedProtocols = childProtocols.map((protocol: ProtocolDTO) => {
        return {
          name: protocol.details.name,
          image: protocol.type.image,
          typeName: protocol.type.name,
        };
      });

      return {
        uniqueChildSDGList,
        uniqueChildSectors,
        summarizedProtocols,
      };
    },
    [projectsRequest?.result, projectsDisplay]
  );

  return (
    <>
      <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems="center">
        <FilterProjects
          filterInputs={!isMarketplace ? [MarketProjectTable.default.filterStatus] : []}
          projects={projectsRequest?.result}
          onSubmit={onSubmitFilters}
        />
        <TablePagination
          component="div"
          count={projectsDisplay?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={t("projectsPerPage")}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
        {isMarketplace && (
          <Stack visibility="hidden">
            <ToggleButtonGroup
              color="primary"
              value={currentTab}
              exclusive
              onChange={setTabValue}
              aria-label="Platform"
            >
              <ToggleButton color={currentTab === 0 ? "primary" : "secondary"} value={0}>
                {t("map")}
              </ToggleButton>
              <ToggleButton color={currentTab === 0 ? "primary" : "secondary"} value={1}>
                {t("table")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        )}
      </Stack>
      {selectedProjectsRef.length > 0 && (
        <ActionBar
          onClickOpenDialog={setAddToParentDialogState}
          onClickDuplicate={handleProjectDuplicate}
        ></ActionBar>
      )}
      <AxessDialog
        onClose={handleDeleteProjectCancel}
        isOpen={deleteDialogState.isOpen}
        title={t("deleteProject")}
        contentText={
          <>
            {t("deleteProjectConfirm") + " "}
            <strong>{deleteDialogState.project?.details?.name}</strong>
            {"?"}
          </>
        }
        actionButtons={[
          {
            label: t("cancel"),
            onClickAction: handleDeleteProjectCancel,
          },
          {
            label: t("delete"),
            onClickAction: () => handleDeleteProject(deleteDialogState.project?.id),
          },
        ]}
      />
      <AxessDialog
        onClose={handleAddToParentCancel}
        isOpen={addToParentDialogState.isOpen}
        title={t("addToGroup")}
        contentText={t("addToGroupDialogMessage")}
        contentBody={addToParentDialogBody}
        actionButtons={[
          {
            label: t("cancel"),
            onClickAction: handleAddToParentCancel,
          },
          {
            label: t("addToGroup"),
            onClickAction: handleAddSelectedProjectsToParent,
          },
        ]}
      />
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="spanning table"
        >
          <TableHead>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                backgroundColor: `${theme.palette.selectBackground}`,
              }}
            >
              <TableCell component="th" scope="row"></TableCell>
              {!isProjectDetails && (
                <TableCell component="th" scope="row">
                  <Checkbox onChange={handleSelectAllClick} />
                </TableCell>
              )}

              {columns.map((rowMarket) => (
                <>
                  <TableCell
                    key={`${rowMarket.id}-header-${rowMarket.Header.toString()}`}
                    component="th"
                    scope="row"
                  >
                    {rowMarket.Header === t("Sectors") || rowMarket.Header === t("protocols")
                      ? `${rowMarket.Header.toString().slice(0, 3)}.`
                      : rowMarket.Header.toString()}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {projectsRequest.status == "PENDING" ? (
              <TableRow>
                <TableCell colSpan={10}>
                  <Loading />
                </TableCell>
              </TableRow>
            ) : (
              currentPageProjects
                ?.filter((project) => project.projectType !== ProjectType.CHILD)
                .map((rowMarket) => {
                  const filteredProtocols = rowMarket.protocols.filter(
                    (protocol: ProtocolDTO) => protocol.type?.name !== t("sdgs")
                  );

                  const filteredSectors = rowMarket.protocols.filter(
                    (protocol: ProtocolDTO) => protocol.details.sector
                  );

                  return (
                    <>
                      <TableRow
                        key={`${rowMarket.id}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          fontWeight:
                            openCollapse !== rowMarket.id ? "bold !important" : "  !important",
                        }}
                      >
                        {!isProjectDetails && (
                          <TableCell key={`${rowMarket.id}-checkbox`} component="th" scope="row">
                            <Checkbox
                              onClick={() => handleClick(rowMarket)}
                              checked={isSelected(rowMarket.id)}
                            />
                          </TableCell>
                        )}
                        <TableCell key={`${rowMarket.id}-expand`}>
                          {rowMarket.projectType !== ProjectType.STANDALONE && (
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() =>
                                setOpenCollapse(openCollapse !== rowMarket.id ? rowMarket.id : "")
                              }
                            >
                              {openCollapse === rowMarket.id ? (
                                <KeyboardArrowUp />
                              ) : (
                                <KeyboardArrowDown />
                              )}
                            </IconButton>
                          )}
                        </TableCell>
                        {StyledTooltip({
                          title: rowMarket.details.name ? rowMarket.details.name : "",
                          children: (
                            <TableCell
                              key={`${rowMarket.id}-name`}
                              sx={{ fontWeight: openCollapse === rowMarket.id ? "bold" : " " }}
                              component="th"
                              scope="row"
                            >
                              {rowMarket.details.name.length > 15
                                ? `${rowMarket.details.name.slice(0, 15)}...`
                                : rowMarket.details.name}
                            </TableCell>
                          ),
                        })}

                        {!isProjectDetails && (
                          <>
                            {rowMarket.parentRef?.name ? (
                              <>
                                {StyledTooltip({
                                  title: rowMarket.parentRef.name ? rowMarket.parentRef.name : "",
                                  children: (
                                    <TableCell
                                      key={`${rowMarket.id}-parent`}
                                      component="th"
                                      sx={{
                                        fontWeight: openCollapse === rowMarket.id ? "bold" : " ",
                                      }}
                                      scope="row"
                                    >
                                      {rowMarket.parentRef?.name.length > 10
                                        ? `${rowMarket.parentRef?.name.slice(0, 10)}...`
                                        : rowMarket.parentRef?.name.length}
                                    </TableCell>
                                  ),
                                })}
                              </>
                            ) : (
                              <TableCell
                                key={`${rowMarket.id}-parent`}
                                sx={{
                                  fontWeight: openCollapse === rowMarket.id ? "bold" : " ",
                                }}
                                component="th"
                                scope="row"
                              >
                                {rowMarket.parentRef?.name.length > 10
                                  ? `${rowMarket.parentRef?.name.slice(0, 10)}...`
                                  : rowMarket.parentRef?.name.length}
                              </TableCell>
                            )}
                          </>
                        )}

                        <TableCell
                          key={`${rowMarket.id}-date`}
                          sx={{ fontWeight: openCollapse === rowMarket.id ? "bold" : " " }}
                          component="th"
                          scope="row"
                        >
                          {new Date(rowMarket.creationDate).toLocaleDateString()}
                        </TableCell>
                        {StyledTooltip({
                          title: countryObject[rowMarket.land.country]?.name
                            ? countryObject[rowMarket.land.country]?.name
                            : "",
                          children: (
                            <TableCell
                              key={`${rowMarket.id}-country`}
                              sx={{ fontWeight: openCollapse === rowMarket.id ? "bold" : " " }}
                              component="th"
                              scope="row"
                            >
                              {countryObject[rowMarket.land.country]?.name.length > 10
                                ? `${countryObject[rowMarket.land.country]?.name.slice(0, 10)}...`
                                : countryObject[rowMarket.land.country]?.name}
                            </TableCell>
                          ),
                        })}
                        <TableCell key={`${rowMarket.id}-sdg`} component="th" scope="row">
                          {rowMarket.projectType === ProjectType.PARENT ? (
                            <Stack direction="row" alignItems="center">
                              <StyledSdgCard
                                size="medium"
                                aligned
                                defaultSdgs={
                                  parentProjectSDGs(rowMarket).uniqueChildSDGList.length > 2
                                    ? parentProjectSDGs(rowMarket).uniqueChildSDGList.slice(0, 2)
                                    : parentProjectSDGs(rowMarket).uniqueChildSDGList
                                }
                                maxLength={2}
                              />
                              {parentProjectSDGs(rowMarket).uniqueChildSDGList.length > 2 && (
                                <> + {parentProjectSDGs(rowMarket).uniqueChildSDGList.length - 2}</>
                              )}
                            </Stack>
                          ) : (
                            <Stack direction="row" alignItems="center">
                              <StyledSdgCard
                                size="medium"
                                aligned
                                defaultSdgs={
                                  rowMarket.sdgList.length > 2
                                    ? rowMarket.sdgList.slice(0, 2)
                                    : rowMarket.sdgList
                                }
                                maxLength={2}
                              />
                              {rowMarket.sdgList.length > 2 && (
                                <> + {rowMarket.sdgList.length - 2}</>
                              )}
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell
                          key={`${rowMarket.id}-protocols`}
                          sx={{ fontWeight: openCollapse === rowMarket.id ? "bold" : " " }}
                          component="th"
                          scope="row"
                        >
                          {rowMarket.projectType === ProjectType.PARENT
                            ? parentProjectSDGs(rowMarket).summarizedProtocols.length > 0
                              ? parentProjectSDGs(rowMarket).summarizedProtocols.length
                              : ""
                            : filteredProtocols.length > 0
                            ? filteredProtocols.length
                            : ""}
                        </TableCell>
                        <TableCell
                          key={`${rowMarket.id}-sectors`}
                          sx={{ fontWeight: openCollapse === rowMarket.id ? "bold" : " " }}
                          component="th"
                          scope="row"
                        >
                          {rowMarket.projectType === ProjectType.PARENT
                            ? parentProjectSDGs(rowMarket).uniqueChildSectors.length > 0
                              ? parentProjectSDGs(rowMarket).uniqueChildSectors.length
                              : ""
                            : filteredSectors.length > 0
                            ? filteredSectors.length
                            : ""}
                        </TableCell>
                        <TableCell key={`${rowMarket.id}-buttons`} component="th" scope="row">
                          <Stack direction="row">
                            {isProjectDetails ? (
                              <AxessButton
                                onClick={() => {
                                  if (isProjectDetails && !isMarketplace) {
                                    resetTabHandler();
                                  }
                                  onClickView && onClickView(rowMarket.id);
                                }}
                              >
                                {t("view")}
                              </AxessButton>
                            ) : (
                              <>
                                <Button
                                  variant="text"
                                  onClick={() => onClickEdit(rowMarket.id)}
                                  sx={{
                                    marginBottom: "1%",
                                    color: theme.palette.text.actionBarFont,
                                  }}
                                >
                                  <Pencil />
                                </Button>
                                <LowLevelMenu
                                  menuStyle={{
                                    height: "auto",
                                    padding: "0 1em",
                                    marginRight: "15px",
                                  }}
                                >
                                  {moreMenuOptions(rowMarket.id)}
                                </LowLevelMenu>
                              </>
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                      {openCollapse === rowMarket.id ? (
                        <>
                          {projectsDisplay
                            ?.filter(
                              (project) =>
                                project.projectType === ProjectType.CHILD &&
                                project.parentRef.projectId === rowMarket.id
                            )
                            .map((childRow) => (
                              <TableRow
                                key={`${childRow.id}`}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                              >
                                {!isProjectDetails && (
                                  <TableCell
                                    key={`${childRow.id}-checkbox`}
                                    component="th"
                                    scope="row"
                                  >
                                    <Checkbox
                                      onClick={() => handleClick(childRow)}
                                      checked={isSelected(childRow.id)}
                                    />
                                  </TableCell>
                                )}
                                <TableCell key={`${childRow.id}-expand`}></TableCell>
                                {StyledTooltip({
                                  title: childRow.details.name ? childRow.details.name : "",
                                  children: (
                                    <TableCell
                                      key={`${childRow.id}-name`}
                                      component="th"
                                      scope="row"
                                    >
                                      {childRow.details.name.length > 15
                                        ? `${childRow.details.name.slice(0, 15)}...`
                                        : childRow.details.name}
                                    </TableCell>
                                  ),
                                })}
                                {!isProjectDetails && (
                                  <>
                                    {childRow.parentRef?.name ? (
                                      <>
                                        {StyledTooltip({
                                          title: childRow.parentRef.name
                                            ? childRow.parentRef.name
                                            : "",
                                          children: (
                                            <TableCell
                                              key={`${childRow.id}-parent`}
                                              component="th"
                                              scope="row"
                                            >
                                              {childRow.parentRef?.name.length > 10
                                                ? `${childRow.parentRef?.name.slice(0, 10)}...`
                                                : childRow.parentRef?.name.length}
                                            </TableCell>
                                          ),
                                        })}
                                      </>
                                    ) : (
                                      <TableCell
                                        key={`${childRow.id}-parent`}
                                        component="th"
                                        scope="row"
                                      >
                                        {childRow.parentRef?.name.length > 10
                                          ? `${childRow.parentRef?.name.slice(0, 10)}...`
                                          : childRow.parentRef?.name.length}
                                      </TableCell>
                                    )}
                                  </>
                                )}

                                <TableCell key={`${childRow.id}-date`} component="th" scope="row">
                                  {new Date(childRow.creationDate).toLocaleDateString()}
                                </TableCell>
                                {StyledTooltip({
                                  title: countryObject[childRow.land.country]?.name
                                    ? countryObject[childRow.land.country]?.name
                                    : "",
                                  children: (
                                    <TableCell
                                      key={`${childRow.id}-country`}
                                      component="th"
                                      scope="row"
                                    >
                                      {countryObject[childRow.land.country]?.name.length > 10
                                        ? `${countryObject[childRow.land.country]?.name.slice(
                                            0,
                                            10
                                          )}...`
                                        : countryObject[childRow.land.country]?.name}
                                    </TableCell>
                                  ),
                                })}
                                <TableCell key={`${childRow.id}-sdg`} component="th" scope="row">
                                  <Stack direction="row" alignItems="center">
                                    <StyledSdgCard
                                      size="medium"
                                      aligned
                                      defaultSdgs={
                                        childRow.sdgList.length > 2
                                          ? childRow.sdgList.slice(0, 2)
                                          : childRow.sdgList
                                      }
                                      maxLength={2}
                                    />
                                    {childRow.sdgList.length > 2 && (
                                      <> + {childRow.sdgList.length - 2}</>
                                    )}
                                  </Stack>
                                </TableCell>
                                <TableCell
                                  key={`${childRow.id}-protocols`}
                                  component="th"
                                  scope="row"
                                >
                                  {filteredProtocols.length > 0 ? filteredProtocols.length : ""}
                                </TableCell>
                                <TableCell
                                  key={`${childRow.id}-sectors`}
                                  component="th"
                                  scope="row"
                                >
                                  {filteredSectors.length > 0 ? filteredSectors.length : ""}
                                </TableCell>
                                <TableCell
                                  key={`${childRow.id}-buttons`}
                                  component="th"
                                  scope="row"
                                >
                                  <Stack direction="row">
                                    {isProjectDetails ? (
                                      <AxessButton
                                        onClick={() => {
                                          if (isProjectDetails && !isMarketplace) {
                                            resetTabHandler();
                                          }
                                          onClickView && onClickView(childRow.id);
                                        }}
                                      >
                                        {t("view")}
                                      </AxessButton>
                                    ) : (
                                      <>
                                        <Button
                                          variant="text"
                                          onClick={() => onClickEdit(childRow.id)}
                                          sx={{
                                            marginBottom: "1%",
                                            color: theme.palette.text.actionBarFont,
                                          }}
                                        >
                                          <Pencil />
                                        </Button>
                                        <LowLevelMenu
                                          menuStyle={{
                                            height: "auto",
                                            padding: "0 1em",
                                            marginRight: "15px",
                                          }}
                                        >
                                          {moreMenuOptions(childRow.id)}
                                        </LowLevelMenu>
                                      </>
                                    )}
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

MarketProjectTable.default = {
  filterStatus: {
    name: "status",
    value: [],
    placeholder: "Status",
    label: "Status",
    type: "checkbox",
    typeValue: "string",
    selectProps: { options: [], multiple: true },
  } as FilterInput,
};
