import { createReducer } from "deox";
import { actions } from "./alert.actions";
import { AlertSeverity } from "@smartb/g2-notifications";

export type Alert = { message: string; severity: AlertSeverity };

export interface AlertState {
  alert?: Alert;
}

export const initialState: AlertState = {
  alert: undefined,
};

export type AlertStateTransformer = (state: AlertState) => AlertState;

const setAlert =
  (alert: Alert): AlertStateTransformer =>
  () => ({
    alert: alert,
  });

export const alertReducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.setAlert, (state: AlertState, action) =>
    setAlert(action.payload.alert)(state)
  ),
]);
