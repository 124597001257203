import { styled, Tab, TabProps, Tabs } from "@mui/material";
import { useCallback, useMemo } from "react";
import { cx } from "@emotion/css";

export interface SlideMenuProps {
  tabs: TabProps[];
  value: number;
  onChangeValue: (value: number) => void;
  className?: string;
  variant?: "standard" | "title";
}

const SlideMenuBase = (props: SlideMenuProps) => {
  const { onChangeValue, tabs, value, className } = props;

  const onChangeMemoized = useCallback(
    //@ts-ignore
    (event, value: number) => {
      onChangeValue(value);
    },
    [onChangeValue]
  );

  const tabsDisplay = useMemo(
    () =>
      tabs.map((tab, index) => (
        <Tab disableRipple key={index} {...tab} className={cx(tab.className, "axessTab")} />
      )),
    [tabs]
  );

  return (
    <Tabs className={className} value={value} onChange={onChangeMemoized}>
      {tabsDisplay}
    </Tabs>
  );
};

export const SlideMenu = styled(SlideMenuBase)(({ theme, variant = "standard" }) => ({
  "& .axessTab": {
    textTransform: variant === "standard" ? "none" : "uppercase",
    fontSize: "1rem",
    marginRight: theme.spacing(1),
    margin: variant === "standard" ? "0px 50px 0px 0px" : "0px 15px 0px 0px",
    overflow: "visible",
    color: variant === "standard" ? theme.palette.text.secondary : "white",
    fontWeight: 400,
    paddingTop: "20px",
    minWidth: variant === "standard" ? "unset" : "",
    paddingLeft: variant === "standard" ? "unset" : "",
    paddingRight: variant === "standard" ? "unset" : "",
    "&:hover": {
      color: variant === "standard" ? theme.palette.secondary.main : "white",
    },
    "&.Mui-selected": {
      color: variant === "standard" ? theme.palette.secondary.main : "white",
      fontWeight: 500,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  },
  "& .MuiTabs-indicator": {
    backgroundColor: variant === "standard" ? theme.palette.secondary.main : "#B7FF08",
    height: "4px",
    borderRadius: "50px",
    bottom: "-1.5px",
  },
  "& .MuiTabs-scroller": {
    overflow: "visible !important",
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
}));
