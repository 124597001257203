import { Paper, Stack } from "@mui/material";
import { Button } from "@smartb/g2-components";
import { useAxessAuth } from "auth";
import { OrderBookOverview, TransactionCreationValues } from "components";
import { OrderBookOverviewDTO, ProjectDTO, ProjectRefDTO } from "datahub";
import { TransactionBuy } from "domain-components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface OrderBookWithActionsProps {
  orderBook: OrderBookOverviewDTO;
  onBuy: (values: TransactionCreationValues) => void;
  project: ProjectDTO | ProjectRefDTO;
}

export const OrderBookWithActions = (props: OrderBookWithActionsProps) => {
  const { orderBook, onBuy, project } = props;
  const { t } = useTranslation();
  const [openBuy, setopenBuy] = useState(false);

  const { keycloak } = useAxessAuth();

  const onCloseBuy = useCallback(() => setopenBuy(false), []);

  const onOpenBuy = useCallback(() => setopenBuy(true), []);

  const onBuyMemoized = useCallback(
    async (values: any) => {
      await onBuy(values);
      setopenBuy(false);
    },
    [onBuy]
  );

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        margin: "15px",
      }}
    >
      <Paper
        sx={{
          paddingTop: "10px",
        }}
      >
        <OrderBookOverview orderBook={orderBook} />
      </Paper>
      {keycloak.authenticated && (
        <>
          <Stack
            justifyContent="space-evenly"
            sx={{
              "& .MuiButton-root": {
                padding: "5px 12px",
              },
            }}
          >
            {orderBook.actions.canBuy && (
              <Button onClick={onOpenBuy} variant="outlined">
                {t("buy")}
              </Button>
            )}
          </Stack>
          <TransactionBuy
            onValidate={onBuyMemoized}
            open={openBuy}
            onClose={onCloseBuy}
            orderBook={orderBook}
            project={project}
          />
        </>
      )}
    </Stack>
  );
};
