import { Box, Stack, Typography } from "@mui/material";
import { OrderDTO, UserRefDTO } from "datahub";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../Loading";
import {
  CanceledAdminTransaction,
  CanceledTransaction,
  PendingAdminTransaction,
  PendingTransaction,
  ValidatedAdminTransaction,
  ValidatedTransaction,
} from "../Transaction";
import { OrderStatusValues } from "../types";

export type TransactionsWalletFiltersValues = {
  from: number;
  to: number;
  unit: string;
};

export interface TransactionsWalletProps {
  orders: OrderDTO[];
  onCancelOrder?: (order: OrderDTO) => void;
  onRefuseOrder?: (comment: string, order: OrderDTO) => void;
  onValidateOrder?: (order: OrderDTO) => void;
  isAdmin?: boolean;
  userRefs?: Map<string, UserRefDTO>;
  isLoading?: boolean;
}

export const TransactionsWallet = (props: TransactionsWalletProps) => {
  const {
    orders = [],
    onCancelOrder,
    onValidateOrder,
    onRefuseOrder,
    isLoading = false,
    isAdmin = false,
    userRefs,
  } = props;
  const { t } = useTranslation();

  const ordersSortedUI = useMemo(() => {
    const validated: JSX.Element[] = [];
    const pending: JSX.Element[] = [];
    const refused: JSX.Element[] = [];
    if (isAdmin && userRefs.size === 0) {
      return {
        validated: validated,
        pending: pending,
        refused: refused,
      };
    }
    orders.forEach((order) => {
      if (userRefs && isAdmin) {
        if (order.status.value === OrderStatusValues.pending()) {
          pending.push(
            <PendingAdminTransaction
              userRef={userRefs.get(order.emittedBy)}
              onValidate={() => onValidateOrder && onValidateOrder(order)}
              onRefuse={(comment) => onRefuseOrder && onRefuseOrder(comment, order)}
              key={order.id}
              order={order}
            />
          );
        } else if (order.status.value === OrderStatusValues.validated()) {
          validated.push(
            <ValidatedAdminTransaction
              userRef={userRefs.get(order.emittedBy)}
              key={order.id}
              order={order}
            />
          );
        } else {
          refused.push(
            <CanceledAdminTransaction
              userRef={userRefs.get(order.emittedBy)}
              key={order.id}
              order={order}
            />
          );
        }
      } else {
        if (order.status.value === OrderStatusValues.pending()) {
          pending.push(
            <PendingTransaction
              onCancel={() => onCancelOrder && onCancelOrder(order)}
              key={order.id}
              order={order}
            />
          );
        } else if (order.status.value === OrderStatusValues.validated()) {
          validated.push(<ValidatedTransaction key={order.id} order={order} />);
        } else {
          refused.push(<CanceledTransaction key={order.id} order={order} />);
        }
      }
    });
    return {
      validated: validated,
      pending: pending,
      refused: refused,
    };
  }, [orders, onCancelOrder, isAdmin, userRefs, onRefuseOrder, onValidateOrder, isLoading]);

  const transactions = (
    <Stack
      direction="row"
      spacing={2}
      flexWrap="wrap"
      sx={{
        visibility: isLoading ? "hidden" : "visible",
      }}
    >
      {ordersSortedUI.pending.length > 0 && (
        <Stack spacing={2}>
          <Typography variant="subtitle1">
            {t("plateformStatus." + OrderStatusValues.pending())}
          </Typography>
          {ordersSortedUI.pending}
        </Stack>
      )}
      {ordersSortedUI.validated.length > 0 && (
        <Stack spacing={2}>
          <Typography variant="subtitle1">
            {t("plateformStatus." + OrderStatusValues.validated())}
          </Typography>
          {ordersSortedUI.validated}
        </Stack>
      )}
      {ordersSortedUI.refused.length > 0 && (
        <Stack spacing={2}>
          <Typography variant="subtitle1">
            {t("plateformStatus." + OrderStatusValues.canceled())}
          </Typography>
          {ordersSortedUI.refused}
        </Stack>
      )}
    </Stack>
  );

  return (
    <Box
      sx={{
        padding: "10px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginBottom="10px"
      >
        <Typography variant="h5">Transactions</Typography>
      </Stack>
      {orders.length === 0 && !isLoading && (
        <Typography textAlign="center">
          {isAdmin
            ? t("transactionWallet.noTransactionsAdmin")
            : t("transactionWallet.noTransactions")}
        </Typography>
      )}
      {isLoading && <Loading />}
      {orders.length > 0 ? transactions : undefined}
    </Box>
  );
};
