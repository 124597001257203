import { useI18n } from "@smartb/g2-providers";

interface Languages {
  en: string;
}

export const languages: Languages = {
  en: "en",
};

export const useExtendedI18n = () => {
  return useI18n<Languages>();
};
