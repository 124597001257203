import { Box, Stack, Typography, useTheme as useThemeMUI } from "@mui/material";
import { Chart, registerables } from "chart.js";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import {
  Agriculture,
  EnergyEfficiency,
  ForestryLandUse,
  HouseholdDevices,
  RenewableEnergy,
  WasteManagement,
  WaterManagement,
} from "../icons";
import { allSectors } from "../types";
import { LabelsPlugin } from "./chartjs-plugin-labels";

Chart.register(...registerables, LabelsPlugin);
export interface PortfolioSectorsChartProps {
  diameter: number;
  data: Record<number, number>;
}

interface TooltipConfigParams {
  text?: string;
  dataIndex?: number;
  chartDiameter: number;
}

export const PortfolioSectorsChart: React.FC<PortfolioSectorsChartProps> = (
  props: PortfolioSectorsChartProps
) => {
  const tooltipRef = useRef(null);
  const chartRef = useRef(null);
  const { t, i18n } = useTranslation();
  const themeMUI = useThemeMUI();

  const getTootipConfig = ({ text, dataIndex, chartDiameter }: TooltipConfigParams) => {
    const tooltipDiameter = chartDiameter / 3;
    const tooltipCenter = (chartDiameter - tooltipDiameter) / 2;
    return {
      text,
      dataIndex,
      x: tooltipCenter,
      y: tooltipCenter,
      width: tooltipDiameter,
      height: tooltipDiameter,
    };
  };

  const getChartConfig = ({ data: datasetData, diameter }: PortfolioSectorsChartProps) => {
    const chartSliceBorderWidth = 4;
    const chartHoverOffset = 20;
    const chartMargins = chartSliceBorderWidth + chartHoverOffset;
    const chartDiameter = diameter + chartMargins * 2;
    const minImageRenderValue = 2;
    const chartHoverColor = themeMUI.palette.primary.main;
    const chartSliceBorderColor = "white";
    const chartSliceBorderRadius = 6;
    const slices = [
      { icon: Agriculture, backgroundColor: themeMUI.palette.sectorsColors.agriculture, color: "#000000 !important" },
      { icon: EnergyEfficiency, backgroundColor: themeMUI.palette.sectorsColors.energyEfficiency },
      { icon: ForestryLandUse, backgroundColor: themeMUI.palette.sectorsColors.forestryLandUse },
      { icon: HouseholdDevices, backgroundColor: themeMUI.palette.sectorsColors.householdDevices },
      { icon: RenewableEnergy, backgroundColor: themeMUI.palette.sectorsColors.renewableEnergy },
      { icon: WasteManagement, backgroundColor: themeMUI.palette.sectorsColors.wasteManagement },
      { icon: WaterManagement, backgroundColor: themeMUI.palette.sectorsColors.waterManagement },
    ];

    const data = {
      labels: allSectors.map((sector) => t(`sectors.${sector}`)),
      datasets: [
        {
          data: datasetData,
          color: "white",
          backgroundColor: slices.map((s) => s.backgroundColor),
          borderColor: slices.map(() => chartSliceBorderColor),
          borderWidth: chartSliceBorderWidth,
          borderRadius: chartSliceBorderRadius,
          hoverBackgroundColor: themeMUI.palette.secondary.main,
          hoverBorderColor: chartSliceBorderColor,
          hoverOffset: chartHoverOffset,
        },
      ],
    };

    const getChartSliceIcon = (height: number | string) =>
      slices.map((slice) => <slice.icon height={height} />);

    const getSliceIconsPaths = (size: number) =>
      slices.map((slice, i) =>
        datasetData[i] > minImageRenderValue
          ? {
            //@ts-ignore
            src: slice.icon.path,
            width: size,
            height: size,
          }
          : {}
      );

    const externalTooltipHandler = (context) => {
      const { tooltip } = context;
      const tooltipEl = tooltipRef?.current;
      if (tooltip.opacity === 0) {
        if (tooltipEl) {
          tooltipEl.style.opacity = 0;
        }
        return;
      }
      if (tooltipEl) {
        tooltipEl.style.opacity = 1;
      }
      const { parsed: text, dataIndex } = tooltip.dataPoints[0];
      setTooltipConfig({ ...tooltipConfig, text: `${text}%`, dataIndex });
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: "61%",
      layout: { padding: chartHoverOffset },
      animation: true,
      plugins: {
        tooltip: {
          enabled: false,
          position: "nearest",
          external: externalTooltipHandler,
        },
        legend: {
          display: false,
        },
        labels: {
          images: getSliceIconsPaths(chartDiameter / 17),
        },
      },
    };

    return {
      data,
      options,
      slices,
      margins: chartMargins,
      diameter: chartDiameter,
      hoverColor: chartHoverColor,
      getChartSliceIcon,
      getSliceIconsPaths,
    };
  };

  const [chartConfig, setChartConfig] = useState(getChartConfig(props));
  const [tooltipConfig, setTooltipConfig] = useState(
    getTootipConfig({ chartDiameter: chartConfig.diameter })
  );

  const chartLabels = useMemo(
    () =>
      chartConfig.getChartSliceIcon("1em").map((icon, i) => (
        <Stack spacing={1.1} direction="row" alignItems="flex-start" key={i}>
          <Typography variant="body2" fontSize={12}>
            {icon}
          </Typography>
          <Typography variant="body2" fontSize={12}>
            {t(`sectors.${i + 1}`)}
          </Typography>
        </Stack>
      )),
    [chartConfig.getChartSliceIcon, i18n.language]
  );

  const tooltip = useMemo(
    () => (
      <Stack
        ref={tooltipRef}
        spacing={1}
        borderRadius={50}
        borderColor={chartConfig.hoverColor}
        width={tooltipConfig.width}
        height={tooltipConfig.height}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="transparent"
        color={chartConfig.hoverColor}
        position="absolute"
        top={tooltipConfig.y}
        left={tooltipConfig.x}
        sx={{ opacity: 0, transition: "opacity 700ms ease-in-out" }}
      >
        {chartConfig.getChartSliceIcon(chartConfig.diameter / 13)[tooltipConfig.dataIndex]}
        <Typography variant="body1">{tooltipConfig.text}</Typography>
      </Stack>
    ),
    [tooltipConfig]
  );

  useEffect(() => {
    setChartConfig(getChartConfig(props));
    setTooltipConfig(getTootipConfig({ chartDiameter: chartConfig.diameter }));
    chartRef.current.update();
  }, [props.data, props.diameter]);

  return (
    <Stack
      spacing={3.2}
      direction="row"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
    >
      <Box
        width={chartConfig.diameter}
        height={chartConfig.diameter}
        position="relative"
        style={{ margin: -chartConfig.margins }}
      >
        {tooltip}
        <Doughnut
          ref={chartRef}
          data={chartConfig.data}
          //@ts-ignore
          options={chartConfig.options}
          width={chartConfig.diameter}
          height={chartConfig.diameter}
        />
      </Box>
      <Stack
        sx={{
          flexWrap: "wrap",
          flexBasis: "min-content",
          flexGrow: 1,
          color: chartConfig.hoverColor,
          padding: "1em 0",
          minWidth: 130,
        }}
      >
        {chartLabels}
      </Stack>
    </Stack>
  );
};
