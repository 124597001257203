import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { styled } from "@mui/material";
import { ProtocolTaskDTO } from "datahub";
import React from "react";
import { TimelineConnectorDashed } from "./ConeOfUncertainty";
import { ConeOfUncertaintyTaskContent } from "./ConeOfUncertaintyTaskContent";
import { StartDot } from "./StartDot";

const StyledTimelineDot = styled(TimelineDot)({
  backgroundColor: "transparent",
  color: "#000000",
  margin: 0,
  paddingBottom: 0,
  paddingTop: 0,
  border: 0,
  boxShadow: "none",
});

interface ConeOfUncertaintyTimelineItemProps {
  isLastItem?: boolean;
  tasks: ProtocolTaskDTO[];
  taskIndex: number;
  task: ProtocolTaskDTO;
  toggleTasksDisplay: () => void;
  hasTasksBelow?: boolean;
  isFirstItem?: boolean;
}

export const ConeOfUncertaintyTimelineItem: React.FC<ConeOfUncertaintyTimelineItemProps> = (
  props
) => {
  const { isLastItem, tasks, taskIndex, task, toggleTasksDisplay, hasTasksBelow, isFirstItem } =
    props;

  return (
    <TimelineItem sx={{ minHeight: "30px" }}>
      <TimelineOppositeContent sx={{ display: "none" }} />
      <TimelineSeparator sx={{ height: "40px" }}>
        <StyledTimelineDot>
          <StartDot task={task} />
        </StyledTimelineDot>
        {!isLastItem && tasks.length > 1 && (
          <>
            {tasks[taskIndex + 1] && tasks[taskIndex + 1].isValidated ? (
              <TimelineConnector />
            ) : (
              <TimelineConnectorDashed />
            )}
          </>
        )}
      </TimelineSeparator>
      <TimelineContent sx={{ padding: 0 }}>
        <ConeOfUncertaintyTaskContent
          task={task}
          isLastTask={isLastItem}
          toggleTasksDisplay={toggleTasksDisplay}
          hasTasksBelow={hasTasksBelow}
          isFirstTask={isFirstItem}
        />
      </TimelineContent>
    </TimelineItem>
  );
};
