import { InputForm, Option } from "@smartb/g2-forms"
import { SectorDTO } from "datahub"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { SectorChip } from '../Chip'
import { allSectors } from "../types"

export interface SectorAdminProps {
  defaultSector?: SectorDTO;
  /**
   * @default false
   */
  edit?: boolean;
  className?: string;
  onChangeSector?: (sectors: SectorDTO) => void;
}

export const SectorAdmin = (props: SectorAdminProps) => {
  const { defaultSector, edit = false, className, onChangeSector } = props;
  const [sector, setSector] = useState(defaultSector);
  const { t } = useTranslation();

  const onBlurSectors = useCallback(() => {
    onChangeSector && onChangeSector(sector);
  }, [onChangeSector, sector]);

  const sectorsOptions = useMemo(
    () =>
      allSectors.map(
        (sectorId): Option => ({
          key: sectorId,
          label: t("sectors." + sectorId),
        })
      ),
    [t]
  );
  
  const onChangeMemoized = useCallback((sectorId: string) => {
    setSector({ id: Number(sectorId) })
  }, []);

  if (edit)
    return (
      <InputForm
        inputStyles={{ select: { width: "150px" } }}
        className={className}
        label={t("Sectors")}
        inputType="select"
        onChangeValue={onChangeMemoized}
        onBlur={onBlurSectors}
        value={sector.id}
        options={sectorsOptions}
      />
    );

  return sector && <SectorChip
    sector={sector.id}
    key={sector.id}
    sx={{
      margin: "10px 0",
    }}
  />
};
