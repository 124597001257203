import { Box, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrderStatusValues } from "../types";
import { useTheme } from "@smartb/g2-themes";
import { useMemo } from "react";

export interface OrderStatusProps {
  status: string;
}

export const OrderStatus = (props: OrderStatusProps) => {
  const { status } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const colors: { [status: string]: string } = useMemo(
    () => ({
      [OrderStatusValues.pending()]: theme.colors.warning,
      [OrderStatusValues.validated()]: theme.colors.success,
      [OrderStatusValues.canceled()]: theme.colors.error,
      [OrderStatusValues.closed()]: theme.colors.info,
    }),
    [theme.colors]
  );

  return (
    <Chip
      label={t("plateformStatus." + status)}
      sx={{
        boxShadow: "0px 2px 4px #0000001A",
        backgroundColor: "white",
        borderRadius: "7px",
        height: "unset",
        padding: "5px 0px",
        paddingLeft: "8px",
        fontSize: "0.9rem",
      }}
      icon={
        <Box
          sx={{
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            backgroundColor: colors[status],
          }}
        />
      }
    />
  );
};
