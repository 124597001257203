import { Button } from "@smartb/g2-components";
import { OrderBookValues, ProtocolInfoCard, ProtocolValues } from "components";
import {
  MeasureUnitDTO,
  OrderBookOverviewDTO,
  ProjectDTO,
  ProjectType,
  ProtocolDTO,
  ProtocolTypeDTO,
  SdgDTO,
} from "datahub";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeepPartial } from "utils";

interface ProtocolsProps {
  edit?: boolean;
  project: ProjectDTO;
  protocols: ProtocolDTO[];
  orderBooks?: OrderBookOverviewDTO[];
  onAddProtocol?: (values: ProtocolValues) => void;
  onDeleteProtocol?: (protocolDTO: Partial<ProtocolDTO>) => void;
  onUpdateProtocol?: (values: ProtocolValues) => void;
  onCreateOrderBook?: (values: OrderBookValues, protocol: ProtocolDTO) => void;
  onEditOrderBook?: (values: OrderBookValues, orderBook: OrderBookOverviewDTO) => void;
  onPublishOrderBook?: (orderBook: OrderBookOverviewDTO) => void;
  onEvaluate?: (quantity: number, protocol: ProtocolDTO) => void;
  onValidate?: (quantity: number, price: number, protocol: ProtocolDTO) => void;
  measures?: Map<string, MeasureUnitDTO>;
  protocolTypes?: Map<string, ProtocolTypeDTO>;
}

export const Protocols = (props: ProtocolsProps) => {
  const {
    edit,
    project,
    protocols,
    onAddProtocol,
    onDeleteProtocol,
    onUpdateProtocol,
    measures = new Map<string, MeasureUnitDTO>(),
    protocolTypes = new Map<string, ProtocolTypeDTO>(),
    orderBooks = [],
    onEditOrderBook,
    onCreateOrderBook,
    onPublishOrderBook,
    onValidate,
    onEvaluate,
  } = props;
  const { t } = useTranslation();
  const [newProtocol, setnewProtocol] = useState(false);

  const orderBooksMapped = useMemo(() => {
    const map = new Map<string, OrderBookOverviewDTO[]>();
    orderBooks.forEach((orderBook) => {
      const old = map.get(orderBook.protocolId) ?? [];
      map.set(orderBook.protocolId, [...old, orderBook]);
    });
    return map;
  }, [orderBooks]);

  const sdgsAlreadySelected = useMemo(() => {
    const projectProtocols = project.protocols as ProtocolDTO[];
    return Array.from(
      new Set(
        projectProtocols.reduce((acc: SdgDTO[], curr) => {
          if (acc.length === 0) return curr.details.sdgList;
          return [...acc, ...curr.details.sdgList];
        }, [])
      )
    );
  }, [project.protocols]);

  const protocolsDisplay = useMemo(
    () =>
      protocols.map((protocol) => (
        <ProtocolInfoCard
          key={protocol.id}
          protocol={protocol}
          protocolTypes={protocolTypes}
          measures={measures}
          selectedSdgs={sdgsAlreadySelected}
          onValidate={(quantity, price) => onValidate(quantity, price, protocol)}
          orderBooks={orderBooksMapped.get(protocol.id)}
          isAdmin={edit}
          onEditOrderBook={onEditOrderBook}
          onPublishOrderBook={onPublishOrderBook}
          onCreateOrderBook={(values) => onCreateOrderBook(values, protocol)}
          onEvaluate={(quantity) => onEvaluate(quantity, protocol)}
          onDelete={onDeleteProtocol}
          onChangeProtocol={onUpdateProtocol}
        />
      )),
    [
      protocols,
      orderBooksMapped,
      edit,
      onEditOrderBook,
      onCreateOrderBook,
      onPublishOrderBook,
      onValidate,
    ]
  );

  const onAddProtocolMemoized = useCallback(
    async (values: ProtocolValues) => {
      if (onAddProtocol) {
        await onAddProtocol(values);
        setnewProtocol(false);
      }
    },
    [onAddProtocol]
  );

  const onDeleteProtocolMemoized = useCallback(
    async (protocolDTO: Partial<ProtocolDTO>) => {
      if (onDeleteProtocol) {
        await onDeleteProtocol(protocolDTO);
        setnewProtocol(false);
      }
    },
    [onDeleteProtocol]
  );

  const onClickAddProtocol = useCallback(() => {
    setnewProtocol(true);
  }, []);

  return (
    <>
      {protocolsDisplay}
      {newProtocol && (
        <ProtocolInfoCard
          selectedSdgs={sdgsAlreadySelected}
          measures={measures}
          protocolTypes={protocolTypes}
          create
          protocol={emptyProtocol as ProtocolDTO}
          onChangeProtocol={onAddProtocolMemoized}
          onDelete={onDeleteProtocolMemoized}
        />
      )}
      {edit && project.projectType !== ProjectType.PARENT && <Button onClick={onClickAddProtocol}>{t("editProjectPage.addAProtocol")}</Button>}
    </>
  );
};

const emptyProtocol: DeepPartial<ProtocolDTO> = {
  id: "new protocol",
  details: {
    name: "",
  },
};
