import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "components";
import { useAxessAuth } from "auth";
import { useAlertHub } from "@smartb/g2-notifications";
import { MenuItems } from "@smartb/g2-components";
import { Alert } from "store/alert/alert.reducer";
import { Link, Outlet } from "react-router-dom";
import { useNavigateProjects } from "store/router";

interface AppProps {
  title: string;
  alert: Alert | undefined;
}

export const AdminApp = (props: AppProps) => {
  const { title, alert } = props;
  const { t } = useTranslation();
  const { keycloak, service } = useAxessAuth();
  const gotoProjects = useNavigateProjects();
  const user = useMemo(() => service.getUser(), [service.getUser]);

  if (!keycloak.authenticated) {
    keycloak.authenticated = keycloak.idToken != null;
  }

  const { pushAlert } = useAlertHub();

  useEffect(() => {
    alert && pushAlert(alert);
  }, [alert]);

  useEffect(() => {
    if (title !== "AxessImpact") {
      document.title = `${title} - AxessImpact`;
    }
  }, [title]);

  const tabs = useMemo(
    () => [
      {
        label: t("user"),
        component: Link,
        to: "/",
      },
      {
        label: t("admin"),
        component: Link,
        to: "/admin",
      },
    ],
    [t, keycloak]
  );

  const userMenu = useMemo((): MenuItems[] => {
    if (keycloak.authenticated) {
      return [
        {
          key: "privacy",
          label: t("privacy"),
        },
        {
          key: "security",
          label: t("security"),
        },
        {
          key: "logout",
          label: t("logout"),
          goto: keycloak.logout,
        },
      ];
    }
    return [
      {
        key: "login",
        label: t("login"),
        goto: keycloak.login,
      },
    ];
  }, [t]);

  return (
    <AppLayout
      currentUser={user}
      tabs={tabs}
      userMenu={userMenu}
      onChangeTab={gotoProjects}
      currentTabIndex={1}
      isAdminBar={true}
    >
      <Outlet />
    </AppLayout>
  );
};
