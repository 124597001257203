import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";

interface GalleryImageProps {
  imageUrl: string;
  onDeleteImage: (filesIndex: number) => void;
  fileIndex: number;
  alt?: string;
}

export const GalleryImage: React.FC<GalleryImageProps> = (props) => {
  const { imageUrl, alt, onDeleteImage, fileIndex } = props;
  const buttonStyling = {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "#495057cc",
    color: "#fff",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "#495057",
    },
  };
  return (
    <Box position="relative">
      <IconButton sx={buttonStyling} onClick={() => onDeleteImage(fileIndex)}>
        <CloseIcon />
      </IconButton>
      <img style={{ borderRadius: 20 }} height={200} src={imageUrl} alt={alt || ""} />
    </Box>
  );
};
