import { connect } from "react-redux";
import { State } from "store";
import { alert } from "store/alert";
import { initStore } from "store/init";
import { title } from "store/title";

const mapStateToProps = (state: State) => ({
  title: title.selectors.title(state),
  alert: alert.selectors.alert(state),
});

const mapDispatchToProps = {
  initStore: initStore,
};

export default connect(mapStateToProps, mapDispatchToProps);
