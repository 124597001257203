import { MeasureUnitDTO, OrderBookOverviewDTO } from "datahub";
import { Table, CellProps, Column } from "@smartb/g2-layout";
import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OrderBookType } from "../OderBookType";
import { formatNumber } from "utils";
import { AxessButton } from "../Button/AxessButton";
import { useTheme } from "@smartb/g2-themes";

export interface OrderBooksTableProps {
  protocolName: string;
  orderBooks: OrderBookOverviewDTO[];
  unit: MeasureUnitDTO;
  onBuy: (orderBook: OrderBookOverviewDTO) => void;
  showBuyButton: boolean
}

export const OrderBooksTable = (props: OrderBooksTableProps) => {
  const { protocolName, orderBooks, unit, onBuy, showBuyButton } = props;

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const columns = useMemo((): Column<OrderBookOverviewDTO>[] => {
    const lng = i18n.language;
    return [
      {
        Header: t("date"),
        id: "date",
        Cell: ({ row }) => (
          <Typography>{new Date(row.original.startDate).toLocaleDateString()}</Typography>
        ),
      },
      {
        Header: t("status"),
        id: "status",
        Cell: ({ row }: CellProps<OrderBookOverviewDTO>) => (
          <OrderBookType type={row.original.type.value} />
        ),
        className: "dataColumn",
      },
      {
        Header: t("vintage"),
        id: "vintage",
        Cell: () => <Typography>-</Typography>,
        className: "dataColumn",
      },
      {
        Header: t("quantityUnit", { unit: `(${unit.notation})` }),
        id: "quantity",
        Cell: ({ row }: CellProps<OrderBookOverviewDTO>) => (
          <Typography>{formatNumber(row.original.volume, lng)}</Typography>
        ),
        className: "dataColumn",
      },
      {
        Header: t("priceUnit", { unit: `($/${unit.notation})` }),
        id: "price",
        Cell: ({ row }: CellProps<OrderBookOverviewDTO>) => (
          <Typography>{formatNumber(row.original.price, lng, 2, true)}</Typography>
        ),
        className: "dataColumn",
      },
      ...(showBuyButton ?
      [{
        id: "buy",
        Cell: ({ row }: CellProps<OrderBookOverviewDTO>) => (
          <AxessButton onClick={() => onBuy(row.original)}>{t("buy")}</AxessButton>
        ),
        className: "buyColumn",
      }]
      :
      [])
    ];
  }, [unit, t, i18n.language, onBuy]);

  return (
    <Box
      sx={{
        "& .dataColumn": {
          textAlign: "center",
        },
        "& .buyColumn": {
          maxWidth: "90px",
        },
        "& .AruiTable-tableRow": {
          padding: "0 40px",
          borderRadius: "12px !important",
          boxShadow: "none !important",
        },
        "& .AruiTable-tableHead": {
          padding: "0 40px",
        },
        "& .AruiTable-tableHeaderCell": {
          color: theme.colors.tertiary,
        },
      }}
    >
      <Typography sx={{ marginBottom: "40px" }} variant="h5">
        {protocolName}
      </Typography>
      <Table<OrderBookOverviewDTO> data={orderBooks} columns={columns} />
    </Box>
  );
};
