import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Typography, useTheme as useThemeMUI } from "@mui/material";
import { ProtocolStepsDTO } from "datahub";
// import { format } from "date-fns";
// import { enUS } from "date-fns/locale";
import { MutableRefObject } from "react";
import { TimelineConnectorDashed } from "./ConeOfUncertainty";
import { ConeOfUncertaintyCell } from "./ConeOfUncertaintyCell";

// const computeLatestStepTaskDate = (item: ProtocolStepsDTO) => {
//   const validatedTasksInItem = item?.tasks?.filter((task) => task.isValidated);
//   if (validatedTasksInItem.length > 0) {
//     return format(
//       new Date(
//         Number(validatedTasksInItem[validatedTasksInItem.length - 1]?.metadata?.date) * 1000
//       ),
//       "LLLL yyyy",
//       {
//         locale: enUS,
//       }
//     );
//   }
// };

export type TimeLineCellsProps = {
  items: ProtocolStepsDTO[];
  endOfTimelineRef: MutableRefObject<any>;
  startOfTimelineRef: MutableRefObject<any>;
};

export const TimeLineCells = (props: TimeLineCellsProps) => {
  const { items, endOfTimelineRef, startOfTimelineRef } = props;

  const themeMUI = useThemeMUI();
  return (
    <>
      {items.map((item, index) => (
        <TimelineSeparator key={index}>
          <TimelineItem>
            <TimelineOppositeContent sx={{ wordSpacing: "99px", width: "5vw" }}>
              {/* <Typography
                sx={{
                  fontSize: "12px",
                  color: "#8C999D",
                }}
              >
                {computeLatestStepTaskDate(item) || ""}
              </Typography> */}
            </TimelineOppositeContent>
            <TimelineSeparator
              sx={{
                margin: 0,
              }}
            >
              <TimelineDot
                sx={{
                  backgroundColor: themeMUI.palette.primary.main,
                }}
              >
                <Typography fontSize={9}>
                  {item.code}
                  {index === items.length - 1 && <div ref={endOfTimelineRef}></div>}
                </Typography>
              </TimelineDot>
              {index !== items.length - 1 && (
                <>
                  {item.tasks.every((task) => task.isValidated) ? (
                    <TimelineConnector />
                  ) : (
                    <TimelineConnectorDashed />
                  )}
                </>
              )}
            </TimelineSeparator>
            <TimelineContent
              sx={{
                minWidth: "25vw",
              }}
            >
              <div
                style={{ borderRadius: "0px 10px 10px 10px", backgroundColor: "#fff" }}
                key={index}
              >
                {index === 0 && <div ref={startOfTimelineRef}></div>}
                <ConeOfUncertaintyCell item={item} />
              </div>
            </TimelineContent>
          </TimelineItem>
        </TimelineSeparator>
      ))}
    </>
  );
};
