import { muiTheme } from 'web-app/src/Themes';

export const chartColors = {
  //@ts-ignore
  primary: muiTheme.palette.chartColorPrimary.main,
  //@ts-ignore
  secondary: muiTheme.palette.chartColorSecondary.main,
};

export const getChartActions = () => {
  return {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 20,
        },
      },
    },
  };
};

export const getChartOptions = (dataset:any) => ({
  barPercentage: 0.9,
  categoryPercentage: 0.3,
  maintainAspectRatio: false,
  responsive: true,
  animation: true,
  scales: getChartActions().scales,
  plugins: {
    tooltip: {
      enabled: true,
      xAlign: 'right',
      yAlign: 'top',
      backgroundColor: 'white',
      titleColor: 'black',
      callbacks:{
        title: (context) => {
          return `${context[0].formattedValue} tCO2`;
        },
        label: (context) => {
          if(context.dataset.label === 'Compensation'){
            return `Missing ${Math.abs(Math.trunc((context.raw / dataset.data[context.dataIndex]) * 100) - 100)}%`;
          }

          if(context.dataset.label === 'Missing compensation'){
            return `Missing ${Math.trunc((context.raw / dataset.data[context.dataIndex]) * 100)}%`;
          }

          if(context.dataset.label === 'Over-compensation'){
            return `Overage +${Math.trunc((context.raw / dataset.data[context.dataIndex]) * 100)}%`;
          }
        },
        labelTextColor: (context) => {
          if(context.dataset.label === 'Missing compensation'){
            return '#6e6e6e';
          }

          if(context.dataset.label === 'Over-compensation'){
            return '#75e233';
          }
        }
      },

    },
    legend: {
      position: "bottom",
      labels: { boxWidth: 17, boxHeight: 17 },
    },
  },
});
