import { Button, Snackbar, Tooltip } from "@mui/material";
import { MouseEvent, useState } from "react";

export interface CopyToClipboardElementProps {
  stringToCopy: string;
  children: JSX.Element;
}

export const CopyToClipboardElement: React.FC<CopyToClipboardElementProps> = (props) => {
  const { stringToCopy, children } = props;
  const [open, setOpen] = useState(false);
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);
    navigator.clipboard.writeText(stringToCopy);
  };
  return (
    <>
      <Tooltip title={stringToCopy}>
        <Button sx={{ padding: 0 }} onClick={handleClick}>
          {children}
        </Button>
      </Tooltip>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={`${stringToCopy} copied to clipboard`}
      />
    </>
  );
};
