import { createSelector } from "reselect";
import { State } from "..";

const getRefsState = (state: State) => state.refs;

export const getMeasures = createSelector([getRefsState], (state) => state.measures);

export const getProjectRefs = createSelector([getRefsState], (state) => state.projectRefs);

export const getUserRefs = createSelector([getRefsState], (state) => state.userRefs);

export const getUserProjectRights = createSelector(
  [getRefsState],
  (state) => state.userProjectRights
);

export const getUserProfile = createSelector([getRefsState], (state) => state.userProfile);

export const getPowerBiToken = createSelector([getRefsState], (state) => state.powerBiToken);

export const getProtocolTypes = createSelector([getRefsState], (state) => state.protocolTypes);

export const getOrderBookOverviews = createSelector(
  [getRefsState],
  (state) => state.orderBookOverviews
);

export const selectors = {
  mesures: getMeasures,
  projectRefs: getProjectRefs,
  userRefs: getUserRefs,
  userProjectRights: getUserProjectRights,
  userProfile: getUserProfile,
  orderBookOverviews: getOrderBookOverviews,
  powerBiToken: getPowerBiToken,
  protocolTypes: getProtocolTypes,
};
