import { Today } from "@mui/icons-material";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { useTheme } from "@smartb/g2-themes";
import { useMemo } from "react";
import { DataTicket } from "../DataTicket";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils";
import { OrderDTO } from "datahub";
import { OrderTypeValues } from "../types";

export interface TransactionProps {
  order: OrderDTO;
  children?: React.ReactNode;
  withPrice?: boolean;
  className?: string;
}

export const Transaction = (props: TransactionProps) => {
  const { order, children, withPrice, className } = props;
  const theme = useTheme();
  const { i18n, t } = useTranslation();

  const stringified = useMemo(() => {
    const formatedPrice = formatNumber(order.price * order.volume, i18n.language);
    const formatedVolume = formatNumber(order.volume, i18n.language);
    return {
      date: new Date(order.creationDate).toLocaleString(i18n.language, {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      }),
      total:
        order.type.value === OrderTypeValues.buy() ? "- " + formatedPrice : "+ " + formatedPrice,
      volume:
        order.type.value === OrderTypeValues.buy() ? "+ " + formatedVolume : "- " + formatedVolume,
    };
  }, [order, i18n.language]);

  const isBurn = order.type.value === OrderTypeValues.burn();

  const borderColor =
    order.type.value === OrderTypeValues.buy()
      ? theme.colors.success
      : isBurn
      ? "#d13711"
      : theme.colors.warning;

  return (
    <Paper
      sx={{
        padding: "5px",
        paddingRight: "10px",
        maxWidth: "500px",
      }}
      className={className}
    >
      <Stack direction="row" spacing={4} alignItems="center">
        <Divider
          sx={{
            borderColor: borderColor,
            borderRightWidth: "3px",
            marginRight: "-15px",
          }}
          orientation="vertical"
          flexItem
        />
        <Stack>
          {withPrice ? (
            <DataTicket
              minified
              data={isBurn ? (t("burned") as string) : stringified.total}
            />
          ) : (
            <DataTicket minified data={stringified.volume} />
          )}
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              color: "#808A9D",
            }}
          >
            <Today sx={{ width: "15px", height: "15px" }} />
            <Typography variant="body2">{stringified.date}</Typography>
          </Stack>
        </Stack>
        {withPrice && <DataTicket data={stringified.volume} />}
        {children}
      </Stack>
    </Paper>
  );
};
