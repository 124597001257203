import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TablePagination,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Tooltip,
  useTheme as useThemeMUI,
} from "@mui/material";
import { CellProps, Column } from "@smartb/g2-layout";
import { countryObject, Loading } from "components";
import {
  getAllProject,
  LinkedProjectDTO,
  ProjectDTO,
  ProjectGetAllQueryDTO,
  ProjectStatusValues,
  ProjectType,
  ProtocolDTO,
  SdgDTO,
} from "datahub";
import { Pencil } from "components";
import { LowLevelMenu } from "../LowLevelMenu/LowLevelMenu";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigateProjectDetails } from "web-app/src/store/router";
import { ActionBar } from "../ActionBar/ActionBar";
import { AxessDialog } from "../AxessDialog";
import { AxessButton } from "../Button/AxessButton";
import { ProjectStatus } from "../PlateformStatus";
import { SdgCard } from "../SdgCard/SdgCard";
import { Close } from "@mui/icons-material";
import { FilterProjects } from "../FilterProjects";
import { ProjectWithSDGHelper, useAsyncResponse } from "utils";
import { useAxessAuth } from "web-app/src/auth";
import { FilterInput } from "../FiltersDrawer";

const StyledSdgCard = styled(SdgCard)({
  boxShadow: "none",
});

const StyledTooltip = (props) => (
  <Tooltip
    {...props}
    componentsProps={{
      tooltip: {
        sx: {
          marginTop: "-20px !important",
          marginLeft: "-20px !important",
        },
      },
    }}
    arrow
  />
);

export type ProjectDTOWithSdg = ProjectDTO & { sdgList: SdgDTO[] };

export interface ProjectTableProps {
  projects: ProjectDTOWithSdg[];
  onClickEdit?: (projectId: string) => void;
  onClickView?: (projectId: string) => void;
  onClickDelete?: (projectId: string) => void;
  onClickAddToGroup?: (subProjectIdsToAdd: LinkedProjectDTO[], parentProjectId: string) => void;
  onClickDuplicate?: (selectedProjects: LinkedProjectDTO[]) => void;
  resetTabHandler?: () => void;
  setTabValue?: (event: React.MouseEvent<HTMLElement>, value: number) => void;
  currentTab?: number;
  isReadOnly?: boolean;
  isProjectDetails?: boolean;
  shouldRenderFilters?: boolean;
  accessAndProjects?: any;
}

type DeleteConfirmDialogState = { isOpen: boolean; project?: Partial<ProjectDTOWithSdg> };

export const ProjectTable = (props: ProjectTableProps) => {
  const {
    onClickEdit,
    onClickView,
    onClickDelete,
    projects,
    onClickAddToGroup,
    onClickDuplicate,
    isProjectDetails,
    resetTabHandler,
    isReadOnly,
    shouldRenderFilters,
    accessAndProjects,
  } = props;
  const { t } = useTranslation();
  const theme = useThemeMUI();
  const [deleteDialogState, setDeleteDialogState] = useState<DeleteConfirmDialogState>({
    isOpen: false,
  });
  const [addToParentDialogState, setAddToParentDialogState] = useState<DeleteConfirmDialogState>({
    isOpen: false,
  });
  const [selectedProjectsRef, setSelectedProjectsRef] = useState<LinkedProjectDTO[]>([]);
  const [dialogSelectedParent, setDialogSelectedParent] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [projectsDisplay, setProjectsDisplay] = useState(projects);
  const { keycloak } = useAxessAuth();

  const gotoProjectDetails = useNavigateProjectDetails();
  const handleDeleteProject = (projectId: string) => {
    setDeleteDialogState((prev) => ({ ...prev, isOpen: false }));
    onClickDelete && onClickDelete(projectId);
  };

  const handleDeleteProjectCancel = () => {
    setDeleteDialogState({ isOpen: false });
  };

  const handleAddToParentCancel = () => {
    setAddToParentDialogState({ isOpen: false });
  };

  const handleProjectDuplicate = () => {
    onClickDuplicate(selectedProjectsRef);
  };

  const columns = useMemo(
    (): Column<ProjectDTOWithSdg>[] => [
      {
        Header: t("name"),
        id: "name",
        Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
          <Typography className="projectTitle">{row.original.details.name}</Typography>
        ),
      },
      {
        Header: t("status"),
        id: "status",
        Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
          <ProjectStatus status={row.original.status.value} />
        ),
      },
      ...(!isProjectDetails
        ? [
            {
              Header: t("groups"),
              id: "groups",
              Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
                <div>
                  {row.original.projectType === ProjectType.PARENT ? (
                    <Typography className="projectTitle">({t("parentProject")})</Typography>
                  ) : row.original.projectType === ProjectType.CHILD ? (
                    <Typography
                      className="projectTitle"
                      onClick={() => gotoProjectDetails(row.original.parentRef.projectId)}
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      {row.original.parentRef.name}
                    </Typography>
                  ) : undefined}
                </div>
              ),
            },
          ]
        : []),
      {
        Header: t("start"),
        id: "start",
        Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
          <div>
            <Typography className="projectTitle">
              {new Date(row.original.creationDate).toLocaleDateString()}
            </Typography>
          </div>
        ),
      },

      {
        Header: t("location"),
        id: "status4",
        Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
          <Typography className="projectTitle">
            {countryObject[row.original.land.country]?.name}
          </Typography>
        ),
      },
      {
        Header: t("sdgs"),
        id: "sdgs",
        Cell: ({ row }: CellProps<ProjectDTOWithSdg>) => (
          <Stack sx={{ width: "100px" }}>
            <StyledSdgCard size="medium" aligned defaultSdgs={row.original.sdgList} maxLength={3} />
          </Stack>
        ),
      },
      {
        Header: t("protocols"),
        id: "protocols",
        Cell: ({
          row: {
            original: { protocols },
          },
        }: CellProps<ProjectDTOWithSdg>) => {
          const filteredProtocols = protocols.filter(
            (protocol: ProtocolDTO) => protocol.type?.name !== "SDGs"
          );
          return (
            <Typography className="projectTitle">
              {filteredProtocols.length > 0 ? filteredProtocols.length : ""}
            </Typography>
          );
        },
      },
      {
        Header: t("Sectors"),
        id: "sectors",
        Cell: ({
          row: {
            original: { protocols },
          },
        }: CellProps<ProjectDTOWithSdg>) => {
          const filteredSectors = protocols.filter(
            (protocol: ProtocolDTO) => protocol.details.sector
          );
          return (
            <Typography className="projectTitle">
              {filteredSectors.length > 0 ? filteredSectors.length : ""}
            </Typography>
          );
        },
      },
      {
        Header: "",
        id: "action",
        Cell: ({
          row: {
            original: { details, status, id },
          },
        }: CellProps<ProjectDTOWithSdg>) => (
          <Stack direction="row" spacing={2}>
            {status.value === ProjectStatusValues.published() && onClickView && (
              <AxessButton
                onClick={() => {
                  if (isProjectDetails) {
                    resetTabHandler();
                  }
                  onClickView && onClickView(id);
                }}
              >
                {t("view")}
              </AxessButton>
            )}
            {!isReadOnly && (
              <AxessButton onClick={() => onClickEdit && !isReadOnly && onClickEdit(id)}>
                {t("edit")}
              </AxessButton>
            )}
            {status.value === ProjectStatusValues.created() && onClickAddToGroup && (
              <AxessButton
                onClick={() => setDeleteDialogState({ isOpen: true, project: { id, details } })}
              >
                {t("delete")}
              </AxessButton>
            )}
          </Stack>
        ),
      },
    ],
    [onClickView, onClickEdit, t, selectedProjectsRef]
  );

  const handleAddSelectedProjectsToParent = () => {
    setAddToParentDialogState({ isOpen: false });
    onClickAddToGroup(selectedProjectsRef, dialogSelectedParent);
    setDialogSelectedParent("");
    setSelectedProjectsRef([]);
  };

  const addToParentDialogBody = useMemo(
    () => (
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="parent-project-label">{t("parentProject")}</InputLabel>
        {projects && (
          <Select
            fullWidth
            label={t("parentProject")}
            labelId="parent-project-label"
            onChange={(e) => setDialogSelectedParent(e.target.value)}
            value={dialogSelectedParent}
          >
            {projects
              .filter((project) => project.projectType === ProjectType.PARENT)
              .map((project) => (
                <MenuItem key={project.id} id={project.id} value={project.id}>
                  {project.details.name}
                </MenuItem>
              ))}
          </Select>
        )}
      </FormControl>
    ),
    [projects, dialogSelectedParent]
  );

  const moreMenuOptions = useMemo(
    () => (stat: string) => {
      const options = [
        {
          key: "view",
          label: t("view"),
          goto: () => onClickView(stat),
        },
      ];
      const buttonSize = 50;
      const fontSize = 15;
      const closeIconSize = 20;
      return (
        <>
          {options.map(({ key, label, goto }) => (
            <Button key={key} onClick={goto} style={{ textTransform: "none" }}>
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={0.6}
                width={buttonSize}
                height={buttonSize}
              >
                <Typography sx={{ color: "primary.main", fontSize }}>{label}</Typography>
              </Stack>
            </Button>
          ))}
          <Button>
            <Stack
              width={buttonSize}
              height={buttonSize}
              alignItems="center"
              justifyContent="center"
            >
              <Close width={closeIconSize} height={closeIconSize} />
            </Stack>
          </Button>
        </>
      );
    },
    [onClickView]
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDialogSelectedParent(event.target.value);
    if (event.target.checked) {
      const allProjectsRefs = projects.map(
        (selectedProject) =>
          ({
            projectId: selectedProject.id,
            name: selectedProject.details.name,
          } as LinkedProjectDTO)
      );

      setSelectedProjectsRef(allProjectsRefs);
      return;
    }
    setSelectedProjectsRef([]);
  };
  const handleClick = (row) => {
    const id = row.id;
    const projectRef = [row].map(
      (selectedProject) =>
        ({
          projectId: selectedProject.id,
          name: selectedProject.details.name,
        } as LinkedProjectDTO)
    );
    const check = selectedProjectsRef.filter((project) => project.projectId === id);
    if (check.length > 0) {
      setSelectedProjectsRef(selectedProjectsRef.filter((project) => project.projectId !== id));
      setDialogSelectedParent("");
    } else {
      setDialogSelectedParent("on");
      setSelectedProjectsRef([...selectedProjectsRef, ...projectRef]);
    }
  };

  const isSelected = useCallback(
    (projectId: string) => {
      let isChecked = false;

      const check = selectedProjectsRef.filter((project) => project.projectId === projectId);
      if (check.length > 0) {
        isChecked = true;
      }
      return isChecked;
    },
    [selectedProjectsRef, handleClick]
  );

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentPageProjects = useMemo(() => {
    return rowsPerPage > 0
      ? projectsDisplay?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : projectsDisplay;
  }, [projectsDisplay, page, rowsPerPage]);

  const getAllProjectMemoized = useCallback(
    (params?: { search: String; status: Number[]; sdgs: Number[]; regions: String[] }) => {
      return getAllProject(
        {
          search: params.search,
          status: params.status,
          sdgs: params.sdgs,
          regions: params.regions,
          isANDCriteria: true,
        } as ProjectGetAllQueryDTO,
        t,
        keycloak.token
      );
    },
    [t]
  );

  const projectsRequest = useAsyncResponse(getAllProjectMemoized, false);

  const onSubmitFilters = useCallback(
    (values: { search?: any; status?: any; sdgs?: any; regions?: any }) => {
      projectsRequest.execute({
        ...values,
        isANDCriteria: true,
        projectIds: Array.from(accessAndProjects?.userAccessMap.keys() || []) || null,
      });
      setPage(0);
    },

    [projectsRequest, accessAndProjects]
  );

  useEffect(() => {
    setProjectsDisplay(ProjectWithSDGHelper(projectsRequest?.result || projects));
  }, [projects, projectsRequest.status]);

  return (
    <>
      {!shouldRenderFilters && (
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <FilterProjects
            filterInputs={[ProjectTable.default.filterStatus]}
            projects={projectsDisplay}
            onSubmit={onSubmitFilters}
          />
          <TablePagination
            component="div"
            count={projectsDisplay.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={t("projectsPerPage")}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </Stack>
      )}

      {selectedProjectsRef.length > 0 && (
        <ActionBar
          onClickOpenDialog={setAddToParentDialogState}
          onClickDuplicate={handleProjectDuplicate}
        ></ActionBar>
      )}
      <AxessDialog
        onClose={handleDeleteProjectCancel}
        isOpen={deleteDialogState.isOpen}
        title={t("deleteProject")}
        contentText={
          <>
            {t("deleteProjectConfirm") + " "}
            <strong>{deleteDialogState.project?.details?.name}</strong>
            {"?"}
          </>
        }
        actionButtons={[
          {
            label: t("cancel"),
            onClickAction: handleDeleteProjectCancel,
          },
          {
            label: t("delete"),
            onClickAction: () => handleDeleteProject(deleteDialogState.project?.id),
          },
        ]}
      />
      <AxessDialog
        onClose={handleAddToParentCancel}
        isOpen={addToParentDialogState.isOpen}
        title={t("addToGroup")}
        contentText={t("addToGroupDialogMessage")}
        contentBody={addToParentDialogBody}
        actionButtons={[
          {
            label: t("cancel"),
            onClickAction: handleAddToParentCancel,
          },
          {
            label: t("addToGroup"),
            onClickAction: handleAddSelectedProjectsToParent,
          },
        ]}
      />
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                backgroundColor: `${theme.palette.selectBackground}`,
              }}
            >
              {!isProjectDetails && (
                <TableCell component="th" scope="row">
                  <Checkbox onChange={handleSelectAllClick} />
                </TableCell>
              )}

              {columns.map((row) => (
                <>
                  <TableCell
                    key={`${row.id}-header-${row.Header.toString()}`}
                    component="th"
                    scope="row"
                  >
                    {row.Header === t("Sectors") || row.Header === t("protocols")
                      ? `${row.Header.toString().slice(0, 3)}.`
                      : row.Header.toString()}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {projectsRequest.status == "PENDING" ? (
              <TableRow>
                <TableCell colSpan={10}>
                  <Loading />
                </TableCell>
              </TableRow>
            ) : (
              currentPageProjects?.map((row) => {
                const filteredProtocols = row.protocols.filter(
                  (protocol: ProtocolDTO) => protocol.type?.name !== t("sdgs")
                );

                const filteredSectors = row.protocols.filter(
                  (protocol: ProtocolDTO) => protocol.details.sector
                );

                return (
                  <TableRow
                    key={`${row.id}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {!isProjectDetails && (
                      <TableCell key={`${row.id}-checkbox`} component="th" scope="row">
                        <Checkbox onClick={() => handleClick(row)} checked={isSelected(row.id)} />
                      </TableCell>
                    )}
                    {StyledTooltip({
                      title: row.details.name ? row.details.name : "",
                      children: (
                        <TableCell key={`${row.id}-name`} component="th" scope="row">
                          {row.details.name.length > 15
                            ? `${row.details.name.slice(0, 15)}...`
                            : row.details.name}
                        </TableCell>
                      ),
                    })}

                    <TableCell key={`${row.id}-status`} component="th" scope="row">
                      <ProjectStatus status={row.status.value} />
                    </TableCell>
                    {!isProjectDetails && (
                      <>
                        {row.parentRef?.name ? (
                          <>
                            {StyledTooltip({
                              title: row.parentRef.name ? row.parentRef.name : "",
                              children: (
                                <TableCell key={`${row.id}-parent`} component="th" scope="row">
                                  {row.parentRef?.name.length > 10
                                    ? `${row.parentRef?.name.slice(0, 10)}...`
                                    : row.parentRef?.name.length}
                                </TableCell>
                              ),
                            })}
                          </>
                        ) : (
                          <TableCell key={`${row.id}-parent`} component="th" scope="row">
                            {row.parentRef?.name.length > 10
                              ? `${row.parentRef?.name.slice(0, 10)}...`
                              : row.parentRef?.name.length}
                          </TableCell>
                        )}
                      </>
                    )}

                    <TableCell key={`${row.id}-date`} component="th" scope="row">
                      {new Date(row.creationDate).toLocaleDateString()}
                    </TableCell>
                    {StyledTooltip({
                      title: countryObject[row.land.country]?.name
                        ? countryObject[row.land.country]?.name
                        : "",
                      children: (
                        <TableCell key={`${row.id}-country`} component="th" scope="row">
                          {countryObject[row.land.country]?.name.length > 10
                            ? `${countryObject[row.land.country]?.name.slice(0, 10)}...`
                            : countryObject[row.land.country]?.name}
                        </TableCell>
                      ),
                    })}
                    <TableCell key={`${row.id}-sdg`} component="th" scope="row">
                      <Stack direction="row" alignItems="center">
                        <StyledSdgCard
                          size="medium"
                          aligned
                          defaultSdgs={
                            row.sdgList.length > 2 ? row.sdgList.slice(0, 2) : row.sdgList
                          }
                          maxLength={2}
                        />
                        {row.sdgList.length > 2 && <> + {row.sdgList.length - 2}</>}
                      </Stack>
                    </TableCell>
                    <TableCell key={`${row.id}-protocols`} component="th" scope="row">
                      {filteredProtocols.length > 0 ? filteredProtocols.length : ""}
                    </TableCell>
                    <TableCell key={`${row.id}-sectors`} component="th" scope="row">
                      {filteredSectors.length > 0 ? filteredSectors.length : ""}
                    </TableCell>
                    <TableCell key={`${row.id}-buttons`} component="th" scope="row">
                      <Stack direction="row">
                        {isProjectDetails ? (
                          <AxessButton
                            onClick={() => {
                              if (isProjectDetails) {
                                resetTabHandler();
                              }
                              onClickView && onClickView(row.id);
                            }}
                          >
                            {t("view")}
                          </AxessButton>
                        ) : (
                          <>
                            <Button
                              variant="text"
                              onClick={() => onClickEdit(row.id)}
                              sx={{ marginBottom: "1%", color: theme.palette.text.actionBarFont }}
                            >
                              <Pencil />
                            </Button>
                            <LowLevelMenu
                              menuStyle={{ height: "auto", padding: "0 1em", marginRight: "15px" }}
                            >
                              {moreMenuOptions(row.id)}
                            </LowLevelMenu>
                          </>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

ProjectTable.default = {
  filterStatus: {
    name: "status",
    value: [],
    placeholder: "Status",
    label: "Status",
    type: "checkbox",
    typeValue: "string",
    selectProps: { options: [], multiple: true },
  } as FilterInput,
};
