import { LocationOnOutlined } from "@mui/icons-material";
import { FormGroup, FormControlLabel, Stack, Switch, Typography, useTheme as useThemeMUI } from "@mui/material";
import { TextField, AutoComplete } from "@smartb/g2-forms";
import { ChangeEvent, SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@smartb/g2-themes";
import { Location } from "../icons";
import { countryArray } from "../countryArray";
import { Country, countryObject } from "../countryObject";

export interface ProjectTitleProps {
  defaultProjectName?: string;
  defaultLocation?: string;
  defaultIsParent?: boolean;
  isParentDisabled?: boolean;
  edit?: boolean;
  onChangeProjectName?: (newProjectName: string) => void;
  onChangeLocation?: (newLocation: string) => void;
  onChangeIsParent?: (newIsParent: boolean) => void;
  className?: string;
}

export const ProjectTitle = (props: ProjectTitleProps) => {
  const {
    defaultProjectName,
    defaultLocation,
    defaultIsParent,
    isParentDisabled,
    edit = false,
    onChangeProjectName,
    onChangeLocation,
    onChangeIsParent,
    className,
  } = props;
  const [projectName, setprojectName] = useState(defaultProjectName ?? "");
  const [location, setlocation] = useState(defaultLocation ?? "");
  const [isParent, setIsParent] = useState(defaultIsParent ?? false);

  const { t } = useTranslation();
  const theme = useTheme();
  const themeMUI = useThemeMUI();

  const onBlurProjectName = useCallback(() => {
    onChangeProjectName && onChangeProjectName(projectName);
  }, [projectName, onChangeProjectName]);

  const onBlurLocation = useCallback(() => {
    onChangeLocation && onChangeLocation(location);
  }, [location, onChangeLocation]);

  const onChangeLocalLocation = useCallback(
    (_: SyntheticEvent<Element, Event>, value: string | Country) => {
      if (typeof value === "object") {
        setlocation(value.code);
      } else {
        setlocation(value);
      }
    },
    [],
  )

  const handleChangeIsParent = (e:ChangeEvent<HTMLInputElement>) => {
    setIsParent(e.target.checked);
    onChangeIsParent(e.target.checked);
  }

  const getOptionLabel = useCallback(
    (option: Country) => option.name,
    [],
  )

  const isOptionEqualToValue = useCallback(
    (option: Country, value: Country) => option.code === value.code,
    [],
  )
  
  const locationLabel = useMemo(() => countryObject[location], [location])
  
  if (edit)
    return (
      <Stack maxWidth="200px" spacing={1} className={className}>
        <TextField
          placeholder={t("projectPage.projectTitle")}
          value={projectName}
          onChange={setprojectName}
          onBlur={onBlurProjectName}
        />
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            color: theme.colors.tertiary,
          }}
        >
          <LocationOnOutlined />
          <AutoComplete<Country>
            style={{
              flex:1
            }}
            options={countryArray}
            noOptionsText="No country found"
            placeholder="Search for a country"
            getOptionLabel={getOptionLabel}
            value={locationLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={onChangeLocalLocation}
            onBlur={onBlurLocation}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <FormGroup>
            <FormControlLabel control={<Switch checked={isParent} disabled={isParentDisabled} 
            onChange={handleChangeIsParent} />} label={t("editProjectPage.parentProject")} />
          </FormGroup>
        </Stack>
      </Stack>
    );
  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="strech">
      <Stack maxWidth="300px" spacing={1} className={className} justifyContent="space-between">
        <Typography variant="subtitle2">{projectName}</Typography>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            color: theme.colors.tertiary,
            "& .locationIcon": {
              color: themeMUI.palette.text.projectSecondary,
              width: "18px",
              height: "18px",
            },
          }}
        >
          <Location className="locationIcon" />
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: themeMUI.palette.text.projectSecondary,
            }}
            className="annotationText"
            variant="caption"
          >
            {countryObject[location]?.name}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
