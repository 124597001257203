import { Chip, ChipProps, styled } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Close } from "../icons";

export type AxessChipProps = ChipProps & {
  increaseLabel?: string;
  decreaseLabel?: string;
  noLabel?: boolean;
};

const AxessChipBase = (props: AxessChipProps) => {
  const { increaseLabel, decreaseLabel, noLabel, icon, label, ...other } = props;
  return (
    <Chip
      label={increaseLabel || decreaseLabel || label}
      icon={increaseLabel || decreaseLabel ? <KeyboardArrowDown className="chipArrow" /> : icon}
      deleteIcon={<Close />}
      {...other}
    />
  );
};

export const AxessChip = styled(AxessChipBase)(({ increaseLabel, decreaseLabel, noLabel, theme }) => ({
  borderRadius: increaseLabel || decreaseLabel ? "10px" : noLabel ? "5px" : "20px",
  padding: increaseLabel || decreaseLabel ? "5px 12px" : noLabel ? "5px" : "5px 14px",
  background: increaseLabel || decreaseLabel ? theme.palette.chipColor.increaseOrDecrease : theme.palette.chipColor.main,
  color: increaseLabel || decreaseLabel ? theme.palette.primary.main : "black",
  fontsize: noLabel ? "1rem" : "0.875rem",
  height: "unset",
  "& .chipArrow": {
    transform: increaseLabel ? "rotate(180deg)" : "",
    color: "currentColor",
    width: "15px",
    height: "15px",
  },
  "& .MuiChip-label": {
    padding: "0",
  },
  "& .MuiChip-deleteIcon": {
    margin: "3px 0px 0 8px",
    color: "currentColor",
    width: "10px",
    height: "10px",
    strokeWidth: "1.2",
  },
  "& .MuiChip-icon": {
    margin: noLabel ? "2px" : "0 8px 0 0px",
    color: "black",
  },
}));
