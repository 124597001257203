import { Divider, Stack, TabProps, Typography, useTheme as useThemeMUI } from "@mui/material";
import { useAxessAuth } from "auth";
import { Loading, OrdersTable, SlideMenu } from "components";
import {
  getOrders,
  getPortfolioStats,
  MeasureUnitDTO,
  OrderBookOverviewDTO,
  OrderGetAllQueryDTO,
  OrderStatusValues,
  PortfolioStatsDTO,
  ProjectRefDTO,
  UserProjectRightDTO,
} from "datahub";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { parse } from "qs";
import { ActionsForOrderbookProps, useAsyncResponse, typeParam } from "utils";
import { Link } from "@smartb/g2-components";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import { ProjectTable } from "../Portfolio/components/ProjectTable";
import { SectorTable } from "../Portfolio/components/SectorTable";
import { ProtocolTable } from "../Portfolio/components/ProtocolTable";
import useUserRights from "utils/src/hooks/useUserAccess";
import useActionsForOrderbook from "utils/src/hooks/useActionsForOrderbook";
import { OrderbookActions } from "../Portfolio/components/OrderbookActions";
import { Positions } from "../Portfolio/components/Positions";

export interface TransactionsProps {
  measures: Map<string, MeasureUnitDTO>;
  projectRefs: Map<string, ProjectRefDTO>;
  userRights?: Map<string, UserProjectRightDTO>;
  orderBookOverviews: Map<string, OrderBookOverviewDTO>;
}

export const Transactions = (props: TransactionsProps) => {
  const { projectRefs, orderBookOverviews, userRights, measures } = props;
  const themeMUI = useThemeMUI();

  const [currentTab, setCurrentTab] = useState(0);
  const { keycloak, service } = useAxessAuth();
  const { t } = useTranslation();

  const accessAndProjects = useUserRights(
    userRights,
    Array.from(projectRefs.values()).map((project) => project.id)
  );

  const getOrdersMemoized = useCallback(() => {
    return getOrders({ emitterId: service.getUser().id } as OrderGetAllQueryDTO, t, keycloak.token);
  }, [t, keycloak.token, service.getUser]);

  const ordersRequest = useAsyncResponse(getOrdersMemoized);

  const getPortfolioStatsMemoized = useCallback(
    (params) => {
      if (keycloak.authenticated) {
        const sectors = params?.sectors
          ? params?.sectors?.map((value: any) => ({
              id: Number(value),
            }))
          : undefined;
        return getPortfolioStats(
          //@ts-ignore
          {
            userId: service.getUser()?.id,
            projectIds: params?.projects,
            sectors: sectors,
            protocolTypeIds: params?.protocols,
          },
          t,
          keycloak.token
        );
      }
    },
    [t, keycloak.authenticated, service.getUser]
  );

  const portfolioStatsRequest = useAsyncResponse(getPortfolioStatsMemoized);

  useEffect(() => {
    executeRequest(portfolioStatsRequest.execute);
  }, [portfolioStatsRequest.execute]);

  const { actionState, onOpenAction, onCloseAction, onBurn, onBuy, onSell } =
    useActionsForOrderbook({
      orderBookOverviews,
      portfolioStatsRequest,
    } as ActionsForOrderbookProps);

  const groupedStats = useMemo(() => {
    const projectsGrouped: { [key: string]: PortfolioStatsDTO[] } = {};
    const sectorsGrouped: { [key: string]: PortfolioStatsDTO[] } = {};
    const unitsGrouped: { [key: string]: PortfolioStatsDTO[] } = {};

    const portfoliosToIterate = service.is_admin()
      ? portfolioStatsRequest.result?.portfolioStatsList
      : portfolioStatsRequest.result?.portfolioStatsList.filter((portfolioStat) =>
          Array.from(accessAndProjects?.userAccessMap.values()).includes(portfolioStat.projectId)
        );

    portfoliosToIterate?.forEach((stat: PortfolioStatsDTO) => {
      const projectId = stat.projectId;
      if (!projectsGrouped[projectId]) {
        projectsGrouped[projectId] = [];
      }
      projectsGrouped[projectId].push(stat);

      if (!sectorsGrouped[stat.sector?.id]) {
        sectorsGrouped[stat.sector?.id] = [];
      }
      sectorsGrouped[stat.sector?.id].push(stat);

      const unitId = stat.unitId;
      if (!unitsGrouped[unitId]) {
        unitsGrouped[unitId] = [];
      }
      unitsGrouped[unitId].push(stat);
    });

    const projectTablesDisplay = [];
    for (var projectId in projectsGrouped) {
      projectTablesDisplay.push(
        <ProjectTable
          onOpenAction={onOpenAction}
          key={projectId}
          measures={measures}
          projectId={projectId}
          projectRefs={projectRefs}
          stats={projectsGrouped[projectId]}
          userRights={userRights}
          isMarketplace
        />
      );
    }

    const protocolTablesDisplay = [];
    for (var unitId in unitsGrouped) {
      protocolTablesDisplay.push(
        <ProtocolTable
          onOpenAction={onOpenAction}
          key={unitId}
          measures={measures}
          unitId={unitId}
          projectRefs={projectRefs}
          stats={unitsGrouped[unitId]}
          userRights={userRights}
          isMarketplace
        />
      );
    }

    const sectorTablesDisplay = [];
    for (var sectorId in sectorsGrouped) {
      sectorTablesDisplay.push(
        <SectorTable
          onOpenAction={onOpenAction}
          key={sectorId}
          measures={measures}
          sectorId={sectorId}
          projectRefs={projectRefs}
          stats={sectorsGrouped[sectorId]}
          userRights={userRights}
          isMarketplace
        />
      );
    }
    return {
      projectTablesDisplay: projectTablesDisplay,
      sectorTablesDisplay: sectorTablesDisplay,
      protocolTablesDisplay: protocolTablesDisplay,
    };
  }, [
    portfolioStatsRequest.result,
    measures,
    projectRefs,
    onOpenAction,
    accessAndProjects,
    userRights,
  ]);

  const allowedProjectRef = useMemo(
    () =>
      service.is_admin()
        ? projectRefs
        : new Map(
            Array.from(projectRefs).filter(([key]) => accessAndProjects?.userAccessMap.get(key))
          ),
    [accessAndProjects, service.is_admin]
  );

  const tabs = useMemo(
    (): TabProps[] => [
      {
        label: t("transactionsPage.pendingOrders"),
      },
      {
        label: t("transactionsPage.availableOrders"),
      },
      {
        label: t("transactionsPage.allOrders"),
      },
    ],
    []
  );

  const pendingOrders = useMemo(() => {
    if (!ordersRequest.result) {
      return [];
    }
    return ordersRequest.result.filter(
      (order) => order.status.value === OrderStatusValues.pending()
    );
  }, [ordersRequest.result]);

  const tabContent = useMemo(() => {
    return (
      <>
        {currentTab === 0 &&
          (pendingOrders.length > 0 ? (
            <OrdersTable orders={pendingOrders} projectRefs={projectRefs} />
          ) : (
            <Stack alignItems="center">
              <Typography>{t("transactionsPage.noPendingOrders")}</Typography>
            </Stack>
          ))}
        {currentTab === 1 && (
          <>
            <Positions groupedStats={groupedStats} isMarketplace />
            <OrderbookActions
              projectRefs={allowedProjectRef}
              onClose={onCloseAction}
              ownedQuantity={actionState.ownedQuantity}
              currentOrderBook={actionState.orderBook}
              action={actionState.action}
              onBuy={onBuy}
              onSell={onSell}
              onBurn={onBurn}
            />
          </>
        )}
        {currentTab === 2 && (
          <OrdersTable orders={ordersRequest.result} projectRefs={projectRefs} />
        )}
      </>
    );
  }, [currentTab, ordersRequest.result]);

  if (ordersRequest.status !== "SUCCESS")
    return (
      <>
        <Typography sx={{ marginBottom: "25px" }} variant="h5">
          {t("transactions")}
        </Typography>
        <Loading />
      </>
    );
  if (!ordersRequest.result || ordersRequest.result.length === 0)
    return (
      <>
        <Typography sx={{ marginBottom: "25px" }} variant="h5">
          {t("transactions")}
        </Typography>
        <Stack alignItems="center">
          <Typography>{t("transactionsPage.noTransactions")}</Typography>
          <Link<LinkProps> component={RouterLink} componentProps={{ to: "/projects" }}>
            {t("checktTheProjects")}
          </Link>
        </Stack>
      </>
    );
  return (
    <>
      <Typography sx={{ marginBottom: "25px" }} variant="h5">
        {t("transactions")}
      </Typography>
      <SlideMenu
        sx={{
          "& .MuiTabs-flexContainer": { justifyContent: "flex-start" },
          "& .axessTab": {
            fontSize: "1.25rem",
            color: themeMUI.palette.text.tabsFontColor,
            "&:hover": { color: themeMUI.palette.text.tabsFontColor },
            "&.Mui-selected": { color: themeMUI.palette.text.tabsFontColor },
          },

          "& .MuiTabs-indicator": { backgroundColor: themeMUI.palette.text.tabsFontColor },
        }}
        onChangeValue={setCurrentTab}
        value={currentTab}
        tabs={tabs}
      />
      <Divider
        sx={{
          marginTop: "-2.5px",
          marginBottom: "30px",
          borderBottom: "3px solid #E5EAEB",
          borderRadius: "50px",
        }}
      />
      {tabContent}
    </>
  );
};

const executeRequest = (execute: (params?: any) => Promise<void>) => {
  const params = parse(window.location.search, { ignoreQueryPrefix: true });
  execute({
    ...params,
    projects: typeParam(params.projects, "array"),
    units: typeParam(params.units, "array"),
    sectors: typeParam(params.sectors, "array"),
    protocols: typeParam(params.protocols, "array"),
  });
};
