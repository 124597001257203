import { Language } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { AxessPaper, PowerBiIframe } from "components";
import { AxessButton } from "components/src/Button/AxessButton";
import { ContactEditWithLogo } from "components/src/Organization/EditOrganization";
import { ProjectDTO } from "datahub";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ProjectPowerBIProps {
  project: ProjectDTO;
  updateProject?: (concatKeysTab: string[], newValues: any[]) => void;
}

export const ProjectPowerBI: React.FC<ProjectPowerBIProps> = (props) => {
  const { project, updateProject } = props;
  const [link, setLink] = useState(project.reportUrl || "");
  const [showIframe, setShowIframe] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();
  const handleChangeLink = (value: string) => {
    setShowForm(true);
    setLink(value);
    updateProject(["reportUrl"], [value]);
  };

  return (
    <Stack>
      <Typography variant="h6">{t("editProjectPage.projectReport")}</Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{
          gap: "15px",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        {!showForm && !project.reportUrl ? (
          <AxessButton onClick={() => setShowForm(true)}>
            {t("editProjectPage.addReport")}
          </AxessButton>
        ) : (
          <AxessPaper
            sx={{
              ...(showIframe && {
                width: "100%",
              }),
            }}
          >
            <ContactEditWithLogo
              logo={<Language className="orga-contactIcon" />}
              placeholder={"Link"}
              onChange={handleChangeLink}
              defaultValue={project.reportUrl}
            />
            <AxessButton
              sx={{
                marginTop: "1rem",
              }}
              onClick={() => setShowIframe(true)}
            >
              {t("editProjectPage.preview")}
            </AxessButton>
            {showIframe && <PowerBiIframe link={link} />}
          </AxessPaper>
        )}
      </Stack>
    </Stack>
  );
};
