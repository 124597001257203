import { FileWithPath } from "@mantine/dropzone";
import { Box, Stack } from "@mui/material";
import { useAxessAuth } from "auth";
import { Loading } from "components";
import {
  createProject,
  deleteGalleryImages,
  fetchGalleryImages,
  FsFile,
  getProject,
  ImageFileDTO,
  MeasureUnitDTO,
  ProjectDTO,
  ProjectStatusValues,
  ProjectType,
  ProtocolDTO,
  ProtocolTypeDTO,
  publishProject,
  saveGalleryImages,
  updateProject as updateDbProject,
} from "datahub";
import { ProjectDetails, ProjectHeader } from "domain-components";
import { ProjectGalleries, ProjectPowerBI } from "domain-components/src/ProjectPageComponents";
import { lensPath, set } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useNavigateAdminEditProject } from "store/router";
import { AsyncStatus, distinct, useAsyncResponse } from "utils";
import { ProjectProtocols } from "./ProjectProtocols";

interface EditProjectProps {
  setTitle: (title: string) => void;
  measures: Map<string, MeasureUnitDTO>;
  protocolTypes: Map<string, ProtocolTypeDTO>;
  isNew: boolean;
}

export const EditProject = (props: EditProjectProps) => {
  const { setTitle, measures, isNew, protocolTypes } = props;
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { projectId } = useParams<{ projectId: string }>();

  const [hasChanges, setHasChanges] = useState(false);
  const [status, setStatus] = useState<AsyncStatus>("IDLE");
  const [protocols, setProtocols] = useState<ProtocolDTO[]>([]);
  const [project, setProject] = useState<ProjectDTO>(emptyProject);
  const [droppedImagesToSave, setDroppedImagesToSave] = useState<FileWithPath[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<ImageFileDTO[]>([]);
  const [galleryImages, setGalleryImages] = useState<FsFile[]>([]);
  const { keycloak } = useAxessAuth();

  const getProjectMemoized = useCallback(() => getProject(projectId, t), [projectId, t]);
  const getGalleryImagesMemoized = useCallback(
    () =>
      fetchGalleryImages(
        { objectId: projectId, objectType: "project", directory: "projectBottomGallery" },
        keycloak.token || ""
      ),
    [projectId]
  );

  const projectRequest = useAsyncResponse(getProjectMemoized, false);
  const galleryRequest = useAsyncResponse(getGalleryImagesMemoized, false);

  useEffect(() => {
    if (!isNew) {
      projectRequest.execute();
      galleryRequest.execute();
    }
  }, [isNew, projectRequest.execute, galleryRequest.execute]);

  useEffect(() => {
    if (galleryRequest.status === "SUCCESS" && galleryRequest.result) {
      setGalleryImages(galleryRequest.result.items);
    }
  }, [galleryRequest.result, galleryRequest.status]);

  useEffect(() => {
    if (droppedImagesToSave.length > 0) {
      setHasChanges(true);
    }
  }, [droppedImagesToSave]);

  useEffect(() => {
    const result = projectRequest.result;
    if (projectRequest.status === "SUCCESS" && result) {
      setProject(result);
      setProtocols(result.protocols);
      setStatus("SUCCESS");
    }
  }, [projectRequest.status, projectRequest.result]);

  const edit =
    project.status.value === ProjectStatusValues.created() ||
    Array.from(searchParams.keys()).includes("edit") ||
    isNew;

  useEffect(() => {
    if (edit) {
      setTitle(t("editProject"));
    } else if (!isNew) {
      setTitle(t("viewProject"));
    } else {
      setTitle(t("createProject"));
    }
  }, [setTitle, t, isNew, edit]);

  const updateProject = useCallback((concatKeysTab: string[], newValues: any[]) => {
    setProject((oldProject) => {
      return concatKeysTab.reduce(
        (project, concatKeys, index) => {
          const lens = lensPath(concatKeys.split("."));
          return set(lens, newValues[index], project);
        },
        { ...oldProject }
      );
    });
    setHasChanges(true);
  }, []);

  const gotoEditProject = useNavigateAdminEditProject();

  const handleUpdateDropImages = (files: FileWithPath[]) => {
    setDroppedImagesToSave(files);
    setHasChanges(true);
  };

  const handleDeleteImage = (image: FsFile) => {
    const imageDTO: ImageFileDTO = {
      directory: image.path.directory,
      objectId: image.path.objectId,
      objectType: image.path.objectType,
      name: image.path.name,
    };
    const galleryImagesWithoutRemoved = [...galleryImages].filter(
      (img) => img.path.name !== imageDTO.name
    );
    setGalleryImages(galleryImagesWithoutRemoved);
    setImagesToDelete([...imagesToDelete, imageDTO]);
    setHasChanges(true);
  };

  const onDeleteGalleryImages = async () => {
    if (imagesToDelete.length > 0) {
      deleteGalleryImages(imagesToDelete, keycloak.token ?? "", t).then(() => {
        setImagesToDelete([]);
      });
    }
  };

  const onSaveGalleryImages = async (projectId: string) => {
    if (droppedImagesToSave.length > 0) {
      saveGalleryImages(
        droppedImagesToSave,
        {
          directory: "projectBottomGallery",
          objectId: projectId,
          objectType: "project",
        },
        keycloak.token ?? "",
        t
      ).then((data: FsFile) => {
        setDroppedImagesToSave([]);
        setGalleryImages([...galleryImages, data]);
      });
    }
  };

  const onSaveChanges = useCallback(async () => {
    if (isNew) {
      const event = await createProject(
        {
          ...project,
          ...project.details,

          stakeholders: project.stakeholders ?? [],
        },
        keycloak.token ?? "",
        t
      );

      if (event) {
        await onSaveGalleryImages(event.project.id);
      }
      event && gotoEditProject(event.project.id);
    } else {
      const proj = await updateDbProject(
        {
          ...project,
          ...project.details,
          stakeholders: project.stakeholders ?? [],
        },
        keycloak.token ?? "",
        t
      );
      if (proj) {
        await onSaveGalleryImages(project.id);
        await onDeleteGalleryImages();
      }
    }
    setHasChanges(false);
  }, [
    isNew,
    project,
    keycloak.token,
    t,
    gotoEditProject,
    droppedImagesToSave,
    imagesToDelete,
    galleryImages,
    onDeleteGalleryImages,
    onSaveGalleryImages,
  ]);

  const onPublishProject = useCallback(async () => {
    await publishProject(projectId, keycloak.token, t);
    updateProject(["status.value"], [ProjectStatusValues.published()]);
  }, [projectId, keycloak.token, t, updateProject]);

  const projectSdgList = useMemo(() => {
    const protocolSdgList = protocols.flatMap((protocol) => protocol.details.sdgList);
    return distinct(protocolSdgList, (sdg) => sdg.id);
  }, [protocols]);

  useEffect(() => {
    setProject({ ...project, protocols: protocols });
  }, [protocols]);

  if (!isNew && status !== "SUCCESS") return <Loading />;
  return (
    <Stack direction="row">
      <Box
        sx={{
          padding: "15px 8px",
          flexGrow: 1,
        }}
      >
        <ProjectHeader
          goToProjectMarketUrl={`/admin/project/${projectId}/market`}
          gobackUrl="/admin"
          hasChanges={hasChanges}
          projectSdgList={projectSdgList}
          isAdmin
          isNew={isNew}
          onPublishProject={onPublishProject}
          onSaveChanges={onSaveChanges}
          updateProject={updateProject}
          project={project}
          edit={edit}
        />
        <ProjectGalleries
          imagesToSave={droppedImagesToSave}
          galleryImageFiles={galleryImages}
          onUpdateDropImages={handleUpdateDropImages}
          onDeleteImage={handleDeleteImage}
          projectId={project.id}
        />

        <ProjectPowerBI updateProject={updateProject} project={project} />
        <ProjectDetails updateProject={updateProject} project={project} edit={edit} />
      </Box>
      <ProjectProtocols
        edit={edit}
        isNew={isNew}
        measures={measures}
        protocolTypes={protocolTypes}
        project={project}
        protocols={protocols}
        setProtocols={setProtocols}
        updateProject={updateProject}
      />
    </Stack>
  );
};

const emptyProject: ProjectDTO = {
  id: "New project",
  details: {
    description: "",
    summary: "",
    image: "",
    name: "New project",
    gallery: {
      objectType: "",
      objectId: "",
      directory: "",
    },
  },
  land: {
    country: "",
    geoData: "",
    owner: {
      contact: {
        address: "",
        email: "",
        phone: "",
        website: "",
      },
      name: "",
      title: "",
      logo: "",
    },
    surface: 0,
  },
  //@ts-ignore
  status: "",
  protocols: [],
  stakeholders: [],
  projectType: ProjectType.STANDALONE,
};
