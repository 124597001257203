import { createActionCreator } from "deox";
import { Alert } from "./alert.reducer";

const setAlert = createActionCreator(
  "SET_ALERT",
  (resolve) => (alert: Alert) => resolve({ alert: alert })
);

export const actions = {
  setAlert: setAlert,
};
