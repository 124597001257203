import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Chip, Grid, Typography } from "@mui/material";
import { AxessPaper } from "components";
import React from "react";

interface InfoCardProps {
  title: string;
  value: string;
  difference: {
    isGrowing: boolean;
    amount: string;
  };
}

export const InfoCard: React.FC<InfoCardProps> = (props) => {
  const { title, value, difference } = props;
  return (
    <AxessPaper>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item xs={8}>
          <Typography fontSize={12} color="gray">
            {title}
          </Typography>
          <Typography>{value}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Chip
            size="small"
            label={difference.amount}
            icon={difference.isGrowing ? <ExpandLess /> : <ExpandMore />}
          />
        </Grid>
      </Grid>
    </AxessPaper>
  );
};
