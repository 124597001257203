import { Box, Stack, Typography, useTheme as useThemeMUI } from "@mui/material";
import Chip from "@mui/material/Chip";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";

export interface AccountingCircularChartProps {
  values: Record<number, number>;
  diameter: number;
  title?: string;
  showLegend?: boolean;
  total: number;
}

interface TooltipConfigParams {
  text?: string;
  dataIndex?: number;
  chartDiameter: number;
}

export const AccountingCircularChart: React.FC<AccountingCircularChartProps> = (
  props: AccountingCircularChartProps
) => {
  const { values, title, showLegend, diameter, total } = props;
  const tooltipRef = useRef(null);
  const chartRef = useRef(null);
  const themeMUI = useThemeMUI();

  const getTootipConfig = ({ text, dataIndex, chartDiameter }: TooltipConfigParams) => {
    const tooltipDiameter = chartDiameter / 3;
    const tooltipCenter = (chartDiameter - tooltipDiameter) / 2;
    return {
      text,
      dataIndex,
      x: tooltipCenter,
      y: tooltipCenter,
      width: tooltipDiameter,
      height: tooltipDiameter,
    };
  };

  const getChartConfig = () => {
    const chartSliceBorderWidth = 4;
    const chartHoverOffset = 20;
    const chartMargins = chartSliceBorderWidth + chartHoverOffset;
    const chartDiameter = diameter + chartMargins * 2;
    const chartHoverColor = themeMUI.palette.chartColorSecondary.main;
    const chartSliceBorderColor = "white";
    const chartSliceBorderRadius = 6;
    const slices = [
      { backgroundColor: themeMUI.palette.scopesColors.scope1 },
      { backgroundColor: themeMUI.palette.scopesColors.scope2 },
      { backgroundColor: themeMUI.palette.scopesColors.scope3 },
      { backgroundColor: themeMUI.palette.scopesColors.scope4 },
      { backgroundColor: themeMUI.palette.scopesColors.scope5 },
      { backgroundColor: themeMUI.palette.scopesColors.scope6 },
      { backgroundColor: themeMUI.palette.scopesColors.scope7 },
    ];

    const data = {
      labels: ["Scope 1", "Scope 2", "Scope3"],
      datasets: [
        {
          label: "Anual Scopes Report",
          data: values,
          //backgroundColor: ["lightBlue", "darkBlue", "blue"],
          pointStyle: "rect",
          pointRadius: 1,
          backgroundColor: slices.map((s) => s.backgroundColor),
          borderColor: slices.map(() => chartSliceBorderColor),
          borderWidth: chartSliceBorderWidth,
          borderRadius: chartSliceBorderRadius,
          hoverBackgroundColor: chartHoverColor,
          hoverBorderColor: chartSliceBorderColor,
          hoverOffset: chartHoverOffset,
        },
      ],
    };

    const externalTooltipHandler = (context: any) => {
      const { tooltip } = context;
      const tooltipEl = tooltipRef?.current;
      if (tooltip.opacity === 0) {
        if (tooltipEl) {
          tooltipEl.style.opacity = 0;
        }
        return;
      }
      if (tooltipEl) {
        tooltipEl.style.opacity = 1;
      }
      const { parsed: text, dataIndex } = tooltip.dataPoints[0];
      setTooltipConfig({ ...tooltipConfig, text, dataIndex });
    };

    const options = {
      // responsive: true,
      maintainAspectRatio: false,
      cutout: "61%",
      layout: { padding: 20 },
      animation: true,
      plugins: {
        tooltip: {
          enabled: false,
          position: "nearest",
          external: externalTooltipHandler,
        },
        legend: showLegend
          ? {
              position: "bottom",
              labels: { boxWidth: 17, boxHeight: 17 },
            }
          : {
              display: false,
            },
      },
    };

    return {
      data,
      options,
      slices,
      margins: chartMargins,
      diameter: chartDiameter,
      hoverColor: chartHoverColor,
    };
  };

  //@ts-ignore
  const [chartConfig, setChartConfig] = useState(getChartConfig(props));
  const [tooltipConfig, setTooltipConfig] = useState(
    getTootipConfig({ chartDiameter: chartConfig.diameter })
  );
  const [defaultTooltipConfig] = useState(getTootipConfig({ chartDiameter: chartConfig.diameter }));

  const tooltip = useMemo(
    () => (
      <Stack
        ref={tooltipRef}
        spacing={1}
        borderRadius={50}
        borderColor={chartConfig.hoverColor}
        width={tooltipConfig.width}
        height={tooltipConfig.height}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="white"
        color={chartConfig.hoverColor}
        position="absolute"
        top={75}
        zIndex={1000}
        left={tooltipConfig.x}
        sx={{ opacity: 0, transition: "opacity 700ms ease-in-out" }}
      >
        <Chip label={`${((Number(tooltipConfig.text) / total) * 100).toFixed(2)}%`} />
        <Typography variant="body1" color="black">{`${Math.round(
          Number(tooltipConfig.text)
        )}`}</Typography>
        <Typography fontSize={12} color="gray">
          MtCO2
        </Typography>
      </Stack>
    ),
    [tooltipConfig, total]
  );

  const tooltipDefault = useMemo(
    () => (
      <Stack
        spacing={1}
        borderRadius={50}
        borderColor={chartConfig.hoverColor}
        width={tooltipConfig.width}
        height={tooltipConfig.height}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="transparent"
        color={chartConfig.hoverColor}
        position="absolute"
        top={75}
        left={tooltipConfig.x}
        sx={{ transition: "opacity 700ms ease-in-out" }}
      >
        <Chip label="Total" />
        <Typography variant="body1" color="black">
          {Math.round(total)}
        </Typography>
        <Typography fontSize={12} color="gray">
          MtCO2
        </Typography>
      </Stack>
    ),
    [defaultTooltipConfig, total]
  );

  useEffect(() => {
    //@ts-ignore
    setChartConfig(getChartConfig(props));
    setTooltipConfig(getTootipConfig({ chartDiameter: chartConfig.diameter }));
    chartRef.current.update();
  }, [props.values]);

  return (
    <Stack
      spacing={3.2}
      direction="row"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
    >
      {title && (
        <Typography color="gray" paddingBottom={2}>
          {title}
        </Typography>
      )}

      <Box
        width={chartConfig.diameter}
        height={chartConfig.diameter}
        position="relative"
        style={{ margin: -chartConfig.margins }}
      >
        {tooltip}
        {tooltipDefault}
        <Doughnut
          ref={chartRef}
          data={chartConfig.data}
          //@ts-ignore
          options={chartConfig.options}
          width={chartConfig.diameter}
          height={chartConfig.diameter}
        />
      </Box>
    </Stack>
  );
};
