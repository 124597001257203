import { FileWithPath } from "@mantine/dropzone";
import { Box, Stack, Typography } from "@mui/material";
import { ImageAutomatedGalleries } from "components";
import { FsFile } from "datahub";
import { useTranslation } from "react-i18next";

export interface ProjectGalleriesProps {
  projectId: string;
  galleryImageFiles: FsFile[];
  imagesToSave: FileWithPath[];
  onUpdateDropImages: (images: FileWithPath[]) => void;
  onDeleteImage: (image: FsFile) => void;
}

export const ProjectGalleries = (props: ProjectGalleriesProps) => {
  const { t } = useTranslation();
  const { galleryImageFiles, onUpdateDropImages, onDeleteImage, imagesToSave } = props;

  return (
    <Stack
      sx={{
        paddingBottom: "20px",
        paddingTop: "40px",
        gap: "40px",
      }}
    >
      <Box></Box>
      <Box>
        <Stack marginBottom="16px">
          <Typography variant="h6">{t("editProjectPage.bottomImageGallery")}</Typography>
        </Stack>

        <ImageAutomatedGalleries
          imagesToSave={imagesToSave}
          onDeleteImage={onDeleteImage}
          galleryImageFiles={galleryImageFiles}
          onImageDropUpdate={onUpdateDropImages}
        />
      </Box>
    </Stack>
  );
};
