import { useAuth, KeycloackService } from "@smartb/g2-providers";
//@ts-ignore
const apiUrl = window._env_.API_URL;

export const keycloakConfigUrl = `${apiUrl}/keycloak`;

export const roles = ["admin" , "asset_manager" , "stake_holder", "project_developer", "polluter", "investor"] as const

export type Roles = typeof roles[number]

const mutableRoles: Roles[] = [...roles]

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

type StaticServices = {
};

const staticServices: KeycloackService<StaticServices, Roles> = {
};

export const useAxessAuth = () => useAuth<StaticServices, Roles>(mutableRoles, staticServices);
