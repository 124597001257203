import {
  Box,
  Chip,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme as useThemeMUI,
} from "@mui/material";
import { useTheme } from "@smartb/g2-themes";
import {
  AxessLabeledSelect,
  AxessOption,
  Country,
  Loading,
  ProjectCard,
  ProjectsMap,
  MarketProjectTable,
  FilterProjects,
} from "components";
import {
  getAllChildrenProjectsMapped,
  getAllPublishedProjects,
  MeasureUnitDTO,
  OrderBookOverviewDTO,
  ProjectGetAllPublishedQueryDTO,
  ProjectRefDTO,
  ProjectType,
  ProtocolTypeDTO,
  UserProjectRightDTO,
} from "datahub";
import { useAxessAuth } from "auth";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigateProjectDetails } from "store/router";
import { distinct, useAsyncResponse } from "utils";
import { useProjectsMarkersInteractions } from "./components/useProjectsMarkersInteractions";
import useUserRights from "utils/src/hooks/useUserAccess";

interface MarketplaceProps {
  measures: Map<string, MeasureUnitDTO>;
  setTitle: (title: string) => void;
  protocolTypes: Map<string, ProtocolTypeDTO>;
  projectRefs: Map<string, ProjectRefDTO>;
  orderBookOverviews: Map<string, OrderBookOverviewDTO>;
  userRights: Map<string, UserProjectRightDTO>;
}

export const Marketplace = (props: MarketplaceProps) => {
  const { setTitle, projectRefs, userRights } = props;
  const { t } = useTranslation();
  const { keycloak, service } = useAxessAuth();

  const theme = useTheme();
  const themeMUI = useThemeMUI();
  const [projectsNumber, setProjectsNumber] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const accessAndProjects = useUserRights(
    userRights,
    Array.from(projectRefs.values()).map((project) => project.id)
  );

  const {
    onMouseEnterMarker,
    onMouseLeaveMarker,
    onMouseEnterProjectCard,
    onMouseLeaveProjectCard,
  } = useProjectsMarkersInteractions();

  useEffect(() => {
    setTitle(t("marketplace"));
  }, [setTitle, t]);

  const getPublishedProjectMemoized = useCallback(
    (params: { search?: any; status?: any; sdgs?: any; regions?: any }) => {
      //const sectorsTosend = params?.sectors?.map((s) => Number(s));
      return getAllPublishedProjects(t, keycloak.token, {
        ...params,
        projectIds: !service.is_admin()
          ? Array.from(accessAndProjects?.userAccessMap.keys())
          : undefined,
        isANDCriteria: true,
        unitIds: [],
        withParentProjects:
          (params?.search && params?.search.length > 0) ||
          (params?.sdgs && params?.sdgs.length > 0) ||
          (params?.regions && params?.regions.length > 0)
            ? true
            : false,
      } as unknown as ProjectGetAllPublishedQueryDTO);
    },
    [t, accessAndProjects, keycloak]
  );

  const projectsRequest = useAsyncResponse(getPublishedProjectMemoized, false);

  useEffect(() => {
    if (
      (accessAndProjects?.userAccessMap.size > 0 || service.is_admin()) &&
      projectsRequest?.status === "IDLE"
    ) {
      projectsRequest.execute();
    }
  }, [keycloak, accessAndProjects]);

  const getAllChildrenProjectAuthMapped = useCallback(() => {
    if (projectRefs.size === 0) return undefined;
    return getAllChildrenProjectsMapped(
      {
        parentRefsIds: Array.from(projectRefs.values())
          .filter((project) => project.projectType === ProjectType.PARENT)
          .map((project) => project.id),
      },
      t,
      keycloak.token
    );
  }, [projectRefs.size]);

  const childProjectsMapped = useAsyncResponse(getAllChildrenProjectAuthMapped, false);

  useMemo(() => {
    childProjectsMapped.execute();
  }, [getAllChildrenProjectAuthMapped]);

  const sortByOptions = useMemo((): AxessOption[] => {
    return Object.values(t("marketplacePage.sortBy.options", { returnObjects: true })).map(
      (option, i) => ({ key: i, label: option as string })
    );
  }, [t]);

  const [sortByValue, setSortByValue] = useState<number>(0);

  const gotoProjectDetails = useNavigateProjectDetails();

  const handleProjectsToDisplay = useMemo(() => {
    if (!projectsRequest.result) return [];
    const sortOrder = -sortByValue || 1;

    const projectsResult = [...projectsRequest.result]
      .filter((project) => (selectedCountry ? project.land.country === selectedCountry.code : true))
      .filter((project) =>
        selectedProjectIds.length > 0 ? selectedProjectIds.includes(project.id) : true
      );

    const parentIds = projectsResult.map((parent) => {
      if (parent.projectType === ProjectType.CHILD) {
        return parent;
      }
    });

    const parentProjectQuery = [...projectsRequest.result].filter((project) =>
      parentIds.find((parent) => project?.id === parent?.parentRef?.projectId)
    );

    const projectsWithQuery = [
      ...projectsResult.filter((project) => project.projectType !== ProjectType.CHILD),
      ...parentProjectQuery,
    ];

    const projectsFiltered = distinct(projectsWithQuery, (project) => project.id);

    setProjectsNumber(projectsFiltered.length);

    return projectsFiltered.sort((a, b) =>
      a.creationDate > b.creationDate ? -sortOrder : sortOrder
    );
  }, [
    projectsRequest?.result,
    childProjectsMapped?.result,
    sortByValue,
    selectedCountry,
    selectedProjectIds,
    projectRefs.size,
  ]);

  const getProjectCards = useMemo(() => {
    const projectsToDisplay = handleProjectsToDisplay;
    return projectsToDisplay.map((project) => {
      if (project.projectType === ProjectType.PARENT && !childProjectsMapped.result)
        return <Loading key={project.id} />;
      const id = `projectCard-${project.id}`;
      const mapRes =
        childProjectsMapped.result && new Map(Object.entries(childProjectsMapped?.result));
      const childProjectsFound = mapRes?.size > 0 ? mapRes?.get(project.id) : [];
      const childProjectFiltered =
        Array.from(accessAndProjects.userAccessMap.keys()).length > 0
          ? childProjectsFound?.filter((project) =>
              Array.from(accessAndProjects.userAccessMap?.keys()).includes(project.id)
            )
          : childProjectsFound;

      return (
        <ProjectCard
          key={id}
          id={`${id}-projectCard`}
          className="projectCard"
          project={project}
          isParent={project.projectType === ProjectType.PARENT}
          childProjects={childProjectFiltered}
          sx={{
            flexShrink: 0,
          }}
          onClick={() => gotoProjectDetails(project.id)}
          onMouseEnter={() => onMouseEnterProjectCard(project)}
          onMouseLeave={() => onMouseLeaveProjectCard(project)}
        />
      );
    });
  }, [
    handleProjectsToDisplay,
    gotoProjectDetails,
    onMouseEnterProjectCard,
    onMouseLeaveProjectCard,
  ]);

  const onSubmitFilters = useCallback(
    (values: { search?: any; status?: any; sdgs?: any; regions?: any }) => {
      Object.keys(values).forEach((key) => {
        if (!values[key] || (values[key] as any[]).length < 1) {
          values[key] = undefined;
        }
      });

      projectsRequest.execute({
        ...values,
        isANDCriteria: true,
        projectIds: Array.from(accessAndProjects?.userAccessMap.keys()) || null,
      });
    },

    [projectsRequest]
  );

  const onClickMarker = useCallback((country: Country) => {
    setSelectedCountry(country);
  }, []);

  const onClickArea = useCallback((ids: string[]) => {
    setSelectedProjectIds(ids);
  }, []);

  const handleDeleteCountrySelected = useCallback(() => {
    setSelectedCountry(undefined);
  }, []);

  const handleRemoveAreaFilter = useCallback(() => {
    setSelectedProjectIds([]);
  }, []);

  const setTabValue = (_event: React.MouseEvent<HTMLElement>, value: number) => {
    if (value !== currentTab) {
      setCurrentTab(value);
    }
  };

  const secondTab = useMemo(
    () => (
      <>
        <Stack direction="row" justifyContent="flex-end" sx={{ marginBottom: "-50px" }}>
          <ToggleButtonGroup
            color="primary"
            value={currentTab}
            exclusive
            onChange={setTabValue}
            aria-label="Platform"
          >
            <ToggleButton disabled={0 === currentTab} value={0}>
              {t("map")}
            </ToggleButton>
            <ToggleButton disabled={1 === currentTab} value={1}>
              {t("table")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <MarketProjectTable
          onClickView={gotoProjectDetails}
          setTabValue={setTabValue}
          isReadOnly={true}
          isProjectDetails
          isMarketplace
          accessAndProjects={accessAndProjects}
        />
      </>
    ),
    [currentTab, setTabValue, gotoProjectDetails, accessAndProjects]
  );

  const tabContent = useMemo(
    () => (
      <>
        {currentTab === 0 && <>{getProjectCards}</>}
        {currentTab === 1 && <>{secondTab}</>}
      </>
    ),
    [currentTab, secondTab, getProjectCards]
  );

  const MarketplaceHeader = useMemo(
    () => (
      <>
        <FilterProjects
          projects={Array.from(projectRefs.values())}
          onSubmit={onSubmitFilters}
          areProjectRefs={true}
        />
        <Stack>
          <ToggleButtonGroup
            color="primary"
            value={currentTab}
            exclusive
            onChange={setTabValue}
            aria-label="Platform"
          >
            <ToggleButton disabled={0 === currentTab} value={0}>
              {t("map")}
            </ToggleButton>
            <ToggleButton disabled={1 === currentTab} value={1}>
              {t("table")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </>
    ),
    [currentTab, t, projectRefs]
  );

  return (
    <>
      {currentTab === 0 ? (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {MarketplaceHeader}
          </Stack>
          <Stack
            direction="row"
            maxHeight="calc(100vh - 125px)"
            sx={{
              maxWidth: "calc(100vw - 50px)",
              width: "calc(100vw - (((100vw - 1500px) / 2) + 25px))",
            }}
          >
            <Stack
              flex={0.45}
              sx={{
                minWidth: "400px",
              }}
            >
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  marginBottom="10px"
                  marginTop="30px"
                >
                  <Typography variant="subtitle2" sx={{ color: theme.colors.tertiary }}>
                    {t("marketplacePage.resultNumber", {
                      number: projectsNumber ?? [],
                    })}
                  </Typography>
                  {selectedCountry && (
                    <Chip
                      variant="outlined"
                      label={selectedCountry.name}
                      onDelete={handleDeleteCountrySelected}
                    />
                  )}
                  {selectedProjectIds.length > 0 && (
                    <Chip
                      color="primary"
                      label={t("marketplacePage.clearProjectAreaFilter")}
                      onDelete={handleRemoveAreaFilter}
                    />
                  )}

                  <AxessLabeledSelect
                    label={t("marketplacePage.sortBy.label")}
                    typographyProps={{
                      color: theme.colors.tertiary,
                      variant: "body2",
                    }}
                    size="small"
                    options={sortByOptions}
                    onChangeValue={(value) => setSortByValue(Number(value))}
                    value={sortByValue}
                  />
                </Stack>
              </>

              {projectsRequest.status === "SUCCESS" && accessAndProjects ? (
                <Stack
                  flex={1}
                  sx={{
                    overflow: "auto",
                    alignItems: "stretch",
                    margin: "0 -20px",
                    marginBottom: "-25px",
                    padding: "25px 20px",
                    gap: "25px",
                    "& .highlightProject": {
                      boxShadow: "0px 6px 26px #0241524D",
                    },
                    "& .highlightClickProject": {
                      boxShadow: "0px 6px 26px #0241524D",
                    },
                  }}
                >
                  {tabContent}
                </Stack>
              ) : (
                <Loading />
              )}
            </Stack>
            <Box
              sx={{
                position: "relative",
                flex: 0.65,
                background: "white",
                marginLeft: "24px",
                marginRight: "-25px",
                height: "calc(100vh - 102px)",
                "& .highlightMarker": {
                  transform: "scale(1.2)",
                  color: themeMUI.palette.mapMarkerColor.main + " !important",
                },
                "& .highlightClickMarker": {
                  transform: "scale(1.2)",
                  color: themeMUI.palette.mapMarkerColor.main + " !important",
                },
              }}
            >
              <ProjectsMap
                onClickArea={onClickArea}
                onClickMarker={onClickMarker}
                onMouseEnterMarker={onMouseEnterMarker}
                onMouseLeaveMarker={onMouseLeaveMarker}
                projects={projectsRequest.result ?? []}
                key={"project-map"}
              />
            </Box>
          </Stack>
        </>
      ) : (
        <Stack
          direction="column"
          maxHeight="calc(100vh - 125px)"
          sx={{
            maxWidth: "calc(100vw - 50px)",
            width: "calc(100vw - (((100vw - 1500px) / 2) + 25px))",
          }}
        >
          {tabContent}
        </Stack>
      )}
    </>
  );
};
