import { Box, Stack, styled, Typography } from "@mui/material";
import { OrderDTO, UserRefDTO } from "datahub";
import { Transaction } from "./Transaction";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { RoleValues } from "../types";

const StyledTransaction = styled(Transaction)({
  maxWidth: "600px",
});

export interface AdminTransactionProps {
  order: OrderDTO;
  children?: React.ReactNode;
  withPrice?: boolean;
  userRef: UserRefDTO;
}

export const AdminTransaction = (props: AdminTransactionProps) => {
  const { order, children, withPrice, userRef } = props;
  const { t } = useTranslation();

  const role = useMemo(() => {
    if (!userRef) return "";
    const roles = userRef.roles.map((role) => role.value);
    if (roles.includes(RoleValues.ADMIN)) {
      return t("plateformRoles." + RoleValues.ADMIN);
    }
    if (roles.includes(RoleValues.ASSET_MANAGER)) {
      return t("plateformRoles." + RoleValues.ASSET_MANAGER);
    }
    return t("plateformRoles." + RoleValues.STAKE_HOLDER);
  }, [userRef]);
  return (
    <Stack spacing={0}>
      <Box
        sx={{
          marginLeft: "10px",
        }}
      >
        <Typography variant="body2">{`${userRef?.firstName ?? ""} ${
          userRef?.lastName ?? ""
        } `}</Typography>
        <Typography sx={{ color: "#727272" }} variant="caption">
          {role}
        </Typography>
      </Box>
      <StyledTransaction order={order} withPrice={withPrice} children={children} />
    </Stack>
  );
};
