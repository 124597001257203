import { Chip, Grid, Stack, Typography, useTheme as useThemeMUI } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface TimeRangeProps {
  selectedYearsRange: number[];
  updateYearsRange: Function;
}

export const TimeRange: React.FC<TimeRangeProps> = (props) => {
  const { selectedYearsRange, updateYearsRange } = props;
  const [timeRange, setTimeRange] = useState([]);
  const { t } = useTranslation();
  const themeMUI = useThemeMUI();

  const buildYearsRange = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let index = 2011; index <= currentYear; index++) {
      years.push(index);
    }
    return years;
  };
  useEffect(() => {
    setTimeRange(buildYearsRange());
  }, []);

  const handleOnYearSelection = (year: number) => {
    if (selectedYearsRange.length === 0) {
      updateYearsRange([year]);
    } else {
      let newRange = [...selectedYearsRange];
      if (selectedYearsRange[selectedYearsRange.length - 1] < year) {
        for (let index = selectedYearsRange[selectedYearsRange.length - 1]; index < year; index++) {
          newRange.push(index + 1);
        }
        updateYearsRange(newRange);
      } else {
        updateYearsRange([year]);
      }
    }
  };

  const chipBackgroundColor = (year: number) => {
    if (!selectedYearsRange.includes(year)) {
      return;
    }
    const yearIndex = selectedYearsRange.findIndex((y) => y === year);
    return yearIndex === 0 || yearIndex === selectedYearsRange.length - 1
      ? themeMUI.palette.text.primary
      : themeMUI.palette.text.secondary;
  };

  return (
    <>
      <Typography
        sx={{
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
        fontSize="18px"
      >
        {t("timeRange")}
      </Typography>
      <Grid container>
        {timeRange.map((year) => (
          <Grid item xs={3}>
            <Stack
              sx={{
                padding: "5px",
              }}
            >
              <Chip
                sx={{
                  width: "4rem",
                  height: "1.75rem",
                  borderRadius: "6px",
                  backgroundColor: chipBackgroundColor(year),
                  color: selectedYearsRange.includes(year) ? "#fff" : "",
                }}
                label={year}
                onClick={() => handleOnYearSelection(year)}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
