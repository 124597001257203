import { Box, styled, TabProps } from "@mui/material";
import { MenuItems } from "@smartb/g2-components";
import { AppLayout as AruiAppLayout, AppStyleProps } from "@smartb/g2-layout";
import React, { useCallback, useState } from "react";
import { AppBarContent } from "./AppBarContent";

export const appLayoutStyleProps: AppStyleProps = {
  appBar: {
    height: 55,
  },
};

const StyledBox = styled(Box)(({ theme }) => ({
  "& .AruiApp-main": {
    minHeight: "100vh",
    boxSizing: "border-box",
    background: "#F3F7F8",
    padding: "25px",
    paddingTop: "100px",
    display: "flex",
    justifyContent: "center",
  },
  "& .AruiAppBar-root": {
    width: "100%",
    marginLeft: "0",
    boxShadow: theme.shadows[7],
    background: theme.palette.primary.main,
    color: "white",
  },
  "& .AruiAppBar-menuButton": {
    color: "black",
  },
  "& .MuiToolbar-root": {
    minHeight: "unset",
    justifyContent: "space-between",
  },
  "& .limitedcontainer": {
    maxWidth: "1500px",
    width: "100%",
  },
}));

export interface AppLayoutProps {
  children?: React.ReactNode;
  tabs: TabProps[];
  currentTabIndex: number;
  onChangeTab: (tabIndex: number) => void;
  currentUser?: {
    firstName: string;
    lastName: string;
  };
  userMenu: MenuItems[];
  isAdminBar?: boolean;
}

export const AppLayout = (props: AppLayoutProps) => {
  const { children, userMenu, currentUser, currentTabIndex, onChangeTab, tabs, isAdminBar } = props;
  const [open, setOpen] = useState(window.innerWidth > 750);
  const onToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), []);
  return (
    <StyledBox>
      <AruiAppLayout
        open={open}
        onToggle={onToggle}
        styleProps={appLayoutStyleProps}
        showAppBar
        showDrawer={false}
        appBarContent={
          <AppBarContent
            userMenu={userMenu}
            currentUser={currentUser}
            currentTabIndex={currentTabIndex}
            onChangeTab={onChangeTab}
            tabs={tabs}
            isAdminBar={isAdminBar}
          />
        }
      >
        <Box className="limitedcontainer">{children}</Box>
      </AruiAppLayout>
    </StyledBox>
  );
};
