import { geoMercator } from "d3-geo";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";
import { useTheme as useThemeMUI } from "@mui/material";

import { countryObject } from "..";
import geography from "./geography.json";

export interface PortfolioRegionsMapProps {
  data: { region: string; percentage: number }[];
  transparent: boolean;
  style?: CSSProperties;
}

export const PortfolioRegionsMap: React.FC<PortfolioRegionsMapProps> = (props) => {
  const themeMUI = useThemeMUI();
  const mapDimensions = { width: 640, height: 410 };
  const defaultMapConfig = {
    color: themeMUI.palette.mapColor.main,
    backgroundColor: themeMUI.palette.mapBgColor.main,
    width: mapDimensions.width,
    height: mapDimensions.height,
    projection: geoMercator()
      .translate([mapDimensions.width / 2, mapDimensions.height / 1.41])
      .scale(100),
    markers: {
      color: themeMUI.palette.mapMarkerColor.main,
      backgroundColor: themeMUI.palette.mapMarkerBgColor.main,
      fontFamily: "'Montserrat',roboto",
      fontWeight: 500,
    },
  };
  const [mapConfig, setMapConfig] = useState(defaultMapConfig);
  const [scaleFactor, setScaleFactor] = useState(1);

  useEffect(() => {
    setMapConfig({
      ...mapConfig,
      backgroundColor: props.transparent ? "transparent" : defaultMapConfig.backgroundColor,
    });
  }, [props.transparent]);

  const geographies = useMemo(
    () => (
      <Geographies geography={geography}>
        {({ geographies: geo }) =>
          geo.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              clipPath="url(#rsm-sphere)"
              fill={mapConfig.color}
              stroke={mapConfig.color}
              style={{
                default: { outline: "none" },
                hover: { outline: "none" },
                pressed: { outline: "none" },
              }}
            />
          ))
        }
      </Geographies>
    ),
    [geography, mapConfig.color]
  );

  const markers = useMemo(
    () =>
      props.data.map(({ region, percentage }) => {
        const { lat, long, name } = countryObject[region];
        const circleRadius = percentage / scaleFactor / 6 + 20 / scaleFactor;
        return (
          <Marker key={name} coordinates={[long, lat]}>
            <filter id="shadow" x="0" y="0" width="200%" height="200%">
              <feDropShadow dx="1" dy="1" stdDeviation="1" floodColor="#000" floodOpacity="0.5" />
            </filter>
            <circle
              r={circleRadius}
              fill={mapConfig.markers.backgroundColor}
              strokeWidth={0}
              filter="url(#shadow)"
            />
            <text
              textAnchor="middle"
              alignmentBaseline="central"
              style={{
                userSelect: "none",
                fontFamily: mapConfig.markers.fontFamily,
                fontWeight: mapConfig.markers.fontWeight,
                fontSize: circleRadius / 1.7,
                fill: mapConfig.markers.color,
              }}
            >
              {percentage}%
            </text>
          </Marker>
        );
      }),
    [props.data, mapConfig.markers, scaleFactor]
  );

  return (
    <ComposableMap
      width={mapConfig.width}
      height={mapConfig.height}
      //@ts-ignore
      projection={mapConfig.projection}
      style={{
        display: "block",
        width: "100%",
        height: "auto",
        margin: "auto",
        background: mapConfig.backgroundColor,
        ...props.style,
      }}
    >
      <ZoomableGroup onMove={({ k }) => setScaleFactor(k)}>
        <>
          {geographies}
          {markers}
        </>
      </ZoomableGroup>
    </ComposableMap>
  );
};
