import { Paper, Stack, styled } from "@mui/material";
import { ProjectRefDTO, PortfolioStatsDTO } from "datahub";
import { formatNumber } from "utils";
import { BluredPaper } from "../BluredPaper";
import { DataTicket } from "../DataTicket";
import { OrderBookType } from "../OderBookType";
import { ProjectTitle } from "../ProjectTitle";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import defaultImage from "./forest.png";

const StyledPaper = styled(Paper)({
  padding: "10px",
  width: "280px",
  position: "relative",
  borderRadius: "5px",
  cursor: "pointer",
  overflow: "hidden",
  background: "#c0daed",
  "& .projectImage": {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    top: "0px",
    left: "0px",
  },
});

export interface ProjectWalletStatsProps {
  className?: string;
  projectRef: ProjectRefDTO;
  walletStats: PortfolioStatsDTO;
  onClick?: () => void;
}

export const ProjectWalletStats = (props: ProjectWalletStatsProps) => {
  const { projectRef, className, walletStats, onClick } = props;

  const { i18n } = useTranslation();

  const image = useMemo(() => {
    if (projectRef.details.image) return projectRef.details.image;
    return defaultImage;
  }, [projectRef.details.image]);

  return (
    <StyledPaper onClick={onClick} className={`${className ?? ""} projectSubmary-paper`}>
      <img
        className="projectImage"
        src={image}
        alt={"image of the project: " + projectRef.details.name}
      />
      <BluredPaper sx={{ width: "100%", marginBottom: "40px", boxSizing: "border-box" }}>
        <ProjectTitle
          defaultProjectName={projectRef.details.name}
          defaultLocation={projectRef.country}
        />
      </BluredPaper>
      <BluredPaper sx={{ width: "100%", boxSizing: "border-box" }}>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <DataTicket
              data={formatNumber(walletStats.volume, i18n.language)}
            />
            <OrderBookType type={walletStats.type.value} />
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <DataTicket
              minified
              label="purchase value"
              data={formatNumber(walletStats.purchaseValue, i18n.language)}
            />
            <DataTicket
              minified
              label="current value"
              data={formatNumber(walletStats.currentValue, i18n.language)}
            />
          </Stack>
        </Stack>
      </BluredPaper>
    </StyledPaper>
  );
};
