import { Stack, Typography } from "@mui/material";
import { OrdersTable } from "components";
import { OrderDTO, ProjectDTO, ProjectRefDTO, ProtocolDTO } from "datahub";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface TransactionsProps {
  orders: OrderDTO[];
  project: ProjectDTO;
  projectRefs: Map<string, ProjectRefDTO>;
}

export const Transactions = (props: TransactionsProps) => {
  const { orders, project, projectRefs } = props;
  const { t } = useTranslation();

  const TransactionsDiplay = useMemo(() => {
    const ordersByProtocol: { [protocolId: string]: OrderDTO[] } = {};
    orders?.forEach((orderBook) => {
      const protocolId = orderBook.protocolId;
      if (!ordersByProtocol[protocolId]) {
        ordersByProtocol[protocolId] = [];
      }
      ordersByProtocol[protocolId].push(orderBook);
    });
    const Transactions: JSX.Element[] = [];
    for (const protocolId in ordersByProtocol) {
      const orders = ordersByProtocol[protocolId];
      const protocol: ProtocolDTO = project.protocols.find(
        (protocol: ProtocolDTO) => protocol.id === protocolId
      );
      Transactions.push(
        <OrdersTable
          key={protocolId}
          orders={orders}
          protocolName={protocol.details.name}
          projectRefs={projectRefs}
        />
      );
    }
    return Transactions;
  }, [orders, project, projectRefs]);

  return (
    <Stack spacing={5}>
      {orders.length === 0 ? (
        <Typography align="center">{t("projectPage.noTransactions")}</Typography>
      ) : (
        TransactionsDiplay
      )}
    </Stack>
  );
};
