import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { AxessButton } from "../Button/AxessButton";

type DialogActionButton = {
  label: string;
  onClickAction: () => void;
};

export interface AxessDialogProps {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  contentText?: JSX.Element;
  contentBody?: JSX.Element | JSX.Element[];
  actionButtons: DialogActionButton[];
}

export const AxessDialog: React.FC<AxessDialogProps> = (props) => {
  const { contentText, contentBody, actionButtons, onClose, isOpen, title } = props;
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {contentBody}
      </DialogContent>
      <DialogActions>
        {actionButtons.map((actionButton, index) => (
          <AxessButton key={index} onClick={actionButton.onClickAction} autoFocus>
            {actionButton.label}
          </AxessButton>
        ))}
      </DialogActions>
    </Dialog>
  );
};
