import styled from "@emotion/styled";
import { Paper, PaperProps } from "@mui/material";

export type AxessPaperProps = Omit<PaperProps, "variant"> & {
  variant?: "small" | "standard" | "big";
};

const AxessPaperBase = (props: AxessPaperProps) => {
  const { variant = "standard", ...other } = props;
  return <Paper elevation={variant === "standard" ? 5 : variant === "small" ? 2 : 10} {...other} />;
};

export const AxessPaper = styled(AxessPaperBase)(({ variant = "standard" }) => ({
  borderRadius: variant === ("standard" || "small") ? "20px" : "30px",
  padding: variant === "standard" ? "25px" : variant === "small" ? "20px" : "35px",
  boxSizing: "border-box",
}));
