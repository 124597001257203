import { connect } from "react-redux";
import { State } from "store";
import { refs } from "store/refs";
import { title } from "store/title";

const mapStateToProps = (state: State) => ({
  measures: refs.selectors.mesures(state),
  userRefs: refs.selectors.userRefs(state),
});

const mapDispatchToProps = {
  setTitle: title.actions.setTitle,
};

export default connect(mapStateToProps, mapDispatchToProps);
