import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import { Backdrop, Fab, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ProtocolStepsDTO } from "datahub";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useOnScreen from "utils/src/hooks/useOnScreen";
import { Loading } from "../Loading";
import { RiskTriangleHalf } from "./RiskTriangleHalf";
import { ScrollOverlay } from "./ScrollOverlay";
import { TimeLineCells } from "./TimeLineCells";

export type ConeOfUncertaintyProps = {
  items: ProtocolStepsDTO[];
  onClose: Function;
  protocolName: string;
};

export const TimelineConnectorDashed = styled(TimelineConnector)({
  borderLeft: "1px dashed #8C999D",
  backgroundColor: "transparent",
});

const StyledFab = styled(Fab)({
  height: "22px",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 2px 6px #00000026",
  borderRadius: "8px",
  opacity: 1,
  fontSize: "10px",
  color: "#80979D",
});

export const ConeOfUncertainty = (props: ConeOfUncertaintyProps) => {
  const { items, onClose, protocolName } = props;
  const [showScrollUpButton, setShowScrollUpButton] = useState(false);
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);
  const divRef = useRef(null);
  const endOfTimelineRef = useRef(null);
  const isEndOfTimelineVisible = useOnScreen(endOfTimelineRef);
  const startOfTimelineRef = useRef(null);
  const isStartOfTimelineVisible = useOnScreen(startOfTimelineRef);
  const { t } = useTranslation();

  useEffect(() => {
    if (isEndOfTimelineVisible) {
      setShowScrollDownButton(false);
    }
  }, [endOfTimelineRef.current]);

  const handleTimeLineScroll = useCallback(() => {
    if (isStartOfTimelineVisible) {
      setShowScrollDownButton(true);
      setShowScrollUpButton(false);
    } else if (!isStartOfTimelineVisible) {
      setShowScrollDownButton(false);
      setShowScrollUpButton(true);
    }
  }, [isStartOfTimelineVisible]);

  return (
    <Backdrop
      open={true}
      sx={{
        justifyContent: "right",
        alignItems: "center",
        zIndex: 10000,
      }}
    >
      {items ? (
        <Grid
          container
          sx={{
            backgroundColor: "#F3F7F8",
            height: "97vh",
            borderRadius: "20px 0px 0px 20px",
            maxWidth: "45vw",
            right: 0,
            float: "right",
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  sx={{
                    fontSize: "25px",
                    color: "#8C999D",
                    paddingLeft: "70px",
                    paddingTop: "20px",
                  }}
                  variant="h4"
                >
                  {t("timeline")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "31px",
                    paddingLeft: "70px",
                  }}
                  variant="h3"
                >
                  {protocolName}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon
                  sx={{ paddingTop: "10px", cursor: "pointer" }}
                  onClick={() => onClose()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container ref={divRef}>
            <Grid
              onScroll={handleTimeLineScroll}
              item
              xs={11}
              sx={{
                overflowY: "scroll",
                maxHeight: "70vh",
                display: "flex",

                justifyContent: "center",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {showScrollUpButton && (
                <ScrollOverlay>
                  <StyledFab variant="extended">
                    <KeyboardArrowUpIcon /> {t("scrollToSeePassed")}
                  </StyledFab>
                </ScrollOverlay>
              )}
              <Timeline position="right">
                <TimeLineCells
                  endOfTimelineRef={endOfTimelineRef}
                  startOfTimelineRef={startOfTimelineRef}
                  items={items}
                />
              </Timeline>
              {showScrollDownButton && (
                <ScrollOverlay isScrollDown>
                  <StyledFab variant="extended">
                    <KeyboardArrowDownIcon /> {t("scrollToSeeUpcoming")}
                  </StyledFab>
                </ScrollOverlay>
              )}
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                height: "80vh",
              }}
            >
              <Grid container alignItems="center">
                {divRef.current && (
                  <Grid item xs={8}>
                    <Typography sx={{ fontSize: "12px", color: "#8C999D" }}>{t("risk")}</Typography>
                    <div style={{ display: "flex" }}>
                      <RiskTriangleHalf isLeft />
                      <RiskTriangleHalf />
                    </div>
                  </Grid>
                )}
                <Grid
                  item
                  xs={4}
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    transform: "rotate(90deg)",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", color: "#8C999D", whiteSpace: "nowrap" }}
                    align="center"
                  >
                    {t("uncertaintyCone")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </Backdrop>
  );
};
