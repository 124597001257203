import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/system";
import { ProtocolTaskDTO } from "datahub";

type StartDotProps = {
  task: ProtocolTaskDTO;
};

const StyledCheckIcon = styled(CheckIcon)({
  color: "#B7FF08",
});

export const StartDot = (props: StartDotProps) => {
  const { task } = props;
  return (
    <>
      {task.isValidated ? (
        <StyledCheckIcon fontSize="small" />
      ) : (
        <div style={{ width: "20px", height: "20px" }}></div>
      )}
      <div
        style={{
          fontSize: "12px",
          opacity: task.isValidated ? 1 : 0.3,
          backgroundColor: "#F3F7F8",
          borderRadius: "50%",
          marginRight: "20px",
          marginLeft: "5px",
          padding: "2px",
        }}
      >
        {task.code}
      </div>
    </>
  );
};
