import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Container, Typography } from "@mui/material";
import { ProtocolTaskDTO } from "datahub";
import { useTranslation } from "react-i18next";

type ConeOfUncertaintyTaskContentProps = {
  task: ProtocolTaskDTO;
  isFirstTask?: boolean;
  isLastTask?: boolean;
  hasTasksBelow?: boolean;
  toggleTasksDisplay: Function;
};

export const ConeOfUncertaintyTaskContent = (props: ConeOfUncertaintyTaskContentProps) => {
  const { task, isFirstTask, isLastTask, hasTasksBelow, toggleTasksDisplay } = props;
  const { t } = useTranslation();
  return (
    <Container sx={{ padding: "0 !important" }}>
      <Typography
        key={task.name}
        fontSize={14}
        sx={{ padding: 0, maxWidth: "250px", opacity: task.isValidated ? 1 : 0.3 }}
      >
        {task.name}
      </Typography>
      {(hasTasksBelow && isFirstTask) || isLastTask ? (
        <Button
          onClick={() => toggleTasksDisplay()}
          variant="text"
          style={{
            height: "11px",
            fontSize: "9px",
            color: "#80979D",
            whiteSpace: "nowrap",
          }}
        >
          {isFirstTask && (
            <>
              <KeyboardArrowDownIcon fontSize="small" /> {t("showNextActions")}
            </>
          )}
          {isLastTask && (
            <>
              <KeyboardArrowUpIcon fontSize="small" /> {t("showLessActions")}
            </>
          )}
        </Button>
      ) : (
        ""
      )}
    </Container>
  );
};
