import { createSelector } from "reselect";
import { State } from "..";

const getAlertState = (state: State) => state.alert;

export const getAlert = createSelector([getAlertState], (state) => state.alert);

export const selectors = {
  alert: getAlert,
};
