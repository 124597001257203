import { Stack, StackProps, Typography } from "@mui/material";
import React from "react";

export type DataLabeledProps = {
  label: string;
  data: React.ReactNode;
} & StackProps;

export const DataLabeled = (props: DataLabeledProps) => {
  const { label, data, ...rest } = props;
  return (
    <Stack direction="row" alignItems="center" {...rest}>
      <Typography
        sx={{
          color: "primary.main",
          width: "100px",
        }}
      >
        {label}
      </Typography>
      {typeof data === "string" ? <Typography variant="h6">{data}</Typography> : data}
    </Stack>
  );
};
