import { Fragment } from "react";

export const objToArray = <T extends {}>(obj: { [key: string]: T }): (T & { key: string })[] => {
  const array: (T & { key: string })[] = [];
  for (var key in obj) {
    array.push({ ...obj[key], key: key });
  }
  return array;
};

export function groupBy<T, R>(
  arr: T[],
  getKey: (elm: T) => string,
  transform: (elm: T) => R = (elm) => elm as unknown as R
): Map<string, R[]> {
  const map = new Map<string, R[]>();

  arr.forEach((item) => {
    const key = getKey(item);
    map.set(key, [...(map.get(key) ?? []), transform(item)]);
  });

  return map;
}

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const formatNumber = (
  num: number,
  language: string,
  fractionDigits: number = 2,
  isCurrency: boolean = false
) => {
  const fractions = fractionDigits > 0 ? Math.pow(10, fractionDigits) : 1;
  const fixedValue = Math.round(num * fractions) / fractions;
  return new Intl.NumberFormat(language, {
    style: isCurrency ? "currency" : "decimal",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(fixedValue);
};

export const distinct = <T extends {}>(
  array: T[],
  getKey: (item: T) => string | number = JSON.stringify
) => {
  const seen = new Set();

  return array.filter((item) => {
    const key = getKey(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};

export const typeParam = <T extends {}>(
  param: any,
  expectedType: "array" | "string"
): string | void | T[] => {
  if (expectedType === "array")
    return Array.isArray(param) ? param : typeof param === "string" ? [param] : undefined;
  if (expectedType === "string") return typeof param === "string" ? param : undefined;
};

export const generateSpacedText = (text?: string): JSX.Element[] => {
  if (!text) return [];
  const tab = text.split("\n");
  return tab.map((item, index) => {
    if (index + 1 === tab.length) return <Fragment key={index}>{item}</Fragment>;
    return (
      <Fragment key={index}>
        {item} <br />
      </Fragment>
    );
  });
};

export * from "./constants";
