import { Stack, styled, Typography, useTheme as useThemeMUI } from "@mui/material";
import { ProjectDTO } from "datahub";
import { Location } from "../icons";
import { AxessPaper } from "../Paper";
import { useTheme } from "@smartb/g2-themes";
import { countryObject } from "../countryObject";
import { useMemo } from "react";
import { generateSpacedText } from "utils";

export interface ProjectPresentationProps {
  project: ProjectDTO;
  className?: string;
}

const ProjectPresentationBase = (props: ProjectPresentationProps) => {
  const { project, className } = props;
  const theme = useTheme();
  const themeMUI = useThemeMUI();
  const summary = useMemo(() => {
    return generateSpacedText(project.details.summary);
  }, [project.details.summary])
  return (
    <AxessPaper
      variant="small"
      sx={{
        position: "relative",
      }}
      className={className}
    >
      {project.details.image && (
        <img
          className="projectImage"
          src={project.details.image}
          alt={"image of the project: " + project.details.name}
        />
      )}
      <Typography align="center" variant="h5">
        {project.details.name}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          marginTop: "15px",
          marginBottom: "30px",
          color: themeMUI.palette.text.projectSecondary,
          "& .locationIcon": {
            color: theme.colors.secondary,
            fill: theme.colors.secondary,
            width: "20px",
            height: "20px",
          },
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Location className="locationIcon" />
        <Typography>{countryObject[project.land.country]?.name}</Typography>
      </Stack>
      <Typography align="center">
        {summary}
      </Typography>
    </AxessPaper>
  );
};

export const ProjectPresentation = styled(ProjectPresentationBase)(({ theme, project }) => ({
  background: theme.palette.primary.main,
  color: "white",
  "& .projectImage": {
    position: "absolute",
    width: "100%",
    height: "200px",
    objectFit: "cover",
    top: "0px",
    left: "0px",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  paddingTop: project.details.image ? "225px !important" : "",
  padding: "35px 70px",
  boxSizing: "border-box",
}));
