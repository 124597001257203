import { Apartment, Email, Language, Phone } from "@mui/icons-material";
import { Box, Divider, Stack, styled, Typography } from "@mui/material";
import { CopyToClipboard, DropPicture, Link } from "@smartb/g2-components";
import { OrganizationDTO } from "datahub";

import { EditOrganization } from "./EditOrganization";

const StyledStack = styled(Stack)({
  width: "300px",
  "& .orga-contactIcon": {
    color: "#808A9D",
  },
  "& .orga-contactText": {
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  "& .orga-contactCopy": {
    marginLeft: "0px !important",
  },
});

//@ts-ignore
export const DropLogo = styled(DropPicture)({
  width: "100%",
  height: "200px !important",
});

export interface OrganizationProps {
  organization?: OrganizationDTO;
  edit?: boolean;
  className?: string;
  onChangeName?: (name: string) => void;
  onChangeTitle?: (title: string) => void;
  onChangeAddress?: (address: string) => void;
  onChangePhone?: (phone: string) => void;
  onChangeEmail?: (email: string) => void;
  onChangeWebsite?: (website: string) => void;
  onChangeLogo?: (logo: File) => void;
}

export const Organization = (props: OrganizationProps) => {
  const { className, edit, organization } = props;
  if (edit) return <EditOrganization {...props} />;
  if (!organization?.name) return <></>;
  return (
    <StyledStack spacing={2} className={className}>
      <Typography
        sx={{ lineHeight: 1, overflow: "hidden", textOverflow: "ellipsis" }}
        variant="subtitle2"
      >
        {organization?.name}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography
          sx={{
            color: "#808A9D",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          variant="caption"
        >
          {organization?.title}
        </Typography>
      </Stack>
      <Divider variant="middle" />
      <ContactWithLogo
        logo={<Apartment className="orga-contactIcon" />}
        text={organization?.contact.address}
      />
      <ContactWithLogo
        logo={<Phone className="orga-contactIcon" />}
        text={organization?.contact.phone}
      />
      <ContactWithLogo
        logo={<Email className="orga-contactIcon" />}
        text={organization?.contact.email}
      />
      <ContactWithLogo
        logo={<Language className="orga-contactIcon" />}
        text={organization?.contact.website}
        link
      />

      <DropLogo
        //@ts-ignore
        defaultPicture={organization?.logo}
        readonly
      />
    </StyledStack>
  );
};

interface ContactWithLogoProps {
  text?: string;
  logo: React.ReactNode;
  link?: boolean;
}

export const ContactWithLogo = (props: ContactWithLogoProps) => {
  const { logo, text, link = false } = props;
  if (!text) return <></>;
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {logo}
      {link ? (
        <Link
          className="orga-contactText"
          target="_blank"
          rel="noreferrer"
          href={text}
          variant="body2"
        >
          {text}
        </Link>
      ) : (
        <Typography className="orga-contactText" variant="body2">
          {text}
        </Typography>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <CopyToClipboard className="orga-contactCopy" value={text ?? ""} />
    </Stack>
  );
};
