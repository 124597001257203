import { Apartment, Email, Language, Phone } from "@mui/icons-material";
import { Divider, Stack, styled } from "@mui/material";
import { TextField } from "@smartb/g2-forms";
import { OrganizationDTO } from "datahub";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { DropLogo } from "./Organization";

const StyledStack = styled(Stack)({
  width: "300px",
  "& .orga-contactIcon": {
    color: "#808A9D",
  },
  "& .orga-contactTextfield": {
    flexGrow: 1,
  },
});

export interface EditOrganizationProps {
  organization?: OrganizationDTO;
  className?: string;
  onChangeName?: (name: string) => void;
  onChangeTitle?: (title: string) => void;
  onChangeAddress?: (address: string) => void;
  onChangePhone?: (phone: string) => void;
  onChangeEmail?: (email: string) => void;
  onChangeWebsite?: (website: string) => void;
  onChangeLogo?: (logo: File) => void;
}

export const EditOrganization = (props: EditOrganizationProps) => {
  const {
    className,
    organization,
    onChangeAddress,
    onChangeEmail,
    onChangeName,
    onChangePhone,
    onChangeTitle,
    onChangeWebsite,
    onChangeLogo,
  } = props;
  const [orgaName, setorgaName] = useState(organization?.name ?? "");
  const [orgaRole, setorgaRole] = useState(organization?.title ?? "");
  const { t } = useTranslation();

  const onBlurOrgaName = useCallback(() => {
    onChangeName && onChangeName(orgaName);
  }, [orgaName, onChangeName]);

  const onBlurOrgaRole = useCallback(() => {
    onChangeTitle && onChangeTitle(orgaRole);
  }, [orgaRole, onChangeTitle]);

  return (
    <StyledStack spacing={2} className={className}>
      <TextField
        placeholder={t("projectPage.organizationName")}
        value={orgaName}
        onChange={setorgaName}
        onBlur={onBlurOrgaName}
      />
      <Stack direction="row" alignItems="center" spacing={1}>
        <TextField
          placeholder={t("projectPage.organizationRole")}
          size="small"
          value={orgaRole}
          onChange={setorgaRole}
          onBlur={onBlurOrgaRole}
        />
      </Stack>
      <Divider variant="middle" />
      <ContactEditWithLogo
        logo={<Apartment className="orga-contactIcon" />}
        placeholder={t("headquarterLocation")}
        defaultValue={organization?.contact.address}
        onChange={onChangeAddress}
      />
      <ContactEditWithLogo
        logo={<Phone className="orga-contactIcon" />}
        placeholder={t("phoneNumber")}
        defaultValue={organization?.contact.phone}
        onChange={onChangePhone}
      />
      <ContactEditWithLogo
        logo={<Email className="orga-contactIcon" />}
        placeholder={t("emailAddress")}
        defaultValue={organization?.contact.email}
        onChange={onChangeEmail}
      />
      <ContactEditWithLogo
        logo={<Language className="orga-contactIcon" />}
        placeholder={t("websiteUrl")}
        defaultValue={organization?.contact.website}
        onChange={onChangeWebsite}
      />
      <DropLogo
        //@ts-ignore
        maxSize={10000000}
        //@ts-ignore
        initialPicture={organization?.logo}
        defaultPicture={organization?.logo}
        addPictureHelperText={t("addLogo")}
        removePictureHelperText={t("removeLogo")}
        onPictureDropped={onChangeLogo}
      />
    </StyledStack>
  );
};

interface ContactWithLogoProps {
  defaultValue?: string;
  logo: React.ReactNode;
  placeholder?: string;
  onChange?: (value: string) => void;
}

export const ContactEditWithLogo = (props: ContactWithLogoProps) => {
  const { logo, defaultValue, onChange, placeholder } = props;
  const [value, setvalue] = useState<string>(defaultValue ?? "");
  const onBlur = useCallback(() => onChange && onChange(value), [onChange, value]);
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {logo}
      <TextField
        className="orga-contactTextfield"
        placeholder={placeholder}
        value={value}
        onChange={setvalue}
        onBlur={onBlur}
      />
    </Stack>
  );
};
