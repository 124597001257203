import { connect } from "react-redux";
import { State } from "store";
import { refs } from "store/refs";

const mapStateToProps = (state: State) => ({
  measures: refs.selectors.mesures(state),
  projectRefs: refs.selectors.projectRefs(state),
  orderBookOverviews: refs.selectors.orderBookOverviews(state),
  userRights: refs.selectors.userProjectRights(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps);
