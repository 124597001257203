import { Box, Stack, styled, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "utils";
import { Sdg } from "../Sdg/Sdg";
import { sdgToColor } from "./sdgToColor";
import { Tooltip } from "@smartb/g2-notifications";

export interface SdgProgressBarProps {
  sdgId: number;
  percentProgress: number;
  className?: string;
}

export const SdgProgressBarBase = (props: SdgProgressBarProps) => {
  const { sdgId, percentProgress, className } = props;
  const { i18n, t } = useTranslation();

  const [parentRef, setparentRef] = useState<HTMLDivElement | undefined>(undefined);

  const percentDownGraded = useMemo(() => {
    const base = parentRef ? 90 / parentRef.clientWidth : 0.2;
    return percentProgress * (1 - base);
  }, [percentProgress, parentRef]);

  const formattedPercentage = useMemo(() => {
    return formatNumber(percentProgress, i18n.language, 0);
  }, [percentProgress, i18n.language]);

  return (
    <Box className={className}>
      <Tooltip helperText={t(`sdg.${sdgId}`)}>
        <Stack
          ref={setparentRef}
          direction="row"
          display="inline-flex"
          spacing={1}
          sx={{
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            display="inline-flex"
            sx={{
              background: sdgToColor[sdgId - 1],
              borderRadius: "15px",
              width: `calc(40px + ${percentDownGraded}%)`,
              minWidth: "40px",
              maxWidth: "calc(100% - 50px)",
              paddingRight: "8px",
              height: "100%",
              alignItems: "center",
              boxSizing: "border-box",
            }}
          >
            <Sdg noTooltip size="small" sdgId={sdgId} />
          </Stack>
          <Typography variant="body2">{formattedPercentage}%</Typography>
        </Stack>
      </Tooltip>
    </Box>
  );
};

export const SdgProgressBar = styled(SdgProgressBarBase)({
  overflow: "hidden",
  background: "#F1F6F7",
  borderRadius: "15px",
});
