import { Paper, Stack, styled } from "@mui/material";
import { OrderBookValues, ProtocolValues, TerritoryMap } from "components";
import {
  MeasureUnitDTO,
  OrderBookOverviewDTO,
  ProjectDTO,
  ProtocolDTO,
  ProtocolTypeDTO,
} from "datahub";
import { useCallback } from "react";
import { Protocols } from "./Protocols";

const StyledMapContainer = styled(Paper)({
  width: "450px",
  maxHeight: "700px",
  overflow: "hidden",
  "& .project-jsonEditor": {
    maxHeight: "300px",
    overflowY: "auto",
  },
  projectMap: {
    width: "100%",
  },
});

interface ProjectAdditionnalsProps {
  edit?: boolean;
  isAdmin?: boolean;
  project: ProjectDTO;
  protocols: ProtocolDTO[];
  orderBooks?: OrderBookOverviewDTO[];
  updateProject?: (concatKeysTab: string[], newValues: any[]) => void;
  onAddProtocol?: (values: ProtocolValues) => void;
  onDeleteProtocol?: (protocolDTO: Partial<ProtocolDTO>) => void;
  onUpdateProtocol?: (values: ProtocolValues) => void;
  onCreateOrderBook?: (values: OrderBookValues, protocol: ProtocolDTO) => void;
  onEditOrderBook?: (values: OrderBookValues, orderBook: OrderBookOverviewDTO) => void;
  onPublishOrderBook?: (orderBook: OrderBookOverviewDTO) => void;
  onEvaluate?: (quantity: number, protocol: ProtocolDTO) => void;
  onValidate?: (quantity: number, price: number, protocol: ProtocolDTO) => void;
  measures?: Map<string, MeasureUnitDTO>;
  protocolTypes?: Map<string, ProtocolTypeDTO>;
  isNew?: boolean;
}

export const ProjectAdditionnals = (props: ProjectAdditionnalsProps) => {
  const {
    project,
    edit,
    isAdmin = false,
    protocols,
    updateProject,
    onAddProtocol,
    onDeleteProtocol,
    onUpdateProtocol,
    measures = new Map<string, MeasureUnitDTO>(),
    protocolTypes = new Map<string, ProtocolTypeDTO>(),
    orderBooks,
    onEditOrderBook,
    onCreateOrderBook,
    onPublishOrderBook,
    isNew = false,
    onEvaluate,
    onValidate,
  } = props;

  const onChangeGeoData = useCallback(
    (geoJson: string) => {
      updateProject && updateProject(["land.geoData"], [geoJson]);
    },
    [updateProject]
  );

  return (
    <Stack
      spacing={4}
      sx={{
        padding: "15px 8px",
      }}
      alignItems="center"
    >
      <StyledMapContainer elevation={3}>
        <TerritoryMap
          onGeoJsonUpdate={onChangeGeoData}
          jsonEditorClassName="project-jsonEditor"
          editable={edit}
          geojson={project.land.geoData}
        />
      </StyledMapContainer>
      {!isNew && (
        <Protocols
          onValidate={onValidate}
          onEvaluate={onEvaluate}
          orderBooks={orderBooks}
          onPublishOrderBook={onPublishOrderBook}
          onEditOrderBook={onEditOrderBook}
          onCreateOrderBook={onCreateOrderBook}
          project={project}
          protocols={protocols}
          edit={isAdmin}
          measures={measures}
          protocolTypes={protocolTypes}
          onAddProtocol={onAddProtocol}
          onDeleteProtocol={onDeleteProtocol}
          onUpdateProtocol={onUpdateProtocol}
        />
      )}
    </Stack>
  );
};
