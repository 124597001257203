import { UserProjectRightDTO } from "datahub/src/types";
import { useMemo } from "react";

/*
 *  0 - No access
 *  1 - Read
 *  2 - Write and Read
 */
export default function useUserRights(
  userRights: Map<string, UserProjectRightDTO>,
  projectIds: string[]
) {
  const accessAndProjects = useMemo(() => {
    const userRightsCurrentProjects = Array.from(userRights ? userRights.values() : []).filter(
      (project) => projectIds?.includes(project.projectId)
    );

    let result = new Map();
    userRightsCurrentProjects.map((elem: UserProjectRightDTO) => {
      return result.set(elem.projectId, elem.userRight);
    });

    return {
      userAccessMap: result,
    };
  }, [userRights, projectIds]);

  return accessAndProjects;
}
