import { Divider, Paper, Stack, styled, Typography } from "@mui/material";
import { Button, EditButton } from "@smartb/g2-components";
import {
  MeasureUnitDTO,
  OrderBookOverviewDTO,
  ProtocolDetailsDTO,
  ProtocolDTO,
  ProtocolTypeDTO,
  SdgDTO,
} from "datahub";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeepPartial } from "utils";
import { DataTicket } from "../DataTicket";
import { OrderBookOverview, OrderBookValues } from "../OrderBookOverview";
import { SdgCard } from "../SdgCard";
import { SectorAdmin } from "../SectorAdmin";
import { ProtocolEditInfoCard } from "./ProtocolEditInfoCard";

const StyledPaper = styled(Paper)({
  maxWidth: "450px",
  width: "100%",
  boxSizing: "border-box",
  position: "relative",
  "& .AruiForm-actions": {
    marginTop: "20px",
    marginBottom: "10px",
  },
  "& .MuiInputLabel-root": {
    fontSize: "16px",
    color: "#808A9D",
    marginBottom: "5px",
  },
  "& .protocol-form-name-unit": {
    display: "flex",
    alignItems: "baseline",
  },
  "& .protocol-form-type-unit": {
    display: "flex",
    alignItems: "baseline",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  "& .protocol-form-type-unit .MuiFormControl-root": {
    width: "200px",
  },
  "& .protocol-form-name-unit .MuiFormControl-root": {
    width: "200px",
    marginLeft: "10px",
  },
  "& .dataFieldsForm": {
    marginTop: "10px",
  },
  "& .unitFieldForm": {
    marginTop: "25px",
  },
  "& .dataFieldsForm .MuiFormControl-root": {
    width: "200px",
  },
  "& .dataFieldsForm .MuiInputAdornment-root .MuiTypography-root": {
    fontSize: "0.875rem",
  },
  "& .dataFieldsForm .MuiInputBase-input": {
    paddingRight: "0px",
  },
  "& .MuiFormHelperText-root": {
    whiteSpace: "nowrap",
  },
  "& .protocol-delete-iconButtton": {
    position: "absolute",
    top: "10px",
    right: "10px",
    padding: 10,
  },
  "& .protocol-delete-icon": {
    width: "25px",
    height: "25px",
    stroke: "#353945",
    strokeWidth: "1.5",
  },
  "& .protocol-form-name-field": {
    width: "300px",
    marginBottom: 0,
  },
  "& .protocol-form-sdgList": {
    width: "120px",
    margin: 0,
  },
  "& .protocol-form-sector": {
    width: "120px",
    marginBottom: 0,
  },
});

export type ProtocolValues = Omit<
  ProtocolDTO,
   "status" | "details" | "projectId" | "sdgList" | "unit"
> &
  ProtocolDetailsDTO & { sdgList: number[]; unit: string };

export interface ProtocolInfoCardProps {
  protocol: ProtocolDTO;
  selectedSdgs?: SdgDTO[];
  measures?: Map<string, MeasureUnitDTO>;
  protocolTypes?: Map<string, ProtocolTypeDTO>;
  className?: string;
  create?: boolean;
  onChangeProtocol?: (values: ProtocolValues) => void;
  onDelete?: (protocolDTO: Partial<ProtocolDTO>) => void;
  isAdmin?: boolean;
  onCreateOrderBook?: (values: OrderBookValues) => void;
  onEditOrderBook?: (values: OrderBookValues, orderBook: OrderBookOverviewDTO) => void;
  onPublishOrderBook?: (orderBook: OrderBookOverviewDTO) => void;
  orderBooks?: OrderBookOverviewDTO[];
  onEvaluate?: (quantity: number) => void;
  onValidate?: (quantity: number, price: number) => void;
}

export const ProtocolInfoCard = (props: ProtocolInfoCardProps) => {
  const {
    protocol,
    className,
    create = false,
    onChangeProtocol,
    onDelete,
    measures,
    protocolTypes,
    onCreateOrderBook,
    orderBooks = [],
    isAdmin = false,
    onEditOrderBook,
    onPublishOrderBook,
    onEvaluate,
    onValidate,
    selectedSdgs,
  } = props;
  const { t } = useTranslation();

  const [evaluate, setEvaluate] = useState(false);

  const [validate, setValidate] = useState(false);

  const [createOrderBook, setCreateOrderBook] = useState(false);

  const [editProtocol, setEditProtocol] = useState(false);

  const toggleCreateOrderBook = useCallback(() => setCreateOrderBook((old) => !old), []);

  const orderBooksUi = useMemo(
    () =>
      orderBooks.map((orderBook) => (
        <OrderBookOverview
          availableQuantity={protocol.availableQuantity}
          key={orderBook.id}
          canEdit={isAdmin}
          orderBook={orderBook}
          onChangeOrderBook={(values) => onEditOrderBook(values, orderBook)}
          onPublish={() => onPublishOrderBook(orderBook)}
        />
      )),
    [orderBooks, onEditOrderBook, onPublishOrderBook, protocol.availableQuantity]
  );

  const onCreateOrderBookMemo = useCallback(
    (values) => {
      onCreateOrderBook(values);
      toggleCreateOrderBook();
    },
    [onCreateOrderBook]
  );

  const onClickEvaluate = useCallback(() => setEvaluate(true), []);

  const onEvaluateMemoized = useCallback(
    async (values) => {
      const quantity = Number(values.availableQuantity);
      onEvaluate && (await onEvaluate(quantity));
      setEvaluate(false);
    },
    [onEvaluate]
  );

  const onClickValidate = useCallback(() => setValidate(true), []);

  const onValidateMemoized = useCallback(
    async (values) => {
      const quantity = Number(values.availableQuantity);
      const price = Number(values.price);
      onValidate && (await onValidate(quantity, price));
      setValidate(false);
    },
    [onValidate]
  );

  const onCancel = useCallback(() => {
    setValidate(false);
    setEvaluate(false);
  }, []);

  const handleDelete = useCallback((protocol: ProtocolDTO) => {
    props.onDelete(protocol);
  },[]);

  const handleEdit = useCallback(() => {
    setEditProtocol(true);
  },[]); 

  const cancelEdit = useCallback(() => {
    setEditProtocol(false)
  },[])

  const handleSubmitEdit = useCallback((values: ProtocolValues) => {
    const protocolDTO = {...values, id:protocol.id } as ProtocolValues;
    onChangeProtocol(protocolDTO);
    setEditProtocol(false);
  },[])

  return (
    <StyledPaper
      sx={{
        padding: create ? "10px" : "20px",
      }}
      id={protocol.id}
      className={className}
      elevation={2}
    >
      {!create && !editProtocol ? (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">{protocol.details.name}</Typography>
            <SdgCard aligned defaultSdgs={protocol.details.sdgList} maxLength={2} />
          </Stack>
          <SectorAdmin defaultSector={protocol.details.sector} />
          <Typography sx={{ margin: "15px 0" }} variant="body2">
            {protocol.details.description}
          </Typography>
          {!evaluate && !validate ? (
          <>
            {protocol.type.name !== t("chooseTheSdgs") && <Stack direction="row" spacing={1} alignItems="center" marginTop="30px"> 
              <DataTicket
                data={(protocol.availableQuantity ?? 0) + " " + protocol.type.unit.notation}
                label={t("availableQuantity")}
              />
              {isAdmin && (
                <Stack justifyContent="center" spacing={1}>
                  <EditButton onClick={onClickEvaluate}>{t("evaluate")}</EditButton>
                  <Button variant="text" onClick={onClickValidate}>
                    {t("editProjectPage.validateTheProtocol")}
                  </Button>
                </Stack>
              )}
            </Stack>}
          </>
          ) : (
            <ProtocolEditInfoCard
              selectedSdgs={selectedSdgs}
              onCancel={onCancel}
              validate={validate}
              evaluate={evaluate}
              protocol={protocol}
              measures={measures}
              protocolTypes={protocolTypes}
              onSubmit={editProtocol ? handleSubmitEdit : evaluate ? onEvaluateMemoized : onValidateMemoized}
            />
          )}
          <Stack spacing={1}>
            {orderBooksUi}
            {createOrderBook && (
              <OrderBookOverview
                availableQuantity={protocol.availableQuantity}
                edit
                //@ts-ignore
                orderBook={emptyOrderBook(protocol)}
                onChangeOrderBook={onCreateOrderBookMemo}
              />
            )}
          </Stack>
          {isAdmin && !createOrderBook && (
            <>
              <Divider
                sx={{
                  marginBottom: "15px",
                  borderBottomWidth: "2px",
                  borderBottomColor: "#C4C4C4",
                }}
              />
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button onClick={handleEdit}>
                  {t("edit")}
                </Button>
                <Button onClick={() => handleDelete(protocol)}>
                  {t("delete")}
                </Button>
                <Button onClick={toggleCreateOrderBook}>
                  {t("editProjectPage.ceateAFundCall")}
                </Button>
              </Stack>
            </>
          )}
        </>
      ) : (
        <ProtocolEditInfoCard
          selectedSdgs={selectedSdgs}
          protocol={protocol}
          measures={measures}
          protocolTypes={protocolTypes}
          onSubmit={editProtocol ? handleSubmitEdit : onChangeProtocol}
          onDelete={onDelete}
          isEditing={editProtocol}
          onCancel={cancelEdit}
        />
      )}
    </StyledPaper>
  );
};

const emptyOrderBook = (protocol: ProtocolDTO): DeepPartial<OrderBookOverviewDTO> => {
  return {
    startDate: Date.now(),
    endDate: undefined,
    status: {
      value: undefined,
    },
    type: {
      value: undefined,
    },
    unit: protocol.type.unit,
  };
};
