import { ArrowBackRounded } from "@mui/icons-material";
import { IconButton, Stack, styled, Typography } from "@mui/material";
import { Button, DropPicture } from "@smartb/g2-components";
import { OrganizationList, ProjectTitle, SdgCard } from "components";
import { ProjectStatus } from "components/src/PlateformStatus";
import {
  OrganizationDTO,
  ProjectDTO,
  ProjectStatusValues,
  ProjectType,
  SdgDTO,
} from "datahub";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, LinkProps } from "react-router-dom";
import { fileToBase64 } from "utils";

//@ts-ignore
const StyledDropPicture = styled(DropPicture)({
  width: "100%",
  height: "200px !important",
});

const ButtonContainer = styled(Stack)({
  "& button": {
    padding: "5px 12px",
  },
});

interface ProjectHeaderProps {
  edit?: boolean;
  isAdmin?: boolean;
  project: ProjectDTO;
  gobackUrl: string;
  goToProjectMarketUrl: string;
  updateProject?: (concatKeysTab: string[], newValues: any[]) => void;
  onSaveChanges?: () => Promise<void>;
  onPublishProject?: () => Promise<void>;
  projectSdgList: SdgDTO[];
  isNew?: boolean;
  hasChanges?: boolean;
}

export const ProjectHeader = (props: ProjectHeaderProps) => {
  const {
    edit = false,
    project,
    updateProject,
    onSaveChanges,
    isNew = false,
    projectSdgList,
    hasChanges = false,
    onPublishProject,
    gobackUrl,
    goToProjectMarketUrl,
    isAdmin = false,
  } = props;
  const { t } = useTranslation();
  const [isParentDisabled, setIsParentDisabled] = useState(false);

  const isCreated = project.status.value === ProjectStatusValues.created();
  const isPublished = project.status.value === ProjectStatusValues.published();

  const onChangeProjecName = useCallback(
    (projectName: string) => {
      updateProject && updateProject(["details.name"], [projectName]);
    },
    [updateProject]
  );

  const onChangeLocation = useCallback(
    (location: string) => {
      updateProject && updateProject(["land.country"], [location]);
    },
    [updateProject]
  );

  const onChangeIsParent = useCallback(
    (isParent: boolean) => {
      updateProject && updateProject(["childProjectsRefs", "projectType"],
      [isParent ? [] : undefined, isParent ? ProjectType.PARENT : ProjectType.STANDALONE]);
    },
    [updateProject]
  );

  const onChangeImage = useCallback(
    async (picture: File) => {
      const image = await fileToBase64(picture);
      updateProject && updateProject(["details.image"], [image]);
    },
    [updateProject]
  );

  const onChangeStakeHolders = useCallback(
    (stakeHolders: OrganizationDTO[]) => {
      updateProject && updateProject(["stakeholders"], [stakeHolders]);
    },
    [updateProject]
  );

  useEffect(() => {
    if(project.projectType === ProjectType.PARENT &&
      project.childProjectsRefs && project.childProjectsRefs.length > 0 ||
      project.protocols && project.protocols.length > 0) {
        setIsParentDisabled(true);
      }else {
        setIsParentDisabled(false);
      }

  },
  [project.protocols, project.projectType]);

  return (
    <>
      <Stack direction="row" alignItems="center" marginBottom="30px" spacing={2}>
        {/* @ts-ignore */}
        <IconButton<Link> LinkComponent={Link} to={gobackUrl}>
          <ArrowBackRounded />
        </IconButton>
        <ProjectTitle
          edit={edit}
          defaultLocation={project.land.country}
          defaultProjectName={project.details.name}
          defaultIsParent={Boolean(project.projectType === ProjectType.PARENT)}
          isParentDisabled={isParentDisabled}
          onChangeLocation={onChangeLocation}
          onChangeProjectName={onChangeProjecName}
          onChangeIsParent={onChangeIsParent}
        />
        <SdgCard defaultSdgs={projectSdgList} maxLength={5} />
        {isAdmin && !isNew && <ProjectStatus status={project.status.value} />}
        {edit && (
          <ButtonContainer justifyContent="center" spacing={1}>
            {hasChanges && (isCreated || isPublished) && (
              <Button onClick={onSaveChanges}>{t("saveChanges")}</Button>
            )}
            {isNew && <Button onClick={onSaveChanges}>{t("createTheProject")}</Button>}
            {isCreated && <Button onClick={onPublishProject}>{t("publishProject")}</Button>}
          </ButtonContainer>
        )}
        {!isNew && !isCreated && (
          <Button<LinkProps> component={Link} componentProps={{ to: goToProjectMarketUrl }}>
            {t("projectMarket")}
          </Button>
        )}
      </Stack>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        flexWrap="wrap"
        marginBottom="15px"
        sx={{
          gap: "20px",
        }}
      >
        {edit && (
          <Stack
            sx={{
              width: "300px",
            }}
          >
            <Typography align="center" variant="subtitle2">
              {t("editProjectPage.editMainPicture")}
            </Typography>

            <StyledDropPicture
              //@ts-ignore
              maxSize={10000000}
              initialPicture={project.details.image}
              addPictureHelperText={t("addImage")}
              removePictureHelperText={t("removeImave")}
              onPictureDroped={onChangeImage}
            />
          </Stack>
        )}
      </Stack>
      <Typography sx={{ margin: "10px 0" }}>{t("stakeholders")}</Typography>
      <OrganizationList
        edit={edit}
        stakeHolders={project.stakeholders ?? []}
        onUpdate={onChangeStakeHolders}
      />
    </>
  );
};
