import { Button } from "@smartb/g2-components";
import { Transaction } from "./Transaction";
import { OrderDTO } from "datahub";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export interface PendingTransactionProps {
  order: OrderDTO;
  onCancel?: () => void;
}

export const PendingTransaction = (props: PendingTransactionProps) => {
  const { order, onCancel } = props;
  const { t } = useTranslation();
  return (
    <Transaction withPrice order={order}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={onCancel}>{t("cancel")}</Button>
      </Box>
    </Transaction>
  );
};
