import { useCallback, useMemo, useState } from "react";
import { FormField, Form, FormState } from "@smartb/g2-forms";
import { Drawer, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AxessButton } from "../Button/AxessButton";
import { Close } from "@mui/icons-material";
import { Filters } from "../icons";

export type ExtendedField = FormField & { isImportant?: boolean };

export interface ResponsiveFiltersProps {
  fields: ExtendedField[];
  formState: FormState;
  className?: string;
  responsiveBreakpoint?: string;
  alwaysResponsive?: boolean;
  onClear?: (formState: FormState) => void;
}

export const ResponsiveFilters = (props: ResponsiveFiltersProps) => {
  const {
    fields,
    formState,
    className,
    responsiveBreakpoint,
    alwaysResponsive = false,
    onClear,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(responsiveBreakpoint ?? theme.breakpoints.down("md"));
  const [open, setopen] = useState(false);

  const groupedFields = useMemo(() => {
    const importants: FormField[] = [];
    const others: FormField[] = [];
    fields.forEach((field) => {
      if (field.isImportant) importants.push(field);
      else others.push(field);
    });
    return {
      importants: importants,
      others: others,
    };
  }, [fields]);

  const onCloseDrawer = useCallback(() => {
    setopen(false);
    formState.submitForm();
  }, [formState.submitForm]);

  const onOpenDrawer = useCallback(() => setopen(true), []);

  const onClickSubmit = useCallback(() => {
    formState.submitForm();
    onCloseDrawer();
  }, [formState.submitForm, onCloseDrawer]);

  const onClickClear = useCallback(() => {
    if (onClear) onClear(formState);
    else {
      formState.resetForm({
        values: {},
      });
    }
  }, [formState.resetForm]);

  if (isMobile || alwaysResponsive)
    return (
      <>
        <Stack
          className={className}
          direction="row"
          alignItems="center"
          sx={{
            "& .filtersIcon": {
              width: "20px",
              height: "20px",
              marginRight: "10px",
            },
          }}
        >
          <AxessButton
            icon={<Filters className="filtersIcon" />}
            onClick={onOpenDrawer}
            sx={{ marginRight: "25px" }}
          >
            {t("filters")}
          </AxessButton>
          <Form formState={formState} fields={groupedFields.importants} />
        </Stack>
        <Drawer
          className={className}
          anchor="left"
          open={open}
          onClose={onCloseDrawer}
          sx={{
            "& .MuiPaper-root": {
              padding: "20px",
              width: "100%",
              maxWidth: "350px",
              background: "#F3F7F8",
            },
            "& .drawer-Form-form": {
              flexDirection: "column",
              alignItems: "flex-end",
            },
            "& .AruiForm-field": {
              width: "100%",
            },
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">{t("filters")}</Typography>
            <IconButton onClick={onCloseDrawer}>
              <Close />
            </IconButton>
          </Stack>
          <Form
            className="drawer-Form-form"
            fieldsStackProps={{
              direction: "column",
              spacing: 2,
              alignItems: "center",
              padding: "10px",
              width: "100%",
              boxSizing: "border-box",
            }}
            actionsStackProps={{ marginBottom: "15px", marginRight: "15px" }}
            formState={formState}
            fields={groupedFields.others}
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <AxessButton className="mobileForm-validate" onClick={onClickSubmit}>
              {t("apply")}
            </AxessButton>
            <AxessButton variant="text" onClick={onClickClear}>
              {t("clear")}
            </AxessButton>
          </Stack>
        </Drawer>
      </>
    );
  return (
    <Form
      fieldsStackProps={{ direction: "row", sx: {gap: "11px !important"}, alignItems: "center" }}
      className={className}
      formState={formState}
      fields={fields}
    />
  );
};
