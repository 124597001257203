import { Stack, Typography } from "@mui/material";
import pattern from "patternomaly";
import React from "react";
import { Bar } from "react-chartjs-2";
import { chartColors, getChartOptions } from "./chart-config";

export interface AccountingStackedBarChartProps {
  values: { emissions: number; compensation: number; year: number }[];
}
export const AccountingStackedBarChart: React.FC<AccountingStackedBarChartProps> = (props) => {
  const { values } = props;

  const data = {
    labels: values.map((value) => value.year),
    datasets: [
      {
        label: "Emissions",
        data: values.map((value) => value.emissions),
        backgroundColor: chartColors.primary,
        stack: "Stack 0",
        pointStyle: "rect",
        pointRadius: 1,
      },
      {
        label: "Compensation",
        data: values.map((value) =>
          value.compensation > value.emissions ? value.emissions : value.compensation
        ),
        backgroundColor: "#B7FF08",
        stack: "Stack 1",
        pointStyle: "rect",
        pointRadius: 1,
      },
      {
        label: "Over-compensation",
        data: values.map((value) => {
          const excess = value.compensation - value.emissions;
          return excess > 0 ? excess : 0;
        }),
        backgroundColor: "#8FC705",
        stack: "Stack 1",
        pointStyle: "rect",
        pointRadius: 1,
      },

      {
        label: "Missing compensation",
        data: values.map((value) => {
          const excess = value.compensation - value.emissions;
          return excess > 0 ? 0 : Math.abs(excess);
        }),
        backgroundColor: pattern.draw("diagonal-right-left", "#D3D3D3", "white", 7),
        stack: "Stack 1",
        pointStyle: "rect",
        pointRadius: 1,
      },
    ],
  };

  return (
    <Stack maxHeight="280px">
      <Typography color="gray" paddingBottom={2}>
        Compensation gap
      </Typography>

      <Typography fontSize={12} color="gray">
        CO2 (in Mt)
      </Typography>
      {/* @ts-ignore */}
      <Bar data={data} options={getChartOptions(data.datasets[0])} />
    </Stack>
  );
};
