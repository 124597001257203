function Label() {
  this.renderToDataset = this.renderToDataset.bind(this);
}

Label.prototype.setup = function (chart, options) {
  this.chart = chart;
  this.ctx = chart.ctx;
  this.args = {};
  this.barTotal = {};
  const chartOptions = chart.config.options;
  this.options = Object.assign(
    {
      position: "default",
      precision: 0,
      fontSize: chartOptions.font ? chartOptions.font.size : 12,
      fontColor: chartOptions.color || "#333333",
      fontStyle: chartOptions.font ? chartOptions.font.style : "normal",
      fontFamily: chartOptions.font
        ? chartOptions.font.family
        : "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      shadowOffsetX: 3,
      shadowOffsetY: 3,
      shadowColor: "rgba(0,0,0,0.3)",
      shadowBlur: 6,
      images: [],
      outsidePadding: 2,
      textMargin: 2,
      overlap: true,
    },
    options
  );
};

Label.prototype.render = function () {
  this.labelBounds = [];
  this.chart.data.datasets.forEach(this.renderToDataset);
};

Label.prototype.renderToDataset = function (dataset, index) {
  this.totalPercentage = 0;
  this.total = null;
  const arg = this.args[index];
  arg.meta.data.forEach(
    function (element, index) {
      this.renderToElement(dataset, arg, element, index);
    }.bind(this)
  );
};

Label.prototype.loadImage = function ({ src, width, height }) {
  this.cache = this.cache || {};
  if (this.cache[src]) {
    return this.cache[src];
  }
  const image = new Image();
  image.src = src;
  image.width = width;
  image.height = height;
  this.cache[src] = image;
  image.onload = () => this.chart.update();
  return image;
};

Label.prototype.renderToElement = function (dataset, arg, element, index) {
  const imageOptions = this.options.images[index];
  if (!imageOptions || !imageOptions.src) {
    return;
  }
  const label = this.loadImage(imageOptions);
  const position = element.tooltipPosition();
  try {
    this.ctx.drawImage(
      label,
      position.x - label.width / 2,
      position.y - label.height / 2,
      label.width,
      label.height
    );
  } catch (e) {
    console.error("Unable to render image:", label.src, e);
  }
};

export const LabelsPlugin = {
  id: "labels",
  beforeDatasetsUpdate: function (chart, args, options) {
    if (!Array.isArray(options)) {
      options = [options];
    }
    const count = options.length;
    if (!chart._labels || count !== chart._labels.length) {
      chart._labels = options.map(function () {
        return new Label();
      });
    }
    for (let i = 0; i < count; ++i) {
      const label = chart._labels[i];
      label.setup(chart, options[i]);
    }
  },
  afterDatasetUpdate: function (chart, args) {
    chart._labels.forEach(function (label) {
      label.args[args.index] = args;
    });
  },
  afterDraw: function (chart) {
    chart._labels.forEach(function (label) {
      label.render();
    });
  },
};
