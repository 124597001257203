import { stringify } from "qs";
import { useCallback } from "react";
import { useNavigate } from "react-router";

export const useNavigateProjects = () => {
  const navigate = useNavigate();

  return useCallback(
    (params?: Object) =>
      navigate(
        `/projects${stringify(params, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
        })}`
      ),
    [navigate]
  );
};

export const useNavigateProjectMarket = () => {
  const navigate = useNavigate();
  return useCallback(
    (projectId: string, params?: Object) =>
      navigate(
        `/project/${projectId}/market${stringify(params, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
        })}`
      ),
    [navigate]
  );
};

export const useNavigateProjectDetails = () => {
  const navigate = useNavigate();
  return useCallback((projectId: string) => navigate(`/project/${projectId}/details`), [navigate]);
};

export const useNavigatePortfolio = () => {
  const navigate = useNavigate();
  return useCallback(
    (params?: Object) =>
      navigate(`/portfolio${stringify(params, { addQueryPrefix: true, arrayFormat: "repeat" })}`),
    [navigate]
  );
};

export const useNavigateAdmin = () => {
  const navigate = useNavigate();
  return useCallback(() => navigate(`/admin`), [navigate]);
};

export const useNavigateTransactions = () => {
  const navigate = useNavigate();
  return useCallback(() => navigate(`/transactions`), [navigate]);
};

export const useNavigateAccounting = () => {
  const navigate = useNavigate();
  return useCallback(() => navigate(`/accounting`), [navigate]);
};

export const useNavigateAdminEditProject = () => {
  const navigate = useNavigate();
  return useCallback(
    (projectId: string) => navigate(`/admin/project/${projectId}/details?edit`),
    [navigate]
  );
};

export const useNavigateAdminViewProject = () => {
  const navigate = useNavigate();
  return useCallback(
    (projectId: string) => navigate(`/admin/project/${projectId}/details`),
    [navigate]
  );
};

export const useNavigateAdminProjectMarket = () => {
  const navigate = useNavigate();
  return useCallback(
    (projectId: string, params?: Object) =>
      navigate(
        `/admin/project/${projectId}/market${stringify(params, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
        })}`
      ),
    [navigate]
  );
};

export const useNavigateAdminProjects = () => {
  const navigate = useNavigate();
  return useCallback(
    (params?: Object) =>
      navigate(
        `/admin${stringify(params, {
          addQueryPrefix: true,
          arrayFormat: "repeat",
        })}`
      ),
    [navigate]
  );
};
