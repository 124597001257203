import { CalendarMonth, InfoOutlined, Waves } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useAxessAuth } from "auth";
import {
  AccountingCircularChart,
  AccountingEmissionsBars,
  AccountingStackedBarChart,
  AxessPaper,
  Profile,
} from "components";
import { AccountingDTO, getAccounting } from "datahub";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAsyncResponse } from "utils";
import { Filters } from "./components/Filters/Filters";
import { InfoCard } from "./components/InfoCard/InfoCard";

const calculateDifferences = (previous: number, latest: number) => {
  if (!previous && latest) {
    return 100;
  } else if (!previous && !latest) {
    return 0;
  } else {
    return Math.round(100 - (latest * 100) / previous);
  }
};

export const Accounting = () => {
  const { keycloak } = useAxessAuth();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedYears, setSelectedYears] = useState([2019, 2020, 2021, 2022]);
  const [accountingData, setAccountingData] = useState<AccountingDTO[]>([]);
  const [open, setopen] = useState(false);
  const [showDateFilters, setShowDateFilters] = useState(false);
  const [emissionsDetails, setEmissionsDetails] = useState<
    {
      label: string;
      percentage: number;
      value: number;
    }[]
  >([]);

  const fetchAccountingData = useCallback(() => {
    return getAccounting({ years: selectedYears }, t, keycloak.token);
  }, [keycloak.token, selectedYears, t]);

  const onGetAccountingData = useAsyncResponse(fetchAccountingData, false);

  useEffect(() => {
    onGetAccountingData.execute();
  }, []);

  useEffect(() => {
    if (onGetAccountingData.result) {
      setAccountingData(onGetAccountingData.result);
    }
  }, [onGetAccountingData.result]);

  useEffect(() => {
    if (accountingData) {
      const dataToUse = accountingData?.map((element) => ({
        ...element,
        emission: element.emission / 1000000,
        compensation: element.compensation / 1000000,
        scope1: element.scope1 / 1000000,
        scope2: element.scope2 / 1000000,
        scope3: element.scope3 / 1000000,
        emissions: element.emissionDetails,
      }));
      setData(dataToUse);
      const emissionDetailsForLastYear = accountingData.sort((a, b) => a.year - b.year)[
        accountingData.length - 1
      ]?.emissionDetails;
      if (emissionDetailsForLastYear) {
        const totalEmissionsElementsYear = emissionDetailsForLastYear.reduce(
          (acc, curr) => curr.value + acc,
          0
        );
        const emissionsWithPercentage = emissionDetailsForLastYear.map((ed) => ({
          label: ed.label,
          percentage: (ed.value / totalEmissionsElementsYear) * 100,
          value: ed.value,
        }));
        setEmissionsDetails(emissionsWithPercentage);
      }
    }
  }, [accountingData]);

  const infoCards = useMemo(() => {
    const emissions = data.sort((a, b) => a.year - b.year).map((ele) => ele.emission);
    const compensations = data.sort((a, b) => a.year - b.year).map((ele) => ele.compensation);
    const lastEmission = emissions[emissions.length - 1] || 0;
    const prevEmissions = emissions[emissions.length - 2] || 0;
    const lastCompensation = compensations[compensations.length - 1] || 0;
    const prevCompensation = compensations[compensations.length - 2] || 0;
    const differenceEmissions = calculateDifferences(prevEmissions, lastEmission);
    const differenceCompensations = calculateDifferences(prevCompensation, lastCompensation);
    const percentageCover = Math.round((lastCompensation / lastEmission) * 100);
    const prevPercentageCover = Math.round((prevCompensation / prevEmissions) * 100);
    const differenceCover = calculateDifferences(prevPercentageCover, percentageCover);

    return (
      <>
        <Grid item xs={4}>
          <InfoCard
            title={t("accountingPage.totalEmissions")}
            value={`${lastEmission.toFixed(1)} MtCO2`}
            difference={{
              isGrowing: lastEmission > prevEmissions,
              amount: `${differenceEmissions}%`,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InfoCard
            title={t("accountingPage.totalCompensations")}
            value={`${lastCompensation.toFixed(1)} MtCO2`}
            difference={{
              isGrowing: lastCompensation > prevCompensation,
              amount: `${differenceCompensations}%`,
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <InfoCard
            title={t("accountingPage.percentageCoverage")}
            value={`${percentageCover}%`}
            difference={{
              isGrowing: percentageCover > prevPercentageCover,
              amount: `${differenceCover}%`,
            }}
          />
        </Grid>
      </>
    );
  }, [data, t]);

  const emissionsStackedBarChart = useMemo(() => {
    const dataForStackedChart = data.map((element) => ({
      emissions: element.emission,
      compensation: element.compensation,
      year: element.year,
    }));

    return (
      <AxessPaper style={{ height: "405px" }}>
        <AccountingStackedBarChart values={dataForStackedChart} />
      </AxessPaper>
    );
  }, [data]);

  const pieChart = useMemo(() => {
    const dataForLatestYear = data[data.length - 1];
    const valuesForPieAnnualScopes: number[] = [
      dataForLatestYear?.scope1 || 0,
      dataForLatestYear?.scope2 || 0,
      dataForLatestYear?.scope3 || 0,
    ];

    const propsPieScope = {
      values: valuesForPieAnnualScopes,
      total: valuesForPieAnnualScopes?.reduce((acc, curr) => acc + curr, 0),
    };

    return (
      <AxessPaper style={{ height: "405px" }}>
        <AccountingCircularChart
          diameter={270}
          values={propsPieScope.values}
          total={propsPieScope.total}
          title={t("accountingPage.annualScopesReport")}
          showLegend
        />
      </AxessPaper>
    );
  }, [data, t]);

  const onCloseDrawer = useCallback(() => setopen(false), []);

  const onOpenDrawer = useCallback(() => setopen(true), []);

  const emissionsProgressBars = useMemo(() => {
    if (emissionsDetails && emissionsDetails.length > 0) {
      return (
        <AxessPaper>
          <AccountingEmissionsBars emissionsDetails={emissionsDetails} />
        </AxessPaper>
      );
    }
  }, [emissionsDetails]);

  const datesFilterButtonLabel = useMemo(() => {
    return (
      <>
        {selectedYears[0]}-{selectedYears[selectedYears.length - 1]}
      </>
    );
  }, [selectedYears]);

  const handleFiltersSubmit = () => {
    onGetAccountingData.execute();
    setShowDateFilters(false);
  };
  const handleFiltersClose = () => {
    setShowDateFilters(false);
  };
  const handleFiltersClear = () => {
    setSelectedYears([2021]);
  };

  return (
    <>
      <Filters
        closeFilters={handleFiltersClose}
        clearFilters={handleFiltersClear}
        submitFilters={handleFiltersSubmit}
        showFilters={showDateFilters}
        selectedYears={selectedYears}
        onSelectedYearsChange={setSelectedYears}
      />
      <Grid container>
        <Grid item xs={10}>
          <Stack direction="row" alignItems="center" spacing={2} paddingBottom="2rem">
            <Waves />
            <Typography>{t("eastMining")}</Typography>
            <Button
              startIcon={<InfoOutlined />}
              onClick={onOpenDrawer}
              sx={{
                backgroundColor: "white",
                color: "black",
              }}
            >
              {t("accountingPage.profile")}
            </Button>
          </Stack>
          <Profile open={open} onCloseDrawer={onCloseDrawer} />
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Button
            variant="contained"
            startIcon={<CalendarMonth />}
            onClick={() => setShowDateFilters(true)}
          >
            {datesFilterButtonLabel}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {infoCards}
        {/* first charts */}
        <Grid item sm={8}>
          {emissionsStackedBarChart}
        </Grid>
        <Grid item sm={4}>
          {pieChart}
        </Grid>
        <Grid item sm={12}>
          {emissionsProgressBars}
        </Grid>
      </Grid>
    </>
  );
};
