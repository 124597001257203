import { org as orgorderbookApi } from "axess-orderbook-api-domain";
import { org as orgorderbookOver } from "axess-orderbook-overview-domain";
import { org } from "axess-project-api-domain";
import { org as orgUser } from "axess-user-api-domain";

export const OrderBookTypeValues =
  orgorderbookOver.axess.s2.orderbook.domain.model.OrderBookTypeValues;
export const ProjectStatusValues = org.axess.f2.project.api.domain.model.ProjectStatusValues;
export const OrderBookStatusValues =
  orgorderbookOver.axess.s2.orderbook.domain.model.OrderBookStatusValues;
export const OrderStatusValues =
  orgorderbookApi.axess.f2.orderbook.api.domain.model.OrderStatusValues;
export const OrderTypeValues = orgorderbookApi.axess.s2.orderbook.domain.model.OrderTypeValues;
export const RoleValues = orgUser.axess.s2.user.domain.model.RoleValues;

export const allSectors = [1, 2, 3, 4, 5, 6, 7];
