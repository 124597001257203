import { styled, TabProps } from "@mui/material";
import { MenuItems } from "@smartb/g2-components";
import { useAlertHub } from "@smartb/g2-notifications";
import { useAxessAuth } from "auth";
import {
  Accounting,
  AccountingSelected,
  Admin,
  AppLayout,
  Logout,
  Portfolio,
  PortfolioSelected,
  Projects,
  ProjectsSelected,
  Transaction,
  TransactionSelected,
} from "components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Alert } from "store/alert/alert.reducer";
import {
  useNavigateAdmin,
  useNavigatePortfolio,
  useNavigateProjects,
  useNavigateTransactions,
  useNavigateAccounting,
} from "store/router";
import { constants } from "utils";

const mainMenuStyles = {
  width: "23px",
  height: "23px",
  marginRight: "3px",
};

const StyledPortfolio = styled(Portfolio)(mainMenuStyles);
const StyledProjects = styled(Projects)(mainMenuStyles);
const StyledAccounting = styled(Accounting)(mainMenuStyles);
const StyledAdmin = styled(Admin)(mainMenuStyles);

const StyledPortfolioSelected = styled(PortfolioSelected)(mainMenuStyles);
const StyledProjectsSelected = styled(ProjectsSelected)(mainMenuStyles);
const StyledAccountingSelected = styled(AccountingSelected)(mainMenuStyles);

const StyledLogout = styled(Logout)({
  width: "20px",
  height: "20px",
  strokeWidth: "1.3",
  marginRight: "15px",
  color: "white",
});

const transactionStyles = {
  width: "25px",
  height: "25px",
};

const StyledTransaction = styled(Transaction)(transactionStyles);
const StyledTransactionSelected = styled(TransactionSelected)(transactionStyles);

interface AppProps {
  title: string;
  alert: Alert | undefined;
}

type TabPropsWithName = TabProps & { name: string };

export const App = (props: AppProps) => {
  const { title, alert } = props;
  const [selectedTab, setSelectedTab] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const { keycloak, service } = useAxessAuth();
  const user = useMemo(() => service.getUser(), [service.getUser]);

  if (!keycloak.authenticated) {
    keycloak.authenticated = keycloak.idToken != null;
  }

  const { pushAlert } = useAlertHub();

  useEffect(() => {
    alert && pushAlert(alert);
  }, [alert]);

  useEffect(() => {
    if (title !== "AxessImpact") {
      document.title = `${title} - AxessImpact`;
    }
  }, [title]);

  const tabs = useMemo(
    (): TabPropsWithName[] => [
      ...(keycloak.authenticated && (service.is_investor() || service.is_admin())
        ? [
            {
              label: t("portfolio"),
              //@ts-ignore
              component: Link,
              to: "/portfolio",
              icon:
                selectedTab === constants.PORTFOLIO ? (
                  <StyledPortfolioSelected />
                ) : (
                  <StyledPortfolio />
                ),
              iconPosition: "start",
              name: constants.PORTFOLIO,
            },
          ]
        : []),
      ...(keycloak.authenticated && (service.is_polluter() || service.is_admin())
        ? [
            {
              label: t("accounting"),
              component: Link,
              to: "/accounting",
              icon:
                selectedTab === constants.ACCOUNTING ? (
                  <StyledAccountingSelected />
                ) : (
                  <StyledAccounting />
                ),
              iconPosition: "start",
              name: constants.ACCOUNTING,
            },
          ]
        : []),
      {
        label: t("projects"),
        component: Link,
        to: "/projects",
        icon: selectedTab === constants.PROJECTS ? <StyledProjectsSelected /> : <StyledProjects />,
        iconPosition: "start",
        name: constants.PROJECTS,
        style: {
          marginRight: keycloak.authenticated ? "0px" : "10vw ",
        },
      },
      ...(service.is_admin() || service.is_project_developer()
        ? [
            {
              label: t("admin"),
              component: Link,
              to: "/admin",
              icon: <StyledAdmin />,
              iconPosition: "start",
              name: constants.ADMIN,
            },
          ]
        : []),
      ...(service.is_admin()
        ? [
            {
              component: Link,
              to: "/transactions",
              icon:
                selectedTab === constants.TRANSACTIONS ? (
                  <StyledTransactionSelected />
                ) : (
                  <StyledTransaction />
                ),
              iconPosition: "start",
              name: constants.TRANSACTIONS,
              style: {
                marginLeft: "10vw",
                marginRight: 0,
                display: "flex",
                paddingBottom: "15px",
                alignItems: "flex-end",
                minWidth: "unset",
              },
            },
          ]
        : []),
    ],
    [
      t,
      keycloak,
      service.is_admin,
      service.is_project_developer,
      service.is_polluter,
      service.is_investor,
      selectedTab,
    ]
  );

  const handleCurrentTab = useCallback(
    (path: string) => {
      let result;

      switch (path) {
        case "/portfolio":
          result = {
            index: tabs.findIndex((tab) => tab.name === constants.PORTFOLIO),
            value: constants.PORTFOLIO,
          };
          setSelectedTab(result.value);
          break;
        case "/accounting":
          result = {
            index: tabs.findIndex((tab) => tab.name === constants.ACCOUNTING),
            value: constants.ACCOUNTING,
          };
          setSelectedTab(result.value);
          break;
        case "/" || "/projects" || path.match("^/[project]*")[0]:
          result = {
            index: tabs.findIndex((tab) => tab.name === constants.PROJECTS),
            value: constants.PROJECTS,
          };
          setSelectedTab(result.value);
          break;
        case path.match("^/[transactions]*")[0]:
          result = {
            index: tabs.findIndex((tab) => tab.name === constants.TRANSACTIONS),
            value: constants.TRANSACTIONS,
          };
          setSelectedTab(result.value);
          break;
        default:
          result = {
            index: tabs.findIndex((tab) => tab.name === constants.PROJECTS),
            value: constants.PROJECTS,
          };
          setSelectedTab(result.value);
      }

      return result;
    },
    [tabs]
  );

  const currentTabIndex = useMemo(() => {
    return handleCurrentTab(location.pathname);
  }, [location.pathname, keycloak.authenticated, service.is_admin, tabs]);

  const userMenu = useMemo((): MenuItems[] => {
    if (keycloak.authenticated) {
      return [
        {
          key: "privacy",
          label: t("privacy"),
        },
        {
          key: "security",
          label: t("security"),
        },
        {
          key: "logout",
          label: t("logout"),
          icon: <StyledLogout />,
          goto: keycloak.logout,
        },
      ];
    }
    return [
      {
        key: "login",
        label: t("login"),
        goto: keycloak.login,
      },
    ];
  }, [t]);

  const gotoPortfolio = useNavigatePortfolio();
  const gotoAdmin = useNavigateAdmin();
  const gotoProjects = useNavigateProjects();
  const gotoTransactions = useNavigateTransactions();
  const gotoAccounting = useNavigateAccounting();

  const onChangeTab = useCallback(
    (tabIndex: number) => {
      handleChangeTab(tabs[tabIndex].name);
    },
    [service.is_admin, gotoAdmin, gotoPortfolio, gotoProjects, gotoTransactions, tabs]
  );

  const handleChangeTab = (tabName: string) => {
    switch (tabName) {
      case constants.PORTFOLIO:
        gotoPortfolio();
        break;
      case constants.ACCOUNTING:
        gotoAccounting();
        break;
      case constants.PROJECTS:
        gotoProjects();
        break;
      case constants.ADMIN:
        gotoAdmin();
        break;
      case constants.TRANSACTIONS:
        gotoTransactions();
        break;
      default:
        gotoProjects();
    }
  };

  return (
    <AppLayout
      currentUser={user}
      tabs={tabs}
      userMenu={userMenu}
      onChangeTab={onChangeTab}
      currentTabIndex={currentTabIndex.index}
    >
      <Outlet />
    </AppLayout>
  );
};
