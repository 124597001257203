import { Stack } from "@mui/material";
import { OrganizationDTO } from "datahub";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fileToBase64 } from "utils";

import { AxessButton } from "../Button/AxessButton";
import { AxessPaper } from "../Paper";
import { Organization } from "./Organization";

export interface OrganizationPaperProps {
  defaultStakeHolder?: OrganizationDTO;
  onValidate: (stakeholder: OrganizationDTO) => void;
  onDelete: () => void;
  edit?: boolean;
}

export const OrganizationPaper = (props: OrganizationPaperProps) => {
  const { defaultStakeHolder, onValidate, onDelete, edit = false } = props;
  const [stakeHolder, setstakeHolder] = useState<OrganizationDTO>(
    defaultStakeHolder ?? {
      name: "",
      title: "",
      contact: {
        email: "",
        phone: "",
        website: "",
        address: "",
      },
      logo: "",
    }
  );

  useEffect(() => {
    if (defaultStakeHolder) {
      setstakeHolder(defaultStakeHolder);
    }
  }, [defaultStakeHolder]);

  const [changed, setchanged] = useState(false);

  const { t } = useTranslation();

  const onValidateMemoized = useCallback(() => {
    onValidate && onValidate(stakeHolder);
    setchanged(false);
  }, [stakeHolder]);

  const onChangeAdress = useCallback((address: string) => {
    setstakeHolder((stakeHolder) => ({
      ...stakeHolder,
      contact: { ...stakeHolder.contact, address },
    }));
    setchanged(true);
  }, []);

  const onChangeEmail = useCallback((email: string) => {
    setstakeHolder((stakeHolder) => ({
      ...stakeHolder,
      contact: { ...stakeHolder.contact, email },
    }));
    setchanged(true);
  }, []);

  const onChangePhone = useCallback((phone: string) => {
    setstakeHolder((stakeHolder) => ({
      ...stakeHolder,
      contact: { ...stakeHolder.contact, phone },
    }));
    setchanged(true);
  }, []);

  const onChangeWebsite = useCallback((website: string) => {
    setstakeHolder((stakeHolder) => ({
      ...stakeHolder,
      contact: { ...stakeHolder.contact, website },
    }));
    setchanged(true);
  }, []);

  const onChangeName = useCallback((name: string) => {
    setstakeHolder((stakeHolder) => ({ ...stakeHolder, name }));
    setchanged(true);
  }, []);

  const onChangeTitle = useCallback((title: string) => {
    setstakeHolder((stakeHolder) => ({ ...stakeHolder, title }));
    setchanged(true);
  }, []);

  const onChangeLogo = useCallback(async (logo: File) => {
    const logo64 = await fileToBase64(logo);
    setstakeHolder((stakeHolder) => ({ ...stakeHolder, logo: logo64 }));
    setchanged(true);
  }, []);

  return (
    <AxessPaper>
      <Organization
        edit={edit}
        organization={defaultStakeHolder}
        onChangeAddress={onChangeAdress}
        onChangeEmail={onChangeEmail}
        onChangeName={onChangeName}
        onChangePhone={onChangePhone}
        onChangeTitle={onChangeTitle}
        onChangeWebsite={onChangeWebsite}
        onChangeLogo={onChangeLogo}
      />
      <Stack
        direction="row"
        sx={{
          justifyContent: "flex-end",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        {edit && (
          <>
            <AxessButton variant="text" onClick={onDelete}>
              {t("delete")}
            </AxessButton>
            {changed && <AxessButton onClick={onValidateMemoized}>{t("validate")}</AxessButton>}
          </>
        )}
      </Stack>
    </AxessPaper>
  );
};
