import { Stack, Typography } from "@mui/material";
import { AxessPaper, countryObject, PortfolioStatsTable, SdgCard } from "components";
import {
  MeasureUnitDTO,
  PortfolioStatsDTO,
  ProjectRefDTO,
  ProjectType,
  UserProjectRightDTO,
  //UserAccessEnum,
} from "datahub";
import { useCallback, useMemo } from "react";
import { CellProps } from "@smartb/g2-layout";
import { useTranslation } from "react-i18next";
//import useUserAccess from "utils/src/hooks/useUserAccess";

export interface ProtocolTableProps {
  projectRefs: Map<string, ProjectRefDTO>;
  measures: Map<string, MeasureUnitDTO>;
  userRights: Map<string, UserProjectRightDTO>;
  stats: PortfolioStatsDTO[];
  unitId: string;
  onOpenAction: (action: "sell" | "buy" | "burn", orderBookId: string, volume: number) => void;
  isMarketplace?: boolean;
}

export const ProtocolTable = (props: ProtocolTableProps) => {
  const { projectRefs, measures, stats, unitId, onOpenAction, isMarketplace } = props;

  //const accessAndProjects = useUserAccess(userRights, Array.from(projectRefs.keys()));
  const { t } = useTranslation();

  const situationnalColumns = useMemo(
    () => [
      {
        Header: t("project"),
        id: "project",
        Cell: ({ row }: CellProps<PortfolioStatsDTO>) => {
          const project = projectRefs.get(row.original.projectId);
          return (
            <Stack spacing={0}>
              <Typography variant="body2">{project?.details?.name}</Typography>

              {isMarketplace && project?.projectType === ProjectType.CHILD ? (
                <>
                  <Typography variant="body2" sx={{ color: "text.tableSecondary" }}>
                    {countryObject[project?.country]?.name}
                  </Typography>
                  <SdgCard aligned defaultSdgs={project?.sdgList} />
                </>
              ) : (
                <Typography variant="body2" sx={{ color: "text.tableSecondary" }}>
                  ID {project?.id}
                </Typography>
              )}
            </Stack>
          );
        },
      },
      {
        Header: t("Sectors"),
        id: "sectors",
        Cell: ({ row }: CellProps<PortfolioStatsDTO>) => {
          return (
            <Typography variant="body2" sx={{ color: "text.tableSecondary" }}>
              {t(`sectors.${row.original.sector?.id}`)}
            </Typography>
          );
        },
        className: "sectorRow",
      },
    ],
    [projectRefs, t]
  );

  const unit = useMemo(() => {
    return measures.get(unitId);
  }, [measures, unitId]);

  const onBurn = useCallback(
    (stat: PortfolioStatsDTO) => {
      onOpenAction("burn", stat.orderbookId, stat.volume);
    },
    [onOpenAction]
  );

  const onBuy = useCallback(
    (stat: PortfolioStatsDTO) => {
      onOpenAction("buy", stat.orderbookId, stat.volume);
    },
    [onOpenAction]
  );

  const onSell = useCallback(
    (stat: PortfolioStatsDTO) => {
      onOpenAction("sell", stat.orderbookId, stat.volume);
    },
    [onOpenAction]
  );

  return (
    <AxessPaper variant="big">
      <Stack
        spacing={3}
        sx={{
          "& .sectorRow": {
            maxWidth: "150px",
          },
        }}
      >
        <Typography variant="h5">{unit?.name}</Typography>
        <PortfolioStatsTable
          onBurn={onBurn}
          onBuy={onBuy}
          onSell={onSell}
          stats={stats}
          //@ts-ignore
          situationnalColumns={situationnalColumns}
          measures={measures}
          isMarketplace
          userCanChange={false} //use stats to check if projectId is in accessAndProjects.userAccessMap.get(projectId) === UserAccessEnum.WRITE_AND_READ
          // userCanChange={
          //   accessAndProjects.userAccessMap.get(projectId) === UserAccessEnum.WRITE_AND_READ
          // }
        />
      </Stack>
    </AxessPaper>
  );
};
