import {
  MeasureUnitDTO,
  OrderBookOverviewDTO,
  ProjectRefDTO,
  ProtocolTypeDTO,
  UserProjectRightDTO,
  UserRefDTO,
  UserProfileDTO,
} from "datahub";
import { createReducer } from "deox";
import { actions } from "./refs.actions";

export interface RefsState {
  projectRefs: Map<string, ProjectRefDTO>;
  measures: Map<string, MeasureUnitDTO>;
  userRefs: Map<string, UserRefDTO>;
  userProjectRights: Map<string, UserProjectRightDTO>;
  userProfile: UserProfileDTO;
  orderBookOverviews: Map<string, OrderBookOverviewDTO>;
  powerBiToken: String;
  protocolTypes: Map<string, ProtocolTypeDTO>;
}

export const initialState: RefsState = {
  projectRefs: new Map(),
  measures: new Map(),
  userRefs: new Map(),
  userProjectRights: new Map(),
  userProfile: undefined,
  orderBookOverviews: new Map(),
  powerBiToken: undefined,
  protocolTypes: new Map(),
};

export type RefsStateTransformer = (state: RefsState) => RefsState;

const setProjectRefs = (projectRefs: ProjectRefDTO[], state: RefsState): RefsStateTransformer => {
  const map = new Map(projectRefs.map((projectRef) => [projectRef.id, projectRef]));
  return () => ({
    ...state,
    projectRefs: map,
  });
};

const setMeasure =
  (measures: MeasureUnitDTO[], state: RefsState): RefsStateTransformer =>
  () => {
    const map = new Map(measures.map((measure) => [measure.id, measure]));
    return {
      ...state,
      measures: map,
    };
  };

const setProtocolTypes =
  (protocolTypes: ProtocolTypeDTO[], state: RefsState): RefsStateTransformer =>
  () => {
    const map = new Map(protocolTypes.map((protocolType) => [protocolType.id, protocolType]));
    return {
      ...state,
      protocolTypes: map,
    };
  };

const setUserRefs =
  (userRefs: UserRefDTO[], state: RefsState): RefsStateTransformer =>
  () => {
    const map = new Map(userRefs.map((userRef) => [userRef.id, userRef]));
    return {
      ...state,
      userRefs: map,
    };
  };

const setUserProjectRights =
  (userProjectRights: UserProjectRightDTO[], state: RefsState): RefsStateTransformer =>
  () => {
    const map = new Map(
      userProjectRights.map((userProjectRight, index) => [index.toString(), userProjectRight])
    );
    return {
      ...state,
      userProjectRights: map,
    };
  };

const setUserProfile =
  (userProfile: UserProfileDTO, state: RefsState): RefsStateTransformer =>
  () => {
    return {
      ...state,
      userProfile: userProfile,
    };
  };

const setOrderBookOverviews =
  (orderBookOverviews: OrderBookOverviewDTO[], state: RefsState): RefsStateTransformer =>
  () => {
    const map = new Map(
      orderBookOverviews.map((orderBookOverview) => [orderBookOverview.id, orderBookOverview])
    );
    return {
      ...state,
      orderBookOverviews: map,
    };
  };

const setPowerBiToken =
  (powerBiToken: String, state: RefsState): RefsStateTransformer =>
  () => {
    return {
      ...state,
      powerBiToken: powerBiToken,
    };
  };

export const refsReducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.setMeasures, (state: RefsState, action) =>
    setMeasure(action.payload.measures, state)(state)
  ),
  handleAction(actions.setProtocolTypes, (state: RefsState, action) =>
    setProtocolTypes(action.payload.protocolTypes, state)(state)
  ),
  handleAction(actions.setProjectRefs, (state: RefsState, action) =>
    setProjectRefs(action.payload.projectRefs, state)(state)
  ),
  handleAction(actions.setUserRefs, (state: RefsState, action) =>
    setUserRefs(action.payload.userRefs, state)(state)
  ),
  handleAction(actions.setUserProjectRights, (state: RefsState, action) =>
    setUserProjectRights(action.payload.userProjectRights, state)(state)
  ),
  handleAction(actions.setUserProfile, (state: RefsState, action) =>
    setUserProfile(action.payload.userProfile, state)(state)
  ),
  handleAction(actions.setOrderBookOverviews, (state: RefsState, action) =>
    setOrderBookOverviews(action.payload.orderBookOverviews, state)(state)
  ),
  handleAction(actions.setPowerBiToken, (state: RefsState, action) =>
    setPowerBiToken(action.payload.powerBiToken, state)(state)
  ),
]);
