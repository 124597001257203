import { connect } from "react-redux";
import { refs } from "store/refs";
import { State } from "store";

const mapStateToProps = (state: State) => ({
  userProfile: refs.selectors.userProfile(state),
});

const mapDispatchToProps = {
  setUserProfile: refs.actions.setUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps);
