import { Stack, Typography } from "@mui/material";
import { AxessChip } from "../Chip/AxessChip";

export interface DataTicketProps {
  data?: string | number | React.ReactNode;
  label?: string;
  className?: string;
  increaseLabel?: string;
  decreaseLabel?: string;
  minified?: boolean;
}

export const DataTicket = (props: DataTicketProps) => {
  const { data, label, className, increaseLabel, decreaseLabel, minified = false } = props;

  return (
    <Stack spacing={!minified ? 2 : 1} className={className}>
      {label && (
        <Typography sx={{ lineHeight: "1", color: "text.secondary" }} variant={"body2"}>
          {label}
        </Typography>
      )}
      {data && (
        <Stack direction="row" alignItems="center" spacing={2}>
          {typeof data === "string" || typeof data === "number" ? (
            <Typography
              variant={!minified ? "h5" : "body2"}
              sx={{ fontWeight: !minified ? 500 : 400 }}
            >
              {data}
            </Typography>
          ) : (
            data
          )}
          {(increaseLabel || decreaseLabel) && (
            <AxessChip increaseLabel={increaseLabel} decreaseLabel={decreaseLabel} />
          )}
        </Stack>
      )}
    </Stack>
  );
};
