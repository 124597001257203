import { styled, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrderBookTypeValues } from "../types";

const StyledChip = styled(Chip)({
  borderRadius: "6px",
  height: "unset",
  padding: "3px 7px",
  textTransform: "uppercase",
  fontsize: "1rem",
  "& .MuiChip-label": {
    padding: "unset",
  },
});

export const colors: { [type: string]: string } = {
  [OrderBookTypeValues.exante()]: "#E7F1F4",
  [OrderBookTypeValues.expost()]: "#B7FF0846",
};

export interface OrderBookTypeProps {
  type: string;
}

export const OrderBookType = (props: OrderBookTypeProps) => {
  const { type } = props;
  const { t } = useTranslation();
  return <StyledChip label={t(type)} sx={{ background: colors[type], color: "black" }} />;
};
