import { ToggleButtons, ToggleButtonsProps } from "@smartb/g2-components";
import { styled } from "@mui/material";

const AxessToggleButtonsBase = (props: ToggleButtonsProps) => {
  return <ToggleButtons {...props} />;
};

export const AxessToggleButtons = styled(AxessToggleButtonsBase)({
  "& .MuiToggleButtonGroup-grouped": {
    margin: "10px",
    border: 0,
    padding: "7px 12px",
    backgroundColor: "#F3F7F8",
    minWidth: "70px",
    "&.Mui-disabled": {
      border: 0,
    },
    borderRadius: "7px !important",
  },
});
