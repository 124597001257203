import { org as orgorderbookApi } from "axess-orderbook-api-domain";
import { org as orgorderbookOver } from "axess-orderbook-overview-domain";
import { org as orgPorfolio } from "axess-portfolio-domain";
import { org as orgproject } from "axess-project-api-domain";
import { org as orgprotocolapi } from "axess-protocol-api-domain";
import { org as orgprotocol } from "axess-protocol-domain";
import { org as orgUserApi } from "axess-user-api-domain";

export interface OrderBookOverviewDTO
  extends orgorderbookOver.axess.f2.orderbook.overview.domain.model.OrderBookOverviewDTO {}
export const OrderBookTypeValues =
  orgorderbookOver.axess.s2.orderbook.domain.model.OrderBookTypeValues;
export const OrderBookStatusValues =
  orgorderbookOver.axess.s2.orderbook.domain.model.OrderBookStatusValues;

export interface OrderDTO extends orgorderbookApi.axess.f2.orderbook.api.domain.model.OrderDTO {}
export const OrderStatusValues =
  orgorderbookApi.axess.f2.orderbook.api.domain.model.OrderStatusValues;
export const OrderTypeValues = orgorderbookApi.axess.s2.orderbook.domain.model.OrderTypeValues;

export enum ProjectType {
  PARENT = 'PARENT',
  CHILD = 'CHILD',
  STANDALONE = 'STANDALONE'
}
export interface LinkedProjectDTO extends orgproject.axess.s2.project.domain.model.LinkedProjectDTO {}
export interface ProjectDTO extends orgproject.axess.f2.project.api.domain.model.ProjectDTO {}
export interface ProjectRefDTO extends orgproject.axess.f2.project.api.domain.model.ProjectRefDTO {}
export const ProjectStatusValues = orgproject.axess.f2.project.api.domain.model.ProjectStatusValues;
export interface OrganizationDTO extends orgproject.axess.s2.project.domain.model.OrganizationDTO {}
export interface SdgDTO extends orgprotocol.axess.s2.protocol.domain.model.SdgDTO {}

export interface SectorDTO extends orgproject.axess.s2.project.domain.model.SectorDTO {}

export interface ProtocolDTO
  extends orgprotocolapi.axess.f2.protocol.api.domain.model.ProtocolDTO {}
export interface ProtocolDetailsDTO
  extends orgprotocolapi.axess.s2.protocol.domain.model.ProtocolDetailsDTO {}
export interface MeasureUnitDTO
  extends orgprotocol.axess.s2.measure.unit.domain.model.MeasureUnitDTO {}

export interface ProtocolTypeDTO
  extends orgproject.axess.f2.protocol.type.api.domain.model.ProtocolTypeDTO {}

export interface PortfolioStatsDTO
  extends orgPorfolio.axess.f2.portfolio.domain.model.PortfolioStatsDTO {}

export interface UserRefDTO extends orgUserApi.axess.f2.user.api.domain.model.UserRefDTO {}

export interface UserProjectRightDTO extends orgUserApi.axess.f2.user.api.domain.model.UserProjectRightDTO {}

export interface UserProfileDTO extends orgUserApi.axess.f2.user.api.domain.model.UserProfileDTO {}

export enum UserAccessEnum {
  NO_ACCESS = 0,
  READ = 1,
  WRITE_AND_READ = 2,
}

export const allSectors = [1, 2, 3, 4, 5, 6, 7];

export interface TimeDataDTO extends orgPorfolio.axess.commons.model.TimeDataDTO<number> {}
