import { Transaction } from "./Transaction";
import { OrderDTO } from "datahub";

export interface ValidatedTransactionProps {
  order: OrderDTO;
}

export const ValidatedTransaction = (props: ValidatedTransactionProps) => {
  const { order } = props;
  return <Transaction withPrice order={order} />;
};
