import React, { useCallback, useMemo } from "react";
import { PopUp, PopUpAction } from "@smartb/g2-layout";
import { MeasureUnitDTO } from "datahub";
import { useTranslation } from "react-i18next";
import { Form, FormField, FormPartialField, useFormWithPartialFields } from "@smartb/g2-forms";
import { Stack, Typography } from "@mui/material";
import { formatNumber } from "utils";
import { makeG2STyles } from "@smartb/g2-themes";
import { DataLabeled } from "../DataLabeled";
import { differenceInDays } from "date-fns";

export const useStyles = makeG2STyles()({
  popup: {
    "& .MuiPaper-root": {
      background: "#F3F7F8",
    },
  },
  button: {
    borderRadius: "6px",
    minWidth: "unset",
    padding: "8px 18px",
    fontSize: "1rem",
    fontWeight: 500,
  },
  close: {
    color: "#8C999D",
  },
});

export type TransactionCreationValues = {
  volume: number;
  price: number;
  validity: Date;
};

export interface TransactionCreationPopUpProps {
  unit: MeasureUnitDTO;
  price: number;
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onValidate: (values: TransactionCreationValues) => void;
  actionLabel: string;
  maxVolume: number;
  onlyVolume?: boolean;
}

export const TransactionCreationPopUp = (props: TransactionCreationPopUpProps) => {
  const {
    onClose,
    open,
    price,
    unit,
    children,
    actionLabel,
    onValidate,
    maxVolume,
    onlyVolume = false,
  } = props;
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();

  const onValidateMemoized = useCallback(
    (values: TransactionCreationValues, formikHelpers: any) => {
      onValidate(values);
      formikHelpers.resetForm();
    },
    [onValidate]
  );

  const partialFiels = useMemo(
    (): FormPartialField[] => [
      {
        name: "volume",
        validator: (value?: number | string) => {
          if (!value || value === "") return t("formError.theQuantityIsRequired") as string;
          const number = Number(value);
          if (number <= 0) return t("formError.theQuantityHasToBePositive") as string;
          if (number > maxVolume) return t("formError.quantityAboveMax") as string;
          return undefined;
        },
      },
      {
        name: "price",
        defaultValue: price,
        validator: (value?: number | string) => {
          if (!value || value === "") return t("formError.thePriceIsRequired") as string;
          const number = Number(value);
          if (number <= 0) return t("formError.thePriceHasToBePositive") as string;
          return undefined;
        },
      },
      {
        name: "validity",
        validator: (value?: Date) => {
          if (onlyVolume) return undefined;
          if (!value) return t("formError.theValidityDateIsRequired") as string;
          const number = value.getTime();
          if (number < Date.now()) return t("formError.theValidityDateIsBeforeNow") as string;
          return undefined;
        },
      },
    ],
    [price, t, maxVolume, onlyVolume]
  );

  const formState = useFormWithPartialFields({
    fields: partialFiels,
    //@ts-ignore
    onSubmit: onValidateMemoized,
    formikConfig: {
      enableReinitialize: true,
    },
  });

  const fields = useMemo(
    (): FormField[] => [
      {
        key: "TransactionCreationPopUp-form-volume",
        name: "volume",
        type: "textfield",
        label: t("quantity") + " :",
        textFieldProps: {
          textFieldType: "number",
          inputProps: {
            min: 0,
          },
          className: "transactionField",
          createInputContainer: (input) => (
            <>
              {input}
              <Typography className="strongText" sx={{ display: "inline", fontWeight: 600 }}>
                {unit.notation}
              </Typography>
              <Typography sx={{ display: "inline", color: "text.modalSecondary" }}>
                {" "}
                /<strong>{formatNumber(maxVolume, i18n.language)}</strong>
                {" " + t("available")}
              </Typography>
            </>
          ),
        },
      },
      ...(!onlyVolume
        ? [
            {
              key: "TransactionCreationPopUp-form-price",
              name: "price",
              type: "textfield",
              label: t("price") + " :",
              textFieldProps: {
                textFieldType: "number",
                inputProps: {
                  min: 0,
                  step: "0.01",
                },
                className: "transactionField",
                createInputContainer: (input) => (
                  <>
                    {input}
                    <Typography className="strongText" sx={{ display: "inline", fontWeight: 600 }}>
                      $
                    </Typography>
                    <Typography sx={{ display: "inline", color: "text.modalSecondary" }}>
                      {" /" + t("referentPrices") + " : "}
                      <strong>{formatNumber(price, i18n.language, 2, true)}</strong>
                    </Typography>
                  </>
                ),
              },
            } as FormField,
            {
              key: "TransactionCreationPopUp-form-validity",
              name: "validity",
              type: "datepicker",
              label: t("validity") + " :",
              datePickerProps: {
                className: "transactionField validityDate",
                createInputContainer: (input) => (
                  <>
                    {input}
                    {new Date(formState.values.validity).getTime() > 0 && (
                      <Typography
                        sx={{
                          display: "inline",
                          color: "text.modalSecondary",
                          marginLeft: "10px",
                        }}
                      >
                        {" "}
                        (
                        {formatNumber(
                          differenceInDays(formState.values.validity, Date.now()),
                          i18n.language
                        )}
                        {" " + t("days")})
                      </Typography>
                    )}
                  </>
                ),
              },
            } as FormField,
          ]
        : []),
    ],
    [unit, price, t, onlyVolume, maxVolume, i18n.language, formState.values]
  );

  const total = useMemo(() => {
    if (!onlyVolume && formState.values.volume && formState.values.price) {
      return formatNumber(formState.values.volume * formState.values.price, i18n.language, 2, true);
    }
    return "0";
  }, [formState.values, i18n.language, onlyVolume]);

  const onCloseMemoized = useCallback(() => {
    onClose();
    formState.resetForm();
  }, [onClose, formState.resetForm]);

  const actions = useMemo(
    (): PopUpAction[] => [
      {
        key: "TransactionCreationPopUp - cancel",
        label: t("cancel"),
        onClick: onCloseMemoized,
        variant: "text",
        className: cx(classes.button, classes.close),
      },
      {
        key: "TransactionCreationPopUp - accept",
        label: actionLabel,
        onClick: formState.submitForm,
        className: classes.button,
      },
    ],
    [actionLabel, formState.submitForm, onCloseMemoized, classes.close, classes.button]
  );

  return (
    <PopUp open={open} onClose={onCloseMemoized} actions={actions} className={classes.popup}>
      {children}
      <Stack
        sx={{
          "& .MuiFormHelperText-root": {
            whiteSpace: "nowrap",
          },
          "& .transactionField": {
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            "& .MuiInputLabel-root": {
              margin: "0px",
              width: "100px",
            },
          },
          "& .MuiFilledInput-root": {
            width: "140px !important",
          },
          "& .strongText": {
            margin: "0 10px",
          },
          "& .validityDate .MuiInputBase-input": {
            paddingRight: "0px",
          },
          "& .MuiButton-root": {
            borderRadius: "6px",
            minWidth: "unset",
            padding: "8px 18px",
          },
        }}
      >
        <Form
          className="TransactionCreationPopUp-form"
          fieldsStackProps={{ sx: { gap: "10px" } }}
          formState={formState}
          fields={fields}
        />
        {!onlyVolume && (
          <DataLabeled sx={{ marginTop: "20px" }} label={t("total") + ":"} data={total} />
        )}
      </Stack>
    </PopUp>
  );
};
